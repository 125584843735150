import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from 'react-dnd';
import { FieldInputProps, useField } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { Basket, ResizeArrowsIcon } from '@lms-elements/icons';
import { throttle } from 'lodash';

import { IImageValues, ImageData, MAX_IMAGE_WIDTH, MIN_IMAGE_WIDTH } from '../ExpandedImageMaterial.types';

import { IDragItem, IImageItemProps, MATERIALS_IMAGE_ITEM } from './ImageItem.types';

import './ImageItem.scss';

const ImageItemCn = cn('expanded-image-material-image-item');

export const ImageItem: React.FC<IImageItemProps> = ({ name, index, showForStudents, onDeleteImage }) => {
    const { input } = useField<IImageValues>(name);

    const data = useMemo(() => input.value.imageData.data[index], [input.value, index]);
    const src = useMemo(() => {
        if (typeof data.data === 'string') {
            return data.data;
        }

        try {
            const src = URL.createObjectURL(data.data);
            return src;
        } catch {
            return '';
        }
    }, [data]);

    const handleDelete = useCallback(() => {
        const id = input.value.imageData.data[index].id;
        if (id && onDeleteImage) {
            onDeleteImage(id);
        }

        const [...images] = input.value.imageData.data;
        images.splice(index, 1);

        input.onChange({
            ...input.value,
            imageData: {
                ...input.value.imageData,
                data: images,
            },
        });
    }, [input, index, onDeleteImage]);

    const [isResizing, setIsResizing] = useState(false);
    const [localWidth, setLocalWidth] = useState(data.width);
    const [startResizeEvent, setStartResizeEvent] =
        useState<{ clientX: number; startWidth: number } | undefined>(undefined);

    const toggleResizing = useCallback(() => setIsResizing((prev) => !prev), []);

    useEffect(() => {
        setLocalWidth(data.width);
    }, [data.width]);

    const resizeRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const onMouseDown = (e: MouseEvent) => {
            if (resizeRef.current && resizeRef.current.contains(e.target as Node)) {
                setStartResizeEvent({ clientX: e.clientX, startWidth: localWidth });
                toggleResizing();
            }
        };
        const onMouseMove = throttle((e: MouseEvent) => {
            if (startResizeEvent && isResizing) {
                setLocalWidth(() =>
                    Math.max(
                        MIN_IMAGE_WIDTH,
                        Math.min(startResizeEvent.startWidth + e.clientX - startResizeEvent.clientX, MAX_IMAGE_WIDTH),
                    ),
                );
            }
        }, 100);
        const onMouseUp = () => {
            if (isResizing) {
                setStartResizeEvent(undefined);
                toggleResizing();

                const [...images] = input.value.imageData.data;
                images[index].width = localWidth;
                input.onChange({
                    ...input.value,
                    imageData: {
                        ...input.value.imageData,
                        data: images,
                    },
                });
            }
        };
        window.addEventListener('mousedown', onMouseDown);
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
        return () => {
            window.removeEventListener('mousedown', onMouseDown);
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        };
    }, [index, input, isResizing, localWidth, startResizeEvent, toggleResizing]);

    const styles: CSSProperties = useMemo(
        () => ({ width: isResizing ? localWidth : data.width }),
        [data.width, isResizing, localWidth],
    );

    const moveFieldsHandler = useRef(
        throttle((input: FieldInputProps<IImageValues, HTMLElement>, dragIndex: number, hoverIndex: number) => {
            const [...images] = input.value.imageData.data;
            let newImagesData: ImageData[];

            if (dragIndex > hoverIndex) {
                newImagesData = images.reduce((newData, image, index, images) => {
                    if (index === hoverIndex) {
                        newData.push(images[dragIndex]);
                    }

                    if (index === dragIndex) {
                        return newData;
                    }

                    newData.push(image);

                    return newData;
                }, [] as ImageData[]);
            } else {
                newImagesData = images
                    .reduceRight((newData, image, index, images) => {
                        if (index === hoverIndex) {
                            newData.push(images[dragIndex]);
                        }

                        if (index === dragIndex) {
                            return newData;
                        }

                        newData.push(image);

                        return newData;
                    }, [] as ImageData[])
                    .reverse();
            }

            input.onChange({
                ...input.value,
                imageData: {
                    ...input.value.imageData,
                    data: newImagesData,
                },
            });
        }, 100),
    );

    const ref = useRef<HTMLDivElement>(null);
    const [{ handlerId }, drop] = useDrop(
        () => ({
            accept: MATERIALS_IMAGE_ITEM,
            collect: (monitor) => ({
                handlerId: monitor.getHandlerId(),
            }),
            hover: (item: IDragItem, monitor: DropTargetMonitor) => {
                if (!ref.current) {
                    return;
                }
                const dragIndex = item.index;
                const hoverIndex = index;
                if (dragIndex === hoverIndex) {
                    return;
                }
                const hoverBoundingRect = ref.current?.getBoundingClientRect();
                const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
                const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
                const clientOffset = monitor.getClientOffset();
                const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;
                const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
                if (
                    (dragIndex < hoverIndex && hoverClientY < hoverMiddleY && hoverClientX < hoverMiddleX) ||
                    (dragIndex > hoverIndex && hoverClientY > hoverMiddleY && hoverClientX > hoverMiddleX)
                ) {
                    return;
                }
                moveFieldsHandler.current(input, dragIndex, hoverIndex);
                item.index = hoverIndex;
            },
            canDrop: () => !showForStudents,
        }),
        [input],
    );

    const [{ isDragging }, drag] = useDrag({
        type: MATERIALS_IMAGE_ITEM,
        item: () => ({ index }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: () => !isResizing && !showForStudents,
    });

    drag(drop(ref));

    return (
        <div className={ImageItemCn({ dragging: isDragging })} style={styles} ref={ref} data-handler-id={handlerId}>
            {!showForStudents && (
                <button ref={resizeRef} type="button" className={ImageItemCn('resize', { active: isResizing })}>
                    <ResizeArrowsIcon />
                </button>
            )}
            <img src={src} style={styles} />
            {!showForStudents && (
                <button type="button" className={ImageItemCn('delete', { active: isResizing })} onClick={handleDelete}>
                    <Basket />
                </button>
            )}
        </div>
    );
};
