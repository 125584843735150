import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgVideoCameraIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={28}
            height={28}
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M2.625 6.563h14a3.5 3.5 0 013.5 3.5v10.5a.875.875 0 01-.875.875h-14a3.5 3.5 0 01-3.5-3.5v-10.5a.875.875 0 01.875-.875v0zM20.125 12.25l6.125-3.5v10.5l-6.125-3.5"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgVideoCameraIcon = React.memo(SvgVideoCameraIcon);
export default MemoSvgVideoCameraIcon;
