import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgPassed({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={17}
            height={20}
            viewBox="0 0 17 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M12.913 3.294h3.25v15.707H1V3.294h3.249"
                stroke="#2F929A"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                clipRule="evenodd"
                d="M10.365 2.835c0-1.013-.8-1.835-1.784-1.835s-1.784.822-1.784 1.835H5.014v2.293h7.135V2.835h-1.784z"
                stroke="#2F929A"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.459 11.092l1.784 1.835 4.013-4.128"
                stroke="#2F929A"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgPassed = React.memo(SvgPassed);
export default MemoSvgPassed;
