import React, { useCallback } from 'react';
import { cn } from '@bem-react/classname';

import { ResizeButton } from '../../ResizeButton';

import { IVerticalResizeButtonGroupProps } from './VerticalResizeButtonGroup.types';

import './VerticalResizeButtonGroup.scss';

const CnResizeButtonGroup = cn('vertical-resize-button-group');

export const VerticalResizeButtonGroup = React.memo<IVerticalResizeButtonGroupProps>(
    ({ widths, tableName, setIsResizing, setNeedToFocus, onClickAddColumns, handleTableResize }) => {
        const getResizeButtonStyle = useCallback(
            (col: number): React.CSSProperties => ({
                left: widths.slice(0, col).reduce((sum, width) => sum + width + 2, 0),
            }),
            [widths],
        );

        return (
            <div className={CnResizeButtonGroup()}>
                {Array.from({ length: widths.length + 1 }).map((_, x) => (
                    <div
                        style={getResizeButtonStyle(x)}
                        key={`ResizeButton${x}`}
                        className={CnResizeButtonGroup('button')}
                    >
                        <ResizeButton
                            isVertical
                            tableName={tableName}
                            index={x - 1}
                            newColumn={x}
                            onPlusClick={onClickAddColumns}
                            handleChangeWidth={x != 0 ? handleTableResize : undefined}
                            width={x != 0 ? widths[x - 1] : undefined}
                            setIsResizing={setIsResizing}
                            setNeedToFocus={setNeedToFocus}
                        />
                    </div>
                ))}
            </div>
        );
    },
);

VerticalResizeButtonGroup.displayName = 'VerticalResizeButtonGroup';
