export interface IExpandedImageMaterialProps {
    /**
     * function that will delete field from final-form array
     */
    onDelete?: () => void;
    /**
     * function that will upload image to server
     */
    onUploadImage?: (file: File) => void;
    /**
     * function that will delete image
     */
    onDeleteImage?: (id: number) => void;
    /**
     * final-form name of array with image sctructures of type
     */
    name: string;
    /**
     * if true activates student's view === readonly
     */
    showForStudents?: boolean;
}

export type ImageData = {
    /**
     * exact file or link to an image. Link may be broken
     */
    data: File | string;
    /**
     * displaying width of an image
     */
    width: number;
    /**
     * id of an image
     */
    id?: number;
};

export interface IDropItem {
    files: FileList;
    items: DataTransferItemList;
}

export interface IImageValues {
    imageData: {
        data: ImageData[];
        description: string;
    };
}

export const MIN_IMAGE_WIDTH = 168;

export const MAX_IMAGE_WIDTH = 732;

export const supportedMimeTypes = ['.jpg', '.jpeg', '.bmp', '.png'];
