import moment from 'moment';

import { capitalizeWord } from './';

export const getCapitalizeEndOfWeek = (date: Date | string, weekWithLetterMonth: boolean): string => {
    const endOfWeek = moment(date)
        .endOf('week')
        .format(weekWithLetterMonth ? 'D MMMM YYYY' : 'D.MM');

    return capitalizeWord(endOfWeek, 2);
};
