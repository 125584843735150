import { useCallback, useEffect, useState } from 'react';

export const useWindowResize = (): {
    isTablet: boolean;
    isMobile: boolean;
} => {
    const [isTablet, setIsTablet] = useState(window.innerWidth <= 1024);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const handleWindowResize = useCallback((event: UIEvent) => {
        const target = event.currentTarget as Window;
        setIsTablet(target.innerWidth <= 1024);
        setIsMobile(target.innerWidth <= 768);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => window.removeEventListener('resize', handleWindowResize);
    }, [handleWindowResize]);

    return {
        isTablet,
        isMobile,
    };
};
