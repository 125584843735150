import React, { useCallback, useRef, useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { Loading } from '@lms-elements/icons';
import { logoIcon, logoText } from 'assets';

import { Button, ButtonViewEnum } from '../Button';
import { Tablets } from '../Tablets';

import { EMAIL_PATTERN } from './RegistrationForm.constants';
import { IRegistrationFormProps, IRegistrationFormValues } from './RegistrationForm.types';
import { validateRegistrationForm, validateSignInForm } from './RegistrationForm.utils';

import './RegistrationForm.scss';

export const CnRegistration = cn('registrationForm');

export const RegistrationForm: React.FC<IRegistrationFormProps> = ({
    error = '',
    isLogoShown = true,
    isLoading,
    isTabletsRequired = false,
    onChange,
    onRemind,
    onSubmit,
    onTabletClick,
    passwordMinLength = 8,
    passwordPlaceholder = 'Пароль',
    passwordRepeatPlaceholder = 'Повторите пароль',
    tabletTitleActive = 'Регистрация',
    tabletTitleRegistration = 'Регистрация',
    tabletTitleSignIn = 'Вход',
    usernamePlaceholder = 'Эл. почта',
}) => {
    const tabletsTitles = [tabletTitleRegistration, tabletTitleSignIn];
    const submitButtonText = tabletTitleActive === tabletTitleSignIn ? tabletTitleSignIn : tabletTitleRegistration;
    const [remindError, setRemindError] = useState('');

    const usernameRef = useRef<HTMLInputElement>(null);

    const handleRemindClick = useCallback(() => {
        if (!usernameRef?.current?.value) {
            setRemindError('Необходимо указать email');
        } else if (EMAIL_PATTERN.exec(usernameRef.current.value) === null) {
            setRemindError('Пожалуйста укажите корректный email');
        } else {
            onRemind(usernameRef.current.value);
        }
    }, [onRemind]);

    const handleTabletClick = useCallback(
        (title) => {
            if (onTabletClick) {
                onTabletClick(title);
            }
        },
        [onTabletClick],
    );

    const handleChangeForm = useCallback(() => {
        if (onChange) {
            onChange();
        }
        setRemindError('');
    }, [onChange]);

    return (
        <div
            className={CnRegistration({
                'uncorrect wiggle': Boolean(error),
                withTabletsRegistration: isTabletsRequired && tabletTitleActive === tabletTitleRegistration,
                withTabletsSignIn: isTabletsRequired && tabletTitleActive === tabletTitleSignIn,
            })}
        >
            <div className={CnRegistration('header')}>
                {isLogoShown && (
                    <div className={CnRegistration('logo')}>
                        <img className="logo-icon" src={logoIcon} alt="Logo" />
                        <img className="logo-text" src={logoText} alt="Logo" />
                    </div>
                )}
            </div>
            {isTabletsRequired && (
                <div className={CnRegistration('tablets')}>
                    <Tablets
                        titles={tabletsTitles}
                        activeElement={tabletTitleActive}
                        setActiveElement={handleTabletClick}
                    />
                </div>
            )}
            <div className={CnRegistration('form')}>
                <Form<IRegistrationFormValues>
                    onSubmit={onSubmit}
                    validate={
                        tabletTitleActive === tabletTitleSignIn
                            ? validateSignInForm
                            : validateRegistrationForm(passwordMinLength)
                    }
                    render={({
                        handleSubmit,
                        submitting,
                        errors,
                        submitFailed,
                        dirtySinceLastSubmit,
                        hasValidationErrors,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Field name="username">
                                {({ input, meta }) => (
                                    <div>
                                        <input
                                            className={CnRegistration('input', {
                                                error:
                                                    !!remindError ||
                                                    (meta.submitFailed && !meta.valid && !dirtySinceLastSubmit),
                                            })}
                                            {...input}
                                            type="text"
                                            placeholder={usernamePlaceholder}
                                            ref={usernameRef}
                                        />
                                    </div>
                                )}
                            </Field>
                            <Field name="password">
                                {({ input, meta }) => (
                                    <div className={CnRegistration('inputWrapper')}>
                                        <input
                                            className={CnRegistration('input', {
                                                error: meta.submitFailed && !meta.valid && !dirtySinceLastSubmit,
                                            })}
                                            {...input}
                                            type="password"
                                            placeholder={passwordPlaceholder}
                                        />
                                        {tabletTitleActive === tabletTitleSignIn && (
                                            <button
                                                type="button"
                                                disabled={submitting}
                                                className="remind"
                                                onClick={handleRemindClick}
                                            >
                                                напомнить
                                            </button>
                                        )}
                                    </div>
                                )}
                            </Field>
                            {tabletTitleActive === tabletTitleRegistration && (
                                <Field name="confirm">
                                    {({ input, meta }) => (
                                        <div>
                                            <input
                                                className={CnRegistration('input', {
                                                    error: meta.submitFailed && !meta.valid && !dirtySinceLastSubmit,
                                                })}
                                                {...input}
                                                type="password"
                                                placeholder={passwordRepeatPlaceholder}
                                            />
                                        </div>
                                    )}
                                </Field>
                            )}
                            <div>
                                <Button
                                    size="m"
                                    view={ButtonViewEnum.action}
                                    className={CnRegistration('button', {
                                        uncorrect: Boolean(error) || (submitFailed && hasValidationErrors),
                                    })}
                                    type="submit"
                                    disabled={submitting || Boolean(error) || (submitFailed && hasValidationErrors)}
                                >
                                    <div className={CnRegistration('text', { loading: isLoading })}>
                                        {isLoading ? <Loading /> : submitButtonText}
                                    </div>
                                </Button>
                            </div>
                            <div className={CnRegistration('error')}>
                                {error ||
                                    remindError ||
                                    (submitFailed && errors?.username) ||
                                    (submitFailed && errors?.password) ||
                                    (submitFailed && errors?.confirm)}
                            </div>
                            <FormSpy
                                subscription={{ dirtySinceLastSubmit: true, dirty: true }}
                                onChange={handleChangeForm}
                            />
                        </form>
                    )}
                />
            </div>
        </div>
    );
};
