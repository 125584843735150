import React, { useCallback, useEffect, useState } from 'react';
import { cn } from '@bem-react/classname';
import { LinkIcon } from '@lms-elements/icons';
import { getEntityRange, getSelectionEntity, getSelectionText } from 'draftjs-utils';

import { LinkData } from '../../../types/entities.types';
import { EntityTypes, ICustomOptionProps } from '../../../types/shared.types';
import { addEntity } from '../../../utils';
import { OptionButton } from '../../OptionButton';
import { LinkPopUp } from '../LinkPopUp';

import './LinkOption.scss';

const LinkOptionCn = cn('link-option');

export const LinkOption: React.FC<ICustomOptionProps> = ({ editorState, onChange }) => {
    const [isPopUpShown, setIsPopUpShown] = useState(false);
    const [url, setUrl] = useState('');
    const [text, setText] = useState('');
    const [isBlank, setIsBlank] = useState(true);

    const togglePopup = useCallback(() => setIsPopUpShown((prev) => !prev), []);

    const addLink = useCallback(
        () =>
            addEntity({
                editorState,
                onChange,
                text,
                entityType: EntityTypes.LINK,
                mutability: 'MUTABLE',
                entityData: { url, target: isBlank ? '_blank' : '_self' },
            }),
        [editorState, isBlank, onChange, text, url],
    );

    const handleSave = useCallback(() => {
        addLink();
        setText('');
        setUrl('');
        setIsBlank(true);
        setIsPopUpShown(false);
    }, [addLink]);

    useEffect(() => {
        const currentEntity = getSelectionEntity(editorState);
        const contentState = editorState.getCurrentContent();
        if (currentEntity && contentState.getEntity(currentEntity).getType() === EntityTypes.LINK) {
            const entityRange = getEntityRange(editorState, currentEntity);
            const { url, target } = contentState.getEntity(currentEntity).getData() as LinkData;
            setText(entityRange.text);
            setUrl(url);
            setIsBlank(target === '_blank');
        } else {
            setText(getSelectionText(editorState));
            setUrl('');
            setIsBlank(true);
        }
    }, [editorState]);

    return (
        <div className={LinkOptionCn('container')}>
            <OptionButton active={isPopUpShown} onClick={togglePopup}>
                <LinkIcon className={LinkOptionCn('icon', { active: isPopUpShown })} />
            </OptionButton>
            <div className={LinkOptionCn('popup-container', { hidden: !isPopUpShown })}>
                <LinkPopUp
                    url={url}
                    setUrl={setUrl}
                    text={text}
                    setText={setText}
                    isBlank={isBlank}
                    setIsBlank={setIsBlank}
                    setIsShown={setIsPopUpShown}
                    onSaveClick={handleSave}
                />
            </div>
        </div>
    );
};
