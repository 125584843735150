import React, { useCallback } from 'react';
import { cn } from '@bem-react/classname';

import { ITabsHeaderItemProps } from './TabsHeaderItem.types';

import './TabsHeaderItem.scss';

const CnTabsHeaderItem = cn('tabsHeaderItem');

export const TabsHeaderItem: React.FC<ITabsHeaderItemProps> = ({ content, isSelected, onClick, id }) => {
    const handleTabsHeaderItemClick = useCallback(() => onClick(id), [id, onClick]);

    return (
        <div onClick={handleTabsHeaderItemClick} className={CnTabsHeaderItem({ selected: isSelected })}>
            {content}
        </div>
    );
};
