import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgNotebook({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={15}
            height={16}
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M7.055 6.664h3.5M7.055 8.54h3.5M12.305 2.445h-8.75c-.242 0-.438.21-.438.47v9.374c0 .259.196.469.438.469h8.75c.241 0 .437-.21.437-.469V2.914c0-.259-.196-.469-.437-.469zM5.305 2.445v10.313"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgNotebook = React.memo(SvgNotebook);
export default MemoSvgNotebook;
