import React from 'react';
import { cn } from '@bem-react/classname';
import { Triangle } from '@lms-elements/icons';

import { IScoreProgressProps, ScoreChanging } from './ScoreProgress.types';

import './ScoreProgress.scss';

const CnScoreProgress = cn('scoreProgress');

export const ScoreProgress: React.FC<IScoreProgressProps> = ({ score, changing }) => (
    <div className={CnScoreProgress()}>
        <span className={CnScoreProgress('score')}>{score}</span>
        {changing !== ScoreChanging.stay && <Triangle className={CnScoreProgress('indicator', { changing })} />}
    </div>
);
