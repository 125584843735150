import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgTaskTemplate({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={22}
            height={26}
            viewBox="0 0 22 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M16.714 4.059H21v20.94H1V4.06h4.286"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                clipRule="evenodd"
                d="M13.354 3.446C13.354 2.096 12.3 1 11 1 9.702 1 8.648 2.096 8.648 3.446H6.295v3.058h9.412V3.446h-2.353z"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.852 18.078a.246.246 0 01-.24-.25V12.91c0-.077.023-.136.07-.177a.228.228 0 01.17-.073h.801a.246.246 0 01.25.25v4.052h1.474V12.91a.246.246 0 01.24-.25h.751c.067 0 .124.024.17.073.047.048.07.107.07.177v4.052h1.474V12.91a.246.246 0 01.25-.25h.801c.074 0 .13.024.17.073.048.041.071.1.071.177v4.917c0 .069-.023.128-.07.177a.208.208 0 01-.17.073H4.851zM17.591 15.244c.014.153.02.274.02.365 0 .09-.006.198-.02.323-.033.694-.264 1.243-.691 1.646-.427.402-1.032.604-1.813.604-.829 0-1.446-.223-1.854-.667-.407-.451-.628-1.118-.661-2l-.01-.625.01-.625c.02-.986.167-1.726.44-2.219.275-.493.672-.85 1.193-1.073.52-.229 1.366-.49 2.535-.78l.06-.011c.053 0 .1.02.14.062.047.035.073.08.08.136l.13.697.01.063a.226.226 0 01-.05.146.239.239 0 01-.13.083 3.436 3.436 0 00-.3.073c-.809.18-1.386.337-1.734.469-.34.125-.6.32-.781.583-.18.257-.284.653-.31 1.188.16-.209.37-.375.63-.5.268-.125.548-.188.842-.188.701 0 1.246.201 1.633.604.387.403.598.952.631 1.646zm-1.382.636c.006-.05.01-.14.01-.271 0-.146-.004-.25-.01-.313-.014-.43-.114-.76-.3-.99-.181-.228-.432-.343-.752-.343-.36 0-.648.118-.862.354-.214.23-.327.556-.34.98l-.01.312.01.27c.013.438.116.771.31 1 .194.223.468.334.822.334.354 0 .624-.111.811-.333.194-.23.297-.563.31-1z"
                fill="#0088E9"
            />
        </svg>
    );
}

const MemoSvgTaskTemplate = React.memo(SvgTaskTemplate);
export default MemoSvgTaskTemplate;
