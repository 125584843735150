import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgSearch({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M12.123 11.706l6.21 6.21M14.167 7.083a6.25 6.25 0 11-12.5 0 6.25 6.25 0 0112.5 0z"
                stroke="#585C6F"
                strokeWidth={1.5}
                strokeLinecap="round"
            />
        </svg>
    );
}

const MemoSvgSearch = React.memo(SvgSearch);
export default MemoSvgSearch;
