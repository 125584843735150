import React from 'react';
import { useField } from 'react-final-form';
import { cn } from '@bem-react/classname';

import { RadioField } from '../../RadioField';

import { SelectionPopupContentProps } from './SelectionPopupContent.types';

import './SelectionPopupContent.scss';

const SelectionPopupCn = cn('selectionPopup');

export const SelectionPopupContent: React.FC<SelectionPopupContentProps> = ({ name, types }) => {
    const input = useField(name);

    return (
        <div className={SelectionPopupCn('main')}>
            {types
                ? types.map((type) => (
                      <>
                          <RadioField
                              name={name}
                              value={type.value}
                              onChange={input.input.onChange}
                              key={type.value}
                              label={type.label}
                          />
                      </>
                  ))
                : null}
        </div>
    );
};
