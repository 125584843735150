import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgPencilAndRuler({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={69}
            height={68}
            viewBox="0 0 69 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                clipRule="evenodd"
                d="M21.563 52.416l-8.625-8.5 8.624 8.5zM53.188 21.25l-8.626-8.5 8.626 8.5z"
                stroke="#EDEEF2"
                strokeWidth={4}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                clipRule="evenodd"
                d="M12.938 43.918l-5.75 14.167 14.375-5.667 36.532-36.003a2.801 2.801 0 000-4.006l-4.56-4.494a2.902 2.902 0 00-4.065 0L12.938 43.918zM24.438 12.75l-5.75 5.667 5.75-5.667zM30.188 18.416l-2.875 2.833 2.875-2.833zM47.438 35.416l-5.75 5.667 5.75-5.667zM53.188 41.084l-2.876 2.833 2.876-2.833z"
                stroke="#EDEEF2"
                strokeWidth={4}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                clipRule="evenodd"
                d="M34.5 22.667L18.687 7.084l-11.5 11.333L23 34.001l11.5-11.334zM43.125 31.166l15.813 15.583-11.5 11.334-15.813-15.584 11.5-11.333z"
                stroke="#EDEEF2"
                strokeWidth={4}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgPencilAndRuler = React.memo(SvgPencilAndRuler);
export default MemoSvgPencilAndRuler;
