import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgToggleLeft({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={8}
            height={8}
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d="M7 1L1 7m0-6l6 6" stroke="#BFC0C7" strokeWidth={1.5} strokeLinecap="round" />
        </svg>
    );
}

const MemoSvgToggleLeft = React.memo(SvgToggleLeft);
export default MemoSvgToggleLeft;
