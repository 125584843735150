import { useCallback, useState } from 'react';

export const useDropdownDots = (): {
    canDrag: boolean;
    handleDropdownDotsMouseEnter: () => void;
    handleDropdownDotsMouseLeave: () => void;
} => {
    const [isDropdownDotsHovered, setIsDropdownDotsHovered] = useState(false);

    const handleDropdownDotsMouseEnter = useCallback(() => {
        setIsDropdownDotsHovered(true);
    }, []);

    const handleDropdownDotsMouseLeave = useCallback(() => {
        setIsDropdownDotsHovered(false);
    }, []);

    return {
        canDrag: isDropdownDotsHovered,
        handleDropdownDotsMouseEnter,
        handleDropdownDotsMouseLeave,
    };
};
