import moment from 'moment';

/**
 * gets a date and returns the number of minutes before the date
 * @param date - incoming date
 * @returns string
 */
export const getNumberMinutesBeforeDate = (date: Date | string): string => {
    const rightTime = moment(date).fromNow();
    return rightTime.includes('через') || rightTime.includes('in')
        ? rightTime
              .split(' ')
              .slice(1, 3)
              .map((word, index) => {
                  if (index === 1 && word.length > 4) {
                      return `${word.split('').slice(0, 3).join('')}.`;
                  }

                  return word;
              })
              .join(' ')
        : rightTime;
};
