import React, { useMemo, useRef } from 'react';
import { cn } from '@bem-react/classname';

import { ITabsProps } from './Tabs.types';
import { TabsHeaderItem } from './TabsHeaderItem';

import './Tabs.scss';

const CnTabs = cn('tabs');

export const Tabs: React.FC<ITabsProps> = ({
    elements,
    selectedElement,
    onSelectedElementChange,
    LoaderComponent,
    customHeight,
}) => {
    const correctSelectedElement = useMemo(() => {
        if (selectedElement < 0) {
            return 0;
        }

        return selectedElement;
    }, [selectedElement]);

    const element = useRef<HTMLDivElement>(null);

    const selectedTab = useMemo(() => {
        const tab = elements.find((el) => el.id === correctSelectedElement);

        if (tab) {
            return tab.component;
        }

        return elements[0].component;
    }, [elements, correctSelectedElement]);

    return (
        <div className={CnTabs()}>
            <div className={CnTabs('header')}>
                {elements.map((element) => (
                    <TabsHeaderItem
                        onClick={onSelectedElementChange}
                        key={element.id}
                        id={element.id}
                        isSelected={element.id === correctSelectedElement}
                        content={element.title}
                    />
                ))}
            </div>
            <div
                ref={element}
                className={CnTabs('content')}
                style={{
                    height: customHeight || `calc(100vh - ${element.current ? element.current.offsetTop : 0}px)`,
                }}
            >
                {LoaderComponent && <LoaderComponent />}
                {selectedTab}
            </div>
        </div>
    );
};
