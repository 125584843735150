import React from 'react';
import { cn } from '@bem-react/classname';
import { LittleArrow } from '@lms-elements/icons';

import { CounterArrowsViewProps } from './CounterArrowsView.types';

import './CounterArrowsView.scss';

const cx = cn('counter-arrows-view');

export const CounterArrowsView: React.FC<CounterArrowsViewProps> = ({
    value,
    label,
    disabled,
    decDisabled,
    incDisabled,
    onChange,
    onIncButtonClick,
    onDecButtonClick,
}) => {
    return (
        <div className={cx()}>
            <label className={cx('label-wrapper')}>
                <p className={cx('label')}>{label}</p>
                <div className={cx('input-wrapper')}>
                    <input
                        spellCheck
                        type="text"
                        value={value}
                        className={cx('input', { disabled })}
                        disabled={disabled}
                        onChange={onChange}
                    />
                </div>
            </label>
            <div className={cx('buttons-wrapper')}>
                <button
                    type="button"
                    className={cx('button', { increment: true, disabled: incDisabled })}
                    disabled={incDisabled}
                    onClick={onIncButtonClick}
                >
                    <LittleArrow />
                </button>
                <button
                    type="button"
                    className={cx('button', { decrement: true, disabled: decDisabled })}
                    disabled={decDisabled}
                    onClick={onDecButtonClick}
                >
                    <LittleArrow />
                </button>
            </div>
        </div>
    );
};
