import React from 'react';
import { cn } from '@bem-react/classname';

import { ScheduleDateItemProps } from './ScheduleDateItem.types';

import './ScheduleDateItem.scss';

const CnScheduleDateItem = cn('schedule-date-item');

export const ScheduleDateItem: React.FC<ScheduleDateItemProps> = ({ text, selected }) => {
    return <div className={CnScheduleDateItem('', { selected })}>{text}</div>;
};
