import React, { useCallback, useEffect, useState } from 'react';
import { useField } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { RadioField } from '@lms-elements/atomic';

import { AnswerOption, IQuestion } from '../Answer.types';
import { usePreviouslySubmittedValue } from '../hooks/usePreviouslySubmittedValue';

import { ISingleAnswerOptionsProps } from './SingleAnswerOptions.types';

import './SingleAnswerOptions.scss';

const SingleAnswerOptionsCn = cn('single-answer-options');

export const SingleAnswerOptions: React.FC<ISingleAnswerOptionsProps> = ({
    name,
    withEditor,
    optionsClassName,
    disabled,
    correctAnswer,
    isFetching = false,
    randomOrder = false,
    isDraft = false,
}) => {
    const { input } = useField<IQuestion>(name);
    const { input: answerOptions } = useField<AnswerOption[]>(`${name}.answerOptions`);

    const [randomArray, setRandomArray] = useState<AnswerOption[]>(answerOptions.value);
    const [fetching, setFetching] = useState<boolean>(false);

    useEffect(() => {
        if (!fetching && isFetching) setFetching(true);
    }, [fetching, isFetching]);

    const previouslySubmittedValue = usePreviouslySubmittedValue(
        input.value.answer?.[0] || undefined,
        isFetching,
        fetching,
        setFetching,
    );

    const checkCorrect = useCallback(
        (value: string) => Boolean(correctAnswer) && correctAnswer === value,
        [correctAnswer],
    );
    const checkNotCorrect = useCallback(
        (value: string) =>
            Boolean(correctAnswer) && previouslySubmittedValue === value && correctAnswer !== value && !isDraft,
        [correctAnswer, isDraft, previouslySubmittedValue],
    );

    useEffect(() => {
        if (randomOrder) {
            if (!input.value.correctAnswers) setRandomArray([...answerOptions.value].sort(() => Math.random() - 0.5));
        } else {
            setRandomArray(answerOptions.value);
        }
    }, [answerOptions.value, input.value.correctAnswers, randomOrder]);

    return (
        <>
            {randomArray.map((option) =>
                withEditor ? (
                    <div
                        className={SingleAnswerOptionsCn('option', {
                            correct: checkCorrect(option.id),
                            'not-correct': checkNotCorrect(option.id),
                        })}
                        key={option.id}
                    >
                        <RadioField name={`${name}.answer.0`} value={option.id} withoutLabel disabled={disabled} />
                        <div className={optionsClassName} dangerouslySetInnerHTML={{ __html: option.markup }} />
                    </div>
                ) : (
                    <div
                        className={SingleAnswerOptionsCn('option', {
                            correct: checkCorrect(option.id),
                            'not-correct': checkNotCorrect(option.id),
                        })}
                        key={option.id}
                    >
                        <RadioField
                            name={`${name}.answer.0`}
                            value={option.id}
                            label={option.markup}
                            disabled={disabled}
                        />
                    </div>
                ),
            )}
        </>
    );
};
