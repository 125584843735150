import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { cn } from '@bem-react/classname';
import {
    CloseBlue,
    DownloadIcon,
    EditFile,
    Principal as PrincipalIcon,
    Toggle as ToggleIcon,
} from '@lms-elements/icons';

import { TooltipItem } from '../AvatarWithTooltip/TooltipItem';
import { Button, ButtonViewEnum } from '../Button';
import { Logo } from '../Logo';
import { Popup } from '../Popup';

import { INavbarProps } from './Navbar.types';
import { NavbarContent } from './NavbarContent';
import { NavBarStudent } from './NavBarStudent';

import './Navbar.scss';

const CnNavBar = cn('navbar');

const downloadLabel = 'Приложение "Онлайн Гимназия №1"';
const smartLabel = 'Умная ручка';

export const Navbar: React.FC<INavbarProps> = ({
    students,
    currentPath,
    navbarItems,
    tooltipItems,
    isOpenButtonEnable = true,
    isOpenedInit = true,
    isMobile = false,
    onNavbarItemClick,
    needDownloadButton,
    needSmartButton,
    needPrincipalButton,
    onDownloadClick,
    onSmartClick,
    onLogoClick,
    onToggleClick,
    onLetterSend,
}) => {
    const [isOpened, setIsOpened] = useState(true);
    const [isMobileOpened, setIsMobileOpened] = useState(false);
    const [isOpenedPopup, setIsOpenedPopup] = useState(false);
    const [studentId, setStudentId] = useState((students?.find((item) => item.selected) || students?.[0])?.id);
    const student = useMemo(
        () => students?.find((item) => item.id === studentId) || students?.[0],
        [studentId, students],
    );
    const toggledStudents = useMemo(
        () => students?.map((item) => ({ ...item, selected: item.id === student?.id })),
        [student, students],
    );

    const handleStudentClick = useCallback(
        (id: number) => {
            const student = students?.find((item) => item.id === id);
            if (student && student.onStudentClick) {
                student.onStudentClick(id);
            }
            setStudentId(id);
        },
        [students],
    );

    const hadleSettingClick = useCallback(
        (id: number) => {
            const student = students?.find((item) => item.id === id);
            if (student && student.onSettingClick) {
                student.onSettingClick(id);
            }
            setIsMobileOpened(false);
        },
        [students],
    );

    const handleLogoClick = useCallback(() => {
        onLogoClick?.();
    }, [onLogoClick]);

    const handleDownloadClick = useCallback(() => {
        onDownloadClick?.();
    }, [onDownloadClick]);

    const handleSmartClick = useCallback(() => {
        onSmartClick?.();
    }, [onSmartClick]);

    const handlePrincipalClick = useCallback(() => {
        setIsMobileOpened(false);
        setIsOpenedPopup(true);
    }, []);

    const handlePopupClose = useCallback(() => setIsOpenedPopup(false), []);

    const handleToggleClick = useCallback(() => {
        if (isOpenButtonEnable) {
            setIsOpened((prev) => !prev);
            onToggleClick?.();
        }
    }, [isOpenButtonEnable, onToggleClick]);

    const handleBurgerClick = useCallback(() => {
        setIsMobileOpened((prev) => !prev);
    }, []);

    const handleNavBarItemClick = useCallback(
        (path) => {
            onNavbarItemClick?.(path);
            setIsMobileOpened(false);
        },
        [onNavbarItemClick],
    );

    const handleTooltipClick = useCallback(() => setIsMobileOpened(false), []);

    useEffect(() => {
        if (isOpenedInit && isOpened !== isOpenedInit && !isOpenButtonEnable) {
            setIsOpened(isOpenedInit);
        }
    }, [isOpenedInit, isOpened, isOpenButtonEnable]);

    return (
        <div className={CnNavBar('wrapper', { isMobile })}>
            {isMobile ? (
                <div className={CnNavBar('mobile')}>
                    <div className={CnNavBar('mobile-container')}>
                        <div className={CnNavBar('mobile-content')}>
                            <div className={CnNavBar('logo')} onClick={handleLogoClick}>
                                <Logo small={true} />
                            </div>
                            <div className={CnNavBar('mobile-student')}>
                                {student && (
                                    <NavBarStudent
                                        id={student.id}
                                        firstName={student.firstName}
                                        lastName={student.lastName}
                                        photoUrl={student.photoUrl}
                                        grade={student.grade}
                                        isInHeader
                                    />
                                )}
                            </div>
                            {isMobileOpened ? (
                                <div className={CnNavBar('burger-close')} onClick={handleBurgerClick}>
                                    <CloseBlue />
                                </div>
                            ) : (
                                <div className={CnNavBar('burger')} onClick={handleBurgerClick}>
                                    <span className={CnNavBar('burger-item', { isMobileOpened })}></span>
                                    <span className={CnNavBar('burger-item', { isMobileOpened })}></span>
                                    <span className={CnNavBar('burger-item', { isMobileOpened })}></span>
                                </div>
                            )}
                        </div>
                        {isMobileOpened && (
                            <div className={CnNavBar('mobile-nav')}>
                                <div className={CnNavBar('mobile-icon-group')}>
                                    {tooltipItems?.map((item, index) => (
                                        <div
                                            className={CnNavBar('mobile-icon')}
                                            key={index}
                                            onClick={handleTooltipClick}
                                        >
                                            <TooltipItem {...item} />
                                        </div>
                                    ))}
                                </div>
                                <NavbarContent
                                    students={toggledStudents}
                                    navbarItems={navbarItems}
                                    currentPath={currentPath}
                                    isMobile={isMobile}
                                    onNavbarItemClick={handleNavBarItemClick}
                                    onStudentClick={handleStudentClick}
                                    onSettingClick={hadleSettingClick}
                                />
                                <div className={CnNavBar('principal', { isMobile })}>
                                    <div className={CnNavBar('principal-button', { isMobile })}>
                                        <Button view={ButtonViewEnum.bordered} size="l" onClick={handlePrincipalClick}>
                                            Написать директору
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {isOpenedPopup && (
                        <Popup isMobile={isMobile} onCloseClick={handlePopupClose} onChangeSend={onLetterSend} />
                    )}
                </div>
            ) : (
                <div className={CnNavBar({ closed: !isOpened })}>
                    <div className={CnNavBar('container')}>
                        <div className={CnNavBar('header-wrapper')}>
                            <div
                                className={CnNavBar('header')}
                                onClick={handleLogoClick}
                                style={onLogoClick && { cursor: 'pointer' }}
                            >
                                <Logo small={false} />
                            </div>
                            <div className={CnNavBar('navbarContent', { isOpened: isOpened })}>
                                <NavbarContent
                                    students={toggledStudents}
                                    navbarItems={navbarItems}
                                    currentPath={currentPath}
                                    onNavbarItemClick={handleNavBarItemClick}
                                    onStudentClick={handleStudentClick}
                                    onSettingClick={hadleSettingClick}
                                />
                            </div>
                        </div>

                        <div className={CnNavBar('buttons', { isOpened: isOpened })}>
                            {(needSmartButton || needDownloadButton) && (
                                <div className={CnNavBar('flex-box', { isOpened: isOpened })}>
                                    {needSmartButton && (
                                        <div className={CnNavBar('smart', { isOpened: isOpened })}>
                                            <EditFile
                                                className={CnNavBar('smart-icon', { isOpened: isOpened })}
                                                onClick={handleSmartClick}
                                                title={smartLabel}
                                            />
                                        </div>
                                    )}
                                    {needDownloadButton && (
                                        <div className={CnNavBar('download', { isOpened: isOpened })}>
                                            <DownloadIcon
                                                className={CnNavBar('download-icon', { isOpened: isOpened })}
                                                onClick={handleDownloadClick}
                                                title={downloadLabel}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                            {needPrincipalButton && (
                                <div className={CnNavBar('principal', { isOpened: isOpened })}>
                                    <div
                                        className={CnNavBar('principal-button', { isOpened })}
                                        onClick={handlePrincipalClick}
                                    >
                                        <Button view={ButtonViewEnum.bordered} size="l">
                                            Написать директору
                                        </Button>
                                    </div>
                                    <PrincipalIcon
                                        className={CnNavBar('principal-icon', { isOpened })}
                                        onClick={handlePrincipalClick}
                                    />

                                    {isOpenedPopup && (
                                        <Popup onCloseClick={handlePopupClose} onChangeSend={onLetterSend} />
                                    )}
                                </div>
                            )}
                            <div
                                onClick={handleToggleClick}
                                className={CnNavBar('toggle', {
                                    onlyToggle: !needDownloadButton && !needSmartButton && isOpened,
                                    hasPrincipal: needPrincipalButton,
                                })}
                            >
                                <ToggleIcon
                                    className={CnNavBar('toggle-icon', {
                                        disabled: !isOpenButtonEnable,
                                        isOpened: isOpened,
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
