import React, { useCallback, useMemo, useState } from 'react';
import { useField } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { INSERT_WORD_REPLACE, NEW_LINE_DELIMETER } from '@lms-elements/custom-editor';

import { InsertWordsOption } from './InsertWordsOption/InsertWordsOption';
import { IAnswerProps, IQuestion } from './Answer.types';
import { BinaryAnswerOptions } from './BinaryAnswerOptions';
import { DetailedAnswer } from './DetailedAnswer';
import { MultipleAnswerOptions } from './MultipleAnswerOption';
import { OpenAnswerOption } from './OpenAnswerOption';
import { SingleAnswerOptions } from './SingleAnswerOptions';
import { getQuestionParts } from './utils';

import './Answer.scss';

const AnswerCn = cn('test-task-answer');

export const Answer: React.FC<IAnswerProps> = ({
    name,
    withEditor = false,
    questionClassName = '',
    optionsClassName = '',
    disabled = false,
    correctAnswers,
    needValidation = true,
    isFetching = false,
    randomOrder = false,
    needHideFileAddButton = false,
    needHideFileEditButton = false,
    needHideFileDeleteButton = false,
    needHideCopyToClipboardButton,
    onSmartBoardClick,
    onUploadFile,
    onDeleteFile,
    needQuestionLink,
    onQuestionLinkClick,
    tooltip,
    onFileOversize,
    isDraft,
}) => {
    const {
        input: {
            value: { type, question },
        },
    } = useField<IQuestion>(name);

    const [linkVisible, setLinkVisible] = useState(false);
    const [popUpVisible, setPopUpVisible] = useState(false);

    const handleQuestionEnter = useCallback(() => setLinkVisible(true), []);
    const handleQuestionLeave = useCallback(() => {
        setLinkVisible(false);
        setPopUpVisible(false);
    }, []);

    const handleLinkClick = useCallback(() => {
        onQuestionLinkClick?.();
    }, [onQuestionLinkClick]);

    const handleContextClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setPopUpVisible(true);
    }, []);

    const questionComponent = useMemo(
        () =>
            withEditor ? (
                <div className={questionClassName} dangerouslySetInnerHTML={{ __html: question }} />
            ) : (
                <p className={`${AnswerCn('question')} ${questionClassName}`}>{question}</p>
            ),
        [question, questionClassName, withEditor],
    );

    const linkComponent = useMemo(
        () =>
            linkVisible ? (
                <div className={`${AnswerCn('link')}`} onClick={handleLinkClick} onContextMenu={handleContextClick}>
                    Перейти в банк вопросов
                    {popUpVisible && tooltip}
                </div>
            ) : null,
        [handleContextClick, handleLinkClick, linkVisible, popUpVisible, tooltip],
    );

    const insertationParts = useMemo(() => {
        if (type === 'insert-words' && question.length) {
            const questionParts = getQuestionParts(question);

            if (questionParts.length) {
                return questionParts
                    .flatMap((questionPart) => questionPart.split(new RegExp(`(${INSERT_WORD_REPLACE})`)))
                    .reduce<{ parts: (string | number)[]; count: number }>(
                        (accum, part) =>
                            part === INSERT_WORD_REPLACE
                                ? { parts: [...accum.parts, accum.count], count: accum.count + 1 }
                                : { parts: [...accum.parts, part], count: accum.count },
                        { parts: [], count: 0 },
                    ).parts;
            }

            return question
                .split(new RegExp(`(${INSERT_WORD_REPLACE})`))
                .reduce<{ parts: (string | number)[]; count: number }>(
                    (accum, part) =>
                        part === INSERT_WORD_REPLACE
                            ? { parts: [...accum.parts, accum.count], count: accum.count + 1 }
                            : { parts: [...accum.parts, part], count: accum.count },
                    { parts: [], count: 0 },
                ).parts;
        }
        return [];
    }, [question, type]);

    switch (type) {
        case 'multiple':
            return (
                <div className={AnswerCn()} data-testid="test-task-answer">
                    <div
                        className={AnswerCn('flex')}
                        onMouseEnter={handleQuestionEnter}
                        onMouseLeave={handleQuestionLeave}
                    >
                        {questionComponent}
                        {needQuestionLink ? linkComponent : null}
                    </div>
                    <p className={AnswerCn('type')}>*несколько правильных ответов</p>
                    <MultipleAnswerOptions
                        name={name}
                        withEditor={withEditor}
                        optionsClassName={optionsClassName}
                        disabled={disabled}
                        correctAnswers={correctAnswers as string[]}
                        isFetching={isFetching}
                        randomOrder={randomOrder}
                    />
                </div>
            );
        case 'detailed':
            return (
                <div className={AnswerCn()} data-testid="test-task-answer">
                    <div
                        className={AnswerCn('flex')}
                        onMouseEnter={handleQuestionEnter}
                        onMouseLeave={handleQuestionLeave}
                    >
                        {questionComponent}
                        {needQuestionLink ? linkComponent : null}
                    </div>
                    <p className={AnswerCn('type')}>*развернутый ответ</p>
                    <DetailedAnswer
                        name={name}
                        withEditor={withEditor}
                        disabled={disabled}
                        needHideFileDeleteButton={needHideFileDeleteButton}
                        needHideFileAddButton={needHideFileAddButton}
                        onSmartBoardClick={onSmartBoardClick}
                        needHideEditButton={needHideFileEditButton}
                        onUploadFile={onUploadFile}
                        onDeleteFile={onDeleteFile}
                        onFileOversize={onFileOversize}
                        needHideCopyToClipboardButton={needHideCopyToClipboardButton}
                    />
                </div>
            );
        case 'single':
            return (
                <div className={AnswerCn()} data-testid="test-task-answer">
                    <div
                        className={AnswerCn('flex')}
                        onMouseEnter={handleQuestionEnter}
                        onMouseLeave={handleQuestionLeave}
                    >
                        {questionComponent}
                        {needQuestionLink ? linkComponent : null}
                    </div>
                    <p className={AnswerCn('type')}>*один правильный ответ</p>
                    <SingleAnswerOptions
                        name={name}
                        withEditor={withEditor}
                        optionsClassName={optionsClassName}
                        disabled={disabled}
                        correctAnswer={(correctAnswers as string[])?.[0]}
                        isFetching={isFetching}
                        randomOrder={randomOrder}
                    />
                </div>
            );
        case 'open':
            return (
                <>
                    <OpenAnswerOption
                        name={name}
                        questionComponent={questionComponent}
                        correctAnswer={correctAnswers as string}
                        disabled={disabled}
                        needValidation={needValidation}
                        placeholder={withEditor ? 'Введите ответ' : question}
                        isFetching={isFetching}
                        onQuestionEnter={handleQuestionEnter}
                        onQuestionLeave={handleQuestionLeave}
                        needQuestionLink={needQuestionLink}
                        linkComponent={linkComponent}
                        isDraft={isDraft}
                    />
                </>
            );
        case 'binary':
            return (
                <div className={AnswerCn()} data-testid="test-task-answer">
                    <div
                        className={AnswerCn('flex')}
                        onMouseEnter={handleQuestionEnter}
                        onMouseLeave={handleQuestionLeave}
                    >
                        {questionComponent}
                        {needQuestionLink ? linkComponent : null}
                    </div>
                    <p className={AnswerCn('type')}>*один правильный ответ</p>
                    <BinaryAnswerOptions name={name} disabled={disabled} isCorrect={correctAnswers as string} />
                </div>
            );
        case 'insert-words':
            return (
                <div
                    className={AnswerCn({ type })}
                    data-testid="test-task-answer"
                    onMouseEnter={handleQuestionEnter}
                    onMouseLeave={handleQuestionLeave}
                >
                    {insertationParts.map((part, index) =>
                        typeof part === 'number' ? (
                            <InsertWordsOption
                                key={index}
                                name={`${name}.answer[${part}]`}
                                correctAnswers={correctAnswers as (string | null)[]}
                                insertationPart={part}
                                disabled={disabled}
                                isFetching={isFetching}
                                isDraft={isDraft}
                            />
                        ) : (
                            part
                                .split(new RegExp(`(${NEW_LINE_DELIMETER})`))
                                .map((item) =>
                                    item === NEW_LINE_DELIMETER ? (
                                        <br />
                                    ) : (
                                        <span dangerouslySetInnerHTML={{ __html: item }} />
                                    ),
                                )
                        ),
                    )}
                    {needQuestionLink ? linkComponent : null}
                </div>
            );
    }
};
