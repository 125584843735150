import { useEffect, useState } from 'react';

interface UseHiddenNodeParams {
    needHide?: boolean;
    containerSelector?: string;
}

export const useHiddenNode = (params?: UseHiddenNodeParams) => {
    const [hiddenNode, setHiddenNode] = useState<HTMLDivElement | undefined>(undefined);

    useEffect(() => {
        const hiddenDiv = document.createElement('div');
        const containerSelector = params?.containerSelector ?? 'html';
        if (params?.needHide) {
            hiddenDiv.style.display = 'none';
        }
        setHiddenNode(hiddenDiv);
        document.querySelector(containerSelector)?.appendChild(hiddenDiv);

        return () => {
            document.querySelector(containerSelector)?.removeChild(hiddenDiv);
        };
    }, [params?.containerSelector, params?.needHide]);

    return hiddenNode;
};
