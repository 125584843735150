import React, { useCallback, useMemo, useRef, useState } from 'react';
import { cn } from '@bem-react/classname';
import { LittleArrow } from '@lms-elements/icons';
import { getTimeArray, getYearArray } from '@lms-elements/utils';

import { ITimepickerProps } from './Timepicker.types';

import './Timepicker.scss';

const CnTimepicker = cn('timepicker');

export const Timepicker: React.FC<ITimepickerProps> = ({
    startTime,
    endTime,
    stepMinutes,
    time,
    disabled,
    showYears,
    timePickerPosition,
    onTimeClick,
}) => {
    const timeContainerRef = useRef<HTMLDivElement>(null);
    const timepickerList = useMemo(
        () => (showYears ? getYearArray() : getTimeArray(startTime, endTime, stepMinutes)),
        [showYears, startTime, endTime, stepMinutes],
    );

    const [selectedTime, setSelectedTime] = useState(time);

    const handleTimeClick = useCallback(
        (time: string) => {
            setSelectedTime(time);
            if (onTimeClick) {
                onTimeClick(time);
            }
        },
        [onTimeClick],
    );

    const handleButtonClick = useCallback(
        (scrollUp?: boolean) => {
            if (timeContainerRef && timeContainerRef.current) {
                const scrollY = scrollUp ? -32 : 32;
                timeContainerRef.current.scrollBy(
                    timePickerPosition === 'bottom'
                        ? { left: scrollY, behavior: 'smooth' }
                        : { top: scrollY, behavior: 'smooth' },
                );
            }
        },
        [timePickerPosition],
    );

    return (
        <div className={CnTimepicker({ timePickerPosition })}>
            <div className={CnTimepicker('action', { timePickerPosition })}>
                <button
                    type="button"
                    className={CnTimepicker('button', { button: 'up', timePickerPosition })}
                    onClick={handleButtonClick.bind(null, true)}
                >
                    <LittleArrow />
                </button>
            </div>
            <div ref={timeContainerRef} className={CnTimepicker('timeContainer', { timePickerPosition })}>
                {timepickerList.map((item) => (
                    <div
                        key={item}
                        className={CnTimepicker('time', { isSelected: item === selectedTime, timePickerPosition })}
                        onClick={handleTimeClick.bind(null, item)}
                    >
                        {item}
                    </div>
                ))}
            </div>
            <div className={CnTimepicker('action', { timePickerPosition })}>
                <button
                    type="button"
                    className={CnTimepicker('button', { button: 'down', timePickerPosition })}
                    onClick={disabled ? undefined : handleButtonClick.bind(null, false)}
                >
                    <LittleArrow />
                </button>
            </div>
        </div>
    );
};
