import React, { useCallback } from 'react';
import { cn } from '@bem-react/classname';
import { Pencil, RoomCardUser } from '@lms-elements/icons';

import { Button, ButtonViewEnum } from '../Button';

import { RoomCardProps } from './RoomCard.types';

import './RoomCard.scss';

const CnRoomCard = cn('roomCard');

export const RoomCard: React.FC<RoomCardProps> = ({
    id,
    date,
    dayOfWeek,
    time,
    teachers,
    allPlace,
    occupiedPlace,
    isLocked,
    isJoined,
    isStudent,
    isRejoined,
    course,
    needHideCourse,
    needHideButton,
    title = 'Комната ',
    onJoinClick,
    onRejoinClick,
    onEditClick,
    onCardClick,
}) => {
    const handleJoinClick = useCallback(() => {
        onJoinClick?.(id);
    }, [id, onJoinClick]);

    const handleRejoinClick = useCallback(() => {
        onRejoinClick?.(id);
    }, [id, onRejoinClick]);

    const handleEditClick = useCallback(() => {
        onEditClick?.(id);
    }, [id, onEditClick]);

    const handleCardClick = useCallback(() => {
        onCardClick?.(id);
    }, [id, onCardClick]);

    const roomTitle = isStudent ? `${title}${date} (${dayOfWeek}) - ${time}` : time;

    return (
        <div className={CnRoomCard()}>
            <div className={CnRoomCard('card')}>
                <div onClick={handleCardClick}>
                    <div className={CnRoomCard('title')}>{roomTitle}</div>
                    <div className={CnRoomCard('teachers-title')}>Проводит:</div>
                    <div className={CnRoomCard('teachers')}>
                        {teachers?.map((teacher) => {
                            return (
                                <div className={CnRoomCard('teacher')} key={teacher}>
                                    {teacher}
                                </div>
                            );
                        })}
                    </div>
                    <div className={CnRoomCard('freeTitle')}>Свободно:</div>
                    <div className={CnRoomCard('places')}>
                        <RoomCardUser className={CnRoomCard('icon')} />
                        <div className={CnRoomCard('place')}>
                            {allPlace - occupiedPlace} из {allPlace} мест
                        </div>
                    </div>
                    {!needHideCourse && !isStudent && <div className={CnRoomCard('teachers-title')}>Курс:</div>}
                    {!needHideCourse && !isStudent && <div className={CnRoomCard('course')}>{course}</div>}
                </div>
                {!needHideButton && isStudent && (
                    <div className={CnRoomCard('buttons')}>
                        {!isRejoined ? (
                            <Button
                                view={!isJoined ? ButtonViewEnum.action : ButtonViewEnum.bordered}
                                size="s"
                                customClasses={CnRoomCard('button')}
                                disabled={isLocked}
                                onClick={handleJoinClick}
                            >
                                {!isJoined ? 'Записаться' : 'Вы записаны'}
                            </Button>
                        ) : (
                            <Button
                                view={!isRejoined ? ButtonViewEnum.bordered : ButtonViewEnum.action}
                                size="s"
                                customClasses={CnRoomCard('button')}
                                disabled={isLocked}
                                onClick={handleRejoinClick}
                            >
                                Перезаписаться
                            </Button>
                        )}
                    </div>
                )}
                {!needHideButton && !isStudent && (
                    <div className={CnRoomCard('teachersButtons')}>
                        <Button
                            customClasses={CnRoomCard('teacher-button')}
                            onClick={handleEditClick}
                            view={ButtonViewEnum.action}
                            icon={<Pencil />}
                            size="l"
                        >
                            Редактировать
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};
