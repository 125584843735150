import moment from 'moment';

export const getDateLesson = (startDate: string | Date, endDate: string | Date): string => {
    const startDDMM = moment(startDate).format('DD.MM');
    const startHHmm = moment(startDate).format('HH:mm');

    const endDDMM = moment(endDate).format('DD.MM');
    const endHHmm = moment(endDate).format('HH:mm');
    if (startDDMM === endDDMM) {
        return `${startDDMM} c ${startHHmm} до ${endHHmm}`;
    }
    return `c ${startDDMM} - ${startHHmm} до ${endDDMM} - ${endHHmm}`;
};
