import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgCodeIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M10.002 12l-4 4.06 4 3.94M22.002 20l4-4.06-4-3.94M17.76 9.62l-3.52 12.65"
                stroke="#252232"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgCodeIcon = React.memo(SvgCodeIcon);
export default MemoSvgCodeIcon;
