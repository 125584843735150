import React from 'react';
import { cn } from '@bem-react/classname';
import { Pencil } from '@lms-elements/icons';

import { IEditButtonProps } from './EditButton.types';

import './EditButton.scss';

const CnEditButton = cn('edit-button');

export const EditButton: React.FC<IEditButtonProps> = ({
    text,
    onClickCallback,
    isActive = true,
    hoverable = true,
}) => {
    return (
        <button
            type="button"
            className={CnEditButton({ active: isActive, hoverable: hoverable && isActive })}
            onClick={onClickCallback}
        >
            <Pencil />
            {text && <div className={CnEditButton('text')}>{text}</div>}
        </button>
    );
};
