import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgHwProcess({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={18}
            height={22}
            viewBox="0 0 18 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M12.913 4.294h3.25v15.707H1V4.294h3.249"
                stroke="#E6A139"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                clipRule="evenodd"
                d="M10.365 3.835c0-1.013-.8-1.835-1.784-1.835s-1.784.822-1.784 1.835H5.014v2.293h7.135V3.835h-1.784z"
                stroke="#E6A139"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle cx={8.75} cy={12.75} r={0.75} fill="#E6A139" />
            <circle cx={11.75} cy={12.75} r={0.75} fill="#E6A139" />
            <circle cx={5.75} cy={12.75} r={0.75} fill="#E6A139" />
        </svg>
    );
}

const MemoSvgHwProcess = React.memo(SvgHwProcess);
export default MemoSvgHwProcess;
