export const getDurationText = (duration: number): string => {
    switch (duration) {
        case 1:
            return '1 час в неделю';
        case 2:
        case 3:
        case 4:
            return `${duration} часа в неделю`;
            break;
        default:
            return `${duration} часов в неделю`;
    }
};
