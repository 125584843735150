import React from 'react';
import { blockRenderMap } from 'draftjs-utils';
import { Map } from 'immutable';

import { CodeBlockWrapper } from '../CodeBlockWrapper';

export const customBlockRenderMap = Map({
    CODE_BLOCK: {
        element: 'li',
        wrapper: <CodeBlockWrapper />,
    },
});

export const customRenderMap = blockRenderMap.merge(customBlockRenderMap);
