import React, { useCallback } from 'react';
import { cn } from '@bem-react/classname';
import { ClasscardUser, Notebook, Pencil, Plus } from '@lms-elements/icons';

import { Button, ButtonViewEnum } from '../../../Button';

import { IClasscardCourseCreatorProps } from './ClasscardCourseCreator.types';

const CnCourseCard = cn('courseCard');
const CnClassCard = cn('classcard');

export const ClasscardCourseCreator: React.FC<IClasscardCourseCreatorProps> = ({
    courseType,
    handleEditButtonClick,
    isEmpty,
    isMetodist,
    name,
    onClasscardClick,
    studentsCount,
    studentsLabel,
    teacher,
    teacherText,
    themesCount,
    themeLabel,
    onTeacherClick,
}): JSX.Element => {
    const handlerTeacherClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            onTeacherClick?.(e.ctrlKey || e.metaKey);
        },
        [onTeacherClick],
    );

    return (
        <div className={CnCourseCard()} onClick={onClasscardClick}>
            <div className={CnCourseCard('title')}>
                {courseType} - {name}
            </div>
            <div className={CnCourseCard('placeStudents')}>
                {!!studentsCount && (
                    <div className={CnCourseCard('students')}>
                        <span className={CnCourseCard('students-icon')}>
                            <ClasscardUser />
                        </span>
                        <span className={CnCourseCard('students-label')}>{studentsLabel}</span>
                    </div>
                )}
                {!!themesCount && (
                    <div className={CnClassCard('lessons')}>
                        <span className={CnClassCard('lessons-icon')}>
                            <Notebook />
                        </span>
                        <span className={CnClassCard('lessons-label')}>{themeLabel}</span>
                    </div>
                )}
            </div>
            {Boolean(teacher) && (
                <div className={CnCourseCard('teacher')} onClick={handlerTeacherClick}>
                    {teacherText}
                </div>
            )}

            {isMetodist &&
                (isEmpty ? (
                    <Button
                        icon={<Plus width="14px" height="14px" fill="#fff" />}
                        view={ButtonViewEnum.action}
                        size="l"
                        customClasses={CnCourseCard('button')}
                        onClick={handleEditButtonClick}
                    >
                        <span className={CnCourseCard('button-text')}>Создать программу</span>
                    </Button>
                ) : (
                    <Button
                        icon={<Pencil width="24px" height="24px" />}
                        view={ButtonViewEnum.action}
                        size="l"
                        customClasses={CnCourseCard('button')}
                        onClick={handleEditButtonClick}
                    >
                        <span className={CnCourseCard('button-text')}>Редактировать</span>
                    </Button>
                ))}
        </div>
    );
};

export default React.memo(ClasscardCourseCreator);
