import React, { useCallback, useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { ArrowClick } from '@lms-elements/icons';
import {
    decreaseDate,
    getCapitalizeEndOfWeek,
    getCapitalizeMonth,
    getCapitalizeMonthAndYear,
    getCapitalizeStartOfWeek,
    increaseDate,
} from '@lms-elements/utils';

import { DateSliderProps } from './DateSlider.types';

import './DateSlider.scss';

const CnDateSlider = cn('dateSlider');

export const DateSlider: React.FC<DateSliderProps> = ({
    time,
    changeDate,
    date,
    isLabelInCenter,
    weekWithLetterMonth = false,
}) => {
    const now = useMemo(() => getCapitalizeMonth(date), [date]);
    const startWeekDate = useMemo(
        () => getCapitalizeStartOfWeek(date, weekWithLetterMonth),
        [date, weekWithLetterMonth],
    );
    const endWeekDate = useMemo(() => getCapitalizeEndOfWeek(date, weekWithLetterMonth), [date, weekWithLetterMonth]);
    const isWeek = useMemo(() => time === 'week', [time]);

    const currentDate = useMemo(() => {
        switch (time) {
            case 'day':
                return now === getCapitalizeMonth() ? `${now} - сегодня` : now;
            case 'week':
                return `${startWeekDate} -
                    ${endWeekDate}`;
            case 'month':
                return getCapitalizeMonthAndYear(date);
            case 'year':
                return decreaseDate({ date, format: 'YYYY' });
        }
    }, [time, date, now, startWeekDate, endWeekDate]);

    const nextDate = useCallback(() => {
        switch (time) {
            case 'day':
                changeDate?.(increaseDate({ date, amount: 1, unit: 'd', format: 'YYYY-MM-DD' }));
                break;
            case 'week':
                changeDate?.(increaseDate({ date, amount: 1, unit: 'w', format: 'YYYY-MM-DD' }));
                break;
            case 'month':
                changeDate?.(increaseDate({ date, amount: 1, unit: 'month', format: 'YYYY-MM-DD' }));
                break;
            case 'year':
                changeDate?.(increaseDate({ date, amount: 1, unit: 'year', format: 'YYYY-MM-DD' }));
                break;
        }
    }, [date, time, changeDate]);

    const prevDate = useCallback(() => {
        switch (time) {
            case 'day':
                changeDate?.(decreaseDate({ date, amount: 1, unit: 'd', format: 'YYYY-MM-DD' }));
                break;
            case 'week':
                changeDate?.(decreaseDate({ date, amount: 1, unit: 'w', format: 'YYYY-MM-DD' }));
                break;
            case 'month':
                changeDate?.(decreaseDate({ date, amount: 1, unit: 'month', format: 'YYYY-MM-DD' }));
                break;
            case 'year':
                changeDate?.(decreaseDate({ date, amount: 1, unit: 'year', format: 'YYYY-MM-DD' }));
                break;
        }
    }, [date, time, changeDate]);

    return (
        <div className={CnDateSlider()}>
            <div className={CnDateSlider('button-container')}>
                <button className={CnDateSlider('leftButton')} onClick={prevDate}>
                    <ArrowClick />
                </button>
                {!isLabelInCenter && (
                    <button className={CnDateSlider('rightButton')} onClick={nextDate}>
                        <ArrowClick />
                    </button>
                )}
            </div>
            <div className={CnDateSlider('date', { isWeek, isLabelInCenter })}>{currentDate}</div>
            {isLabelInCenter && (
                <div className={CnDateSlider('button-container')}>
                    <button className={CnDateSlider('rightButton', { right_edge: true })} onClick={nextDate}>
                        <ArrowClick />
                    </button>
                </div>
            )}
        </div>
    );
};
