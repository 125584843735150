import React, { useCallback, useEffect, useRef } from 'react';
import { cn } from '@bem-react/classname';

import { DEFAULT_SCROLL_SHIFT } from '../constant';

import { GroupCoreProps } from './GroupCore.types';

import './GroupCore.scss';

const CnGroupCore = cn('group-core');

export const GroupCore: React.FC<GroupCoreProps> = ({
    nameGroup,
    id,
    onGroupSelect,
    isSelected,
    error,
    isHorizontal,
    mainRef,
}) => {
    const handleGroupCoreClick = useCallback(() => onGroupSelect?.(nameGroup, id), [id, nameGroup, onGroupSelect]);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const shiftLeft = ref.current?.offsetLeft;
        const shiftTop = ref.current?.offsetTop;
        if (isSelected && isHorizontal && shiftLeft !== undefined) {
            mainRef?.current?.scrollTo({
                left: shiftLeft - DEFAULT_SCROLL_SHIFT,
                behavior: 'smooth',
            });
            return;
        }

        if (isSelected && !isHorizontal && shiftTop !== undefined) {
            mainRef?.current?.scrollTo({
                top: shiftTop - DEFAULT_SCROLL_SHIFT,
                behavior: 'smooth',
            });
            return;
        }
    }, [isHorizontal, isSelected, mainRef]);

    return (
        <div
            ref={ref}
            onClick={handleGroupCoreClick}
            className={CnGroupCore({ selected: isSelected, isError: error, isHorizontal: isHorizontal })}
        >
            {nameGroup}
        </div>
    );
};
