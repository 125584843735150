import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgCam({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={23}
            height={20}
            viewBox="0 0 23 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M11.334 14.332a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.667 18.5H3a1.667 1.667 0 01-1.667-1.667V5.167A1.667 1.667 0 013 3.5h3.333L8 1h6.666l1.667 2.5h3.334a1.666 1.666 0 011.666 1.667v11.666a1.666 1.666 0 01-1.666 1.667z"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgCam = React.memo(SvgCam);
export default MemoSvgCam;
