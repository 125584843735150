import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgButtonAddCircle({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={40}
            height={40}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <circle cx={20} cy={20} r={19.5} stroke="#0088E9" strokeDasharray="4 4" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.4 13a.6.6 0 01.6.6V19h5.4a.6.6 0 01.6.6v.8a.6.6 0 01-.6.6H21v5.4a.6.6 0 01-.6.6h-.8a.6.6 0 01-.6-.6V21h-5.4a.6.6 0 01-.6-.6v-.8a.6.6 0 01.6-.6H19v-5.4a.6.6 0 01.6-.6h.8z"
                fill="#0088E9"
            />
        </svg>
    );
}

const MemoSvgButtonAddCircle = React.memo(SvgButtonAddCircle);
export default MemoSvgButtonAddCircle;
