import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgBorderGradient({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={1025}
            height={398}
            viewBox="0 0 1025 398"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <rect
                x={0.75}
                y={0.75}
                width={1023.5}
                height={396.5}
                rx={20.25}
                stroke="url(#border-gradient_svg__paint0_linear)"
                strokeWidth={1.5}
                strokeLinejoin="round"
                strokeDasharray="12 12"
            />
            <defs>
                <linearGradient
                    id="border-gradient_svg__paint0_linear"
                    x1={159.048}
                    y1={57.856}
                    x2={806.236}
                    y2={135.33}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6865FF" />
                    <stop offset={1} stopColor="#FFA767" />
                </linearGradient>
            </defs>
        </svg>
    );
}

const MemoSvgBorderGradient = React.memo(SvgBorderGradient);
export default MemoSvgBorderGradient;
