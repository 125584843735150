import React, { useCallback, useMemo, useState } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useField } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { ImageIcon } from '@lms-elements/icons';

import { IDropItem, IImageValues, supportedMimeTypes } from '../ImageUploader.types';
import { computeWidth, isFileSizeValid, isMimeTypeValid } from '../utils';

import { IUploadDialogProps } from './UploadDialog.types';

import './UploadDialog.scss';

const UploadDialogCn = cn('imageUploader-upload-dialog');

export const UploadDialog: React.FC<IUploadDialogProps> = ({ name, onUpload }) => {
    const { input } = useField<IImageValues>(name);
    const [isFileSizeError, setIsFileSizeError] = useState(false);
    const [isMimeTypeError, setIsMimeTypeError] = useState(false);

    const handleFile = useCallback(
        (file: File) => {
            if (file.type.split('/')[0] !== 'image') {
                return;
            }

            if (!isFileSizeValid(file)) {
                setIsFileSizeError(true);
                setTimeout(() => setIsFileSizeError(false), 6000);
                return;
            }
            if (file.name && !isMimeTypeValid(file)) {
                setIsMimeTypeError(true);
                setTimeout(() => setIsMimeTypeError(false), 6000);
                return;
            }
            void createImageBitmap(file)
                .then(({ width }) => computeWidth(width))
                .then(() => {
                    const { imageData } = input.value.values;

                    input.onChange({
                        ...input.value.values,
                        values: {
                            imageData: {
                                ...imageData,
                                data: {
                                    data: file,
                                },
                            },
                        },
                    });
                    if (onUpload) {
                        onUpload(file);
                    }
                });
        },
        [input, onUpload],
    );

    const [, drop] = useDrop(
        () => ({
            accept: [NativeTypes.FILE],
            drop: (item: IDropItem) => {
                handleFile(item.files[0]);
            },
        }),
        [input],
    );

    const handleFileAdd = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            if (!e.target.files?.length) {
                return;
            }
            handleFile(e.target.files[0]);
        },
        [handleFile],
    );

    const bordered = useMemo(
        () => !!input.value?.values.imageData?.data.data,
        [input.value?.values.imageData?.data.data],
    );

    return (
        <div ref={drop} className={UploadDialogCn({ bordered })}>
            <h4>Загрузите изображение</h4>
            <div className={UploadDialogCn('manual')}>
                {!bordered && <ImageIcon className={UploadDialogCn('image-icon')} />}
                перетащив файл сюда или
                <label className={UploadDialogCn('file-label')}>
                    укажите путь
                    <input type="file" accept="image/*" onChange={handleFileAdd} />
                </label>
            </div>
            {isMimeTypeError && (
                <div className={UploadDialogCn('file-error')}>Тип загружаемого файла не поддерживается</div>
            )}
            {isFileSizeError && (
                <div className={UploadDialogCn('file-error')}>
                    Вес загружаемого файла более 5mb, уменьшите размер и загрузите повторно
                </div>
            )}
            <div className={UploadDialogCn('mime-types')}>подходят {supportedMimeTypes.join(' ')} до 5mb</div>
        </div>
    );
};
