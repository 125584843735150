import React, { useCallback } from 'react';

import { CounterArrowsView, CounterMathView } from './CounterViews';
import { CounterViews, IFieldInputCounterCoreProps } from './FieldInputCounterCore.types';

const valueInRange = (min: number, max: number, value: number): number => Math.max(min, Math.min(max, value));

export const FieldInputCounterCore: React.FC<IFieldInputCounterCoreProps> = ({
    value,
    onChange,
    makeTitle,
    parse = (value) => value,
    disabled = false,
    min = 1,
    max = 999,
    step = 1,
    label,
    view = CounterViews.MATH,
}) => {
    const handleIncButtonClick = useCallback(() => {
        onChange(parse(valueInRange(min, max, value + step)));
    }, [max, min, onChange, parse, step, value]);

    const handleDecButtonClick = useCallback(() => {
        onChange(parse(valueInRange(min, max, value - step)));
    }, [max, min, onChange, parse, step, value]);

    const decDisabled = disabled || value === min;

    const incDisabled = disabled || value === max;

    const handleInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value == '') {
                return onChange(min);
            }

            const newValue = parseInt(e.target.value);

            if (!isNaN(newValue)) {
                onChange(parse(valueInRange(min, max, newValue)));
            }
        },
        [max, min, onChange, parse],
    );

    switch (view) {
        case CounterViews.ARROWS:
            return (
                <CounterArrowsView
                    value={value}
                    label={label}
                    onChange={handleInputChange}
                    decDisabled={decDisabled}
                    incDisabled={incDisabled}
                    disabled={disabled}
                    onIncButtonClick={handleIncButtonClick}
                    onDecButtonClick={handleDecButtonClick}
                />
            );
        case CounterViews.MATH:
        default:
            return (
                <CounterMathView
                    value={value}
                    onChange={handleInputChange}
                    decDisabled={decDisabled}
                    incDisabled={incDisabled}
                    makeTitle={makeTitle}
                    disabled={disabled}
                    onIncButtonClick={handleIncButtonClick}
                    onDecButtonClick={handleDecButtonClick}
                />
            );
    }
};
