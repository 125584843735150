import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgPassword({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={19}
            height={13}
            viewBox="0 0 19 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M18.87 6.073C18.7 5.823 14.646 0 9.5 0 4.353 0 .3 5.822.13 6.073a.76.76 0 000 .854C.3 7.177 4.353 13 9.5 13c5.147 0 9.2-5.822 9.37-6.073a.76.76 0 000-.854zM1.593 6.5a18.61 18.61 0 012.189-2.357 6.636 6.636 0 000 4.714A18.61 18.61 0 011.594 6.5zM9.5 11.464a4.594 4.594 0 01-2.635-.836A4.923 4.923 0 015.118 8.4a5.18 5.18 0 01-.27-2.868A5.04 5.04 0 016.146 2.99 4.681 4.681 0 018.575 1.63a4.547 4.547 0 012.74.283 4.797 4.797 0 012.129 1.828c.52.816.799 1.776.799 2.758a5.091 5.091 0 01-1.391 3.508A4.645 4.645 0 019.5 11.464zm5.717-2.607a6.636 6.636 0 000-4.714A18.608 18.608 0 0117.406 6.5a18.609 18.609 0 01-2.189 2.357zM9.5 4.813c-.319 0-.63.1-.895.285a1.673 1.673 0 00-.594.757 1.76 1.76 0 00-.091.974c.062.327.215.628.44.864.226.236.513.396.826.461.312.065.636.032.93-.096a1.63 1.63 0 00.724-.62c.177-.278.271-.604.271-.938 0-.447-.17-.876-.472-1.193A1.576 1.576 0 009.5 4.813z"
                fill="#0088E9"
            />
        </svg>
    );
}

const MemoSvgPassword = React.memo(SvgPassword);
export default MemoSvgPassword;
