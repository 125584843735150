import React, { useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { formatDate } from '@lms-elements/utils';

import { IStatusBarProps } from './StatusBar.types';

import './StatusBar.scss';

const CnStatusBar = cn('classcard-statusbar');

export const StatusBar: React.FC<IStatusBarProps> = ({
    isCompleted,
    tasks,
    createTasksLabel,
    overdue,
    nextLessonDate,
    isStudent = false,
}) => {
    const correctTaskForm = useMemo(
        () => (ending: string) => {
            if (createTasksLabel) {
                return `${createTasksLabel(tasks ?? 0)} ${ending}`.slice(String(tasks).length);
            }
            return `${tasks ?? 0} заданий ${ending}`.slice(String(tasks).length);
        },
        [createTasksLabel, tasks],
    );

    const label =
        nextLessonDate !== undefined && isCompleted
            ? `все проверено, следующее занятие ${formatDate(nextLessonDate)}`
            : correctTaskForm('на проверку');

    const studentLabel = useMemo(() => {
        if (isCompleted) {
            return 'Заданий нет';
        }

        if (overdue) {
            return correctTaskForm('просрочено');
        }

        return correctTaskForm('сделать');
    }, [overdue, isCompleted, correctTaskForm]);

    const taskLabel = useMemo(() => (isStudent ? studentLabel : label), [isStudent, label, studentLabel]);

    return (
        <div className={CnStatusBar('task')}>
            {!isCompleted && <div className={CnStatusBar('task-count', { overdue })}>{tasks}</div>}
            <span className={CnStatusBar('task-label')}>{taskLabel}</span>
        </div>
    );
};
