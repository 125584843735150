import { Parallel } from '../ScheduleItem/ScheduleItem.types';

export const getClassTitleUtil = (parall: Parallel[]): string => {
    const parallels = parall.slice().sort((a, b) => a.id - b.id);
    if (parallels.length) {
        return parallels.reduce((label, parallel, index, parallels) => {
            if (parallels.length === 1) {
                return parallel.title.length === 1
                    ? parallel.title.slice(0, 1) + ' класс'
                    : parallel.title.slice(0, 2) + ' класс';
            }

            if (index === 0) {
                return parallel.titleShort ?? parallel.title.split(' ')[0];
            }

            if (index === parallels.length - 1) {
                return `${label}, ${parallel.titleShort ?? parallel.title.split(' ')[0]}`;
            }

            return `${label}, ${parallel.titleShort ?? parallel.title.split(' ')[0]}`;
        }, '');
    }

    return '';
};
