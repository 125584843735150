import React, { useCallback, useEffect, useRef } from 'react';
import { cn } from '@bem-react/classname';
import { useResizeTextarea } from '@lms-elements/hooks';

import { IInputProps } from './Input.types';

import './Input.scss';

const CnInput = cn('inputListInput');

export const Input: React.FC<IInputProps> = ({
    value,
    onRender,
    onBlur,
    onChange,
    onFocus,
    onKeyDown,
    placeholder,
}) => {
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const { handleTextareaInput, handleTextareaRender, handleTextareaResize } = useResizeTextarea();

    const handleWindowResize = useCallback(() => handleTextareaResize(textareaRef.current), [handleTextareaResize]);

    useEffect(() => {
        handleTextareaRender(textareaRef.current);
        onRender?.(textareaRef.current);
    }, [handleTextareaRender, onRender]);

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.addEventListener('resize', handleWindowResize);
        };
    }, [handleWindowResize]);

    return (
        <textarea
            spellCheck
            className={CnInput()}
            ref={textareaRef}
            value={value}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            onInput={handleTextareaInput}
            rows={1}
        />
    );
};
