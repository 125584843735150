import React, { SetStateAction } from 'react';
import { EditorState, Modifier } from 'draft-js';

interface IremoveEntityFromSelectionProps {
    editorState: EditorState;
    onChange: React.Dispatch<SetStateAction<EditorState>>;
}

export const removeEntityFromSelection = ({ editorState, onChange }: IremoveEntityFromSelectionProps) => {
    let selection = editorState.getSelection();
    const contentState = Modifier.applyEntity(editorState.getCurrentContent(), selection, null);
    let newEditorState = EditorState.push(editorState, contentState, 'apply-entity');
    selection = newEditorState.getSelection().merge({
        anchorOffset: selection.getAnchorOffset(),
        focusOffset: selection.getAnchorOffset(),
    });
    newEditorState = EditorState.acceptSelection(newEditorState, selection);

    onChange(newEditorState);
};
