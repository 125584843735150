import moment from 'moment';

type AddMinutesData = {
    date?: string | Date;
    minutes?: number;
};

export const addMinutes = ({ date, minutes = 0 }: AddMinutesData): string => {
    return moment(date).add(minutes, 'minutes').format();
};
