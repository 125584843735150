import React, { ReactElement, useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { useWindowResize } from '@lms-elements/hooks';
import { Basket } from '@lms-elements/icons';

import { Button, ButtonViewEnum } from '../../Button';
import { FieldDropdown } from '../../FieldDropdown';
import { TextareaField } from '../../TextareaField';

import { getFieldDropdownOptions } from './utils';

import './ThemeBlockForm.scss';

export interface Theme {
    themeId?: number;
    order: number;
    orderTitle?: string;
    name: string;
    description: string;
}

interface IThemeBlockFormProps {
    initialValues?: Theme;
    isEdit: boolean;
    overallThemes?: number;
    needPublishButton?: boolean;
    onPublishClick?: () => void;
    onCancelClick?: () => void;
    onCreateClick?: (value: Theme) => void;
    onEditClick?: (value: Theme) => void;
    onDeleteClick?: () => void;
}

const validate = (value: string) => (value ? undefined : 'error');
const CnThemeEdit = cn('theme_edit');

export const ThemeBlockForm: React.FC<IThemeBlockFormProps> = ({
    initialValues = {
        themeId: 1,
        order: 0,
        name: '',
        orderTitle: '',
        description: '',
    },
    isEdit = false,
    needPublishButton,
    onPublishClick,
    onCreateClick,
    onEditClick,
    onDeleteClick,
    onCancelClick,
    overallThemes = 0,
}) => {
    const { isTablet } = useWindowResize();

    initialValues.orderTitle = useMemo(() => {
        const orderOption = isEdit ? initialValues.order + 1 : initialValues.order;

        return `${orderOption} по порядку`;
    }, [initialValues, isEdit]);

    const handleFormSubmit = useCallback(
        (values) => {
            if (isEdit && onEditClick) {
                onEditClick(values);
            }
            if (!isEdit && onCreateClick) {
                onCreateClick(values);
            }
        },
        [onCreateClick, onEditClick, isEdit],
    );

    return (
        <div className={CnThemeEdit()}>
            <Form
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                key={`ThemeForm-${initialValues.themeId || 'newTheme'}`}
            >
                {({ handleSubmit }): ReactElement => (
                    <form id={`themeForm-${initialValues.themeId || 'newTheme'}`} onSubmit={handleSubmit}>
                        {isEdit ? (
                            <div className={CnThemeEdit('form')}>
                                <FieldDropdown
                                    options={getFieldDropdownOptions(overallThemes, [])}
                                    name={'orderTitle'}
                                    placeholder={`${initialValues.order} по порядку`}
                                    validators={[]}
                                    key={`EditForm-dropdown-${initialValues.themeId || 'newTheme'}`}
                                />
                                <TextareaField
                                    name={'name'}
                                    placeholder={'Название темы'}
                                    validators={[validate]}
                                    key={`EditForm-name_field-${initialValues.themeId || 'newTheme'}`}
                                    isField
                                />
                                <TextareaField
                                    name={'description'}
                                    placeholder={'Описание темы'}
                                    validators={[]}
                                    key={`EditForm-description_field-${initialValues.themeId || 'newTheme'}`}
                                    isField
                                />
                                <div className={CnThemeEdit('form-buttons')}>
                                    <div className={CnThemeEdit('form-buttons-row')}>
                                        <Button
                                            view={ButtonViewEnum.action}
                                            size={'l'}
                                            disabled={false}
                                            type="submit"
                                            key={`EditForm-edit-${initialValues.themeId || 'newTheme'}`}
                                        >
                                            {isTablet ? 'Сохранить' : 'Редактировать тему'}
                                        </Button>
                                        <Button
                                            view={ButtonViewEnum.bordered}
                                            size={'l'}
                                            disabled={false}
                                            key={`EditForm-return-${initialValues.themeId || 'newTheme'}`}
                                            onClick={onCancelClick}
                                        >
                                            Отмена
                                        </Button>
                                        <Button
                                            view={ButtonViewEnum.bordered}
                                            size={'l'}
                                            disabled={false}
                                            icon={<Basket />}
                                            key={`EditForm-delete-${initialValues.themeId || 'newTheme'}`}
                                            customClasses={CnThemeEdit('form-buttons-delete_button', {
                                                visible: Boolean(isEdit),
                                            })}
                                            onClick={onDeleteClick}
                                        />
                                    </div>
                                    {needPublishButton && (
                                        <div className={CnThemeEdit('form-buttons-row')}>
                                            <Button view={ButtonViewEnum.action} size={'l'} onClick={onPublishClick}>
                                                Опубликовать тему
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className={CnThemeEdit('form')} id="create">
                                <FieldDropdown
                                    options={getFieldDropdownOptions(overallThemes + 1, [])}
                                    name={'orderTitle'}
                                    placeholder={`${overallThemes + 1} по порядку`}
                                    key={`dropdown-${initialValues.themeId || 'newTheme'}`}
                                    validators={[]}
                                />
                                <TextareaField
                                    name={'name'}
                                    placeholder={'Название темы'}
                                    validators={[validate]}
                                    key={`CreateForm-name_field-${initialValues.themeId || 'newTheme'}`}
                                    isField
                                />
                                <TextareaField
                                    name={'description'}
                                    placeholder={'Описание темы'}
                                    validators={[]}
                                    key={`CreateForm-description_field-${initialValues.themeId || 'newTheme'}`}
                                    isField
                                />
                                <div className={CnThemeEdit('form-buttons-create')}>
                                    <Button
                                        view={ButtonViewEnum.action}
                                        size={'l'}
                                        disabled={false}
                                        type="submit"
                                        key={`CreateForm-create-${initialValues.themeId || 'newTheme'}`}
                                    >
                                        Создать тему
                                    </Button>
                                    <Button
                                        view={ButtonViewEnum.bordered}
                                        size={'l'}
                                        disabled={false}
                                        key={`CreateForm-return-${initialValues.themeId || 'newTheme'}`}
                                        onClick={onCancelClick}
                                    >
                                        Отмена
                                    </Button>
                                </div>
                            </div>
                        )}
                    </form>
                )}
            </Form>
        </div>
    );
};
