/**
 * return ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']
 */
export const getWeekdayNames = (): string[] => {
    return new Array(7).fill(' ').map((_, index) => {
        switch (index) {
            case 0:
                return 'Пн';
            case 1:
                return 'Вт';
            case 2:
                return 'Ср';
            case 3:
                return 'Чт';
            case 4:
                return 'Пт';
            case 5:
                return 'Сб';
            default:
                return 'Вс';
        }
    });
};
