import React, { useCallback, useMemo } from 'react';
import { cn } from '@bem-react/classname';

import { StatusBar } from '../../../StatusBar';

import { IClasscardStudentProps } from './ClasscardStudent.types';

const CnClassCard = cn('classcard');

export const ClasscardStudent: React.FC<IClasscardStudentProps> = ({
    createTasksLabel,
    hwCount,
    isCompleted,
    name,
    onClasscardClick,
    progressesOverdue,
    teacherText,
    onTeacherClick,
}): JSX.Element => {
    const studentOverdue = useMemo(() => !!progressesOverdue, [progressesOverdue]);
    const correctHwCount = useMemo(
        () => (studentOverdue ? progressesOverdue : hwCount),
        [studentOverdue, progressesOverdue, hwCount],
    );

    const handlerTeacherClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            onTeacherClick?.(e.ctrlKey || e.metaKey);
        },
        [onTeacherClick],
    );

    return (
        <div className={CnClassCard({ overdue: studentOverdue })} onClick={onClasscardClick}>
            <div className={CnClassCard('title')}>{name}</div>
            <div className={CnClassCard('info')}>
                <div className={CnClassCard('students')} onClick={handlerTeacherClick}>
                    <span className={CnClassCard('students-label')}>{teacherText}</span>
                </div>
            </div>

            <StatusBar
                isCompleted={isCompleted}
                tasks={correctHwCount}
                overdue={studentOverdue}
                isStudent
                createTasksLabel={createTasksLabel}
            />
        </div>
    );
};

export default React.memo(ClasscardStudent);
