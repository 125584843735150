import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgDropdownIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={16}
            height={9}
            viewBox="0 0 16 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d="M15.236 8.118L8.118 1 1 8.118" stroke="#000" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

const MemoSvgDropdownIcon = React.memo(SvgDropdownIcon);
export default MemoSvgDropdownIcon;
