import React, { useCallback, useMemo } from 'react';
import { cn } from '@bem-react/classname';
import {
    convertDateToHtmlDateString,
    getDateWithAbbreviatedMonth,
    getDayOfMonth,
    getTwoCharsDate,
    minusDaysToCurrentDate,
    plusDaysToCurrentDate,
} from '@lms-elements/utils';

import { ScheduleDateItem } from '../ScheduleDateItem';

import { CarouselItemsProps } from './CarouselItems.types';

import './CarouselItems.scss';
const CnScheduleDateList = cn('schedule-date-list');

const FIRST_DAY_OF_MONTH = 1;

export const CarouselItems: React.FC<CarouselItemsProps> = ({ onChange, selectedDate, carouselDate }) => {
    const renderCarousel = useMemo(() => {
        const start = minusDaysToCurrentDate(carouselDate, 2);
        const end = plusDaysToCurrentDate(carouselDate, 10);

        const result = [];

        for (let temp = start; temp < end; temp.setDate(temp.getDate() + 1)) {
            result.push(new Date(temp));
        }
        return result;
    }, [carouselDate]);

    const handleDateClick = useCallback(
        () => (event: React.MouseEvent<HTMLElement>) => {
            const date = event.currentTarget.dataset.temp;
            if (date) {
                onChange(new Date(date));
            }
        },
        [onChange],
    );

    return (
        <div className={CnScheduleDateList()}>
            {renderCarousel.map((temp) => {
                const formatedTempDate = convertDateToHtmlDateString(temp);
                return (
                    <div key={formatedTempDate} onClick={handleDateClick} data-temp={temp}>
                        <ScheduleDateItem
                            key={formatedTempDate}
                            selected={formatedTempDate === convertDateToHtmlDateString(selectedDate)}
                            text={
                                getDayOfMonth(temp) === FIRST_DAY_OF_MONTH
                                    ? getDateWithAbbreviatedMonth(temp)
                                    : getTwoCharsDate(temp)
                            }
                        />
                    </div>
                );
            })}
        </div>
    );
};
