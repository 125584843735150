import React, { useCallback, useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { checkIsBeetween, decreaseDate, getNumberMinutesBeforeDate, statusHomework } from '@lms-elements/utils';

import { Button, ButtonViewEnum } from '../Button';

import { ScheduleItemProps } from './ScheduleItem.types';

import './ScheduleItem.scss';

const CnScheduleItem = cn('schedule-item');

export const ScheduleItem: React.FC<ScheduleItemProps> = ({
    id,
    eventTypeInfo,
    datetimeEnd,
    datetimeStart,
    title,
    label,
    description,
    disabledActionButton,
    handleButtonActionClick,
}) => {
    const status = useMemo(() => statusHomework(datetimeStart, datetimeEnd), [datetimeStart, datetimeEnd]);

    const timeInfoText = useMemo(() => {
        if (status === 'future') {
            return `начнется в ${decreaseDate({ date: datetimeStart, format: 'HH:mm' })}`;
        }

        if (status === 'past') {
            return 'прошел';
        }

        return 'идет сейчас';
    }, [status, datetimeStart]);

    const isLinkReady = useMemo(() => {
        const linkReadyStart = decreaseDate({ date: datetimeStart, amount: 10, unit: 'm' });
        const isReady = checkIsBeetween(linkReadyStart, datetimeEnd);
        return isReady;
    }, [datetimeStart, datetimeEnd]);

    const [buttonView, buttonLabel] = useMemo(() => {
        return status === 'future' && !isLinkReady
            ? [ButtonViewEnum.bordered, `Через ${getNumberMinutesBeforeDate(datetimeStart)}`]
            : [ButtonViewEnum.action, 'Войти'];
    }, [status, isLinkReady, datetimeStart]);

    const handleButtonClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            if (handleButtonActionClick && id) {
                handleButtonActionClick(id);
            }
        },
        [id, handleButtonActionClick],
    );

    return (
        <div key={id} className={CnScheduleItem({ notLesson: !eventTypeInfo.buttonActive, status })}>
            <div className={CnScheduleItem('info')}>
                <div
                    className={CnScheduleItem('eventType')}
                    style={{ backgroundColor: eventTypeInfo.backgroundColor, color: eventTypeInfo.textColor }}
                >
                    {eventTypeInfo.displayTitle}
                </div>
                <div className={CnScheduleItem('title')}>{title}</div>
                {label && <div className={CnScheduleItem('parallelsTitle')}>{label}</div>}

                {description && (
                    <div className={CnScheduleItem('topicTitle')} title={description}>
                        {description}
                    </div>
                )}
            </div>
            <div>
                <div className={CnScheduleItem('statusInfo')}>
                    <div className={CnScheduleItem('indicator', { status })} />
                    <span className={CnScheduleItem('statusLabel', { status })}>{timeInfoText}</span>
                </div>
                {eventTypeInfo.buttonActive && status === 'past' && (
                    <div className={CnScheduleItem('pastTimeInfo')}>{`Сегодня в ${decreaseDate({
                        date: datetimeEnd,
                        format: 'HH:mm',
                    })}`}</div>
                )}
                {eventTypeInfo.buttonActive && status !== 'past' && (
                    <Button
                        size="m"
                        view={buttonView}
                        onClick={handleButtonClick}
                        disabled={!isLinkReady || disabledActionButton}
                    >
                        {buttonLabel}
                    </Button>
                )}
            </div>
        </div>
    );
};
