import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgTask({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={24}
            height={25}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                stroke="#585C6F"
                d="M16.857 5.75H20.5v16h-17v-16h3.643"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                stroke="#585C6F"
                d="M14 5.25a2 2 0 00-4 0H8v2.5h8v-2.5zM8.5 14.25l2 2 4.5-4.5"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgTask = React.memo(SvgTask);
export default MemoSvgTask;
