import { ReactElement } from 'react';

export enum AssignmentProgressStatus {
    DEFERRED_ASSIGNMENT = 'deferred_assignment',
    ON_CHECK = 'on_check',
    ASSIGNED = 'assigned',
    COMPLETED = 'completed',
}

export enum AssignmentLevels {
    ADMINISTRATIVE = 'administrative',
    TEACHER = 'teacher',
}

export enum ExecutionTimes {
    IN_CLASS = 'in_class',
    AT_HOME = 'at_home',
}

export enum VerificationTypes {
    MANUAL = 'manual',
    AUTO = 'auto',
}

export interface AssignmentType {
    id: number;
    title: string;
    attemptsQuantity: number;
    verificationType: VerificationTypes[]; // тип проверки: ручная или автоматическая
    executionTime: ExecutionTimes[]; // время выполнения задания: дома или на уроке
    isMandatory: boolean; // обязательный или необязательный
    assignmentLevel: AssignmentLevels;
    weight?: number;
    delayAutoCompletion?: number; // количество минут после soft-deadline для отправки ДЗ
}

export type HomeworkItemProps = {
    isShort?: boolean;
    isForPupils?: boolean;
    isOnCheck?: boolean;
    hasRecord?: boolean;
    lessonTitle?: string;
    lessonDescription?: string;
    assignedDate: string;
    taskId: number;
    subjectId?: number;
    assignmentCount?: number;
    onHomeworkClick?: (options: { subjectId: number; lessonId: number; taskId: number; groupId?: number }) => void;
    description?: string;
    deadline?: string;
    title?: string;
    lessonId?: number;
    type?: AssignmentType;
    withAttempts?: boolean;
    courseGroupId?: number;
    status: AssignmentProgressStatus;
    completeDate?: string;
    extraInfo?: { IconElement?: ReactElement; label: string; underTheDate?: boolean };
};
