export enum TooltipViewEnum {
    comment = 'comment',
    devices = 'devices',
    detailed = 'detailed',
    semester = 'semester',
    attendance = 'attendance',
    period = 'period',
    list = 'list',
    na = 'na',
}

export enum PointerPosition {
    LEFT = 'left',
    RIGHT = 'right',
    CENTER = 'center',
}

export enum Position {
    bottom = 'bottom',
    left = 'left',
    top = 'top',
    right = 'right',
}

export interface MarkMetaData {
    date: string;
    type: string;
    weight: number;
    isMark: boolean;
}

export interface AttendanceData {
    isMicDisabled: boolean;
    isCameraDisabled: boolean;
    isUserDisabled: boolean;
    micDescription: string;
    cameraDescription: string;
    userDescription: string;
}

export interface TooltipListData {
    title: string;
    list: {
        text: string;
        isValid: boolean;
    }[];
}

interface IFile {
    title: string;
    url: string;
}

export interface ITooltipProps {
    view?: TooltipViewEnum;
    approved?: boolean;
    average?: number | string;
    metaData?: MarkMetaData;
    attandanceData?: AttendanceData;
    pointerPosition?: PointerPosition;
    pointerSide?: Position;
    commentLabel?: string;
    filesLabel?: string;
    files?: IFile[];
    withPointer?: boolean;
    isError?: boolean;
    listData?: TooltipListData;
    author?: string;
}
