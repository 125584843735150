import React, { useCallback, useRef, useState } from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { FormSpy } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { PlanTableCell } from '@lms-elements/atomic';
import { useDropdownDots, useThrottle } from '@lms-elements/hooks';
import { SixDots, Trash } from '@lms-elements/icons';

import { EducationPlanValues } from '../../EducationalPlan.types';
import { ERROR_MESSAGES } from '../CourseTable.constants';
import { HoursInYear, NumberWithFraction } from '../utils';

import { CourseTableRowProps } from './CourseTableRow.types';

import './CourseTableRow.scss';

const CnCourseTableSubjectRow = cn('course-table-subject-row');

export const CuorseTableRow: React.FC<CourseTableRowProps> = ({
    index,
    name,
    courseName,
    handleDeleteAndRemoveField,
    handleSwap,
    fieldsValue,
    isTablet,
    typeCourse,
    interationCount,
    isMain,
    readOnly = false,
    calendarPlanOptions,
}) => {
    const ref = useRef<HTMLDivElement>(null);

    const { canDrag, handleDropdownDotsMouseEnter, handleDropdownDotsMouseLeave } = useDropdownDots();

    const lastDifferenceFromInitialOffsetY = useRef<number>();

    const onDropZoneHover = useCallback(
        (item: { index: number; courseName: string }, monitor: DropTargetMonitor) => {
            const currentDifferenceFromInitialOffsetY = monitor.getDifferenceFromInitialOffset()?.y;
            if (lastDifferenceFromInitialOffsetY.current === currentDifferenceFromInitialOffsetY) {
                return;
            }

            lastDifferenceFromInitialOffsetY.current = currentDifferenceFromInitialOffsetY;

            const dragIndex = item.index;
            const dragName = item.courseName;
            const hoverIndex = index;
            const hoverName = courseName;

            if (dragIndex === hoverIndex || dragName !== hoverName) {
                return;
            }

            handleSwap(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
        [index, courseName, handleSwap],
    );
    const handleCourseDeleteAndRemove = useCallback(() => {
        handleDeleteAndRemoveField(index);
    }, [handleDeleteAndRemoveField, index]);

    const throttledOnDropZoneHover = useThrottle(onDropZoneHover, 500);

    const [, drop] = useDrop({
        accept: 'subject',
        hover: throttledOnDropZoneHover,
    });

    const [{ isDragging }, drag] = useDrag(
        () => ({
            item: {
                index,
                courseName,
            },
            type: 'subject',
            canDrag: !readOnly && canDrag,
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [index, canDrag],
    );
    drag(drop(ref));

    const onMouseEnter = useCallback(() => {
        handleDropdownDotsMouseEnter();
    }, [handleDropdownDotsMouseEnter]);
    const [isSelected, seIsSelected] = useState(false);

    const handleMouseEnter = useCallback(() => !readOnly && seIsSelected(true), [readOnly]);
    const handleMouseLeave = useCallback(() => !readOnly && seIsSelected(false), [readOnly]);

    return (
        <div className={CnCourseTableSubjectRow({ hidden: isDragging })} ref={ref}>
            <div className={CnCourseTableSubjectRow('container')} ref={drag}>
                <div
                    className={CnCourseTableSubjectRow('subject', {
                        hoverable: isSelected,
                        tablet: isTablet,
                    })}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <div
                        className={CnCourseTableSubjectRow('dots', { preview: !isSelected })}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={handleDropdownDotsMouseLeave}
                    >
                        <SixDots />
                    </div>
                    <div title={fieldsValue?.name} className={CnCourseTableSubjectRow('label')}>
                        {fieldsValue.name}
                    </div>
                    <div className={CnCourseTableSubjectRow('icon-delete')} onClick={handleCourseDeleteAndRemove}>
                        <Trash />
                    </div>
                </div>
                <div className={CnCourseTableSubjectRow('table')}>
                    <FormSpy<EducationPlanValues> subscription={{ values: true }}>
                        {({ values }) => (
                            <PlanTableCell
                                fieldName={`${name}.inWeek`}
                                width={`${100 / interationCount.length}%`}
                                isError={
                                    Number(values[typeCourse][index].inWeek ?? 0) <
                                    Number(values[typeCourse][index].lecturesInWeek ?? 0) +
                                        Number(values[typeCourse][index].lessonsInWeek ?? 0)
                                }
                                errorMessage={ERROR_MESSAGES.LESSONS_SUM_IN_WEEK_ERROR}
                                isByHand
                                allowFractional
                                numericalLimit={100}
                                validators={[]}
                            />
                        )}
                    </FormSpy>
                    <FormSpy<EducationPlanValues> subscription={{ values: true }}>
                        {({ values }) => (
                            <PlanTableCell
                                fieldName={`${name}.inYear`}
                                width={`${100 / interationCount.length}%`}
                                initValue={HoursInYear(
                                    values.calendarPlan,
                                    calendarPlanOptions,
                                    Number(values[typeCourse][index].inWeek),
                                )}
                                isError={NumberWithFraction(Number(values[typeCourse][index].inYear ?? 0))}
                                errorMessage={ERROR_MESSAGES.FRACTIONAL_IN_YEAR_ERR}
                                allowFractional
                                validators={[]}
                                withValue
                            />
                        )}
                    </FormSpy>
                    <PlanTableCell
                        fieldName={`${name}.fact`}
                        width={`${100 / interationCount.length}%`}
                        allowFractional
                        validators={[]}
                    />
                    <PlanTableCell
                        fieldName={`${name}.lessonsInWeek`}
                        width={`${100 / interationCount.length}%`}
                        isByHand
                        allowFractional
                        numericalLimit={100}
                        validators={[]}
                    />
                    <FormSpy<EducationPlanValues> subscription={{ values: true }}>
                        {({ values }) => (
                            <PlanTableCell
                                fieldName={`${name}.lessonsInYear`}
                                width={`${100 / interationCount.length}%`}
                                initValue={String(
                                    HoursInYear(
                                        values.calendarPlan,
                                        calendarPlanOptions,
                                        Number(values[typeCourse][index].lessonsInWeek),
                                    ),
                                )}
                                isError={NumberWithFraction(Number(values[typeCourse][index].lessonsInYear ?? 0))}
                                errorMessage={ERROR_MESSAGES.FRACTIONAL_IN_YEAR_ERR}
                                allowFractional
                                validators={[]}
                                withValue
                            />
                        )}
                    </FormSpy>
                    {isMain && (
                        <>
                            <PlanTableCell
                                fieldName={`${name}.lecturesInWeek`}
                                width={`${100 / interationCount.length}%`}
                                isByHand
                                allowFractional
                                numericalLimit={100}
                                validators={[]}
                            />
                            <FormSpy<EducationPlanValues> subscription={{ values: true }}>
                                {({ values }) => (
                                    <PlanTableCell
                                        fieldName={`${name}.lecturesInYear`}
                                        width={`${100 / interationCount.length}%`}
                                        initValue={HoursInYear(
                                            values.calendarPlan,
                                            calendarPlanOptions,
                                            Number(values[typeCourse][index].lecturesInWeek),
                                        )}
                                        isError={NumberWithFraction(
                                            Number(values[typeCourse][index].lecturesInYear ?? 0),
                                        )}
                                        errorMessage={ERROR_MESSAGES.FRACTIONAL_IN_YEAR_ERR}
                                        allowFractional
                                        validators={[]}
                                        withValue
                                    />
                                )}
                            </FormSpy>
                        </>
                    )}
                    <FormSpy<EducationPlanValues> subscription={{ values: true }}>
                        {({ values }) => (
                            <PlanTableCell
                                fieldName={`${name}.selection`}
                                width={`${100 / interationCount.length}%`}
                                types={[
                                    { label: 'Не выбрано', value: 'Не выбрано' },
                                    { label: 'Дифференцируемый зачет', value: 'Диф' },
                                    { label: 'Зачет', value: 'Зачет' },
                                ]}
                                isField
                                initValue={
                                    values[typeCourse][index].selection !== undefined
                                        ? values[typeCourse][index].selection
                                        : 'Не выбрано'
                                }
                                isSelection
                                validators={[]}
                            />
                        )}
                    </FormSpy>
                </div>
            </div>
        </div>
    );
};
