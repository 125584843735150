import React, { useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { Notebook } from '@lms-elements/icons';

import { IClasscardOLStudentProps } from './ClasscardOLStudent.types';

const CnClassCard = cn('classcard');

const ClasscardOLStudent: React.FC<IClasscardOLStudentProps> = ({
    courseType,
    lessonsLabel,
    isSubscribed,
    name,
    onClasscardClick,
    themeLabel,
}): JSX.Element => {
    const subjectLabel = useMemo(() => `${name}${courseType ? `, ${courseType}` : ''}`, [name, courseType]);
    const subjectInfoLabel = useMemo(() => `${themeLabel}, ${lessonsLabel}`, [themeLabel, lessonsLabel]);

    return (
        <div className={CnClassCard({ isOLStudent: true, isSubscribed })} onClick={onClasscardClick}>
            <div className={CnClassCard('title', { isOLStudent: true })}>{subjectLabel}</div>
            <div className={CnClassCard('info')}>
                <div className={CnClassCard('lessons', { isOLStudent: true })}>
                    <span className={CnClassCard('lessons-icon')}>
                        <Notebook />
                    </span>
                    <span className={CnClassCard('lessons-label')}>{subjectInfoLabel}</span>
                </div>
            </div>
        </div>
    );
};

export default React.memo(ClasscardOLStudent);
