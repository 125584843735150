import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgQuestions({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={24}
            height={25}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M10.95 13.98a2.254 2.254 0 01.033-.952c.09-.288.211-.552.367-.792.167-.24.35-.462.55-.663.211-.212.406-.413.583-.605.19-.202.345-.4.467-.591.122-.192.183-.399.183-.62 0-.346-.122-.624-.366-.836-.234-.21-.561-.317-.984-.317-.344 0-.655.068-.933.202a3.128 3.128 0 00-.783.548L9 8.504c.367-.365.8-.663 1.3-.894a3.988 3.988 0 011.717-.36c.433 0 .833.053 1.2.159.366.096.677.244.933.446.267.202.472.452.617.75.155.297.233.643.233 1.037 0 .307-.067.586-.2.836-.122.24-.278.47-.467.691-.189.221-.394.433-.616.634a6.604 6.604 0 00-.584.634 3.6 3.6 0 00-.416.706 1.6 1.6 0 00-.084.836H10.95zm.85 3.27c-.356 0-.65-.106-.883-.317a1.04 1.04 0 01-.35-.807c0-.327.116-.596.35-.807.233-.211.527-.317.883-.317s.65.106.883.317c.245.211.367.48.367.807 0 .327-.122.596-.367.807-.233.211-.527.317-.883.317z"
                fill="#585C6E"
            />
            <circle cx={12} cy={12.25} r={9.25} stroke="#585C6E" strokeWidth={1.5} />
        </svg>
    );
}

const MemoSvgQuestions = React.memo(SvgQuestions);
export default MemoSvgQuestions;
