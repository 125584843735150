import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgFormulasIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M9.317 24.94c-.233 0-.39-.118-.472-.355l-3.28-9.88-.04-.209a.43.43 0 01.123-.314.46.46 0 01.307-.125h.78c.122 0 .225.035.307.104.095.07.157.147.184.23l2.726 8.352 4.202-15.3a.996.996 0 01.184-.335.443.443 0 01.349-.168h3.566c.15 0 .266.042.348.126.082.084.123.202.123.356v.607a.49.49 0 01-.143.356.438.438 0 01-.328.125h-2.644l-4.427 15.907c-.055.182-.123.314-.205.398a.438.438 0 01-.328.125H9.317zM17.2 24.94a.412.412 0 01-.308-.125.43.43 0 01-.123-.314c0-.126.048-.258.143-.398l3.567-4.73-3.341-4.5c-.11-.153-.164-.28-.164-.377a.43.43 0 01.123-.314.46.46 0 01.307-.125h.984c.205 0 .383.111.533.335l2.767 3.662 2.767-3.662c.082-.112.157-.196.225-.252a.534.534 0 01.308-.083h.942a.39.39 0 01.288.125.406.406 0 01.122.293c0 .14-.047.272-.143.398l-3.382 4.542 3.566 4.688a.71.71 0 01.144.398.43.43 0 01-.123.314.412.412 0 01-.308.125H25.09c-.205 0-.382-.104-.533-.314l-2.93-3.872-2.931 3.872a1.24 1.24 0 01-.247.23.442.442 0 01-.286.084h-.964z"
                fill="#252232"
            />
        </svg>
    );
}

const MemoSvgFormulasIcon = React.memo(SvgFormulasIcon);
export default MemoSvgFormulasIcon;
