import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { TextareaField } from '@lms-elements/atomic';
import { Basket } from '@lms-elements/icons';
import { getObjectPropertyByString } from '@lms-elements/utils';

import { ITextareaFieldWrapperProps } from './TextareaFieldWrapper.types';

import './TextareaFieldWrapper.scss';

const TextareaFieldWrapperCn = cn('textarea-field-wrapper');

export const TextareaFieldWrapper: React.FC<ITextareaFieldWrapperProps> = ({
    name,
    placeholder,
    onDelete,
    isStudentView,
}) => {
    const { values } = useFormState();
    const { change } = useForm();

    const [value, setValue] = useState(getObjectPropertyByString<string>(values, name) || '');

    // !!! DONT TOUCH
    useEffect(() => {
        setValue(() => getObjectPropertyByString<string>(values, name) || '');
    }, [name, values]);

    const onClear = useCallback(() => {
        setValue('');
        change(name, '');
    }, [change, name]);

    const onChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value), []);

    const onBlur = useCallback(() => change(name, value), [change, name, value]);

    const isError = useMemo(() => value?.trim().length === 0, [value]);

    return (
        <Field name={name} validate={(value: string) => (value ? undefined : 'error')}>
            {() => (
                <>
                    {isStudentView ? (
                        <p className={TextareaFieldWrapperCn('student')}>{value}</p>
                    ) : (
                        <>
                            <h3 className={TextareaFieldWrapperCn('header')}>Текст</h3>
                            <div className={TextareaFieldWrapperCn()}>
                                <TextareaField
                                    className={TextareaFieldWrapperCn('textarea')}
                                    isField={false}
                                    placeholder={placeholder}
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    isError={isError}
                                    onClear={onClear}
                                />
                                <button className={TextareaFieldWrapperCn('delete')} onClick={onDelete} type="button">
                                    <Basket />
                                </button>
                            </div>
                        </>
                    )}
                </>
            )}
        </Field>
    );
};
