import React from 'react';
import { Field } from 'react-final-form';
import { composeValidators } from '@lms-elements/utils';

import { DropdownCustomProps } from './DropDownCustom.types';
import { DropdownCustomCore, IDropdownValues } from './DropdownCustomCore';

export const DropdownCustom: React.FC<DropdownCustomProps> = ({ isField, name, getValue, validators, ...props }) => {
    return isField ? (
        <Field<IDropdownValues> name={name} validate={composeValidators(...validators)}>
            {({ input, meta }) => <DropdownCustomCore {...props} input={input} meta={meta} getValue={getValue} />}
        </Field>
    ) : (
        <DropdownCustomCore {...props} getValue={getValue} />
    );
};
