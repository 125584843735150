import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgNewsIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={16}
            height={14}
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M4.44 11.464c0 1.294-.88 1.645-1.32 1.66h10.598c1.123 0 1.49-1.107 1.532-1.66V3.165c0-1.532-1.135-1.915-1.703-1.915H6.27c-1.498 0-1.844 1.277-1.83 1.915v8.299z"
                stroke="#0088E9"
                strokeLinejoin="round"
            />
            <rect x={6.398} y={5.422} width={7.405} height={1.192} rx={0.596} stroke="#0088e9" />
            <rect x={10.994} y={7.891} width={2.809} height={1.192} rx={0.596} stroke="#0088e9" />
            <rect x={10.994} y={10.016} width={2.809} height={1.192} rx={0.596} stroke="#0088e9" />
            <rect x={6.398} y={7.891} width={3.575} height={3.32} rx={1} stroke="#0088e9" />
            <rect x={6.398} y={2.953} width={7.405} height={1.192} rx={0.596} stroke="#0088e9" />
            <path d="M2.683 5.117v5.8a.967.967 0 11-1.933 0V7.051c0-1.068.866-1.934 1.933-1.934z" stroke="#0088e9" />
        </svg>
    );
}

const MemoSvgNewsIcon = React.memo(SvgNewsIcon);
export default MemoSvgNewsIcon;
