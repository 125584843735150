import { RawDraftContentBlock } from 'draft-js';

import { BlockTypes, ListTypes } from '../../types/shared.types';

import { getBlockInnerMarkup } from './block';
import { CustomEntityTransform, EntityMap, HashtagConfig } from './draftToHtml.types';
import { getBlockTag } from './markup';
import { getBlockStyle } from './style';

/**
 * Function to check if a block is of type list.
 */
export const isList = (blockType: string) =>
    blockType === ListTypes.ORDERED_LIST_ITEM || blockType === ListTypes.UNORDERED_LIST_ITEM;

/**
 * Function will return html markup for a list block.
 */
export const getListMarkup = (
    listBlocks: RawDraftContentBlock[],
    entityMap: EntityMap,
    hashtagConfig?: HashtagConfig,
    directional?: boolean,
    customEntityTransform?: CustomEntityTransform,
) => {
    const getListTag = (type: string) => getBlockTag(type as BlockTypes) || 'li';

    const listHtml: string[] = [];
    let nestedListBlock: RawDraftContentBlock[] = [];
    let previousBlock: RawDraftContentBlock | undefined = undefined;
    listBlocks.forEach((block) => {
        let nestedBlock = false;
        if (!previousBlock) {
            listHtml.push(`<${getListTag(block.type as BlockTypes)}>\n`);
        } else if (previousBlock.type !== block.type) {
            listHtml.push(`</${getListTag(previousBlock.type as BlockTypes)}>\n`);
            listHtml.push(`<${getListTag(block.type as BlockTypes)}>\n`);
        } else if (previousBlock.depth === block.depth) {
            if (nestedListBlock && nestedListBlock.length > 0) {
                listHtml.push(
                    getListMarkup(nestedListBlock, entityMap, hashtagConfig, directional, customEntityTransform),
                );
                nestedListBlock = [];
            }
        } else {
            nestedBlock = true;
            nestedListBlock.push(block);
        }
        if (!nestedBlock) {
            listHtml.push('<li');
            const blockStyle = getBlockStyle(block.data);
            if (blockStyle) {
                listHtml.push(` style="${blockStyle}"`);
            }
            if (directional) {
                listHtml.push(' dir = "auto"');
            }
            listHtml.push('>');
            listHtml.push(getBlockInnerMarkup(block, entityMap, hashtagConfig, customEntityTransform));
            listHtml.push('</li>\n');
            previousBlock = block;
        }
    });
    if (nestedListBlock && nestedListBlock.length > 0) {
        listHtml.push(getListMarkup(nestedListBlock, entityMap, hashtagConfig, directional, customEntityTransform));
    }
    listHtml.push(`</${getListTag((previousBlock as unknown as RawDraftContentBlock).type as BlockTypes)}>\n`);
    return listHtml.join('');
};
