import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgQuote({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M7.834 22.732c-.611 0-1.073-.221-1.385-.664-.3-.455-.449-1.086-.449-1.892 0-.924.228-1.737.683-2.44.35-.54.842-1.006 1.477-1.4.328-.204.75-.058.923.288.173.346.03.76-.288.982-.298.208-.54.434-.727.677-.253.334-.394.75-.423 1.247-.006.097.092.177.19.177.415 0 .767.13 1.053.39.299.248.449.592.449 1.035 0 .494-.143.884-.43 1.17-.286.287-.644.43-1.073.43zm4.605 0c-.611 0-1.073-.221-1.385-.664-.3-.455-.45-1.086-.45-1.892 0-.924.229-1.737.684-2.44.35-.54.842-1.006 1.477-1.4.328-.204.75-.058.922.288.174.346.03.76-.287.982-.298.208-.54.434-.727.677-.253.334-.395.75-.424 1.247-.005.097.093.177.19.177.416 0 .768.13 1.054.39.299.248.448.592.448 1.035 0 .494-.143.884-.429 1.17-.286.287-.644.43-1.073.43zM24.166 8.999c.611 0 1.073.22 1.385.663.3.455.449 1.086.449 1.893 0 .923-.228 1.736-.683 2.439-.35.54-.842 1.007-1.477 1.4-.328.205-.75.058-.923-.287-.173-.346-.03-.761.288-.982.298-.208.54-.434.727-.678.253-.334.394-.75.423-1.247.006-.096-.092-.177-.19-.177-.415 0-.767-.13-1.053-.39-.299-.247-.449-.592-.449-1.034 0-.495.143-.885.43-1.171.286-.286.644-.43 1.073-.43zm-4.605 0c.611 0 1.073.22 1.385.663.3.455.45 1.086.45 1.893 0 .923-.229 1.736-.684 2.439-.35.54-.842 1.007-1.477 1.4-.328.205-.75.058-.922-.287-.173-.346-.03-.761.287-.982.298-.208.54-.434.727-.678.253-.334.394-.75.423-1.247.006-.096-.092-.177-.189-.177-.416 0-.767-.13-1.054-.39-.299-.247-.449-.592-.449-1.034 0-.495.144-.885.43-1.171.286-.286.644-.43 1.073-.43z"
                fill="#EA5959"
            />
        </svg>
    );
}

const MemoSvgQuote = React.memo(SvgQuote);
export default MemoSvgQuote;
