import React, { useCallback, useMemo } from 'react';
import { cn } from '@bem-react/classname';

import { ITableContentStudentProps } from './TableContentStudent.types';

import './TableContentStudent.scss';

const CnTableContent = cn('table-content-student');

export const TableContentStudent = React.memo<ITableContentStudentProps>(
    ({ tableName, tableWidths, tableHTML, cellClassName }) => {
        const checkBorder = useCallback((value: number, checkValue: number) => value < checkValue - 1, []);

        const contentMaxWidthStyle = useMemo(
            () => ({ maxWidth: `${tableWidths.reduce((sum, cur) => sum + cur + 2, 0)}px` }),
            [tableWidths],
        );

        const contentStyle = useMemo<React.CSSProperties>(
            () => ({ width: `${tableWidths.reduce((sum, cur) => sum + cur + 2, 0)}px` }),
            [tableWidths],
        );

        const makeCellWidthStyle = useCallback(
            (x: number): React.CSSProperties => ({ width: `${tableWidths[x]}px` }),
            [tableWidths],
        );

        return (
            <div className={CnTableContent()} style={contentMaxWidthStyle}>
                {tableHTML.map((row, y) => (
                    <div
                        key={`ContentRow${tableName}${y}`}
                        className={CnTableContent('row', { border: checkBorder(y, tableHTML.length) })}
                        style={contentStyle}
                    >
                        {row.map((cell, x) => (
                            <div
                                className={CnTableContent('cell', {
                                    border: checkBorder(x, row.length),
                                })}
                                key={`ContentCell${tableName}${x}_${y}`}
                                style={makeCellWidthStyle(x)}
                            >
                                <div className={CnTableContent('text')}>
                                    <div className={cellClassName} dangerouslySetInnerHTML={{ __html: cell }} />
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    },
);
TableContentStudent.displayName = 'TableContentStudent';
