import React, { useMemo } from 'react';
import { cn } from '@bem-react/classname';

import { IPercentageProps, PercentageColor } from './Percentage.types';

import './Percentage.scss';

const CnPercentage = cn('percentage');

export const Percentage: React.FC<IPercentageProps> = ({ percent, middleFrom = 70, highFrom = 80, shouldRound }) => {
    const correctPercent = useMemo(() => (shouldRound ? Math.round(percent) : percent), [percent, shouldRound]);

    const color = useMemo(() => {
        if (correctPercent >= highFrom) {
            return PercentageColor.green;
        }
        if (correctPercent >= middleFrom) {
            return PercentageColor.yellow;
        }
        return PercentageColor.red;
    }, [correctPercent, highFrom, middleFrom]);

    return (
        <div className={CnPercentage({ color })}>
            <span className={CnPercentage('text')}>{correctPercent}%</span>
        </div>
    );
};
