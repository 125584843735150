import React, { useCallback } from 'react';
import { cn } from '@bem-react/classname';

import { ResizeButton } from '../../ResizeButton';

import { IHorizontalResizeButtonGroupProps } from './HorizontalResizeButtonGroup.types';

import './HorizontalResizeButtonGroup.scss';

const CnResizeButtonGroup = cn('horizontal-resize-button-group');

export const HorizontalResizeButtonGroup = React.memo<IHorizontalResizeButtonGroupProps>(
    ({ heights, tableName, setIsResizing, setNeedToFocus, onClickAddRows, handleTableResize, minHeights, width }) => {
        const getResizeButtonStyle = useCallback(
            (row: number): React.CSSProperties => ({
                top: heights.slice(0, row).reduce((sum, height) => {
                    return sum + height;
                }, 0),
            }),
            [heights],
        );

        return (
            <div className={CnResizeButtonGroup()} style={{ width: `${width}px` }}>
                {Array.from({ length: heights.length + 1 }).map((_, y) => (
                    <div
                        style={getResizeButtonStyle(y)}
                        key={`ResizeHorizontalButton${y}`}
                        className={CnResizeButtonGroup('button')}
                    >
                        <ResizeButton
                            tableName={tableName}
                            index={y - 1}
                            newColumn={y}
                            onPlusClick={onClickAddRows}
                            handleChangeHeight={y != 0 ? handleTableResize : undefined}
                            height={y != 0 ? heights[y - 1] : undefined}
                            minHeight={y != 0 ? minHeights[y - 1] : undefined}
                            setIsResizing={setIsResizing}
                            setNeedToFocus={setNeedToFocus}
                        />
                    </div>
                ))}
            </div>
        );
    },
);

HorizontalResizeButtonGroup.displayName = 'HorizontalResizeButtonGroup';
