import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgTableIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M4 7h24v17a1 1 0 01-1 1H5a1 1 0 01-1-1V7zM4 13h24M4 19h24M11 13v12"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgTableIcon = React.memo(SvgTableIcon);
export default MemoSvgTableIcon;
