import { deepEqual } from './deepEqual';

/**
 * if the arrays contain the same elements regardless of their order returns true otherwise false
 * @param a any array
 * @param b any array
 * @param comp comparator function
 * @returns boolean
 */
export const equalItemsInArrays = <T extends unknown>(a: T[], b: T[], comp?: (a: T, b: T) => number): boolean => {
    if (a.length !== b.length) {
        return false;
    }
    const sortedA = a.sort(comp);
    const sortedB = b.sort(comp);

    return deepEqual(sortedA, sortedB);
};
