import moment from 'moment';

import { capitalizeWord } from './';

export const getCapitalizeStartOfWeek = (date: Date | string, weekWithLetterMonth: boolean): string => {
    const startOfWeek = moment(date)
        .startOf('week')
        .format(weekWithLetterMonth ? 'D MMMM YYYY' : 'D.MM');

    return capitalizeWord(startOfWeek, 2);
};
