import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgArrowTop({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg viewBox="0 0 15 27" aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path d="M6.486 26a1 1 0 102 0h-2zM8.193.293a1 1 0 00-1.414 0L.415 6.657A1 1 0 001.83 8.07l5.657-5.657 5.657 5.657a1 1 0 101.414-1.414L8.193.293zM8.486 26V1h-2v25h2z" />
        </svg>
    );
}

const MemoSvgArrowTop = React.memo(SvgArrowTop);
export default MemoSvgArrowTop;
