import React, { useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { OrderedListIcon, UnorderedListIcon } from '@lms-elements/icons';
import { RichUtils } from 'draft-js';
import { getSelectedBlock } from 'draftjs-utils';

import { ListTypes } from '../../types/shared.types';
import { OptionButton } from '../OptionButton';

import { IBlockOptionProps } from './BlockOption.types';

import './BlockOption.scss';

const CnBlockOption = cn('block-option');

export const BlockOption: React.FC<IBlockOptionProps> = ({ editorState, onChange, type }) => {
    const toggleBlockType = () => {
        const newState = RichUtils.toggleBlockType(editorState, type);
        if (newState) {
            onChange(newState);
        }
    };

    const active = useMemo(() => (getSelectedBlock(editorState).get('type') as string) === type, [editorState, type]);

    const Icon = useMemo(() => {
        switch (type) {
            case ListTypes.ORDERED_LIST_ITEM:
                return OrderedListIcon;
            case ListTypes.UNORDERED_LIST_ITEM:
                return UnorderedListIcon;
            default:
                return OrderedListIcon;
        }
    }, [type]);

    return (
        <OptionButton active={active} onClick={toggleBlockType}>
            <Icon className={CnBlockOption('icon', { type, active })} />
        </OptionButton>
    );
};
