import React, { useCallback, useMemo } from 'react';
import { useField } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { Basket } from '@lms-elements/icons';

import { IImageValues } from '../ImageUploader.types';

import { IImageItemProps } from './ImageItem.types';

import './ImageItem.scss';

const ImageItemCn = cn('imageUploader-image-item');

export const ImageItem: React.FC<IImageItemProps> = ({ name, onDeleteImage }) => {
    const { input } = useField<IImageValues>(name);

    const data = useMemo(() => input.value?.values.imageData?.data, [input.value.values]);
    const src = useMemo(() => {
        if (typeof data?.data === 'string') {
            return data.data;
        }

        try {
            const src = URL.createObjectURL(data?.data);
            return src;
        } catch {
            return '';
        }
    }, [data]);

    const handleDelete = useCallback(() => {
        const id = input.value.values.imageData.data.id;
        if (id && onDeleteImage) {
            onDeleteImage(id);
        }

        const images = null;

        input.onChange({
            ...input.value,
            values: {
                imageData: {
                    ...input.value.values.imageData,
                    data: {
                        data: images,
                    },
                },
            },
        });
    }, [input, onDeleteImage]);

    return (
        <div className={ImageItemCn({})}>
            <img src={src} />
            <button type="button" className={ImageItemCn('delete')} onClick={handleDelete}>
                <Basket />
            </button>
        </div>
    );
};
