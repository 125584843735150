import React, { useCallback, useMemo, useState } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useField } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { Basket, NoteBlank, PaperclipIcon } from '@lms-elements/icons';
import { isFilenameInAvailableExtensions, isURL } from '@lms-elements/utils';

import { ACCEPT_FILE_TYPES, FIFTEEN_MB_IN_BYTES } from './ExpandedDocumentMaterialTeacher.constants';
import {
    IDocumentData,
    IDropItem,
    IExpandedDocumentMaterialTeacherProps,
} from './ExpandedDocumentMaterialTeacher.types';

import './ExpandedDocumentMaterialTeacher.scss';

const ExpandedDocumentMaterialCn = cn('expanded-document-material-teacher');

const INPUT_ACCEPT_FILE_TYPES = ACCEPT_FILE_TYPES.concat(ACCEPT_FILE_TYPES.map((type) => type.toUpperCase())).join(',');

export const ExpandedDocumentMaterialTeacher: React.FC<IExpandedDocumentMaterialTeacherProps> = ({
    name,
    onDelete,
}) => {
    const { input, meta } = useField<IDocumentData>(name);

    const [fileError, setFileError] = useState(false);

    const [url, setUrl] = useState('');

    const fileNames = useMemo(
        () => input.value.documentData.map((f) => (typeof f === 'string' ? f : f.name)),
        [input.value],
    );

    const [, drop] = useDrop(
        () => ({
            accept: [NativeTypes.FILE],
            drop: (item: IDropItem) => {
                const file = item.files[0];
                if (file.name && !isFilenameInAvailableExtensions(file.name.toLowerCase(), ACCEPT_FILE_TYPES)) {
                    return;
                }
                if (file.size <= FIFTEEN_MB_IN_BYTES) {
                    const { documentData } = input.value;
                    documentData.push(file);
                    input.onChange({
                        ...input.value,
                        documentData,
                    });
                    return;
                }
                setFileError(true);
                setTimeout(() => setFileError(false), 6000);
            },
        }),
        [input],
    );

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const file = e.target?.files?.[0];
            if (file) {
                const { documentData } = input.value;
                documentData.push(file);
                input.onChange({
                    ...input.value,
                    documentData,
                });
            }
        },
        [input],
    );

    const makeRemoveHandler = useCallback(
        (index: number) => () => {
            // fields.remove(index)
            const [...newData] = input.value.documentData;
            newData.splice(index, 1);

            input.onChange({
                ...input.value,
                documentData: newData,
            });
        },
        [input],
    );

    const handleUrlChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setUrl(e.target.value), []);

    const handleUrlBlur = useCallback(() => {
        if (isURL(url)) {
            const { documentData } = input.value;
            documentData.push(url);
            input.onChange({
                ...input.value,
                documentData,
            });
            setUrl('');
        }
    }, [input, url]);

    const urlError = useMemo(() => !!url && !isURL(url), [url]);

    return (
        <div>
            <h3 className={ExpandedDocumentMaterialCn('material-title')}>Файл</h3>
            <div ref={drop} className={ExpandedDocumentMaterialCn({ error: !meta.valid })}>
                <h4 className={ExpandedDocumentMaterialCn('title')}>Загрузите файлы</h4>
                {!!fileNames.length && (
                    <div className={ExpandedDocumentMaterialCn('file-list')}>
                        {fileNames.map((fileName, index) => (
                            <div key={fileName} className={ExpandedDocumentMaterialCn('file-list-item')}>
                                <NoteBlank />
                                <p>{fileName}</p>
                                <button type="button" onClick={makeRemoveHandler(index)}>
                                    <Basket />
                                </button>
                            </div>
                        ))}
                    </div>
                )}
                <div className={ExpandedDocumentMaterialCn('bottom', { row: !!fileNames.length })}>
                    <div className={ExpandedDocumentMaterialCn('bottom-text')}>
                        <span className={ExpandedDocumentMaterialCn('file-input-label')}>
                            перетащив файл сюда или
                            <label>
                                укажите путь
                                <input type="file" accept={INPUT_ACCEPT_FILE_TYPES} onChange={handleChange} />
                            </label>
                        </span>
                        <span className={ExpandedDocumentMaterialCn('mime-types', { error: fileError })}>
                            {fileError ? 'слишком большой файл' : 'размер файла - до 15mb'}
                        </span>
                    </div>
                    <input
                        className={ExpandedDocumentMaterialCn('url-input', {
                            error: urlError,
                            row: !!fileNames.length,
                        })}
                        type="text"
                        value={url}
                        onChange={handleUrlChange}
                        onBlur={handleUrlBlur}
                        placeholder="Или укажите прямую ссылку"
                    />
                </div>
                <button type="button" onClick={onDelete} className={ExpandedDocumentMaterialCn('delete')}>
                    <Basket />
                </button>
                {!fileNames.length && <PaperclipIcon className={ExpandedDocumentMaterialCn('clip')} />}
            </div>
        </div>
    );
};
