import moment from 'moment';

import { capitalizeWord, getCapitalizeMonth, getDayOfMonthTitle } from './';

export const getCalendarWeekTitle = (date: Date | string): string => {
    const weekday = capitalizeWord(moment(date).format('dd'), 1);

    if (weekday === 'Пн') {
        return `${weekday} - ${getCapitalizeMonth(date)}`;
    }

    return `${weekday} - ${getDayOfMonthTitle(date)}`;
};
