import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgPayment({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M18.75 10.595v6.412c0 1.07-.802 1.96-1.781 1.96H6.28c-.98 0-1.781-.89-1.781-1.96V6.943c0 1.07.802 1.693 1.781 1.693H16.97c.98 0 1.781.89 1.781 1.959z"
                stroke="#585C6F"
                strokeWidth={1.5}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.5 6.942c0-.445.178-.801.356-1.069.357-.356.89-.623 1.425-.623H18.75"
                stroke="#585C6F"
                strokeWidth={1.5}
                strokeMiterlimit={10}
                strokeLinecap="round"
            />
            <path d="M16.08 14.6a.98.98 0 100-1.959.98.98 0 000 1.96z" fill="#585C6F" />
        </svg>
    );
}

const MemoSvgPayment = React.memo(SvgPayment);
export default MemoSvgPayment;
