import React, { useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { Definition, Info, Quote, Theorem, WarningTriangle } from '@lms-elements/icons';
import { EditorBlock } from 'draft-js';

import { ActionBlockTypes } from '../../../types/shared.types';

import { ActionBlockComponentProps } from './ActionBlockComponent.types';

import './ActionBlockComponent.scss';

const ActionBlockComponentCn = cn('action-block-component');

export const ActionBlockComponent: React.FC<ActionBlockComponentProps> = ({ blockProps: { type }, ...props }) => {
    const ActionIcon = useMemo(() => {
        switch (type) {
            case ActionBlockTypes.INFO_BLOCK:
                return Info;
            case ActionBlockTypes.WARNING_BLOCK:
                return WarningTriangle;
            case ActionBlockTypes.THEOREM_BLOCK:
                return Theorem;
            case ActionBlockTypes.QUOTE_BLOCK:
                return Quote;
            case ActionBlockTypes.DEFINITION_BLOCK:
                return Definition;
        }
    }, [type]);

    return (
        <>
            {type === ActionBlockTypes.CODE_BLOCK ? (
                <EditorBlock {...props} />
            ) : (
                <div className={ActionBlockComponentCn({ type })}>
                    {ActionIcon && <ActionIcon className={ActionBlockComponentCn('icon')} />}
                    <div className={ActionBlockComponentCn('editable-area')}>
                        <EditorBlock {...props} />
                    </div>
                </div>
            )}
        </>
    );
};
