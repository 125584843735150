import { IOptions } from './FieldDropdownCore.types';

export const convertOptionsToObject = (
    array?: string[],
    initOptions?: string[],
    idArray?: number[],
): { [key: string]: IOptions } | undefined => {
    if (!array) {
        return undefined;
    }
    return array.reduce((result, value, index) => {
        if (initOptions?.length) {
            result[`${index}`] = {
                value: value,
                id: idArray && idArray.length > 0 && idArray.length === array.length ? idArray?.[index] : index,
                isSelected: !initOptions.length || initOptions.includes(value),
            };
            return result;
        }
        result[`${index}`] = {
            value: value,
            id: idArray && idArray.length > 0 && idArray.length === array.length ? idArray?.[index] : index,
            isSelected: true,
        };
        return result;
    }, {} as { [key: string]: IOptions });
};
