import React, { useCallback, useMemo, useState } from 'react';
import { cn } from '@bem-react/classname';
import { Button, ButtonViewEnum, FieldForm } from '@lms-elements/atomic';
import { Basket, LittleArrow } from '@lms-elements/icons';

import { CourseTable } from '../CourseTable';
import { LessonType } from '../CourseTable/CourseTable.types';
import { GeneralSettings } from '../GeneralSettings';

import { EducationPlanProps } from './EducationPlan.types';

import './EducationPlan.scss';

const CnEducationPlan = cn('education-plan');

export const EducationPlan: React.FC<EducationPlanProps> = ({
    calendarPlanOptions,
    parallelsOptions,
    isTablet = false,
    readOnly = false,
    onDeleteClick,
    coursesOptions,
    onTitleClick,
    onParallelChange,
    onCalendarPlanChange,
    onDeleteCourseClick,
    valid,
    createCalendarPlanSignature,
    onMainBasicCourseAddClick,
    onMainAdditionalCourseAddClick,
    onSelectiveBasicCourseAddClick,
    onSelectiveAdditionalCourseAddClick,
}) => {
    const handleShowSettingsClick = useCallback(() => {
        setShowSettings((prev) => !prev);
    }, []);

    const [showSettings, setShowSettings] = useState(isTablet ? false : true);

    const basicCoursesOptions = useMemo(() => {
        return coursesOptions.filter((option) => option.type === LessonType.BASIC);
    }, [coursesOptions]);

    const additionalCoursesOptions = useMemo(() => {
        return coursesOptions.filter((option) => option.type === LessonType.ADDITIONAL);
    }, [coursesOptions]);

    return (
        <div className={CnEducationPlan({ tablet: isTablet })}>
            {(!isTablet || showSettings) && !readOnly && (
                <div className={CnEducationPlan('settings-container', { tablet: isTablet })}>
                    <GeneralSettings
                        calendarPlanOptions={calendarPlanOptions}
                        parallelsOptions={parallelsOptions}
                        isTablet={isTablet}
                        isValid={Boolean(valid)}
                        onCrossClick={handleShowSettingsClick}
                        onDeleteClick={onDeleteClick}
                        onTitleClick={onTitleClick}
                        onCalendarPlanChange={onCalendarPlanChange}
                        onParallelChange={onParallelChange}
                        createCalendarPlanSignature={createCalendarPlanSignature}
                    />
                </div>
            )}
            <div className={CnEducationPlan('right-container', { fullBorder: isTablet })}>
                {isTablet && !readOnly && (
                    <>
                        <div className={CnEducationPlan('title')} onClick={onTitleClick}>
                            <div className={CnEducationPlan('icon')}>
                                <LittleArrow />
                            </div>
                            Название
                        </div>
                        <div className={CnEducationPlan('field')}>
                            <FieldForm name={'name'} placeholder={'Введите название учебного плана'} />
                            <Button
                                view={ButtonViewEnum.bordered}
                                size={'m'}
                                type={'button'}
                                customClasses={CnEducationPlan('button')}
                                onClick={handleShowSettingsClick}
                            >
                                {'Настройки'}
                            </Button>
                            <Button
                                view={ButtonViewEnum.action}
                                size={'m'}
                                disabled={!valid}
                                type={'submit'}
                                customClasses={CnEducationPlan('button')}
                            >
                                {'Сохранить'}
                            </Button>
                            <Button
                                view={ButtonViewEnum.bordered}
                                size={'l'}
                                disabled={false}
                                icon={<Basket />}
                                onClick={onDeleteClick}
                                customClasses={CnEducationPlan('delete_button')}
                                type="button"
                            />
                        </div>
                    </>
                )}
                <div className={CnEducationPlan('table-container-scroll', { tablet: isTablet })} id="scroll-bar">
                    <div className={CnEducationPlan('table-container')}>
                        <CourseTable
                            isMain={true}
                            coursesOptions={basicCoursesOptions}
                            calendarPlanOptions={calendarPlanOptions}
                            isTablet={isTablet}
                            readOnly={readOnly}
                            isBasic={true}
                            onDeleteCourseClick={onDeleteCourseClick}
                            onMainBasicCourseAddClick={onMainBasicCourseAddClick}
                        />
                        <CourseTable
                            isMain={true}
                            coursesOptions={basicCoursesOptions}
                            calendarPlanOptions={calendarPlanOptions}
                            isTablet={isTablet}
                            readOnly={readOnly}
                            isBasic={false}
                            onDeleteCourseClick={onDeleteCourseClick}
                            onMainAdditionalCourseAddClick={onMainAdditionalCourseAddClick}
                        />
                        <CourseTable
                            isMain={false}
                            coursesOptions={basicCoursesOptions}
                            calendarPlanOptions={calendarPlanOptions}
                            isTablet={isTablet}
                            readOnly={readOnly}
                            isBasic={true}
                            onDeleteCourseClick={onDeleteCourseClick}
                            onSelectiveBasicCourseAddClick={onSelectiveBasicCourseAddClick}
                        />
                        <CourseTable
                            isMain={false}
                            coursesOptions={additionalCoursesOptions}
                            calendarPlanOptions={calendarPlanOptions}
                            isTablet={isTablet}
                            readOnly={readOnly}
                            isBasic={false}
                            onDeleteCourseClick={onDeleteCourseClick}
                            onSelectiveAdditionalCourseAddClick={onSelectiveAdditionalCourseAddClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
