import React from 'react';
import { FormSpy } from 'react-final-form';
import { cn } from '@bem-react/classname';
import {
    Button,
    ButtonViewEnum,
    CounterViews,
    DropdownCustom,
    FieldForm,
    FieldInputCounter,
    IDropdownType,
} from '@lms-elements/atomic';
import { Basket, DeleteIcon, LittleArrow } from '@lms-elements/icons';

import { EducationPlanValues } from '../EducationalPlan.types';

import { GeneralSettingsProps } from './GeneralSettings.types';
import { validate } from './utils';

import './GeneralSettings.scss';

const CnGeneralSettings = cn('general-settings');

const MIN_WEEK_HOURS_LABEL = 'Мин. кол-во часов в неделю';
const FREE_WEEK_HOURS_LABEL = 'Бесплатное кол-во часов в неделю';

const MIN_COUNTER = 0;

const createCalendarPlanSignatureStab = (weekCount: number, dayCount: number) =>
    `${weekCount} учебные недели в год / ${dayCount} учебных дней`;

export const GeneralSettings: React.FC<GeneralSettingsProps> = ({
    calendarPlanOptions,
    parallelsOptions,
    isTablet = false,
    isValid = false,
    onTitleClick,
    onDeleteClick,
    onCrossClick,
    createCalendarPlanSignature = createCalendarPlanSignatureStab,
    onCalendarPlanChange,
    onParallelChange,
}) => {
    return (
        <div className={CnGeneralSettings()}>
            <div className={CnGeneralSettings('header')}>
                <div className={CnGeneralSettings('title')}>
                    {!isTablet && (
                        <div className={CnGeneralSettings('icon')}>
                            <LittleArrow />
                        </div>
                    )}
                    <div className={CnGeneralSettings('title-text', { pointer: true })} onClick={onTitleClick}>
                        Общие настройки
                    </div>
                </div>
                {isTablet && (
                    <div className={CnGeneralSettings('cross')} onClick={onCrossClick}>
                        <DeleteIcon />
                    </div>
                )}
            </div>

            {!isTablet && (
                <>
                    <div className={CnGeneralSettings('label')}>Название</div>
                    <FieldForm name={'name'} placeholder={'Введите название учебного плана'} validators={[validate]} />
                </>
            )}

            <div className={CnGeneralSettings('label')}>Календарный план</div>
            <DropdownCustom
                name={'calendarPlan'}
                placeholder="Выберите календарный план"
                validators={[]}
                type={IDropdownType.optionWithId}
                options={calendarPlanOptions}
                isField={true}
                getValue={(value) => {
                    if (onCalendarPlanChange) {
                        return onCalendarPlanChange(value);
                    }
                }}
            />
            <FormSpy<EducationPlanValues> subscription={{ values: true }}>
                {({ values }) => {
                    if (values.calendarPlan) {
                        const selectedPlan = calendarPlanOptions.find(
                            (plan) => plan.id === Number(values?.calendarPlan?.id),
                        );
                        return (
                            <div className={CnGeneralSettings('signature')}>
                                {createCalendarPlanSignature(selectedPlan?.weekCount ?? 0, selectedPlan?.dayCount ?? 0)}
                            </div>
                        );
                    }
                    return null;
                }}
            </FormSpy>

            <div className={CnGeneralSettings('label')}>Параллель</div>

            <FormSpy<EducationPlanValues> subscription={{ values: true }}>
                {({ values }) => {
                    return (
                        <DropdownCustom
                            name={'parallel'}
                            placeholder="Выберите параллель"
                            validators={[]}
                            type={IDropdownType.optionWithId}
                            options={parallelsOptions}
                            isField={true}
                            getValue={(value) => {
                                if (value.id !== values.parallel?.id) {
                                    values.mainAdditionalCourses = [];
                                    values.mainBasicCourses = [];
                                    values.selectiveBasicCourses = [];
                                    values.selectiveAdditionalCourses = [];
                                }
                                if (onParallelChange) {
                                    return onParallelChange(value);
                                }
                            }}
                        />
                    );
                }}
            </FormSpy>

            <div className={CnGeneralSettings('signature')}>При смене параллели таблица будет очищена</div>

            <div className={CnGeneralSettings('title-text', { optionally: true })}>Настройки курсов по выбору</div>

            <div className={CnGeneralSettings('input-counter')}>
                <FieldInputCounter
                    name="minWeekHours"
                    isField
                    label={MIN_WEEK_HOURS_LABEL}
                    disabled={false}
                    min={MIN_COUNTER}
                    view={CounterViews.ARROWS}
                    validators={[]}
                />
            </div>

            <FieldInputCounter
                name="freeWeekHours"
                isField
                label={FREE_WEEK_HOURS_LABEL}
                disabled={false}
                min={MIN_COUNTER}
                view={CounterViews.ARROWS}
                validators={[]}
            />

            <div className={CnGeneralSettings('buttons-container')}>
                <Button
                    view={ButtonViewEnum.action}
                    size={'l'}
                    disabled={!isValid}
                    type={'button'}
                    onClick={onCrossClick}
                >
                    {isTablet ? 'Применить' : 'Сохранить изменения'}
                </Button>
                {!isTablet && (
                    <Button
                        view={ButtonViewEnum.bordered}
                        size={'l'}
                        disabled={false}
                        icon={<Basket />}
                        onClick={onDeleteClick}
                        customClasses={CnGeneralSettings('delete_button')}
                    />
                )}
            </div>
        </div>
    );
};
