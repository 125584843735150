import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgPuzzlePieceIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={26}
            height={24}
            viewBox="0 0 26 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                clipRule="evenodd"
                d="M17.792 18.409v-.001a1.228 1.228 0 01-.37-.876v-.005c0-1.026.938-1.806 1.97-1.638l5.608.917V1.94l-5.607-.917C18.36.854 17.42 1.635 17.42 2.66v.005c0 .328.134.644.37.876h.001c.571.56.892 1.32.892 2.112v.002c0 1.37-1.13 2.478-2.526 2.478-1.395 0-2.526-1.109-2.526-2.478v-.002c0-.792.32-1.551.891-2.111l.002-.001c.237-.232.37-.548.37-.876V2.66c0-1.026-.939-1.807-1.97-1.638l-5.61.917.872 4.267c.202.997-.576 1.927-1.611 1.927-.335 0-.656-.13-.893-.363L5.68 7.77a3.075 3.075 0 00-2.152-.875h-.003C2.132 6.895 1 8.005 1 9.373c0 1.369 1.132 2.477 2.526 2.477h.003c.807 0 1.581-.314 2.152-.874l.002-.001a1.28 1.28 0 01.893-.363c1.035 0 1.813.93 1.611 1.926l-.871 4.268 5.608-.917c1.032-.168 1.97.612 1.97 1.638v.005c0 .328-.132.644-.37.876h0a2.956 2.956 0 00-.892 2.112v.002c0 1.37 1.131 2.478 2.526 2.478 1.396 0 2.526-1.109 2.526-2.478v-.002c0-.792-.32-1.551-.892-2.111z"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgPuzzlePieceIcon = React.memo(SvgPuzzlePieceIcon);
export default MemoSvgPuzzlePieceIcon;
