import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgMoveLessonIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M7 6.945a7.115 7.115 0 00-2 4.963c0 3.916 3.134 7.09 7 7.09s7-3.174 7-7.09a7.115 7.115 0 00-2-4.963M12 4v9m0-9l-2 1.308M12 4l2 1.308"
                stroke="#252232"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgMoveLessonIcon = React.memo(SvgMoveLessonIcon);
export default MemoSvgMoveLessonIcon;
