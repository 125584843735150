import React from 'react';
import { Field } from 'react-final-form';

import { SearchCore } from './SearchCore/SearchCore';
import { ISearchProps } from './Search.types';

export const Search: React.FC<ISearchProps> = ({ isField = true, ...props }) => {
    if (isField) {
        return (
            <Field name={props.name}>{({ input, meta }) => <SearchCore {...props} input={input} meta={meta} />}</Field>
        );
    }

    return <SearchCore {...props} />;
};
