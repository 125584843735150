import React, { useCallback, useEffect, useState } from 'react';
import { cn } from '@bem-react/classname';

import { Checkbox } from '../../Checkbox';

import { SubscriptionListItemProps } from './SubscriptionListItem.types';

import './SubscriptionListItem.scss';

const CnSubscriptionListItem = cn('subscriptionListItem');

export const SubscriptionListItem: React.FC<SubscriptionListItemProps> = ({
    id,
    date,
    description,
    cost,
    isMobile,
    GroupChecked = 'none',
    handleListItemChange,
}) => {
    const [isItemChecked, setIsItemChecked] = useState(false);

    const handleCheckboxChange = useCallback(() => {
        const isChecked = !isItemChecked;
        setIsItemChecked(isChecked);
        handleListItemChange?.({ isItemChecked: isChecked, itemCost: cost, itemId: id });
    }, [cost, handleListItemChange, id, isItemChecked]);

    useEffect(() => {
        if (GroupChecked === 'all') {
            setIsItemChecked(true);
        } else if (GroupChecked === 'none') {
            setIsItemChecked(false);
        }
    }, [GroupChecked]);

    return (
        <div className={CnSubscriptionListItem('row')}>
            <Checkbox checked={isItemChecked} handleCheckboxChange={handleCheckboxChange} />
            <span className={CnSubscriptionListItem('text', { isMobile })}>
                <span className={CnSubscriptionListItem('date', { isMobile })}>{date} </span>
                <span className={CnSubscriptionListItem('description')}>
                    {' - '}
                    {description}{' '}
                </span>
                <span className={CnSubscriptionListItem('cost')}>
                    ({cost.toLocaleString()}
                    {' руб.'})
                </span>
            </span>
        </div>
    );
};
