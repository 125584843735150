import { MAX_IMAGE_WIDTH, MIN_IMAGE_WIDTH, supportedMimeTypes } from './ImageUploader.types';

export const computeWidth = (width: number): number => Math.max(MIN_IMAGE_WIDTH, Math.min(width, MAX_IMAGE_WIDTH));

const FIVE_MB_IN_BYTES = 5242880;

export const isMimeTypeValid = (file: File): boolean =>
    file.type.startsWith('image') &&
    !!supportedMimeTypes.map((mimeType) => file.name.toLowerCase().endsWith(mimeType)).filter(Boolean).length;

export const isFileSizeValid = (file: File): boolean => file.size <= FIVE_MB_IN_BYTES;
