import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgLockIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M24.182 13H7.818c-.452 0-.818.392-.818.875v12.25c0 .483.366.875.818.875h16.364c.452 0 .818-.392.818-.875v-12.25c0-.483-.366-.875-.818-.875zM11.908 12.92V8.96c0-1.05.431-2.057 1.198-2.8A4.16 4.16 0 0116 5a4.16 4.16 0 012.893 1.16 3.897 3.897 0 011.198 2.8v3.96"
                stroke="#C0C2C9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16 21.721c1.004 0 1.818-.788 1.818-1.76s-.814-1.76-1.818-1.76-1.818.788-1.818 1.76.814 1.76 1.818 1.76z"
                stroke="#C0C2C9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgLockIcon = React.memo(SvgLockIcon);
export default MemoSvgLockIcon;
