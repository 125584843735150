import React from 'react';
import { Field } from 'react-final-form';
import { composeValidators } from '@lms-elements/utils';

import { IFieldFormProps } from './FieldForm.types';
import { FieldFormCore } from './FieldFormCore';

export const FieldForm: React.FC<IFieldFormProps> = ({ name, isOnlyNumbers, validators, ...props }) => {
    const normalizePhone = (value: string) => {
        if (!value || !isOnlyNumbers) return value;
        return value.replace(/[^\d]/g, '');
    };

    return (
        <Field
            name={name}
            validate={composeValidators(...(validators || []))}
            subscriptions={{ value: true, touched: true, error: true, active: true }}
            parse={normalizePhone}
        >
            {({ input, meta }) => <FieldFormCore {...props} input={input} meta={meta} />}
        </Field>
    );
};
