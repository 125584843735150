import React, { useCallback, useRef, useState } from 'react';
import { cn } from '@bem-react/classname';
import { useOuterClick } from '@lms-elements/hooks';

import { IDropDownProps, StatusLabelEnum } from './Dropdown.types';
import { DropdownLabel } from './DropdownLabel';
import { DropdownOption } from './DropdownOption';

import './Dropdown.scss';

const CnDropdown = cn('dropdown');

export const Dropdown: React.FC<IDropDownProps> = ({
    status,
    lateTime,
    selectHandler,
    needHideAbsentReasonOptions,
}) => {
    const [isOpened, setIsOpened] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(status);
    const [selectedLateTime, setSelectedLateTime] = useState(lateTime);
    const ref = useRef<HTMLDivElement>(null);

    useOuterClick(ref, setIsOpened);

    const handleButtonClick = useCallback(() => {
        setIsOpened(!isOpened);
    }, [isOpened]);

    const handleOptionClick = useCallback(
        (newStatus: StatusLabelEnum, newLateTime?: number) => {
            setSelectedStatus(newStatus);

            const selectedLateTime = newLateTime ? newLateTime : 0;
            setSelectedLateTime(selectedLateTime);

            setIsOpened(false);

            if (selectHandler) {
                selectHandler({ status: newStatus, lateMinutes: selectedLateTime });
            }
        },
        [selectHandler],
    );

    return (
        <div ref={ref} className={CnDropdown({ isOpened })}>
            <button className={CnDropdown('button', { isOpened })} onClick={handleButtonClick} tabIndex={-1}>
                <DropdownLabel status={selectedStatus} lateTime={selectedLateTime} isOpened={isOpened} />
            </button>
            {isOpened && (
                <ul className={CnDropdown('list')}>
                    {selectedStatus !== StatusLabelEnum.wasInClass && (
                        <li>
                            <DropdownOption status={StatusLabelEnum.wasInClass} handleOptionClick={handleOptionClick} />
                        </li>
                    )}
                    {selectedStatus !== StatusLabelEnum.wasAbsent && (
                        <li>
                            <DropdownOption status={StatusLabelEnum.wasAbsent} handleOptionClick={handleOptionClick} />
                        </li>
                    )}
                    {selectedStatus !== StatusLabelEnum.seriousReason && !needHideAbsentReasonOptions && (
                        <li>
                            <DropdownOption
                                status={StatusLabelEnum.seriousReason}
                                handleOptionClick={handleOptionClick}
                            />
                        </li>
                    )}
                    {selectedStatus !== StatusLabelEnum.illness && !needHideAbsentReasonOptions && (
                        <li>
                            <DropdownOption status={StatusLabelEnum.illness} handleOptionClick={handleOptionClick} />
                        </li>
                    )}
                    <li>
                        <DropdownOption
                            status={StatusLabelEnum.isLate}
                            lateTime={selectedLateTime}
                            handleOptionClick={handleOptionClick}
                            isSelected={selectedStatus === StatusLabelEnum.isLate}
                        />
                    </li>
                </ul>
            )}
        </div>
    );
};
