import React from 'react';
import { DraftDecorator } from 'draft-js';

import { useHandleCopyCut } from '../../../hooks/useHandleCopyCut';
import { EntityTypes } from '../../../types/shared.types';

import { IInsertWordWrapperProps } from './InsertWordDecorator.types';

import './InsertWordDecorator.scss';

const findInsertWordEntities: DraftDecorator['strategy'] = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === EntityTypes.INSERT_WORD;
    }, callback);
};

const InsertWordWrapper: React.FC<IInsertWordWrapperProps> = ({ decoratedText, children }) => {
    const handleCopyCut = useHandleCopyCut(decoratedText);

    return children ? (
        <span onCopy={handleCopyCut} onCut={handleCopyCut} className="custom-editor-insert-text-wrapper">
            {children}
        </span>
    ) : null;
};

export const insertWordDecorator = {
    strategy: findInsertWordEntities,
    component: InsertWordWrapper,
};
