import React, { useCallback, useMemo } from 'react';
import { useField } from 'react-final-form';

import { InputListCore } from '../InputListCore';

import { IInputListFieldProps } from './InputListField.types';
import { validateError } from './InputListField.utils';

export const InputListField: React.FC<IInputListFieldProps> = ({ onChange, name, placeholder }) => {
    const { input, meta } = useField<string[]>(name);

    const error = useMemo(() => validateError(meta.error), [meta.error]);

    const handleInputChange = useCallback(
        (newItems: string[]) => {
            input.onChange(newItems);
            onChange?.(newItems);
        },
        [input, onChange],
    );

    return (
        <InputListCore items={input.value || []} onChange={handleInputChange} placeholder={placeholder} error={error} />
    );
};
