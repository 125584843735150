import React from 'react';
import { cn } from '@bem-react/classname';

import { IRadioFieldCoreProps } from './RadioFieldCore.types';

import './RadioFieldCore.scss';

const RadioCoreCn = cn('radio-core');

export const RadioFieldCore = React.forwardRef<HTMLInputElement, IRadioFieldCoreProps>(
    ({ error = false, disabled = false, customClassNames = [], label, value, withoutLabel, ...props }, ref) => {
        return (
            <div className={[RadioCoreCn(), ...customClassNames].join(' ')} data-testid="radio-core-test">
                <label className={RadioCoreCn('label')} data-testid="radio-label">
                    <input
                        type="radio"
                        className={RadioCoreCn('input', { error })}
                        {...props}
                        value={value}
                        disabled={disabled}
                        ref={ref}
                    />
                    {<span className={RadioCoreCn('span')}>{!withoutLabel && (label || value || '')}</span>}
                </label>
            </div>
        );
    },
);
