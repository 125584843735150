import React from 'react';
import { cn } from '@bem-react/classname';
import { emptySubject } from 'assets';

import { IEmptyNotificationsProps } from './EmptyNotifications.types';

import './EmptyNotifications.scss';

const CnEmptyNotifications = cn('emptyNotifications');

export const EmptyNotifications: React.FC<IEmptyNotificationsProps> = ({ text }) => (
    <div className={CnEmptyNotifications()}>
        <img className={CnEmptyNotifications('emptyImg')} src={emptySubject} alt="" />
        <p className={CnEmptyNotifications('description')}>{text}</p>
    </div>
);
