import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgLogoIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={24}
            height={23}
            viewBox="0 0 24 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M20.958 13.093l-1.189.66L3.764 4.7c-.278-.155-.397-.505-.198-.777.158-.272.515-.388.792-.194l16.6 9.364z"
                fill="#0088E9"
            />
            <path
                d="M22.661 10.218L12.163 4.273 5.705.582C3.169-.817 0 .97 0 3.807v14.765c0 2.836 3.13 4.585 5.626 3.186l12.598-7.072L2.971 6.06a2.125 2.125 0 01-.792-2.914C2.773 2.097 4.12 1.747 5.15 2.33l17.353 9.83.119-.077c.792-.389.792-1.476.04-1.865z"
                fill="#0088E9"
            />
        </svg>
    );
}

const MemoSvgLogoIcon = React.memo(SvgLogoIcon);
export default MemoSvgLogoIcon;
