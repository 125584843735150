import React, { useCallback, useEffect, useRef } from 'react';
import { cn } from '@bem-react/classname';

import { DEFAULT_SPEED } from './constant';
import { GroupCore } from './GroupCore';
import { GroupTabsProps } from './GroupTabs.types';

import './GroupTabs.scss';

const CnGroupTabs = cn('group-tabs');

export const GroupTabs: React.FC<GroupTabsProps> = ({
    groups,
    selectedGroupId,
    onGroupSelect,
    customClassName = '',
    isHorizontal,
    scrollSpeed,
}) => {
    const ref = useRef<HTMLDivElement>(null);

    // horizontal scroll
    const handleMouseWheel = useCallback(
        (evt: WheelEvent) => {
            if (evt.deltaY == 0 || !isHorizontal) return;
            evt.preventDefault();
            if (ref.current) {
                const speed = scrollSpeed ? scrollSpeed : DEFAULT_SPEED;

                ref.current.scrollBy({
                    left: evt.deltaY < 0 ? -speed : speed,
                });
            }
        },
        [isHorizontal, scrollSpeed],
    );

    useEffect(() => {
        const element = ref.current;
        element?.addEventListener('wheel', handleMouseWheel);
        return () => element?.removeEventListener('wheel', handleMouseWheel);
    }, [handleMouseWheel]);

    return (
        <div className={CnGroupTabs({ isHorizontal: isHorizontal })}>
            <p className={CnGroupTabs('title', { isHorizontal: isHorizontal })}>Группы:</p>
            <div ref={ref} className={`${CnGroupTabs('wrapper', { isHorizontal: isHorizontal })} ${customClassName}`}>
                <div className={CnGroupTabs('scroll', { isHorizontal: isHorizontal })}>
                    <div className={CnGroupTabs('list', { isHorizontal: isHorizontal })}>
                        {groups.map((group) => (
                            <GroupCore
                                key={group.id}
                                nameGroup={group.nameGroup}
                                id={group.id}
                                error={group.error}
                                isSelected={group.id === selectedGroupId}
                                onGroupSelect={onGroupSelect}
                                isHorizontal={isHorizontal}
                                mainRef={ref}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
