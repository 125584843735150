import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgProctoring({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={20}
            height={18}
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M15.525 8.41v6.038a2.305 2.305 0 01-2.308 2.302H6.783a2.305 2.305 0 01-2.308-2.302V8.41l4.561 2.442a2.024 2.024 0 001.927 0l4.562-2.442z"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.175 6.983L15.525 8.4l-4.561 2.442a2.024 2.024 0 01-1.927 0L4.475 8.4l-2.65-1.418a.996.996 0 010-1.767l7.212-3.86a2.023 2.023 0 011.927 0l7.211 3.86a.996.996 0 010 1.767zM18.712 6.107v6.511"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgProctoring = React.memo(SvgProctoring);
export default MemoSvgProctoring;
