import React from 'react';
import { cn } from '@bem-react/classname';
import { Angle as AngleIcon } from '@lms-elements/icons';

import { IBreadcrumbsProps } from './Breadcrumbs.types';
import { BreadcrumbsItem } from './BreadcrumbsItem';

import './Breadcrumbs.scss';

const CnBreadcrumbs = cn('breadcrumbs');

export const Breadcrumbs: React.FC<IBreadcrumbsProps> = React.memo(
    ({ crumbs, isShort, withWordWrapping, onItemClick }) => (
        <div className={CnBreadcrumbs({ withWordWrapping })}>
            {crumbs
                .map((crumb, index) => (
                    <BreadcrumbsItem
                        isChecked={index === crumbs.length - 1}
                        key={typeof crumb?.title === 'string' ? crumb.title : `title-${index}`}
                        {...crumb}
                        isShort={isShort}
                        onItemClick={onItemClick}
                    />
                ))
                .map((crumb, index) => [
                    index > 0 && (
                        <div key={`angle-${index}`} className={CnBreadcrumbs('angle')}>
                            <AngleIcon width={8} height={4} />
                        </div>
                    ),
                    crumb,
                ])}
        </div>
    ),
);
