import moment from 'moment';

import { capitalizeWord } from './';

/**
 * @example 2021-05-06T15:30 -> 06 Мая в 15:30
 * @param date incoming date
 * @returns
 */
export const getDateWithTime = (date: Date | string): string =>
    capitalizeWord(moment(date).format('D MMMM [в] HH:mm'), 2);
