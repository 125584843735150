import { questionType } from './MinimizedQuestion.types';

export const ChooseHeading = (type: questionType): string => {
    switch (type) {
        case 'binary':
            return 'Верно или нет';
        case 'detailed':
            return 'Открытый вопрос - эссе';
        case 'multiple':
            return 'Вопрос - тестовый';
        case 'open':
            return 'Вопрос - тестовый, с текстовым ответом';
    }
};

export const ChooseParagraph = (type: questionType): string => {
    switch (type) {
        case 'binary':
            return 'Автопроверка';
        case 'detailed':
            return 'Ручная проверка';
        case 'multiple':
            return 'Автопроверка';
        case 'open':
            return 'Автопроверка';
    }
};
