import React, { useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { FieldDropdown } from '@lms-elements/atomic';
import { Basket } from '@lms-elements/icons';
import { getObjectPropertyByString } from '@lms-elements/utils';

import { EditorField } from '../EditorField';
import { TextInputField } from '../TextInputField';

import { IOpenAnswerFieldsProps, truthfulnessArr } from './OpenAnswerFields.types';

import './OpenAnswerFields.scss';

const OpenCn = cn('open-question');

const OpenAnswerFields: React.FC<IOpenAnswerFieldsProps> = ({
    name,
    option,
    onOptionDelete,
    isBlocked,
    disableActionBlocks,
    savePastedStyles,
}) => {
    const { values } = useFormState();

    const isEditor = useMemo(
        () => typeof getObjectPropertyByString(values, `${name}.answer`) === 'object',
        [name, values],
    );

    const placeholder = useMemo(
        () => (option.truthfulness === '100 % от оценки' ? 'Введите правильный ответ' : 'Добавить вариант написания'),
        [option.truthfulness],
    );

    return (
        <div className={[OpenCn('answer-option'), 'expanded-question-answer-option'].join(' ')}>
            <div className={'center-vertical'}>
                {isEditor ? (
                    <div className="expanded-question-input expanded-question-input-editor">
                        <EditorField
                            isBlocked={isBlocked}
                            name={`${name}.answer`}
                            hideToolbarOnBlur
                            placeholder={placeholder}
                            disableActionBlocks={disableActionBlocks}
                            savePastedStyles={savePastedStyles}
                        />
                    </div>
                ) : (
                    <TextInputField name={`${name}.answer`} placeholder={placeholder} />
                )}
            </div>
            <div className={OpenCn('btn-group')}>
                <FieldDropdown
                    options={truthfulnessArr.map((elem) => elem)}
                    name={`${name}.truthfulness`}
                    placeholder={'Правильность ответа'}
                    validators={[(value: string) => (value ? undefined : 'error')]}
                />
                <button className={OpenCn('delete')} type="button" onClick={onOptionDelete}>
                    <Basket />
                </button>
            </div>
        </div>
    );
};

export default OpenAnswerFields;
