import React from 'react';
import { cn } from '@bem-react/classname';

import { IAdditionalCourseCardProps } from './AdditionalCourseCard.types';
import { getDurationText } from './AdditionalCourseCard.utils';

import './AdditionalCourseCard.scss';

const CnAdditionalCourseCard = cn('additionalCourseCard');

export const AdditionalCourseCard: React.FC<IAdditionalCourseCardProps> = ({
    image,
    subject,
    age,
    duration,
    type,
    isEnrolled,
    isInText,
    isMobile,
    isTablet,
}) => {
    const durationText = duration ? getDurationText(duration) : null;

    return (
        <div className={CnAdditionalCourseCard({ isInText, isMobile })}>
            <div className={CnAdditionalCourseCard('image-wrapper', { isInText, isMobile, isTablet })}>
                <img className={CnAdditionalCourseCard('image')} src={image} alt={subject} />
            </div>
            <div className={CnAdditionalCourseCard('content')}>
                <div className={CnAdditionalCourseCard('title', { isInText })}>
                    <span className={CnAdditionalCourseCard('subject', { isInText })}>{subject}</span>
                    {isEnrolled && <span className={CnAdditionalCourseCard('status')}>Вы записаны</span>}
                </div>
                {age && (
                    <div className={CnAdditionalCourseCard('row')}>
                        <span className={CnAdditionalCourseCard('description')}>Возраст учащихся: </span>
                        <span className={CnAdditionalCourseCard('text')}>{age}</span>
                    </div>
                )}
                {durationText && (
                    <div className={CnAdditionalCourseCard('row')}>
                        <span className={CnAdditionalCourseCard('description')}>Длительность: </span>
                        <span className={CnAdditionalCourseCard('text')}>{durationText}</span>
                    </div>
                )}
                {type && (
                    <div className={CnAdditionalCourseCard('row')}>
                        <span className={CnAdditionalCourseCard('description')}>Тип курса: </span>
                        <span className={CnAdditionalCourseCard('text')}>{type}</span>
                    </div>
                )}
            </div>
        </div>
    );
};
