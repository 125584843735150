import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgWebcamIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={18}
            height={22}
            viewBox="0 0 18 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <circle cx={9} cy={10} r={7.25} stroke="#252232" strokeWidth={1.5} />
            <circle cx={9} cy={10} r={3.25} stroke="#252232" strokeWidth={1.5} />
            <path
                d="M4.346 16l-2.118 2.771a1 1 0 00.795 1.607H9M13.654 16l2.118 2.771a1 1 0 01-.795 1.607H9"
                stroke="#252232"
                strokeWidth={1.5}
            />
        </svg>
    );
}

const MemoSvgWebcamIcon = React.memo(SvgWebcamIcon);
export default MemoSvgWebcamIcon;
