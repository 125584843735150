import React, { useCallback } from 'react';
import { cn } from '@bem-react/classname';
import { Setting } from '@lms-elements/icons';

import { NavBarStudentProps } from './NavBarStudent.types';

import './NavBarStudent.scss';

const cnNavBarStudent = cn('navbarStudent');

export const NavBarStudent: React.FC<NavBarStudentProps> = ({
    id,
    firstName,
    lastName,
    photoUrl,
    grade,
    selected,
    isMobile = false,
    isInHeader = false,
    onSettingClick,
    onStudentClick,
}) => {
    const handleSettingClick = useCallback(() => {
        if (onSettingClick) {
            onSettingClick(id);
        }
    }, [id, onSettingClick]);

    const handleStudentClick = useCallback(() => {
        if (onStudentClick) {
            onStudentClick(id);
        }
    }, [id, onStudentClick]);

    return isInHeader ? (
        <div className={cnNavBarStudent({ isInHeader })}>
            <div className={cnNavBarStudent('text', { isInHeader })}>
                <div className={cnNavBarStudent('name')}>{firstName}</div>
                <div className={cnNavBarStudent('grade')}>{grade}</div>
            </div>
            <div className={cnNavBarStudent('image-wrapper')}>
                <img className={cnNavBarStudent('image')} src={photoUrl} alt={firstName} />
            </div>
        </div>
    ) : (
        <div className={cnNavBarStudent({ selected })} onClick={handleStudentClick}>
            <div className={cnNavBarStudent('image-wrapper')}>
                <img className={cnNavBarStudent('image')} src={photoUrl} alt={firstName} />
            </div>
            <div className={cnNavBarStudent('text', { isMobile })}>
                <div className={cnNavBarStudent('name', { selected })}>
                    {lastName} {firstName}
                </div>
                <div className={cnNavBarStudent('grade', { selected })}>{grade}</div>
            </div>
            {selected && (
                <div className={cnNavBarStudent('setting', { selected })} onClick={handleSettingClick}>
                    <Setting />
                </div>
            )}
        </div>
    );
};
