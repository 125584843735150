import { RefObject, useCallback, useEffect, useState } from 'react';

export const useGetScrollPosition = (
    element: RefObject<HTMLElement>,
    elementToObserveHeight?: RefObject<HTMLElement>,
    {
        scrollStartOffset = 100,
        scrollEndOffset = 0,
    }: {
        scrollStartOffset?: number;
        scrollEndOffset?: number;
    } = {},
): { isScrollEnd: boolean; isScrollStart: boolean; wasScrolled: boolean; onScroll: () => void } => {
    const [wasScrolled, setWasScrolled] = useState(false);
    const [isScrollEnd, setIsScrollEnd] = useState(false);
    const [isScrollStart, setIsScrollStart] = useState(false);

    const updateScrollEnd = useCallback(() => {
        const { clientHeight = 0, scrollHeight = 0, scrollTop = 0 } = element.current || {};
        setIsScrollEnd(!(scrollHeight - scrollTop - scrollEndOffset <= clientHeight && clientHeight !== 0));
    }, [element, scrollEndOffset]);

    const updateScrollStart = useCallback(() => {
        const { scrollHeight = 0, scrollTop = 0 } = element.current || {};
        setIsScrollStart(scrollTop - scrollStartOffset >= 0 && scrollHeight !== 0);
    }, [element, scrollStartOffset]);

    const handleObservingElementResize = useCallback(() => {
        updateScrollStart();
        updateScrollEnd();
        setWasScrolled(false);
    }, [updateScrollEnd, updateScrollStart]);

    const handleElementScroll = useCallback(() => {
        updateScrollStart();
        updateScrollEnd();
        setWasScrolled(true);
    }, [updateScrollEnd, updateScrollStart]);

    useEffect(() => {
        updateScrollStart();
    }, [updateScrollStart]);

    useEffect(() => {
        updateScrollEnd();
    }, [updateScrollEnd]);

    useEffect(() => {
        if (elementToObserveHeight?.current) {
            const resizeObserver = new ResizeObserver(handleObservingElementResize);
            resizeObserver.observe(elementToObserveHeight.current);

            return () => {
                resizeObserver.disconnect();
            };
        }
    }, [elementToObserveHeight, handleObservingElementResize]);

    return { isScrollEnd, isScrollStart, wasScrolled, onScroll: handleElementScroll };
};
