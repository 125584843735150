import { RefObject, useEffect, useState } from 'react';

export const useTouched = (ref: RefObject<HTMLElement>): boolean => {
    const [touched, setTouched] = useState(false);

    useEffect(() => {
        const handleFocus = () => {
            if (!touched && ref.current && document.activeElement === ref.current) {
                setTouched(true);
            }
        };

        window.addEventListener('focus', handleFocus, true);

        return () => window.removeEventListener('focus', handleFocus, true);
    }, [ref, touched]);

    return touched;
};
