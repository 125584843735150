import React, { useCallback, useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { Schedule } from '@lms-elements/icons';
import { getDatepickerSelectTitle } from '@lms-elements/utils';

import { IDataSelectProps } from './DataSelect.types';

import './DataSelect.scss';

const CnDataSelect = cn('dataSelect');

export const DataSelect: React.FC<IDataSelectProps> = ({
    placeholder = 'Выберите время',
    input,
    meta,
    inactive,
    initValue,
    isPaymentForm,
    onFieldClick,
}) => {
    const title = useMemo(() => {
        if (input.value) {
            const title = getDatepickerSelectTitle(input.value);
            if (title.includes('Invalid')) {
                return '';
            } else return title;
        }

        if (initValue) {
            input.onChange(initValue);
            const title = getDatepickerSelectTitle(initValue);
            if (title.includes('Invalid')) {
                return '';
            } else return title;
        }

        return '';
    }, [input, initValue]);

    const handleDataSelectClick = useCallback(() => {
        if (onFieldClick && !inactive) {
            onFieldClick();
        }
    }, [onFieldClick, inactive]);

    return (
        <div
            className={CnDataSelect({ alert: Boolean(meta.error), inactive, isPaymentForm })}
            onClick={handleDataSelectClick}
        >
            <div className={CnDataSelect('inputContainer', { isPaymentForm })}>
                <input
                    spellCheck
                    type="text"
                    className={CnDataSelect('input', { isPaymentForm })}
                    {...input}
                    placeholder={placeholder}
                    value={title}
                    readOnly={true}
                />
            </div>
            <div className={CnDataSelect('icon', { isPaymentForm })}>
                <Schedule />
            </div>
        </div>
    );
};
