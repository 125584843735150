import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useField } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { Basket, MusicNotes, PauseButton, PlayButton } from '@lms-elements/icons';
import { isFilenameInAvailableExtensions } from '@lms-elements/utils';

import { ACCEPT_FILE_EXTENSIONS, ACCEPT_FILE_TYPES } from './ExpandedAudioMaterial.constants';
import { IAudioValues, IExpandedAudioMaterialProps, IUploadAudioProps } from './ExpandedAudioMaterial.types';
import { secondsToHms } from './utils';

import './ExpandedAudioMaterial.scss';

const ExpandedAudioMaterialCn = cn('expanded-audio-material');

export const ExpandedAudioMaterial: React.FC<IExpandedAudioMaterialProps> = ({
    name,
    onDelete,
    audioUrl = '',
    onClickAddAudio,
    showForStudents = false,
}) => {
    const { input, meta } = useField<IAudioValues>(name);

    const [dropAudio, setDropAudio] = useState(audioUrl);
    const [playMusic, setPlayMusic] = useState(false);
    const [songName, setSongName] = useState(input.value?.audioData?.songName || '');
    const [duration, setDuration] = useState('');
    const [currentTime, setCurrentTime] = useState('');

    const [, drop] = useDrop(
        () => ({
            accept: [NativeTypes.FILE],
            drop: (item) => {
                if (!dropAudio) {
                    const audio = item as IUploadAudioProps;
                    const audioFile = audio.files[0];

                    if (
                        audioFile.name &&
                        isFilenameInAvailableExtensions(audioFile.name.toLowerCase(), ACCEPT_FILE_EXTENSIONS)
                    ) {
                        input.onChange({
                            ...input.value,
                            audioData: {
                                file: audioFile,
                                songName: audioFile.name,
                            },
                        });
                        setDropAudio(URL.createObjectURL(audioFile));
                        setSongName(audioFile.name);
                    }
                }
            },
        }),
        [input],
    );

    const onAdd = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            if (!e.target.files) {
                return;
            }
            const file = e.target?.files[0];
            input.onChange({
                ...input.value,
                audioData: {
                    file,
                    songName: songName,
                },
            });
            setSongName(file.name);
            setDropAudio(URL.createObjectURL(file));
            if (onClickAddAudio) {
                onClickAddAudio(file);
            }
        },
        [input, songName, onClickAddAudio],
    );

    const onClickDelete = useCallback(() => {
        player.current?.pause();
        setPlayMusic(false);
        setDropAudio('');
        input.onChange({
            ...input.value,
            audioData: {
                file: '',
                songName: '',
            },
        });
        if (onDelete) {
            onDelete();
        }
    }, [input, onDelete]);

    const showForm = useMemo(() => !dropAudio && !showForStudents, [dropAudio, showForStudents]);

    const trackLine = useRef<HTMLInputElement>(null);
    const trackBar = useRef<HTMLDivElement>(null);
    const player = useRef<HTMLAudioElement>(null);
    const musicTrackLine = useRef<HTMLInputElement>(null);

    const onChangeTrackLine = useCallback(() => {
        if (player.current && trackLine.current) {
            player.current.currentTime = player.current.duration * Number(trackLine.current.value);
        }
    }, []);

    const onClickPlay = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            if (playMusic) {
                void player.current?.pause();
                setPlayMusic(false);
            } else {
                setPlayMusic(true);
                void player.current?.play();
            }
        },
        [playMusic],
    );

    const onTimeUpdate = useCallback(() => {
        const trackLineWidth = trackBar.current?.clientWidth || 0;
        const oneLinerSecond = trackLineWidth / (player.current?.duration || 1);
        if (trackLine.current && player.current && musicTrackLine.current) {
            musicTrackLine.current.style.width = `${oneLinerSecond * player.current.currentTime}px`;
        }
    }, []);

    const onChangeMusicLine = useCallback(() => {
        const trackLineWidth = trackBar.current?.clientWidth || 0;
        const musicLineWight = musicTrackLine.current?.style.width;
        const musicLineWidthNumber = Number(musicLineWight?.slice(0, musicLineWight.length - 2));
        if (player.current && musicTrackLine.current) {
            player.current.currentTime =
                player.current.duration *
                (Number(musicTrackLine.current.value || 0) * (musicLineWidthNumber / trackLineWidth));
        }
    }, []);

    useEffect(() => {
        const refPlayer = player.current;
        const handleDuration = () => {
            setCurrentTime(secondsToHms(refPlayer?.currentTime || 0));
        };
        if (refPlayer) {
            refPlayer.addEventListener('timeupdate', handleDuration);
        }
        return () => {
            if (refPlayer) {
                refPlayer.removeEventListener('timeupdate', handleDuration);
            }
        };
    }, []);

    useEffect(() => {
        const refPlayer = player.current;
        const handleCurrentTime = () => {
            setDuration(secondsToHms(refPlayer?.duration || 0));
        };
        if (refPlayer) {
            refPlayer.addEventListener('loadedmetadata', handleCurrentTime);
        }
        return () => {
            if (refPlayer) {
                refPlayer.removeEventListener('loadedmetadata', handleCurrentTime);
            }
        };
    }, []);

    const handleSongNameChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            input.onChange({
                ...input.value,
                audioData: {
                    file: input.value.audioData.file,
                    songName: event.target.value,
                },
            });
            setSongName(event.target.value);
        },
        [input],
    );

    return (
        <div>
            {!showForStudents && <h3 className="audiofile-title">Аудиофайл</h3>}
            <audio id="player" src={audioUrl || dropAudio} onTimeUpdate={onTimeUpdate} ref={player}>
                <source src={audioUrl || dropAudio} type="audio/*" />
            </audio>
            <div className={ExpandedAudioMaterialCn({ showForStudents, error: !meta.valid })} ref={drop}>
                {showForm ? (
                    <div>
                        <div className={ExpandedAudioMaterialCn('heading')}>
                            <div>
                                <div className={ExpandedAudioMaterialCn('title')}>Загрузите аудиофайл</div>
                                <div className={ExpandedAudioMaterialCn('instaction')}>
                                    <span className={ExpandedAudioMaterialCn('text')}>перетащив файл сюда или</span>
                                    <div className={ExpandedAudioMaterialCn('upload')}>
                                        <label className={ExpandedAudioMaterialCn('note')}>
                                            укажите путь
                                            <input
                                                type="file"
                                                className={ExpandedAudioMaterialCn('addvideo')}
                                                accept={ACCEPT_FILE_TYPES.join(',')}
                                                onChange={onAdd}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className={ExpandedAudioMaterialCn('helper')}>.mp3 до 100mb</div>
                            </div>
                            <div className={ExpandedAudioMaterialCn('icon')}>
                                <MusicNotes />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={ExpandedAudioMaterialCn('player')}>
                        <div onClick={onClickPlay} className={ExpandedAudioMaterialCn('playButton')}>
                            {playMusic ? <PauseButton /> : <PlayButton />}
                        </div>
                        <div className={ExpandedAudioMaterialCn('track-line')}>
                            <div className={ExpandedAudioMaterialCn('name')}>
                                <input
                                    className={ExpandedAudioMaterialCn('name-input')}
                                    onChange={handleSongNameChange}
                                    value={songName}
                                />
                            </div>
                            <div ref={trackBar} className={ExpandedAudioMaterialCn('input-range-wrapper')}>
                                <input
                                    className={ExpandedAudioMaterialCn('input-range')}
                                    id={'track-lineId'}
                                    onInput={onChangeTrackLine}
                                    type="range"
                                    min="0"
                                    max="1"
                                    step="0.001"
                                    value="0"
                                    ref={trackLine}
                                    onClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
                                />
                                <input
                                    className={ExpandedAudioMaterialCn('musicLine', { hide: showForm })}
                                    id={'music-lineId'}
                                    onInput={onChangeMusicLine}
                                    type="range"
                                    min="0"
                                    max="1"
                                    step="0.001"
                                    value="0"
                                    ref={musicTrackLine}
                                />
                            </div>
                            <div className={ExpandedAudioMaterialCn('time')}>
                                <div>{currentTime}</div>
                                <div>{duration}</div>
                            </div>
                        </div>
                    </div>
                )}
                {!showForStudents && (
                    <button onClick={onClickDelete} className={ExpandedAudioMaterialCn('delete-card')} type="button">
                        <Basket />
                    </button>
                )}
            </div>
        </div>
    );
};
