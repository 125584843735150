import moment from 'moment';

import { capitalizeWord } from './';

export const getCapitalizeMonthAndYear = (date?: Date | string | [number, number]): string => {
    let monthAndYear = moment(date).format('MMMM YYYY');

    if (Array.isArray(date)) {
        const [month, year] = date;
        monthAndYear = moment().set({ month, year }).format('MMMM YYYY');
    }

    return capitalizeWord(monthAndYear, 1);
};
