import React, { useCallback, useRef } from 'react';
import { FieldInputProps, useField } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { Basket } from '@lms-elements/icons';
import debounce from 'lodash.debounce';

import { headerSizes } from './constants';
import { Header, HeaderSize, IExpandedHeaderMaterialProps, IHeaderValues } from './ExpandedHeaderMaterial.types';
import { StudentViewHeader } from './StudentViewHeader';

import './ExpandedHeaderMaterial.scss';

const cx = cn('expanded-header-material');

export const ExpandedHeaderMaterial: React.FC<IExpandedHeaderMaterialProps> = ({
    name,
    onDelete,
    isStudentView,
    customClassName = '',
}) => {
    const { input, meta } = useField<IHeaderValues>(name);

    const changeInput = useRef(
        debounce(
            (input: FieldInputProps<IHeaderValues, HTMLElement>, headerData: Header) =>
                input.onChange({
                    ...input.value,
                    headerData,
                }),
            500,
        ),
    );

    const handleChangeText = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const headerData: Header = {
                size: input.value.headerData.size,
                text: event.target.value,
            };

            changeInput.current(input, headerData);
        },
        [input],
    );

    const handleChangeSize = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const headerData: Header = {
                text: input.value.headerData.text,
                size: event.target.value as HeaderSize,
            };

            input.onChange({
                ...input.value,
                headerData,
            });
        },
        [input],
    );

    if (isStudentView) {
        return <StudentViewHeader name={name} customClassName={customClassName} />;
    }

    return (
        <div>
            <h3 className={cx('title')}>Заголовок</h3>
            <div className={`${cx({ error: !meta.valid })} ${customClassName}`}>
                <div className={cx('sizes', { selectedSize: input.value?.headerData.size })}>
                    {headerSizes.map(({ size, verboseName }) => (
                        <label key={size}>
                            <input name={`${name}.size`} onChange={handleChangeSize} type="radio" value={size} />
                            <span className={cx(size)}>{verboseName}</span>
                        </label>
                    ))}
                </div>
                <div className={cx('text', { size: input.value?.headerData.size })}>
                    <input
                        spellCheck
                        defaultValue={input.value?.headerData.text || ''}
                        type="text"
                        placeholder="Введите текст заголовка"
                        onChange={handleChangeText}
                    />
                </div>
                <button type="button" className={cx('delete')} onClick={onDelete}>
                    <Basket />
                </button>
            </div>
        </div>
    );
};
