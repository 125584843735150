import moment from 'moment';

type substractData = {
    date?: string | Date;
    amount?: number;
    unit?: moment.unitOfTime.DurationConstructor;
    format?: string;
};

export const decreaseDate = ({ date, amount, unit, format }: substractData): string => {
    return moment(date).subtract(amount, unit).format(format);
};
