import React from 'react';
import { cn } from '@bem-react/classname';

import { RadioFieldCore } from '../../../RadioField/RadioFieldCore';

import { ICustomOption } from './CustomOption.types';

import './CustomOption.scss';

const CnCustomOption = cn('custom-option');

export const CustomOption: React.FC<ICustomOption> = ({ title, options, withRadio, makeHandlerClick }) => {
    return (
        <div className={CnCustomOption()}>
            <p className={CnCustomOption('title')}>{title}</p>
            {options?.map((option, index) =>
                withRadio ? (
                    <RadioFieldCore
                        onChange={makeHandlerClick(`${title} ${option}`, index)}
                        customClassNames={[CnCustomOption('radio')]}
                        key={option}
                        label={option}
                        name={title}
                        value={option}
                    />
                ) : (
                    <span title={option}>{option}</span>
                ),
            )}
        </div>
    );
};
