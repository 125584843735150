import { IDropdownValues } from '@lms-elements/atomic';

import { CalendarPlan } from '../EducationalPlan.types';

export interface Subject {
    id: number;
    courseId: number;
    name: string;
    inWeek?: string;
    inYear?: string;
    fact?: string;
    lessonsInWeek?: string;
    lessonsInYear?: string;
    lecturesInWeek?: string;
    lecturesInYear?: string;
    type?: LessonType;
    selection?: string;
}

export enum LessonType {
    ADDITIONAL = 'additional',
    BASIC = 'basic',
}

export enum EvaluationType {
    DIFFERENTIATED = 'differentiated',
    BINARY = 'binary',
}

export interface CourseTableProps {
    isMain: boolean;
    isBasic: boolean;
    coursesOptions: Subject[];
    calendarPlanOptions: CalendarPlan[];
    isTablet?: boolean;
    readOnly?: boolean;
    onDeleteCourseClick?: (value: number) => void;
    onMainBasicCourseAddClick?: (values: IDropdownValues) => void;
    onMainAdditionalCourseAddClick?: (values: IDropdownValues) => void;
    onSelectiveBasicCourseAddClick?: (values: IDropdownValues) => void;
    onSelectiveAdditionalCourseAddClick?: (values: IDropdownValues) => void;
}
