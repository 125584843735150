export interface IScoreProgressProps {
    score: number | string;
    changing: ScoreChanging;
}

export enum ScoreChanging {
    increase = 'increase',
    decrease = 'decrease',
    stay = 'stay',
}
