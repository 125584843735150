import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgPuzzle({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={63}
            height={67}
            viewBox="0 0 63 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                clipRule="evenodd"
                d="M48.687 20.92l-.003.003a3.33 3.33 0 01-2.349.972h-.013c-2.751 0-4.844-2.464-4.392-5.176L44.388 2H4.52L2.062 16.719c-.452 2.712 1.64 5.176 4.392 5.176h.013a3.33 3.33 0 002.349-.972l.003-.003a8.015 8.015 0 015.661-2.341h.007c3.671 0 6.645 2.968 6.645 6.631 0 3.661-2.974 6.632-6.645 6.632h-.007a8.015 8.015 0 01-5.66-2.34l-.004-.004a3.317 3.317 0 00-2.349-.972h-.013c-2.751 0-4.844 2.464-4.392 5.173L4.52 48.42l11.446-2.288c2.67-.53 5.166 1.512 5.166 4.231a3.31 3.31 0 01-.974 2.344l-.003.004a7.983 7.983 0 00-2.346 5.65v.006c0 3.661 2.977 6.632 6.645 6.632 3.671 0 6.645-2.97 6.645-6.632v-.006a7.983 7.983 0 00-2.346-5.65l-.003-.004a3.324 3.324 0 01-.974-2.344c0-2.719 2.496-4.761 5.167-4.23l11.445 2.287L41.93 33.7c-.452-2.71 1.641-5.173 4.392-5.173h.013c.88 0 1.728.349 2.35.972l.002.003a8.015 8.015 0 005.662 2.341h.006c3.672 0 6.645-2.97 6.645-6.631 0-3.664-2.974-6.632-6.645-6.632h-.006c-2.123 0-4.16.842-5.662 2.34z"
                stroke="#EDEEF2"
                strokeWidth={4}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgPuzzle = React.memo(SvgPuzzle);
export default MemoSvgPuzzle;
