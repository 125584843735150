import React from 'react';
import { Field } from 'react-final-form';
import { composeValidators } from '@lms-elements/utils';

import { IInputListProps } from './InputList.types';
import { InputListCore } from './InputListCore';
import { InputListField } from './InputListField';

export const InputList: React.FC<IInputListProps> = ({
    isField,
    name = 'inputList',
    validators = [],
    items = [],
    onChange = () => undefined,
    placeholder,
}) => {
    if (!isField) {
        return <InputListCore items={items} onChange={onChange} placeholder={placeholder} />;
    }

    return (
        <Field<string[]> name={name} validate={composeValidators(...validators)}>
            {() => <InputListField name={name} onChange={onChange} placeholder={placeholder} />}
        </Field>
    );
};
