export enum GroupChecked {
    all = 'all',
    none = 'none',
    part = 'part',
}

export interface ListData {
    isItemChecked: boolean;
    itemCost: number;
    itemId: number;
}
export interface SubscriptionListItemProps {
    id: number;
    date: string;
    description: string;
    cost: number;
    isMobile?: boolean;
    GroupChecked?: GroupChecked;
    handleListItemChange?: ({ isItemChecked, itemCost, itemId }: ListData) => void;
}
