import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgUser({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={24}
            height={25}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M9 13.75l-.124.87a2.225 2.225 0 01-1.177 1.443l-1.723.848a4.446 4.446 0 00-2.354 2.887L3 22.25h18l-.623-2.452a4.44 4.44 0 00-2.354-2.887l-1.722-.848a2.222 2.222 0 01-1.177-1.443L15 13.75"
                stroke="#585C6F"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                clipRule="evenodd"
                d="M13.5 14.755v0a2.52 2.52 0 01-3 0A8.639 8.639 0 017 7.822v-.62C7 4.467 9.239 2.25 12 2.25s5 2.217 5 4.952v.62a8.642 8.642 0 01-3.5 6.933z"
                stroke="#585C6F"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgUser = React.memo(SvgUser);
export default MemoSvgUser;
