import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { cn } from '@bem-react/classname';
import { useOuterClickField } from '@lms-elements/hooks';
import { CloseIcon } from '@lms-elements/icons';

import { FieldSuggestCoreProps } from './FieldSuggestCore.types';

import './FieldSuggestCore.scss';

const CnFieldSuggest = cn('fieldSuggest');

export const FieldSuggestCore: React.FC<FieldSuggestCoreProps> = ({
    input,
    meta,
    placeholder,
    items,
    disable,
    onInputChange,
}) => {
    const ref = useRef(null);
    const [isBlur, setIsBlur] = useState(false);

    const onClickXIcon = useCallback(() => {
        input.onChange('');
        onInputChange?.({ text: '', data: null });
    }, [input, onInputChange]);

    const handleOptionClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            setIsBlur(false);
            const text = event.currentTarget.dataset.el || '';
            input.onChange(text);
            const result = items?.find((item) => item.text === text);
            if (result) {
                onInputChange?.({ text: result.text, data: result.data });
            }
        },
        [input, items, onInputChange],
    );

    const handleInputChange = useCallback(
        (evt: ChangeEvent<HTMLInputElement>) => {
            const value = evt.target.value;
            input.onChange(value);
            onInputChange?.({ text: value, data: null });
        },
        [input, onInputChange],
    );

    useEffect(() => {
        if (meta.modified || meta.active) {
            setIsBlur(items?.length !== 0);
        }
    }, [items, meta.active, meta.modified]);

    useOuterClickField(
        ref,
        () => {
            input.onBlur();
            setIsBlur((prev) => !prev);
        },
        isBlur,
    );

    return (
        <div className={`${CnFieldSuggest('container')}`} ref={ref}>
            <div className={`${CnFieldSuggest('input-wrapper')}`}>
                <input
                    spellCheck
                    type={'text'}
                    title={input.value}
                    className={CnFieldSuggest('input', {
                        focus: meta.active,
                        open: isBlur,
                        disable: disable,
                        notValid: Boolean(meta.error),
                    })}
                    {...input}
                    onFocus={(e): void => {
                        input.onFocus(e);
                    }}
                    placeholder={placeholder}
                    autoComplete="off"
                    onChange={handleInputChange}
                />
                {!disable && (
                    <div className={CnFieldSuggest('icon', { notEmpty: Boolean(input.value) })} onClick={onClickXIcon}>
                        <CloseIcon />
                    </div>
                )}
            </div>
            <div className={CnFieldSuggest('suggest', { focus: isBlur })}>
                <div className={CnFieldSuggest('options')}>
                    {items?.map((el) => (
                        <div
                            key={el.text}
                            data-el={el.text}
                            className={CnFieldSuggest('option', { focus: isBlur })}
                            onClick={handleOptionClick}
                        >
                            <span title={el.text}>{el.text}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
