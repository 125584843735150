import React, { useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { LogoIcon, LogoText } from '@lms-elements/icons';

import { FALLBACK_EMAIL } from './MessageBlock.constants';
import { IMessageBlockProps, MessageBlockType } from './MessageBlock.types';

import './MessageBlock.scss';

const CnMessageBlock = cn('messageBlock');

export const MessageBlock: React.FC<IMessageBlockProps> = ({ text, type, email = FALLBACK_EMAIL }) => {
    const message = useMemo(
        () => (type === MessageBlockType.ToBeDone ? `${text} ${email}` : text),
        [type, text, email],
    );

    return (
        <div className={CnMessageBlock('content', { '': type })}>
            <div className={CnMessageBlock('logoWrapper')}>
                <LogoIcon />
                <LogoText />
            </div>
            <div className={CnMessageBlock('message')}>{message}</div>
        </div>
    );
};
