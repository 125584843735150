import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgWarning({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={56}
            height={52}
            viewBox="0 0 56 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                clipRule="evenodd"
                d="M9.589 18.825S5.04 12.25 9.214 8.322c4.171-3.928 11.15.356 11.15.356S22.084 1 27.984 1c5.899 0 7.618 7.678 7.618 7.678s6.982-4.284 11.153-.356c4.174 3.928-.374 10.503-.374 10.503s8.15 1.62 8.15 7.175c0 5.556-8.15 7.175-8.15 7.175s4.548 6.575.374 10.503c-4.17 3.928-11.153-.356-11.153-.356S33.882 51 27.983 51c-5.9 0-7.619-7.678-7.619-7.678s-6.979 4.284-11.15.356c-4.174-3.928.375-10.503.375-10.503S1.436 31.555 1.436 26c0-5.556 8.153-7.175 8.153-7.175z"
                stroke="#32946E"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M27.983 15.975v11.11" stroke="#32946E" strokeLinecap="round" strokeLinejoin="round" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.46 31.233c0 .766-.661 1.388-1.476 1.388-.814 0-1.474-.622-1.474-1.388 0-.767.66-1.39 1.474-1.39.815 0 1.475.623 1.475 1.39z"
                fill="#32946E"
            />
        </svg>
    );
}

const MemoSvgWarning = React.memo(SvgWarning);
export default MemoSvgWarning;
