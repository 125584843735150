import moment from 'moment';

import { DateComponents, getCapitalizeMonth } from './';

export const getDateComponents = (date?: string | Date): DateComponents => {
    return {
        month: moment(date)?.get('month') || moment().get('month'),
        year: moment(date)?.get('year') || moment().get('year'),
        dateWithoutTime: date ? moment(date)?.format('YYYY-MM-DD') || moment().format('YYYY-MM-DD') : undefined,
        time: date ? moment(date)?.format('HH:mm') || moment(date).format('HH:mm') : undefined,
        dateWithMonth: date
            ? moment().isBefore(date) && moment().format('D') !== moment(date).format('D')
                ? getCapitalizeMonth(date)
                : 'сегодня'
            : undefined,
        date: moment(date).get('date'),
        hours: moment(date).hours(),
        weekday: moment(date).weekday(),
    };
};
