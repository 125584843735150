import React, { useCallback, useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { BoldTextIcon, ItalicTextIcon, StrikethroughTextIcon, UnderlinedTextIcon } from '@lms-elements/icons';
import { RichUtils } from 'draft-js';
import { getSelectionInlineStyle } from 'draftjs-utils';

import { InlineStyles } from '../../types/shared.types';
import { OptionButton } from '../OptionButton';

import { IInlineOptionProps } from './InlineOption.types';

import './InlineOption.scss';

const CnInlineOption = cn('inline-option');

export const InlineOption: React.FC<IInlineOptionProps> = ({ editorState, onChange, type }) => {
    const toggleInlineStyle = useCallback(() => {
        const newState = RichUtils.toggleInlineStyle(editorState, type);
        if (newState) {
            onChange(newState);
        }
    }, [editorState, onChange, type]);

    const active = useMemo(() => !!getSelectionInlineStyle(editorState)[type], [editorState, type]);

    const Icon = useMemo(() => {
        switch (type) {
            case InlineStyles.BOLD:
                return BoldTextIcon;
            case InlineStyles.ITALIC:
                return ItalicTextIcon;
            case InlineStyles.STRIKETHROUGH:
                return StrikethroughTextIcon;
            case InlineStyles.UNDERLINE:
                return UnderlinedTextIcon;
            default:
                return BoldTextIcon;
        }
    }, [type]);

    return (
        <OptionButton onClick={toggleInlineStyle} active={active}>
            <Icon className={CnInlineOption('icon', { type, active })} />
        </OptionButton>
    );
};
