import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgBook({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={24}
            height={25}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M12 7.327a3.077 3.077 0 013.077-3.077h6.154a.77.77 0 01.769.77v12.307a.77.77 0 01-.77.77h-6.153A3.077 3.077 0 0012 21.172M2 17.327a.77.77 0 00.77.77h6.153A3.077 3.077 0 0112 21.172V7.327A3.077 3.077 0 008.923 4.25H2.77A.77.77 0 002 5.02v12.307z"
                stroke="#585C6F"
                strokeWidth={1.4}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgBook = React.memo(SvgBook);
export default MemoSvgBook;
