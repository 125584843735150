import { getDateWithoutYear } from '@lms-elements/utils';

import { MarkMetaData, TooltipViewEnum } from './Tooltip.types';

export const getMeta = (tooltipView: TooltipViewEnum, metaData?: MarkMetaData): string => {
    if (tooltipView === TooltipViewEnum.semester) {
        return 'сумма(оценка * вес)/кол-во';
    }

    if (tooltipView === TooltipViewEnum.detailed && metaData) {
        const markDate = getDateWithoutYear(metaData.date);

        return `${markDate}, ${metaData.type}${metaData.isMark ? ` (вес ${metaData.weight})` : ''}`;
    }

    if (tooltipView === TooltipViewEnum.attendance && metaData) {
        const markDate = getDateWithoutYear(metaData.date);

        return `${markDate}, ${metaData.type}${metaData.isMark ? ` (вес ${metaData.weight})` : ''}`;
    }

    return '';
};
