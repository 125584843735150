import React from 'react';
import { useField } from 'react-final-form';
import { cn } from '@bem-react/classname';

import { ImageItem } from './ImageItem';
import { IImageUploaderProps, IImageValues } from './ImageUploader.types';
import { UploadDialog } from './UploadDialog';

import './ImageUploader.scss';

const ImageUploaderCn = cn('imageUploader');

export const ImageUploader: React.FC<IImageUploaderProps> = ({ name, onUploadImage, onDeleteImage }) => {
    const { input } = useField<IImageValues>(name);

    return (
        <div>
            <div className={ImageUploaderCn()}>
                <div className={ImageUploaderCn('row')}>
                    <div className={ImageUploaderCn('row-item')}>
                        <ImageItem name={name} onDeleteImage={onDeleteImage} />
                    </div>
                    {!input.value?.values.imageData?.data?.data && (
                        <UploadDialog name={name} onUpload={onUploadImage} />
                    )}
                </div>
            </div>
        </div>
    );
};
