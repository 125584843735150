import React, { useCallback, useMemo } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { Loading } from '@lms-elements/icons';
import { composeFormValidators } from '@lms-elements/utils';

import { Button, ButtonViewEnum } from '../Button';
import { FieldForm } from '../FieldForm';

import { IChangePasswordFormProps, IFormValues } from './ChangePasswordForm.types';
import { createValidateNewPasswordFormFields, validateCurrentPasswordFormField } from './ChangePasswordForm.utils';

import './ChangePasswordForm.scss';

export const CnForm = cn('changePasswordForm');

export const ChangePasswordForm: React.FC<IChangePasswordFormProps> = ({
    formTitle = 'Изменить пароль',
    currentPassPlaceholder = 'Введите пароль',
    newPassPlaceholder = 'Введите новый пароль',
    confirmPassPlaceholder = 'Введите новый пароль еще раз',
    confirmButtonLabel = 'Сохранить изменения',
    withCurrentPasswordField,
    isLoading,
    submitErrorMessage,
    submitSuccessMessage,
    hiddenEmailInputValue,
    needInputDeleteIcon = true,
    hidePasswordText = true,
    customClassName = '',
    passwordMinLenght = 6,
    onSubmit,
    onChange = () => undefined,
}) => {
    const formValidation = useMemo(() => {
        if (submitSuccessMessage) return undefined;

        return withCurrentPasswordField
            ? composeFormValidators(
                  createValidateNewPasswordFormFields(passwordMinLenght),
                  validateCurrentPasswordFormField,
              )
            : createValidateNewPasswordFormFields(passwordMinLenght);
    }, [passwordMinLenght, submitSuccessMessage, withCurrentPasswordField]);

    const inputType = useMemo(() => (hidePasswordText ? 'password' : 'text'), [hidePasswordText]);

    const handleFormChange = useCallback(
        (state: { dirty: boolean }) => {
            if (state.dirty) onChange();
        },
        [onChange],
    );

    return (
        <div className={`${customClassName} ${CnForm()}`}>
            <span className={CnForm('title')}>{formTitle}</span>
            <Form<IFormValues> onSubmit={onSubmit} validate={formValidation}>
                {({ handleSubmit, hasValidationErrors, errors, submitting, touched }) => (
                    <form onSubmit={handleSubmit}>
                        <div className={CnForm('input-block')}>
                            {hiddenEmailInputValue && (
                                <input
                                    className={CnForm('input', { hidden: true })}
                                    type="email"
                                    name="username"
                                    value={hiddenEmailInputValue}
                                />
                            )}
                            {withCurrentPasswordField && (
                                <FieldForm
                                    name="currentPassword"
                                    placeholder={currentPassPlaceholder}
                                    type="password"
                                    needDeleteIcon={needInputDeleteIcon}
                                />
                            )}
                            <FieldForm
                                name="newPassword"
                                placeholder={newPassPlaceholder}
                                type={inputType}
                                needDeleteIcon={needInputDeleteIcon}
                            />
                            <FieldForm
                                name="confirmPassword"
                                placeholder={confirmPassPlaceholder}
                                type={inputType}
                                needDeleteIcon={needInputDeleteIcon}
                            />
                        </div>
                        <Button
                            type="submit"
                            view={ButtonViewEnum.action}
                            size="l"
                            disabled={
                                submitting ||
                                isLoading ||
                                hasValidationErrors ||
                                Boolean(submitErrorMessage) ||
                                Boolean(submitSuccessMessage)
                            }
                        >
                            <div className={CnForm('button-label', { loading: isLoading })}>
                                {isLoading ? <Loading /> : confirmButtonLabel}
                            </div>
                        </Button>
                        {(Boolean(submitErrorMessage) ||
                            (hasValidationErrors && touched && Object.values(touched).some(Boolean))) && (
                            <div className={CnForm('error')}>
                                {submitErrorMessage ||
                                    (touched && Object.values(touched).some(Boolean) && errors?.currentPassword) ||
                                    (touched && Object.values(touched).some(Boolean) && errors?.newPassword) ||
                                    (touched && Object.values(touched).some(Boolean) && errors?.confirmPassword)}
                            </div>
                        )}
                        {Boolean(submitSuccessMessage) && (
                            <div className={CnForm('success')}>{submitSuccessMessage}</div>
                        )}
                        <FormSpy
                            subscription={{ dirty: true, dirtySinceLastSubmit: true }}
                            onChange={handleFormChange}
                        />
                    </form>
                )}
            </Form>
        </div>
    );
};
