/** Returns trimmed text
 * @param text the text to be trimmed
 * @param size number of characters to which you want to cut the text
 * @param endCharacter character that will replace the truncated text
 * @returns result string
 */

export const trimString = (text: string, size: number, endCharacter: string): string => {
    let result = text;
    if (text.length > size) {
        result = text.substr(0, size);
        result += endCharacter;
    }
    return result;
};
