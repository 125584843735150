import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgMusicNotes({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={67}
            height={67}
            viewBox="0 0 67 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M47.11 52.344a7.328 7.328 0 100-14.657 7.328 7.328 0 000 14.657zM13.61 60.719a7.328 7.328 0 100-14.657 7.328 7.328 0 000 14.657zM54.438 20.938l-33.5 8.375"
                stroke="#EDEEF2"
                strokeWidth={4}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.938 53.39V16.75l33.5-8.375v36.64"
                stroke="#EDEEF2"
                strokeWidth={4}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgMusicNotes = React.memo(SvgMusicNotes);
export default MemoSvgMusicNotes;
