import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgCodifier({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={24}
            height={25}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M6.5 17.25h-3a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5zM13.5 17.25h-3a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5zM20.5 17.25h-3a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5z"
                stroke="#585C6E"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M6 17.25v-3a1 1 0 011-1h10a1 1 0 011 1v3M12 17.25v-8.5" stroke="#585C6E" strokeWidth={1.5} />
            <circle cx={12} cy={6.25} r={2.25} stroke="#585C6E" strokeWidth={1.5} />
        </svg>
    );
}

const MemoSvgCodifier = React.memo(SvgCodifier);
export default MemoSvgCodifier;
