import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { useFormState } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { useDropdownDots, useRefForDrag } from '@lms-elements/hooks';
import { Basket, DropdownDots, NoteBlanks } from '@lms-elements/icons';
import { getObjectPropertyByString } from '@lms-elements/utils';
import { XYCoord } from 'dnd-core';

import { CheckboxField } from '../CheckboxField';
import { EditorField } from '../EditorField';
import { TextInputField } from '../TextInputField';

import { IDragItem, IMultipleAnswerFieldsProps, IMultipleAnswerOption, itemTypes } from './MultipleAnswerFields.types';

import './MultipleAnswerFields.scss';

const MultipleCn = cn('multiple-question');
const BtnGroupCn = cn(MultipleCn(), 'btn-group');

const ARRAY_ELEM_CALL_REGEXP = /(\[\d\]){1}$/g;

const MultipleAnswerFields: React.FC<IMultipleAnswerFieldsProps> = ({
    name,
    option,
    onOptionCopy,
    onOptionDelete,
    moveFieldsHandler,
    index,
    checkboxesValidators = [],
    isBlocked,
    disableActionBlocks = false,
    savePastedStyles,
}) => {
    const { canDrag, handleDropdownDotsMouseEnter, handleDropdownDotsMouseLeave } = useDropdownDots();

    const ref = useRef<HTMLDivElement>(null);
    const [{ handlerId }, drop] = useDrop(
        () => ({
            accept: itemTypes.MULTIPLE_ANSWER_FIELDS,
            collect: (monitor) => ({
                handlerId: monitor.getHandlerId(),
            }),
            hover: (item: IDragItem, monitor: DropTargetMonitor) => {
                if (!ref.current) {
                    return;
                }
                const dragIndex = item.index;
                const hoverIndex = index;
                if (dragIndex === hoverIndex) {
                    return;
                }
                const hoverBoundingRect = ref.current?.getBoundingClientRect();
                const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
                const clientOffset = monitor.getClientOffset();
                const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                    return;
                }
                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                    return;
                }
                moveFieldsHandler(dragIndex, hoverIndex);
                item.index = hoverIndex;
            },
        }),
        [index, moveFieldsHandler],
    );

    const [{ isDragging }, drag] = useDrag({
        type: itemTypes.MULTIPLE_ANSWER_FIELDS,
        item: () => ({ name, index }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag,
    });

    const { element, handleContainerBlur, handleContainerFocus } = useRefForDrag(ref.current);

    drag(drop(element));

    const { values } = useFormState();

    const options = getObjectPropertyByString<IMultipleAnswerOption[]>(
        values,
        name.replace(ARRAY_ELEM_CALL_REGEXP, ''),
    );

    const [checkboxError, setCheckboxError] = useState(false);

    useEffect(() => {
        if (Array.isArray(options)) {
            setCheckboxError(!options?.some((q) => q.isCorrect));
        }
    }, [values, options]);

    const isEditor = useMemo(
        () => typeof getObjectPropertyByString(values, `${name}.answer`) === 'object',
        [name, values],
    );

    const placeholder = useMemo(
        () => (option.isCorrect ? 'Введите правильный ответ' : 'Введите неправильный ответ'),
        [option.isCorrect],
    );

    return (
        <div
            ref={ref}
            data-handler-id={handlerId}
            style={{ opacity: isDragging ? 0.7 : 1 }}
            className={[MultipleCn('answer-option'), 'expanded-question-answer-option'].join(' ')}
        >
            <div className={'center-vertical'} onFocus={handleContainerFocus} onBlur={handleContainerBlur}>
                <CheckboxField
                    name={`${name}.isCorrect`}
                    validators={[...checkboxesValidators, () => (checkboxError ? 'error' : undefined)]}
                    className={MultipleCn('checkbox-wrapper', { isEditor })}
                    isError={checkboxError}
                />
                {isEditor ? (
                    <div className="expanded-question-input expanded-question-input-editor">
                        <EditorField
                            isBlocked={isBlocked || isDragging}
                            name={`${name}.answer`}
                            hideToolbarOnBlur
                            placeholder={placeholder}
                            disableActionBlocks={disableActionBlocks}
                            savePastedStyles={savePastedStyles}
                        />
                    </div>
                ) : (
                    <TextInputField name={`${name}.answer`} placeholder={placeholder} />
                )}
            </div>
            <div className={MultipleCn('btn-group')}>
                <button
                    className={BtnGroupCn('move')}
                    type="button"
                    onMouseEnter={handleDropdownDotsMouseEnter}
                    onMouseLeave={handleDropdownDotsMouseLeave}
                >
                    <DropdownDots />
                </button>
                <button className={BtnGroupCn('copy')} type="button" onClick={onOptionCopy}>
                    <NoteBlanks />
                </button>
                <button className={BtnGroupCn('delete')} type="button" onClick={onOptionDelete}>
                    <Basket />
                </button>
            </div>
        </div>
    );
};

export default MultipleAnswerFields;
