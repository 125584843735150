import { RawDraftContentBlock } from 'draft-js';

import { forEachKeyValueInObject } from './common';
import { InlineStyles, InlineStylesProperty, TagStyles } from './draftToHtml.types';

/**
 * The function will return array of inline styles applicable to the block.
 */
export const getStyleArrayForBlock = (block: RawDraftContentBlock) => {
    const { text, inlineStyleRanges } = block;
    const inlineStyles = {
        BOLD: new Array(text.length),
        ITALIC: new Array(text.length),
        UNDERLINE: new Array(text.length),
        STRIKETHROUGH: new Array(text.length),
        CODE: new Array(text.length),
        SUPERSCRIPT: new Array(text.length),
        SUBSCRIPT: new Array(text.length),
        COLOR: new Array(text.length),
        BGCOLOR: new Array(text.length),
        FONTSIZE: new Array(text.length),
        FONTFAMILY: new Array(text.length),
        length: text.length,
    };
    if (inlineStyleRanges && inlineStyleRanges.length > 0) {
        inlineStyleRanges.forEach((range) => {
            const { offset } = range;
            const length = offset + range.length;
            for (let i = offset; i < length; i += 1) {
                if (range.style.indexOf('color-') === 0) {
                    inlineStyles.COLOR[i] = range.style.substring(6);
                } else if (range.style.indexOf('bgcolor-') === 0) {
                    inlineStyles.BGCOLOR[i] = range.style.substring(8);
                } else if (range.style.indexOf('fontsize-') === 0) {
                    inlineStyles.FONTSIZE[i] = range.style.substring(9);
                } else if (range.style.indexOf('fontfamily-') === 0) {
                    inlineStyles.FONTFAMILY[i] = range.style.substring(11);
                } else if (inlineStyles[range.style]) {
                    inlineStyles[range.style][i] = true;
                }
            }
        });
    }
    return inlineStyles;
};

/**
 * The function will return inline style applicable at some offset within a block.
 */
export const getStylesAtOffset = (inlineStyles: InlineStyles, offset: number) => {
    const styles: TagStyles = {};
    if (inlineStyles.COLOR?.[offset]) {
        styles.COLOR = inlineStyles.COLOR[offset];
    }
    if (inlineStyles.BGCOLOR?.[offset]) {
        styles.BGCOLOR = inlineStyles.BGCOLOR[offset];
    }
    if (inlineStyles.FONTSIZE?.[offset]) {
        styles.FONTSIZE = inlineStyles.FONTSIZE[offset];
    }
    if (inlineStyles.FONTFAMILY?.[offset]) {
        styles.FONTFAMILY = inlineStyles.FONTFAMILY[offset];
    }
    if (inlineStyles.UNDERLINE?.[offset]) {
        styles.UNDERLINE = true;
    }
    if (inlineStyles.ITALIC?.[offset]) {
        styles.ITALIC = true;
    }
    if (inlineStyles.BOLD?.[offset]) {
        styles.BOLD = true;
    }
    if (inlineStyles.STRIKETHROUGH?.[offset]) {
        styles.STRIKETHROUGH = true;
    }
    if (inlineStyles.CODE?.[offset]) {
        styles.CODE = true;
    }
    if (inlineStyles.SUBSCRIPT?.[offset]) {
        styles.SUBSCRIPT = true;
    }
    if (inlineStyles.SUPERSCRIPT?.[offset]) {
        styles.SUPERSCRIPT = true;
    }
    return styles;
};

/**
 * Function returns true for a set of styles if the value of these styles at an offset
 * are same as that on the previous offset.
 */
export const sameStyleAsPrevious = (inlineStyles: InlineStyles, styles: InlineStylesProperty[], index: number) => {
    let sameStyled = true;
    if (index > 0 && index < (inlineStyles?.length ?? 0)) {
        styles.forEach((style) => {
            sameStyled = sameStyled && inlineStyles[style]?.[index] === inlineStyles[style]?.[index - 1];
        });
    } else {
        sameStyled = false;
    }
    return sameStyled;
};

/**
 * Function will return style string for a block.
 */
export const getBlockStyle = (data: Record<string, string> | undefined) => {
    let styles = '';
    forEachKeyValueInObject(data, (key, value) => {
        if (value) {
            styles += `${key}:${value as string};`;
        }
    });
    return styles;
};

/**
 * Function returns html for text depending on inline style tags applicable to it.
 */
export const addStylePropertyMarkup = (styles: TagStyles, text: string) => {
    if (styles && (styles.COLOR || styles.BGCOLOR || styles.FONTSIZE || styles.FONTFAMILY)) {
        let styleString = 'style="';
        if (styles.COLOR) {
            styleString += `color: ${styles.COLOR};`;
        }
        if (styles.BGCOLOR) {
            styleString += `background-color: ${styles.BGCOLOR};`;
        }
        if (styles.FONTSIZE) {
            styleString += `font-size: ${styles.FONTSIZE}${/^\d+$/.test(styles.FONTSIZE) ? 'px' : ''};`;
        }
        if (styles.FONTFAMILY) {
            styleString += `font-family: ${styles.FONTFAMILY};`;
        }
        styleString += '"';
        return `<span ${styleString}>${text}</span>`;
    }
    return text;
};
