import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgLinkIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M13.348 18.65l5.303-5.303M17.325 19.977l-2.21 2.21a3.75 3.75 0 01-5.303-5.303l2.21-2.21M19.977 17.325l2.21-2.21a3.75 3.75 0 00-5.303-5.303l-2.21 2.21"
                stroke="#252232"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgLinkIcon = React.memo(SvgLinkIcon);
export default MemoSvgLinkIcon;
