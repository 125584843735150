/**
 * creates composed function that can be passed to Field from final-form
 * @param validators validating functions
 * @returns validating function
 */
export const composeValidators =
    <T extends unknown>(...validators: ((value: T) => undefined | string)[]) =>
    (value: T): string | undefined =>
        validators.reduce(
            (error: string | undefined, validator) => error || (validator && validator(value)),
            undefined,
        );
