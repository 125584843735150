import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgLikeIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={12}
            height={10}
            viewBox="0 0 12 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M5.971 9C1.72 6.38 1 4.239 1 3.478v-.036C1 2.084 2.19 1 3.658 1 4.67 1 5.552 1.525 6 2.297 6.448 1.525 7.33 1 8.342 1 9.81 1 11 2.084 11 3.442v.036C11 4.15 10.361 6.14 5.971 9z"
                stroke="#919399"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgLikeIcon = React.memo(SvgLikeIcon);
export default MemoSvgLikeIcon;
