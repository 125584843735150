import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgMic({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={17}
            height={23}
            viewBox="0 0 17 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M15.283 12.309a7.27 7.27 0 01-14.45 0M8.058 18.77V22"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.059 1h0a4.039 4.039 0 014.038 4.038V11.5a4.038 4.038 0 01-4.038 4.039h0A4.04 4.04 0 014.021 11.5V5.038A4.038 4.038 0 018.059 1v0z"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgMic = React.memo(SvgMic);
export default MemoSvgMic;
