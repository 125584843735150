export interface AnyObject {
    [key: string]: string;
}
export type ValidationErrors = AnyObject | undefined;

export const composeFormValidators =
    <T extends unknown>(...validators: ((values: T) => ValidationErrors)[]) =>
    (values: T): ValidationErrors =>
        validators.reduce(
            (error: ValidationErrors, validator) => ({
                ...error,
                ...validator(values),
            }),
            {} as ValidationErrors,
        );
