import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { cn } from '@bem-react/classname';
import { ClockIcon, Comment } from '@lms-elements/icons';
import { getDateWithoutYear, trimString } from '@lms-elements/utils';

import { Tooltip } from '../Tooltip';
import { MarkMetaData, TooltipViewEnum } from '../Tooltip/Tooltip.types';

import { IMarkProps } from './Mark.types';

import './Mark.scss';

const CnMark = cn('mark');

export const Mark: React.FC<IMarkProps> = ({
    markId,
    comment,
    value,
    average,
    approved = true,
    hoverable = true,
    isPeriodMark,
    withDetailedTooltip,
    type,
    weight,
    date,
    deadline,
    showComment,
    isTestPassed,
    withAttendanceTooltip,
    attendenceData,
    commentLabel,
    filesLabel,
    files,
    needTrimComment = true,
    maxCommentLength = 95,
    isNotSelected,
    needHideWeight,
    onMarkClick,
    author,
}) => {
    const markStatus = useMemo(() => {
        switch (value) {
            case 5:
            case 4:
                return 'good';
            case 3:
                return 'normal';
            case 2:
                return 'bad';
            case -1:
                return 'na';
            case 0:
                return 'comment';
            case null:
                return 'comment';
            case undefined:
                return isTestPassed ? 'accept' : 'failed';
            default:
                return 'comment';
        }
    }, [isTestPassed, value]);

    const showTooltip = useMemo(
        () => (isPeriodMark || withDetailedTooltip || comment || withAttendanceTooltip) && value !== -1,
        [isPeriodMark, withDetailedTooltip, comment, withAttendanceTooltip, value],
    );

    const tooltipView = useMemo(() => {
        switch (true) {
            case withDetailedTooltip:
                return TooltipViewEnum.detailed;
            case value === -1:
                return TooltipViewEnum.na;
            case isPeriodMark:
                return TooltipViewEnum.period;
            case withAttendanceTooltip:
                return TooltipViewEnum.attendance;
            default:
                return TooltipViewEnum.comment;
        }
    }, [isPeriodMark, value, withAttendanceTooltip, withDetailedTooltip]);

    const tooltipMetaData: MarkMetaData = useMemo(() => {
        let markType = type || 'Урок';

        if (withAttendanceTooltip) {
            markType = type || 'Работа в классе';
        }

        return {
            type: markType,
            weight: Number((weight && weight <= 3 ? (weight < 0.01 ? 0.01 : weight) : 0).toFixed(3)),
            date: date || '',
            isMark: value === undefined || value > 0,
        };
    }, [type, withAttendanceTooltip, weight, date, value]);

    const isComment = useMemo(() => markStatus === 'comment', [markStatus]);
    const isChangableGrade = useMemo(
        () => (markStatus === 'normal' || markStatus === 'bad') && deadline,
        [deadline, markStatus],
    );

    const weightValue = useMemo(
        () => Number((weight && weight <= 3 ? (weight < 0.01 ? 0.01 : weight) : 0).toFixed(3)),
        [weight],
    );

    const weightClassName = useMemo(() => (approved ? 'weight' : 'weight-notApproved'), [approved]);
    const tooltipClassName = useMemo(() => (approved ? 'tooltip' : 'tooltip-notApproved'), [approved]);

    const [hovered, setHovered] = useState(false);
    const [tooltipState, setTooltipState] = useState({ top: -9999, left: -9999 });
    const [weightState, setWeightState] = useState({ top: -9999, left: -9999 });

    const handleDocumentScroll = useCallback(() => {
        setHovered(false);
    }, []);

    const tooltipRef = useRef<HTMLDivElement>(null);

    const handleMarkMouseEnter = useCallback(
        (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
            const mark = e.currentTarget as HTMLDivElement;
            const { x, y } = mark.getBoundingClientRect();

            setTooltipState({ top: y, left: x });
            setWeightState({ top: y, left: x });

            document.addEventListener('touchmove', handleDocumentScroll);
            document.addEventListener('mousewheel', handleDocumentScroll);
            document.addEventListener('scroll', handleDocumentScroll);

            setHovered(true);
        },
        [handleDocumentScroll],
    );

    useEffect(() => {
        if (tooltipRef.current) {
            const { width } = tooltipRef.current.getBoundingClientRect();

            setTooltipState((prev) => {
                if (prev.left + width / 2 + 30 > window.innerWidth) {
                    return {
                        ...prev,
                        left: window.innerWidth - width / 2 - 30,
                    };
                }

                if (prev.left < width / 2) {
                    return {
                        ...prev,
                        left: width / 2,
                    };
                }

                return prev;
            });
        }
    }, [tooltipState.left]);

    const handleMarkMouseLeave = useCallback(() => {
        setTooltipState({ top: -9999, left: -9999 });
        setWeightState({ top: -9999, left: -9999 });

        document.removeEventListener('touchmove', handleDocumentScroll);
        document.removeEventListener('mousewheel', handleDocumentScroll);
        document.removeEventListener('scroll', handleDocumentScroll);

        setHovered(false);
    }, [handleDocumentScroll]);

    const commentText = useMemo(() => {
        if (comment && isChangableGrade && deadline) {
            return `${comment} ${getDateWithoutYear(deadline)}`;
        }
        if (comment && needTrimComment) {
            return trimString(comment, maxCommentLength, '...');
        }

        return comment;
    }, [comment, deadline, isChangableGrade, maxCommentLength, needTrimComment]);

    const handleMarkClick = useCallback(() => {
        if ((value !== undefined || isTestPassed !== undefined) && onMarkClick) {
            onMarkClick({
                id: markId,
                score: value !== undefined ? value : isTestPassed ? true : false,
                isApproved: approved,
            });
        }
    }, [approved, isTestPassed, markId, onMarkClick, value]);

    if (showComment) {
        return (
            <div className={CnMark('container')}>
                <div className={CnMark({ status: markStatus, notApproved: !approved })}>
                    {isComment ? (
                        <div className={CnMark('comment')}>
                            <Comment />
                        </div>
                    ) : (
                        <div className={CnMark('group')}>{value}</div>
                    )}
                </div>
                {comment && <div className={CnMark('commentText')}>{comment}</div>}
            </div>
        );
    }

    if (isComment) {
        return (
            <div
                className={CnMark({ status: markStatus, notApproved: !approved, hoverable })}
                onMouseEnter={handleMarkMouseEnter}
                onMouseLeave={handleMarkMouseLeave}
                onTouchStart={handleMarkMouseEnter}
            >
                <div className={CnMark('comment')}>
                    <Comment />
                </div>
                {comment && showTooltip && hovered && hoverable && (
                    <>
                        <div className={CnMark(tooltipClassName)} style={tooltipState} ref={tooltipRef}>
                            <Tooltip
                                view={tooltipView}
                                metaData={tooltipMetaData}
                                attandanceData={attendenceData}
                                commentLabel={commentLabel}
                                filesLabel={filesLabel}
                                files={files}
                                withPointer={false}
                            >
                                {comment && commentText}
                            </Tooltip>
                        </div>
                        <div className={CnMark('tooltip-pointer')} />
                    </>
                )}
            </div>
        );
    }
    return (
        <div
            className={CnMark({ status: markStatus, notApproved: !approved, hoverable, isNotSelected })}
            onMouseEnter={handleMarkMouseEnter}
            onMouseLeave={handleMarkMouseLeave}
            onTouchStart={handleMarkMouseEnter}
            onClick={handleMarkClick}
        >
            {comment && !isChangableGrade && (
                <div className={CnMark('icon')}>
                    <Comment />
                </div>
            )}
            {isChangableGrade && (
                <div className={CnMark('icon', { with: 'clock' })}>
                    <ClockIcon />
                </div>
            )}
            {((comment && showTooltip && hovered && hoverable && tooltipView !== TooltipViewEnum.period) ||
                author ||
                (tooltipView === TooltipViewEnum.period && markStatus !== 'accept' && markStatus !== 'failed') ||
                (!comment && showTooltip && hovered && hoverable)) && (
                <>
                    <div className={CnMark(tooltipClassName)} style={tooltipState} ref={tooltipRef}>
                        <Tooltip
                            view={tooltipView}
                            metaData={tooltipMetaData}
                            attandanceData={attendenceData}
                            commentLabel={commentLabel}
                            filesLabel={filesLabel}
                            files={files}
                            withPointer={false}
                            approved={approved}
                            average={average}
                            author={author}
                        >
                            {comment && commentText}
                        </Tooltip>
                    </div>
                    {hovered && <div className={CnMark('tooltip-pointer')} />}
                </>
            )}
            {!needHideWeight && hovered && hoverable && !deadline && (
                <div className={CnMark(weightClassName)} style={weightState}>
                    {weightValue}
                </div>
            )}
            {value !== undefined && isTestPassed === undefined ? (
                <div className={CnMark('group', { isNotSelected })}>{value === -1 ? 'Н/A' : value}</div>
            ) : (
                <div className={CnMark('group-text', { isNotSelected, isAccept: isTestPassed })}>
                    {isTestPassed === true ? 'Зач' : 'Нзч'}
                </div>
            )}
        </div>
    );
};
