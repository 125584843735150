import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgDeferredHomeworkIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={18}
            height={22}
            viewBox="0 0 18 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M12.913 4.294h3.25v15.707H1V4.294h3.249"
                stroke="#585C6E"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                clipRule="evenodd"
                d="M10.365 3.835c0-1.013-.8-1.835-1.784-1.835s-1.784.822-1.784 1.835H5.014v2.293h7.135V3.835h-1.784z"
                stroke="#585C6E"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.35 16a.204.204 0 01-.21-.21l.02-.09 2.4-6.45c.067-.167.187-.25.36-.25h1.1c.173 0 .293.083.36.25l2.4 6.45.02.09a.19.19 0 01-.07.15.192.192 0 01-.14.06h-.89a.29.29 0 01-.29-.19l-.46-1.21H6.99l-.46 1.21a.29.29 0 01-.29.19h-.89zm1.99-2.56H9.6l-1.13-3.08-1.13 3.08z"
                fill="#585C6E"
            />
        </svg>
    );
}

const MemoSvgDeferredHomeworkIcon = React.memo(SvgDeferredHomeworkIcon);
export default MemoSvgDeferredHomeworkIcon;
