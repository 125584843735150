import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { cn } from '@bem-react/classname';

import { ExtendedTooltip } from '../ExtendedTooltip';
import { Loader } from '../Loader';

import { INotificationTooltipProps, NotificationPage } from './NotificationTooltip.types';
import { getNotificationSortedGroups, getPropsByPage } from './NotificationTooltip.utils';

import './NotificationTooltip.scss';

const CnNotificationTooltip = cn('notificationTooltip');

export const NotificationTooltip: React.FC<INotificationTooltipProps> = ({
    newNotifications,
    viewedNotifications,
    notificationsTitle,
    viewedLinkText,
    returnButtonText,
    isLoading,
    onCloseButtonClick,
    onScrollComplete,
    onNotificationRead,
}) => {
    const [currentPage, setCurrentPage] = useState(NotificationPage.new);

    const scrollingContentRef = useRef<HTMLDivElement>(null);

    const newNotificationGroups = useMemo(() => getNotificationSortedGroups(newNotifications), [newNotifications]);
    const viewedNotificationGroups = useMemo(
        () => getNotificationSortedGroups(viewedNotifications),
        [viewedNotifications],
    );

    const handleViewedNotificationsButtonClick = useCallback(() => {
        setCurrentPage(NotificationPage.viewed);
    }, []);

    const handleNewNotificationsButtonClick = useCallback(() => {
        setCurrentPage(NotificationPage.new);
    }, []);

    useEffect(() => {
        scrollingContentRef.current?.scrollTo({
            top: 0,
        });
    }, [currentPage]);

    const { notificationsElement, title, hasCloseButton, hasReturnButton } = useMemo(
        () =>
            getPropsByPage({
                page: currentPage,
                newNotificationProps: {
                    notificationGroups: newNotificationGroups,
                    viewedLinkText: viewedLinkText,
                    onViewedButtonClick: handleViewedNotificationsButtonClick,
                    onNotificationRead,
                },
                viewedNotificationProps: {
                    notificationGroups: viewedNotificationGroups,
                },
                notificationsTitle,
            }),
        [
            currentPage,
            handleViewedNotificationsButtonClick,
            newNotificationGroups,
            notificationsTitle,
            onNotificationRead,
            viewedLinkText,
            viewedNotificationGroups,
        ],
    );

    return (
        <ExtendedTooltip
            contentRef={scrollingContentRef}
            title={title}
            onCloseButtonClick={onCloseButtonClick}
            onReturnButtonClick={handleNewNotificationsButtonClick}
            returnButtonText={returnButtonText}
            onScrollComplete={onScrollComplete}
            hasCloseButton={hasCloseButton}
            hasReturnButton={hasReturnButton}
            withTopOverlay
            withBottomOverlay
        >
            <div className={CnNotificationTooltip()}>
                {notificationsElement}
                {isLoading && (
                    <div className={CnNotificationTooltip('loaderPopup')}>
                        <Loader />
                    </div>
                )}
            </div>
        </ExtendedTooltip>
    );
};
