import moment from 'moment';

/**
 *
 * @param date verified date
 * @param inactiveCondition date inactive condition or callback to check
 * @returns
 */
export const isInactive = (
    date: Date | string,
    inactiveCondition?: 'past' | 'future' | ((date: Date | string) => boolean),
): boolean => {
    if (inactiveCondition === 'past') {
        return moment().startOf('day').isAfter(date);
    }

    if (inactiveCondition === 'future') {
        return !moment().endOf('day').isAfter(date);
    }

    if (inactiveCondition instanceof Function) {
        return inactiveCondition(date);
    }

    return false;
};
