import { DraftDecorator } from 'draft-js';

import { EntityTypes } from '../../../types/shared.types';

import { LinkWrapper } from './LinkWrapper';

const findLinkEntities: DraftDecorator['strategy'] = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === EntityTypes.LINK;
    }, callback);
};

export const linkDecorator = {
    strategy: findLinkEntities,
    component: LinkWrapper,
};
