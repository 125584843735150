import React, { useCallback, useRef } from 'react';
import { cn } from '@bem-react/classname';
import { useOuterClick } from '@lms-elements/hooks';

import { Checkbox } from '../../Checkbox';
import { ExtendedTooltip, ExtendedTooltipWidth } from '../../ExtendedTooltip';
import { PointerPosition } from '../../Tooltip';

import { ICheckboxesTooltipProps } from './CheckboxesTooltip.types';

import './CheckboxesTooltip.scss';

const CnCheckboxesTooltip = cn('checkboxesTooltip');

export const CheckboxesTooltip: React.FC<ICheckboxesTooltipProps> = ({
    checkboxes,
    onCheckboxChange,
    onTooltipClose,
    tooltipTitle,
}) => {
    const tooltipRef = useRef<HTMLDivElement | null>(null);

    const makeHandleCheckboxChange = useCallback(
        (checkboxId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
            onCheckboxChange(checkboxId, event.target.checked);
        },
        [onCheckboxChange],
    );

    useOuterClick(tooltipRef, onTooltipClose);

    return (
        <div className={CnCheckboxesTooltip()}>
            <ExtendedTooltip
                mainWrapperRef={tooltipRef}
                title={tooltipTitle}
                onCloseButtonClick={onTooltipClose}
                pointerPosition={PointerPosition.LEFT}
                width={ExtendedTooltipWidth.LARGE}
                hasCloseButton
                isTitleRegular
            >
                <div className={CnCheckboxesTooltip('content')}>
                    {checkboxes.map(({ id, label, checked }) => (
                        <div className={CnCheckboxesTooltip('checkbox')} key={id}>
                            <Checkbox
                                handleCheckboxChange={makeHandleCheckboxChange(id)}
                                label={label}
                                checked={checked}
                            />
                        </div>
                    ))}
                </div>
            </ExtendedTooltip>
        </div>
    );
};
