import React, { useMemo, useRef } from 'react';
import { cn } from '@bem-react/classname';
import { useGetScrollPosition } from '@lms-elements/hooks';
import { XIcon } from '@lms-elements/icons';

import { ReturnButton } from '../ReturnButton';
import { PointerPosition } from '../Tooltip';

import { useScrollCompleteListener } from './ExtendedTooltip.hooks';
import { ExtendedTooltipWidth, IExtendedTooltipProps } from './ExtendedTooltip.types';

import './ExtendedTooltip.scss';

const CnExtendedTooltip = cn('extendedTooltip');

export const ExtendedTooltip: React.FC<IExtendedTooltipProps> = ({
    children,
    title,
    hasCloseButton,
    hasReturnButton,
    returnButtonText,
    withTopOverlay = false,
    withBottomOverlay = false,
    scrollPositionOptions = {},
    isTitleRegular = false,
    width = ExtendedTooltipWidth.MEDIUM,
    pointerPosition = PointerPosition.CENTER,
    onCloseButtonClick,
    onReturnButtonClick,
    onScrollComplete,
    contentRef,
    mainWrapperRef,
    customWrapperClass = '',
    customContentClass = '',
    customHeaderClass = '',
    customButtonCloseClass = '',
}) => {
    const scrollingContentRef = useRef<HTMLDivElement>(null);
    const childrenContainerRef = useRef<HTMLDivElement>(null);

    const usingContentRef = useMemo(() => (contentRef === undefined ? scrollingContentRef : contentRef), [contentRef]);

    const hasHeader = useMemo(
        () => title || hasCloseButton || hasReturnButton,
        [hasCloseButton, hasReturnButton, title],
    );

    const { isScrollStart, isScrollEnd, wasScrolled, onScroll } = useGetScrollPosition(
        usingContentRef,
        childrenContainerRef,
        {
            scrollStartOffset: 50,
            scrollEndOffset: 0,
            ...scrollPositionOptions,
        },
    );

    useScrollCompleteListener({
        onScrollComplete,
        isScrollEnd,
        wasScrolled,
    });

    return (
        <div className={`${CnExtendedTooltip({ pointerPosition, width })} ${customWrapperClass}`} ref={mainWrapperRef}>
            <div
                ref={usingContentRef}
                onScroll={onScroll}
                className={`${CnExtendedTooltip('content')} ${customContentClass}`}
            >
                {hasHeader && (
                    <div className={`${CnExtendedTooltip('header')} ${customHeaderClass}`}>
                        {hasReturnButton && <ReturnButton text={returnButtonText} onClick={onReturnButtonClick} />}
                        {title && (
                            <p
                                className={CnExtendedTooltip('title', {
                                    withMarginLeft: !hasReturnButton,
                                    isRegular: isTitleRegular,
                                })}
                            >
                                {title}
                            </p>
                        )}
                        {hasCloseButton && (
                            <button
                                className={`${CnExtendedTooltip('closeButton')} ${customButtonCloseClass}`}
                                onClick={onCloseButtonClick}
                            >
                                <XIcon />
                            </button>
                        )}
                    </div>
                )}
                <div ref={childrenContainerRef}>{children}</div>
            </div>
            {withTopOverlay && (
                <div className={CnExtendedTooltip('overlay', { direction: 'top', hidden: !isScrollStart })} />
            )}
            {withBottomOverlay && (
                <div className={CnExtendedTooltip('overlay', { direction: 'bottom', hidden: !isScrollEnd })} />
            )}
        </div>
    );
};
