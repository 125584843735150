import React from 'react';
import { useDrag } from 'react-dnd';
import { CodifierElement, CodifierElementIconEnum } from '@lms-elements/atomic';

import { MINIMIZED_DESC, MINIMIZED_TITLE } from './CONSTANTS';
import { IMinimizedExerciseMaterialProps, MIN_EXERCISE_MATERIAL_DRAG_TYPE } from './MinimizedExerciseMaterial.types';

const MinimizedExerciseMaterial: React.FC<IMinimizedExerciseMaterialProps> = React.memo(
    ({ onDragEnd, disabled = false }) => {
        const [{ isDragging }, drag] = useDrag({
            type: MIN_EXERCISE_MATERIAL_DRAG_TYPE,
            item: () => ({ name: 'EXERCISE_MATERIAL' }),
            end: (item, monitor) => {
                const dropResult = monitor.getDropResult();
                if (item && dropResult && onDragEnd) {
                    onDragEnd();
                }
            },
            collect: (monitor) => ({ isDragging: monitor.isDragging() }),
        });
        return (
            <CodifierElement
                title={MINIMIZED_TITLE}
                description={MINIMIZED_DESC}
                icon={CodifierElementIconEnum.exercise}
                dragging={isDragging}
                dragRef={drag}
                disabled={disabled}
            />
        );
    },
);

export default MinimizedExerciseMaterial;
