import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgUnorderedListIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M12.25 10h12M12.25 16h12M12.25 22h12"
                stroke="#252232"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.125 11.125a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25zM8.125 17.125a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25zM8.125 23.125a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z"
                fill="#252232"
            />
        </svg>
    );
}

const MemoSvgUnorderedListIcon = React.memo(SvgUnorderedListIcon);
export default MemoSvgUnorderedListIcon;
