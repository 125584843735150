import { EditorState } from 'draft-js';

import { ITableCoordinates } from './TableSize/TableSize.types';
import { MIN_CELL_HEIGHT, MIN_CELL_WIDTH } from './constants';
import { TableData } from '.';

export const createEmptyTable = (size: ITableCoordinates): Array<EditorState[]> => {
    return Array(size.y).fill(Array(size.x).fill('')) as Array<EditorState[]>;
};

export const createEmptyHTML = (size: ITableCoordinates): Array<string[]> => {
    return Array(size.y).fill(Array(size.x).fill('<p></p>')) as Array<string[]>;
};

export const createColumnWidth = (cols: number): number[] => Array(cols).fill(MIN_CELL_WIDTH) as number[];

export const createRowHeight = (rows: number): number[] => Array(rows).fill(MIN_CELL_HEIGHT) as number[];

export const addTableRow = (oldData: TableData, newRow: number): TableData => {
    const newData = { ...oldData };

    if (newRow === oldData.size.y && newData.table) {
        newData.table.push(Array(oldData.size.x).fill(''));
        newData.html.push(Array(oldData.size.x).fill('<p></p>'));
    } else {
        const { table = [], html = [] } = oldData;

        newData.table = table
            .slice(0, newRow)
            .concat([Array(oldData.size.x).fill('')])
            .concat(table.slice(newRow));
        newData.html = html
            .slice(0, newRow)
            .concat([Array(oldData.size.x).fill('<p></p>')])
            .concat(html.slice(newRow));
    }

    if (newRow === oldData.size.y) {
        newData.heights = [...oldData.heights, MIN_CELL_HEIGHT];
        newData.minHeights = [...oldData.minHeights, MIN_CELL_HEIGHT];
    } else {
        newData.heights = [...oldData.heights.slice(0, newRow), MIN_CELL_HEIGHT, ...oldData.heights.slice(newRow)];
        newData.minHeights = [
            ...oldData.minHeights.slice(0, newRow),
            MIN_CELL_HEIGHT,
            ...oldData.minHeights.slice(newRow),
        ];
    }

    newData.size.y += 1;
    return newData;
};

export const addTableColumn = (oldData: TableData, newColumn: number): TableData => {
    const newData = {
        size: { x: 0, y: 0 },
        table: [],
        html: [],
        widths: [],
        heights: [],
        minHeights: [],
        editorBuffer: EditorState.createEmpty(),
    } as TableData;

    newData.table = (oldData.table ?? []).map((row) => {
        if (newColumn === oldData.size.x) {
            return [...row, ''];
        }

        return [...row.slice(0, newColumn), '', ...row.slice(newColumn)];
    });
    newData.html = (oldData.html ?? []).map((row) => {
        if (newColumn === oldData.size.x) {
            return [...row, '<p></p>'];
        }

        return [...row.slice(0, newColumn), '<p></p>', ...row.slice(newColumn)];
    });

    if (newColumn === oldData.size.x) {
        newData.widths = [...oldData.widths, MIN_CELL_WIDTH];
    } else {
        newData.widths = [...oldData.widths.slice(0, newColumn), MIN_CELL_WIDTH, ...oldData.widths.slice(newColumn)];
    }
    newData.heights = oldData.heights;
    newData.minHeights = oldData.minHeights;

    newData.size = { x: oldData.size.x + 1, y: oldData.size.y };
    return newData;
};

export const removeTableRow = (oldData: TableData, row: number): TableData => {
    const newData = {
        size: { x: 0, y: 0 },
        table: [],
        html: [],
        widths: [],
        heights: [],
        minHeights: [],
        editorBuffer: EditorState.createEmpty(),
    } as TableData;

    if (oldData.size.y === 1) {
        return newData;
    }

    newData.size = {
        x: oldData.size.x,
        y: oldData.size.y - 1,
    };

    newData.table = (oldData.table ?? []).filter((_, index) => index !== row);
    newData.html = (oldData.html ?? []).filter((_, index) => index !== row);
    newData.heights = (oldData.heights ?? []).filter((_, index) => index !== row);
    newData.minHeights = (oldData.minHeights ?? []).filter((_, index) => index !== row);
    newData.widths = oldData.widths;
    newData.editorBuffer = oldData.editorBuffer;

    return newData;
};

export const removeTableColumn = (oldData: TableData, col: number): TableData => {
    const newData = {
        size: { x: 0, y: 0 },
        table: [],
        html: [],
        widths: [],
        heights: [],
        minHeights: [],
        editorBuffer: EditorState.createEmpty(),
    } as TableData;

    if (oldData.size.x === 1) {
        return newData;
    }

    newData.size = {
        x: oldData.size.x - 1,
        y: oldData.size.y,
    };

    newData.table = (oldData.table ?? []).map((row) => row.filter((_, index) => index !== col));
    newData.html = (oldData.html ?? []).map((row) => row.filter((_, index) => index !== col));
    newData.heights = oldData.heights;
    newData.minHeights = oldData.minHeights;
    newData.widths = (oldData.widths ?? []).filter((_, index) => index !== col);
    newData.editorBuffer = oldData.editorBuffer;

    return newData;
};
