export interface IExpandedVideoMaterialProps {
    onDelete?: () => void;
    name: string;
    videoUrl?: string;
    onSaveVideo?: (url: string) => void;
    onClickAddVideo?: (video: File) => void;
    showForStudents?: boolean;
}

export interface IUploadVideoProps {
    files: {
        lastModified: number;
        lastModifiedDate: Date;
        name: string;
        size: number;
        type: string;
        webkitRelativePath: string;
    }[];
    items: DataTransferItemList;
}

export const supportedVideoFormats = [
    'www.youtube.com',
    'vimeo.com',
    'soundcloud',
    'facebook',
    'storage.yandexcloud.net/lms',
] as const;

export type SupportedVideoFormats = typeof supportedVideoFormats[number];
export interface IVideoValues {
    videoData: {
        url: string;
        file?: File;
    };
}
