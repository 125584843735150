import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgMonitoring({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={18}
            height={18}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <rect x={0.75} y={0.75} width={16.5} height={16.5} rx={2.25} stroke="#585C6F" strokeWidth={1.5} />
            <circle cx={6} cy={6} r={2} stroke="#585C6F" strokeWidth={1.5} />
            <path d="M8 6h6" stroke="#585C6F" strokeWidth={1.5} strokeLinecap="round" />
            <circle cx={12} cy={12} r={2} stroke="#585C6F" strokeWidth={1.5} />
            <path d="M10 12H4" stroke="#585C6F" strokeWidth={1.5} strokeLinecap="round" />
        </svg>
    );
}

const MemoSvgMonitoring = React.memo(SvgMonitoring);
export default MemoSvgMonitoring;
