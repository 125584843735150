import { RefObject } from 'react';

import { PointerPosition } from '../Tooltip';

export enum ExtendedTooltipWidth {
    MEDIUM = 'medium',
    LARGE = 'large',
    NOT_LIMITED = 'notLimited',
}

export interface IExtendedTooltipProps {
    title?: string;
    hasCloseButton?: boolean;
    hasReturnButton?: boolean;
    returnButtonText?: string;
    withTopOverlay?: boolean;
    withBottomOverlay?: boolean;
    pointerPosition?: PointerPosition;
    isTitleRegular?: boolean;
    width?: ExtendedTooltipWidth;
    onCloseButtonClick?: () => void;
    onReturnButtonClick?: () => void;
    scrollPositionOptions?: {
        scrollStartOffset?: number;
        scrollEndOffset?: number;
    };
    onScrollComplete?: () => void;
    contentRef?: RefObject<HTMLDivElement>;
    mainWrapperRef?: RefObject<HTMLDivElement>;
    customWrapperClass?: string;
    customContentClass?: string;
    customHeaderClass?: string;
    customButtonCloseClass?: string;
}
