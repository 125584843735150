import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgSchedule({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={24}
            height={25}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <rect x={2.75} y={5.5} width={18.5} height={16.5} rx={1.25} stroke="#585C6F" strokeWidth={1.5} />
            <path d="M7 18.75h10M7 15.75h10M7 12.75h10" stroke="#585C6F" strokeWidth={1.5} strokeLinecap="round" />
            <path d="M2.5 9.25H21" stroke="#585C6F" strokeWidth={1.5} />
            <path d="M6.5 2.25v4.5m11-4.5v4.5" stroke="#585C6F" strokeWidth={1.5} strokeLinecap="round" />
        </svg>
    );
}

const MemoSvgSchedule = React.memo(SvgSchedule);
export default MemoSvgSchedule;
