import React from 'react';
import { cn } from '@bem-react/classname';
import { Add, Minus } from '@lms-elements/icons';

import { CounterMathViewProps } from './CounterMathView.types';

import './CounterMathView.scss';

const CnFieldInputCounterCore = cn('field-input-counter-core');

export const CounterMathView: React.FC<CounterMathViewProps> = ({
    value,
    disabled,
    decDisabled,
    incDisabled,
    makeTitle,
    onChange,
    onIncButtonClick,
    onDecButtonClick,
}) => {
    return (
        <div className={CnFieldInputCounterCore()}>
            <button
                type="button"
                className={CnFieldInputCounterCore('button', { decrement: true, disabled: decDisabled })}
                disabled={decDisabled}
                onClick={onDecButtonClick}
            >
                <Minus />
            </button>
            <label className={CnFieldInputCounterCore('label', { disabled })}>
                <input
                    spellCheck
                    type="text"
                    className={CnFieldInputCounterCore('input', { disabled, withoutTitle: !makeTitle })}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                />
                {makeTitle && <span className={CnFieldInputCounterCore('label-text')}>{makeTitle(value)}</span>}
            </label>
            <button
                type="button"
                className={CnFieldInputCounterCore('button', { increment: true, disabled: incDisabled })}
                disabled={incDisabled}
                onClick={onIncButtonClick}
            >
                <Add />
            </button>
        </div>
    );
};
