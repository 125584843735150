import moment from 'moment';

/**
 *
 * @param dates array of dates
 * @returns earliest date
 */
export const getEarliestDate = (dates: string[]): string => {
    return dates.reduce((closestDate, date) => {
        return moment(closestDate).isBefore(date) ? moment(closestDate).format() : moment(date).format();
    }, moment().format());
};
