import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { cn } from '@bem-react/classname';

import { Checkbox } from '../../Checkbox';
import { SubscriptionListItem } from '../SubscriptionListItem';
import { GroupChecked, ListData } from '../SubscriptionListItem/SubscriptionListItem.types';

import { SubscriptionListMonthProps } from './SubscriptionListMonth.types';

import './SubscriptionListMonth.scss';

const CnSubscriptionListMonth = cn('subscriptionListMonth');

export const SubscriptionListMonth: React.FC<SubscriptionListMonthProps> = ({
    month,
    list,
    isMobile,
    handleListMonthChange,
}) => {
    const cost = useMemo(() => list.reduce((sum, item) => sum + item.cost, 0), [list]);
    const ids = useMemo(() => list.map((item) => item.id), [list]);
    const [monthData, setMonthData] = useState({
        checked: false,
        groupChecked: GroupChecked.none,
        cost: 0,
        ids: [] as number[],
    });

    const handleCheckboxChange = useCallback(() => {
        setMonthData((prev) =>
            prev.checked
                ? {
                      checked: false,
                      groupChecked: GroupChecked.none,
                      cost: 0,
                      ids: [],
                  }
                : { checked: true, groupChecked: GroupChecked.all, cost, ids },
        );
    }, [cost, ids]);

    const handleListItemChange = useCallback(({ isItemChecked, itemCost, itemId }: ListData) => {
        setMonthData((prev) =>
            isItemChecked
                ? {
                      checked: prev.checked,
                      groupChecked: GroupChecked.part,
                      cost: prev.cost + itemCost,
                      ids: [...prev.ids, itemId],
                  }
                : {
                      checked: false,
                      groupChecked: GroupChecked.part,
                      cost: prev.cost - itemCost,
                      ids: prev.ids.filter((item) => item !== itemId),
                  },
        );
    }, []);

    useEffect(() => {
        handleListMonthChange?.({ month, cost: monthData.cost, ids: monthData.ids });
    }, [handleListMonthChange, month, monthData]);

    return (
        <div className={CnSubscriptionListMonth()}>
            <div className={CnSubscriptionListMonth('row', { isMobile })}>
                <Checkbox handleCheckboxChange={handleCheckboxChange} checked={monthData.checked} />
                <span className={CnSubscriptionListMonth('month', { isMobile })}>{month}</span>
                {list.length > 1 && (
                    <span className={CnSubscriptionListMonth('cost')}>({cost.toLocaleString()} руб.)</span>
                )}
            </div>
            <div className={CnSubscriptionListMonth('content')}>
                <div className={CnSubscriptionListMonth('line')}></div>
                <ul className={CnSubscriptionListMonth('list', { isMobile })}>
                    {list?.map((item) => (
                        <li key={item.id} className={CnSubscriptionListMonth('list-item')}>
                            <SubscriptionListItem
                                id={item.id}
                                date={item.date}
                                description={item.description}
                                cost={item.cost}
                                isMobile={isMobile}
                                GroupChecked={monthData.groupChecked}
                                handleListItemChange={handleListItemChange}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
