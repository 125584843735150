import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgLogoutIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M8.16 14.295L12.456 10 8.16 5.704M1 10h11.454M10.816 1h7.364m0 0a.818.818 0 01.818.818v16.364a.819.819 0 01-.818.818m0-18H9.691m8.489 18h-7.364m7.364 0H9.691"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgLogoutIcon = React.memo(SvgLogoutIcon);
export default MemoSvgLogoutIcon;
