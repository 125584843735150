import { checkIsAfter } from '@lms-elements/utils';

import { LessonItem } from '../../Lesson';

export const validateCheckboxesArray = (lessonItems: LessonItem[]): string[] | undefined => {
    if (lessonItems.every(({ checked }) => !checked)) {
        return lessonItems.map(() => 'error');
    }

    return undefined;
};

export const validate = (value: string): string | undefined => (value ? undefined : 'error');
export const validateDate = (value: string): string | undefined => {
    return checkIsAfter(value) ? undefined : 'error';
};
