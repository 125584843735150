import React from 'react';
import { Field } from 'react-final-form';
import { composeValidators } from '@lms-elements/utils';

import { IFieldDropdownProps } from './FieldDropdown.types';
import { FieldDropdownCore } from './FieldDropdownCore';

export const FieldDropdown: React.FC<IFieldDropdownProps> = ({ name, validators, multiple, value, ...props }) => (
    <Field name={name} validate={composeValidators(...validators)}>
        {({ input, meta }) => (
            <FieldDropdownCore value={value} multiple={multiple} {...props} input={input} meta={meta} />
        )}
    </Field>
);
