import React, { useCallback, useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { CodeIcon, Definition, Info, Quote, TheoremSmall, WarningTriangle } from '@lms-elements/icons';
import { getSelectedBlock } from 'draftjs-utils';

import { ActionBlockTypes } from '../../../types/shared.types';
import { addNewBlockAt } from '../../../utils';
import { OptionButton } from '../../OptionButton';

import { IActionBlockOptionProps } from './ActionBlockOption.types';

import './ActionBlockOption.scss';

const ActionBlockOptionCn = cn('action-block-option');

export const ActionBlockOption: React.FC<IActionBlockOptionProps> = ({ editorState, onChange, type }) => {
    const onClick = useCallback(
        () => onChange(addNewBlockAt(editorState, editorState.getSelection().getAnchorKey(), type)),
        [type, editorState, onChange],
    );

    const active = useMemo(() => (getSelectedBlock(editorState).get('type') as string) === type, [editorState, type]);

    const { ActionIcon, title } = useMemo(() => {
        switch (type) {
            case ActionBlockTypes.INFO_BLOCK:
                return { ActionIcon: Info, title: 'Информация' };
            case ActionBlockTypes.WARNING_BLOCK:
                return { ActionIcon: WarningTriangle, title: 'Важное' };
            case ActionBlockTypes.THEOREM_BLOCK:
                return { ActionIcon: TheoremSmall, title: 'Теорема' };
            case ActionBlockTypes.QUOTE_BLOCK:
                return { ActionIcon: Quote, title: 'Цитата' };
            case ActionBlockTypes.DEFINITION_BLOCK:
                return { ActionIcon: Definition, title: 'Определение' };
            case ActionBlockTypes.CODE_BLOCK:
                return { ActionIcon: CodeIcon, title: 'Код' };
        }
    }, [type]);

    return (
        <div title={title}>
            <OptionButton active={active} onClick={onClick}>
                <ActionIcon className={ActionBlockOptionCn('icon', { type })} />
            </OptionButton>
        </div>
    );
};
