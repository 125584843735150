import { ReactElement } from 'react';

export enum ButtonViewEnum {
    link = 'link',
    solid = 'solid',
    action = 'action',
    bordered = 'bordered',
    danger = 'danger',
}

export interface IButtonProps
    extends Omit<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'ref'> {
    view: ButtonViewEnum;
    size: 'l' | 'm' | 's';
    customClasses?: string;
    icon?: ReactElement;
    loading?: boolean;
    disabledBlue?: boolean;
}
