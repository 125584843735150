import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgRoomCardUser({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={10}
            height={12}
            viewBox="0 0 10 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M3.5 6.75l-.062.435c-.08.313-.295.577-.588.722l-.862.424A2.223 2.223 0 00.81 9.774L.5 11h9l-.312-1.226A2.22 2.22 0 008.012 8.33l-.862-.424a1.11 1.11 0 01-.588-.722L6.5 6.75"
                stroke="#252232"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                clipRule="evenodd"
                d="M5.75 7.253v0a1.26 1.26 0 01-1.5 0A4.32 4.32 0 012.5 3.786v-.31C2.5 2.11 3.62 1 5 1s2.5 1.109 2.5 2.476v.31a4.321 4.321 0 01-1.75 3.467z"
                stroke="#252232"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgRoomCardUser = React.memo(SvgRoomCardUser);
export default MemoSvgRoomCardUser;
