import React from 'react';
import { cn } from '@bem-react/classname';

import { EmptyNotifications } from '../EmptyNotifications';
import { NotificationList } from '../NotificationList';

import { INewNotificationsProps } from './NewNotifications.types';

import './NewNotifications.scss';

const CnNewNotifications = cn('newNotifications');

export const NewNotifications: React.FC<INewNotificationsProps> = ({
    notificationGroups,
    viewedLinkText = 'просмотренные - архив',
    emptyText,
    onViewedButtonClick,
    onNotificationRead,
}) => {
    return (
        <div className={CnNewNotifications()}>
            <NotificationList
                notificationGroups={notificationGroups}
                needShowFirstTitle={false}
                onNotificationRead={onNotificationRead}
            />
            {notificationGroups.length === 0 && (
                <EmptyNotifications
                    text={emptyText || 'Сейчас нет новых оповещений, вы можете увидеть просмотренные ниже'}
                />
            )}
            <button className={CnNewNotifications('viewedButton')} onClick={onViewedButtonClick}>
                {viewedLinkText}
            </button>
        </div>
    );
};
