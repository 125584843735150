import moment from 'moment';

/**
 * @example 2021-05-06T15:30 -> 06.05.2021 в 15:30
 * @param date incomind date
 * @returns
 */
export const getDatepickerSelectTitle = (date: string | Date): string => {
    const resultDate = moment(date).format('DD.MM.YYYY');
    const resultTime = moment(date).format('HH:mm');
    if (resultTime === '00:00') {
        return `${resultDate}`;
    }
    return `${resultDate} в ${resultTime}`;
};
