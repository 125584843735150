import { CalendarPlan, Option } from '../EducationalPlan.types';

import { Subject } from './CourseTable.types';

export const validateEmpty = (value: string): string | undefined =>
    value ? (value !== '-' ? undefined : 'error') : 'error';

export const validateFraction = (value: string): string | undefined =>
    isNaN(Number(value)) ? 'error' : Number(value) === Math.trunc(Number(value)) ? undefined : 'error';

export const getPercent = (current: number, total: number): number => {
    return Math.round((current / total) * 100);
};

export const SumInWeek = (array: Subject[]): number => {
    const total = array.reduce(function (sum, current) {
        if (!isNaN(Number(current.inWeek))) {
            return sum + Number(current.inWeek);
        }

        return sum;
    }, 0);
    return Number(total.toFixed(2));
};

export const SumInYear = (array: Subject[]): number => {
    const total = array.reduce(function (sum, current) {
        if (!isNaN(Number(current.inYear))) {
            return sum + Number(current.inYear);
        }

        return sum;
    }, 0);
    return Number(total.toFixed(2));
};
export const SumFact = (array: Subject[]): number => {
    const total = array.reduce(function (sum, current) {
        if (!isNaN(Number(current.fact))) {
            return sum + Number(current.fact);
        }

        return sum;
    }, 0);
    return Number(total.toFixed(2));
};
export const SumLessonsInWeek = (array: Subject[]): number => {
    const total = array.reduce(function (sum, current) {
        if (!isNaN(Number(current.lessonsInWeek))) {
            return sum + Number(current.lessonsInWeek);
        }

        return sum;
    }, 0);
    return Number(total.toFixed(2));
};

export const SumLessonsInYear = (array: Subject[]): number => {
    const total = array.reduce(function (sum, current) {
        if (!isNaN(Number(current.lessonsInYear))) {
            return sum + Number(current.lessonsInYear);
        }

        return sum;
    }, 0);
    return Number(total.toFixed(2));
};

export const SumLecturesInWeek = (array: Subject[]): number => {
    const total = array.reduce(function (sum, current) {
        if (!isNaN(Number(current.lecturesInWeek))) {
            return sum + Number(current.lecturesInWeek);
        }

        return sum;
    }, 0);
    return Number(total.toFixed(2));
};

export const SumLecturesInYear = (array: Subject[]): number => {
    const total = array.reduce(function (sum, current) {
        if (!isNaN(Number(current.lecturesInYear))) {
            return sum + Number(current.lecturesInYear);
        }

        return sum;
    }, 0);
    return Number(total.toFixed(2));
};

export const HoursInYear = (
    calendarPlan: Option | undefined,
    calendarPlanOptions: CalendarPlan[],
    hoursInWeek: number,
): string => {
    if (calendarPlan) {
        const selectedPlan = calendarPlanOptions.find((plan) => plan.id === Number(calendarPlan.id));
        return hoursInWeek ? String(Number((hoursInWeek * (selectedPlan?.weekCount ?? 0)).toFixed(2))) : '-';
    }
    return '-';
};

export const NumberWithFraction = (hours: number): boolean => {
    return isNaN(hours) ? false : hours !== Math.trunc(hours);
};
