import React, { useCallback, useRef, useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { Loading } from '@lms-elements/icons';

import { Button, ButtonViewEnum } from '../Button';

import { ISignInFormProps, ISignInFormValues } from './SignInForm.types';
import { validateForm } from './SignInForm.utils';

import './SignInForm.scss';

export const CnSignIn = cn('signin-form');

export const SignInForm: React.FC<ISignInFormProps> = ({
    passwordPlaceholder = 'Пароль',
    usernamePlaceholder = 'Эл. почта',
    onSubmit,
    onRemind,
    onChange,
    error = '',
    isLoading,
    title = 'Вход',
}) => {
    const usernameRef = useRef<HTMLInputElement>(null);
    const [needShowUsernameError, setNeedShowUsernameError] = useState(false);

    const handleRemindClick = useCallback(
        (hasErrors: boolean) => {
            setNeedShowUsernameError(true);
            if (usernameRef?.current?.value && !hasErrors) {
                onRemind(usernameRef.current.value);
            }
        },
        [onRemind],
    );

    const handleChangeForm = useCallback(() => {
        if (onChange) {
            onChange();
        }
    }, [onChange]);

    const handleUsernameChange = useCallback((baseOnChange: (event: unknown) => unknown, event: unknown) => {
        baseOnChange(event);
        setNeedShowUsernameError(false);
    }, []);

    return (
        <div className={CnSignIn({ 'uncorrect wiggle': Boolean(error) })}>
            <div className={CnSignIn('header')}>
                <div className={CnSignIn('login')}>{title}</div>
            </div>
            <div className={CnSignIn('form')}>
                <Form<ISignInFormValues>
                    onSubmit={onSubmit}
                    validate={validateForm}
                    render={({
                        handleSubmit,
                        submitting,
                        dirtySinceLastSubmit,
                        errors,
                        submitFailed,
                        hasValidationErrors,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Field name="username">
                                {({ input, meta }) => (
                                    <div>
                                        <input
                                            ref={usernameRef}
                                            className={CnSignIn('input', {
                                                error:
                                                    (needShowUsernameError && !meta.valid) ||
                                                    (meta.submitFailed && !meta.valid && !dirtySinceLastSubmit),
                                            })}
                                            {...input}
                                            onChange={handleUsernameChange.bind(null, input.onChange)}
                                            type="email"
                                            placeholder={usernamePlaceholder}
                                        />
                                    </div>
                                )}
                            </Field>
                            <Field name="password">
                                {({ input, meta }) => (
                                    <div>
                                        <input
                                            className={CnSignIn('input', {
                                                error: meta.submitFailed && !meta.valid && !dirtySinceLastSubmit,
                                            })}
                                            {...input}
                                            type="password"
                                            placeholder={passwordPlaceholder}
                                        />
                                        <button
                                            type="button"
                                            className="remember"
                                            onClick={handleRemindClick.bind(
                                                null,
                                                submitting || Boolean(errors?.username),
                                            )}
                                        >
                                            напомнить
                                        </button>
                                    </div>
                                )}
                            </Field>
                            <div>
                                <Button
                                    size="m"
                                    view={ButtonViewEnum.action}
                                    className={CnSignIn('button', {
                                        uncorrect: Boolean(error) || (submitFailed && hasValidationErrors),
                                    })}
                                    type="submit"
                                    disabled={submitting || Boolean(error) || (submitFailed && hasValidationErrors)}
                                >
                                    <div className={CnSignIn('text', { loading: isLoading })}>
                                        {isLoading ? <Loading /> : 'Войти'}
                                    </div>
                                </Button>
                            </div>
                            {(Boolean(error) || hasValidationErrors) && (
                                <div className={CnSignIn('error')}>
                                    {error ||
                                        ((submitFailed || needShowUsernameError) && errors?.username) ||
                                        (submitFailed && errors?.password)}
                                </div>
                            )}
                            <FormSpy subscription={{ dirtySinceLastSubmit: true }} onChange={handleChangeForm} />
                        </form>
                    )}
                />
            </div>
        </div>
    );
};
