import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgHome({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={24}
            height={25}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                clipRule="evenodd"
                d="M12 2.25l-10 10v2h2v6h6v-3.893c0-.997.681-1.919 1.664-2.08A2.001 2.001 0 0114 16.25v4h6v-6h2v-2l-10-10z"
                stroke="#585C6E"
                strokeWidth={1.4}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgHome = React.memo(SvgHome);
export default MemoSvgHome;
