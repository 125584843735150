import { RefObject, useCallback, useEffect, useMemo, useState } from 'react';

export const useGetHeightStyle = (element: RefObject<HTMLDivElement>): Record<string, string> => {
    const currentElement = useMemo(() => element.current, [element]);
    const [clientOffset, setClientOffset] = useState(currentElement ? currentElement.offsetTop : 0);
    const handleWindowResize = useCallback(() => {
        setClientOffset(currentElement ? currentElement.offsetTop : 0);
    }, [currentElement]);

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => window.removeEventListener('resize', handleWindowResize);
    }, [handleWindowResize]);

    return { height: `calc(100vh - ${clientOffset}px)` };
};
