import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgLittleArrow({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={8}
            height={6}
            viewBox="0 0 8 6"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M.228 1.635A1 1 0 011 0h6a1 1 0 01.772 1.635L4.808 5.589a.999.999 0 01-1.616 0L.228 1.635z"
            />
        </svg>
    );
}

const MemoSvgLittleArrow = React.memo(SvgLittleArrow);
export default MemoSvgLittleArrow;
