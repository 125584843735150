import React from 'react';
import { cn } from '@bem-react/classname';
import { RadioField } from '@lms-elements/atomic';

import { IBinaryAnswerOptionsProps } from './BinaryAnswerOptions.types';

import './BinaryAnswerOptions.scss';

const BinaryAnswerOptionsCn = cn('binary-answer-options');

export const BinaryAnswerOptions: React.FC<IBinaryAnswerOptionsProps> = ({ name, disabled = false, isCorrect }) => {
    return (
        <>
            <div
                className={BinaryAnswerOptionsCn('option', {
                    correct: isCorrect === 'true',
                    'not-correct': isCorrect === 'false',
                })}
            >
                <RadioField name={`${name}.answer`} value="true" label="Верно" disabled={disabled} />
            </div>
            <div
                className={BinaryAnswerOptionsCn('option', {
                    correct: isCorrect === 'false',
                    'not-correct': isCorrect === 'true',
                })}
            >
                <RadioField name={`${name}.answer`} value="false" label="Неверно" disabled={disabled} />
            </div>
        </>
    );
};
