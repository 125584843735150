import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgDueIllnessIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={22}
            height={22}
            viewBox="5 5 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M14 12.5V21M14 16.5h2.5c.833 0 2.5 0 2.5 2v.5c0 2-1.667 2-2.5 2H14M18 12.5h-4"
                stroke="#E6A139"
                strokeWidth={1.5}
                strokeLinecap="round"
            />
            <path
                d="M16 26.5c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
                stroke="#E6A139"
                strokeWidth={1.5}
                strokeMiterlimit={10}
            />
        </svg>
    );
}

const MemoSvgDueIllnessIcon = React.memo(SvgDueIllnessIcon);
export default MemoSvgDueIllnessIcon;
