import React, { useMemo } from 'react';
import { cn } from '@bem-react/classname';

import { IButtonProps } from './Button.types';

import './Button.scss';

const CnButton = cn('button');

export const Button = React.forwardRef<HTMLButtonElement, IButtonProps>(
    ({ view, size, customClasses = '', icon, loading, disabled, children, disabledBlue = false, ...props }, ref) => {
        const disabledBlueButton = useMemo(
            () => (disabledBlue ? 'disabledBlue' : disabled ? 'disabled' : ''),
            [disabledBlue, disabled],
        );

        return (
            <button
                type="button"
                className={`${customClasses} ${CnButton({ view, size, disabledBlueButton })}`}
                {...props}
                disabled={disabled}
                ref={ref}
            >
                <div className={CnButton('text', { 'with-icon': Boolean(icon), loading: Boolean(loading) })}>
                    {icon}
                    {children}
                </div>
            </button>
        );
    },
);
