import React, { useEffect, useRef, useState } from 'react';
import { useField, useForm } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { CustomEditor, useEditorToHTML } from '@lms-elements/custom-editor';
import { Basket } from '@lms-elements/icons';
import { getObjectPropertyByString } from '@lms-elements/utils';
import { EditorState } from 'draft-js';
import debounce from 'lodash.debounce';

import { IEditorFieldData, IEditorFieldProps } from './EditorFIeld.types';

import './EditorField.scss';

const EditorFieldCn = cn('editor-field');

interface IAnyLevel<T extends unknown> {
    [key: string]: T | IAnyLevel<T> | unknown[];
}

export const EditorField: React.FC<IEditorFieldProps> = ({
    name,
    onDelete,
    placeholder,
    isBlocked,
    editorFieldName,
    htmlFieldName,
    needUnlimitedHeight,
}) => {
    const { input, meta } = useField<IEditorFieldData>(name);
    const { batch, change } = useForm();
    const [editorState, setEditorState] = useState(
        getObjectPropertyByString<EditorState>(input.value as unknown as IAnyLevel<EditorState>, editorFieldName) ||
            EditorState.createEmpty(),
    );

    const [isError, setIsError] = useState(false);

    // !!! DONT TOUCH
    useEffect(() => {
        if (!isBlocked) {
            setEditorState(
                () =>
                    getObjectPropertyByString<EditorState>(
                        input.value as unknown as IAnyLevel<EditorState>,
                        editorFieldName,
                    ) || EditorState.createEmpty(),
            );
        }
    }, [isBlocked, editorFieldName, input.value]);

    const { render, markup } = useEditorToHTML(editorState);

    const updateFormState = useRef(
        debounce((name: string, editorState: EditorState, markup: string) => {
            batch(() => {
                change(`${name}.${editorFieldName}`, editorState);

                if (htmlFieldName) {
                    change(`${name}.${htmlFieldName}`, markup);
                }
            });
        }, 25),
    );

    useEffect(() => {
        if (!isBlocked) {
            updateFormState.current(name, editorState, markup);
        }
    }, [editorState, isBlocked, markup, name]);

    const setError = useRef(
        debounce((editorState: EditorState) => {
            setIsError(() => editorState.getCurrentContent().getPlainText().trim().length === 0);
        }, 500),
    );

    useEffect(() => {
        setError.current(editorState);
    }, [editorState]);

    return (
        <>
            <h3 className={EditorFieldCn('header')}>Текст</h3>
            <div className={EditorFieldCn({ error: !meta.valid })}>
                <CustomEditor
                    editorState={editorState}
                    setEditorState={setEditorState}
                    isError={isError && meta.modified}
                    placeholder={placeholder}
                    needLimitHeight={!needUnlimitedHeight}
                    spellCheck
                />
                <button className={EditorFieldCn('delete')} onClick={onDelete} type="button">
                    <Basket />
                </button>
            </div>
            {Boolean(htmlFieldName) && render()}
        </>
    );
};
