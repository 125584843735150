import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgImageIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M27 6H5a1 1 0 00-1 1v18a1 1 0 001 1h22a1 1 0 001-1V7a1 1 0 00-1-1z"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4 21l6.293-6.293a1 1 0 011.414 0l5.586 5.586a1 1 0 001.414 0l2.586-2.586a1 1 0 011.414 0L28 23M20 14a2 2 0 100-4 2 2 0 000 4z"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgImageIcon = React.memo(SvgImageIcon);
export default MemoSvgImageIcon;
