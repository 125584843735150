import React, { ReactElement, useCallback, useRef } from 'react';
import { cn } from '@bem-react/classname';
import { useTouched } from '@lms-elements/hooks';
import { CloseIcon } from '@lms-elements/icons';

import { ITextareaFieldCoreProps } from './TextareaFieldCore.types';

import './TextareaFieldCore.scss';

const TextareaFieldCoreCn = cn('textarea-field-core');

export const TextareaFieldCore: React.FC<ITextareaFieldCoreProps> = ({
    meta,
    input,
    isError = false,
    disabled,
    onClear,
    value,
    onFocus,
    className,
    readOnly,
    isLetter,
    ...props
}: ITextareaFieldCoreProps): ReactElement => {
    const onClickXIcon = useCallback(() => {
        if (input) {
            input.onChange('');
            input.onBlur();
            return;
        }
        if (onClear) {
            onClear();
        }
    }, [input, onClear]);

    const handleFocus = useCallback<(e: React.FocusEvent<HTMLTextAreaElement>) => void>(
        (e) => {
            if (input) {
                input.onFocus(e);
                return;
            }
            if (onFocus) {
                onFocus(e);
            }
        },
        [input, onFocus],
    );

    const ref = useRef<HTMLTextAreaElement>(null);
    const touched = useTouched(ref);

    return (
        <div className={[TextareaFieldCoreCn(), className].join(' ')}>
            <textarea
                spellCheck
                {...props}
                ref={ref}
                type="text"
                className={TextareaFieldCoreCn('input', {
                    disabled,
                    notValid: meta ? Boolean(meta.error) && meta.touched : isError && touched,
                    isLetter: isLetter,
                })}
                {...input}
                value={input?.value || value || ''}
                onFocus={handleFocus}
                autoComplete="off"
                disabled={disabled}
                readOnly={readOnly}
            />
            {!disabled && !readOnly && (
                <div
                    className={TextareaFieldCoreCn('icon', { notEmpty: Boolean(input?.value || value || '') })}
                    onClick={onClickXIcon}
                >
                    <CloseIcon />
                </div>
            )}
        </div>
    );
};
