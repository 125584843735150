import React, { RefObject, useEffect } from 'react';

let timer = setTimeout(() => '', 0);

export enum DragScrollStateEnum {
    notScrolling = 0,
    scrollStart = 1,
    scrollEnd = 2,
}

export const useDragScroll = (
    currentPosition: number,
    moveState: number,
    ref: RefObject<HTMLDivElement>,
    setCurrentPosition: React.Dispatch<React.SetStateAction<DragScrollStateEnum>>,
    setMoveState: React.Dispatch<React.SetStateAction<number>>,
): void => {
    useEffect(() => {
        if (moveState === DragScrollStateEnum.notScrolling && currentPosition !== 0) {
            setCurrentPosition(0);
        }
        if (moveState === DragScrollStateEnum.scrollStart) {
            const scrollLeft = ref.current?.scrollLeft || 0;
            document.onmousemove = (event) => {
                if (currentPosition === 0) {
                    setCurrentPosition(event.clientX);
                } else {
                    ref.current?.scrollTo({
                        left: scrollLeft + (currentPosition - event.clientX),
                    });
                    clearTimeout(timer);
                    timer = setTimeout(() => setCurrentPosition(event.clientX), 100);
                }
            };

            document.onmouseup = () => {
                setMoveState(DragScrollStateEnum.scrollEnd);
                clearTimeout(timer);
            };
        }
        if (moveState === DragScrollStateEnum.scrollEnd) {
            document.onmousemove = null;
            document.onmousedown = null;
            document.onmouseup = null;
            document.ondragstart = () => {
                return false;
            };
            setMoveState(DragScrollStateEnum.notScrolling);
        }
    }, [ref, moveState, currentPosition, setCurrentPosition, setMoveState]);
};
