import React, { useCallback } from 'react';
import { cn } from '@bem-react/classname';
import { SettingsBlackNoBg } from '@lms-elements/icons';

import { ThemeBlockProps } from './ThemeBlock.types';
import { ThemeBlockForm } from './ThemeBlockForm';

import './ThemeBlock.scss';

const CnBlock = cn('block');
const CnSettings = cn('block-settingsIcon');

export const ThemeBlock: React.FC<ThemeBlockProps> = ({
    themeId = 1,
    isChecked,
    order,
    name,
    description,
    isSettingsVisible = false,
    needHideTopicOrder = false,
    isEditModeOn = false,
    isUnfocused = false,
    needPublishButton,
    withNotPublishedLabel,
    onPublishClick,
    handleClickTheme,
    onClickSettings,
}) => {
    const title = `${needHideTopicOrder ? '' : `Т${order + 1}\u00A0-\u00A0`}${name}`;

    const handleSettingsClick = useCallback(() => {
        if (handleClickTheme && onClickSettings) {
            handleClickTheme(false);
            onClickSettings();
        }
        if (onClickSettings) {
            onClickSettings();
        }
    }, [handleClickTheme, onClickSettings]);

    const makeClickTheme = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            if (e.ctrlKey || e.metaKey) {
                handleClickTheme?.(true);
            }
        },
        [handleClickTheme],
    );

    return (
        <div className="themeContainer">
            <div className={CnBlock({ type_checked: isChecked, unfocused: isUnfocused })} onClick={makeClickTheme}>
                <div className={CnBlock('main')}>
                    <div className={CnBlock('title')}>{title}</div>
                    <div className={CnBlock('theme')}>{description}</div>
                    {withNotPublishedLabel && <div className={CnBlock('label')}>Не опубликовано</div>}
                </div>
                {isSettingsVisible && (
                    <div className={CnSettings({ visible: isSettingsVisible })} onClick={handleSettingsClick}>
                        <SettingsBlackNoBg key={`SettingsIcon-${themeId}`} />
                    </div>
                )}
            </div>
            {isEditModeOn && (
                <ThemeBlockForm
                    initialValues={{ themeId: themeId, order: order, name: name, description: description }}
                    isEdit={isEditModeOn}
                    needPublishButton={needPublishButton}
                    onPublishClick={onPublishClick}
                />
            )}
        </div>
    );
};
