import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgMinus({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M14.4 7H1.6a.6.6 0 00-.6.6v.8a.6.6 0 00.6.6h12.8a.6.6 0 00.6-.6v-.8a.6.6 0 00-.6-.6z"
                fill="#0088E9"
            />
        </svg>
    );
}

const MemoSvgMinus = React.memo(SvgMinus);
export default MemoSvgMinus;
