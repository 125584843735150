import { RawDraftContentBlock, RawDraftContentState } from 'draft-js';

import { ActionBlockTypes } from '../../types/shared.types';

import { getBlockMarkup } from './block';
import { getCodeMarkup } from './code';
import { CustomBlockTransform, CustomEntityTransform, HashtagConfig } from './draftToHtml.types';
import { getListMarkup, isList } from './list';

/**
 * The function will generate html markup for given draftjs editorContent.
 */
export const draftToHtml = (
    editorContent: RawDraftContentState,
    hashtagConfig?: HashtagConfig,
    directional?: boolean,
    customEntityTransform?: CustomEntityTransform,
    customBlockTransform?: CustomBlockTransform,
) => {
    const html = [];
    if (editorContent) {
        const { blocks, entityMap } = editorContent;
        if (blocks && blocks.length > 0) {
            let listBlocks: RawDraftContentBlock[] = [];
            let codeBlocks: RawDraftContentBlock[] = [];
            blocks.forEach((block) => {
                if (isList(block.type)) {
                    listBlocks.push(block);
                    return;
                }
                if (block.type === ActionBlockTypes.CODE_BLOCK) {
                    codeBlocks.push(block);
                    return;
                }
                if (listBlocks.length > 0) {
                    const listHtml = getListMarkup(
                        listBlocks,
                        entityMap,
                        hashtagConfig,
                        directional,
                        customEntityTransform,
                    );
                    html.push(listHtml);
                    listBlocks = [];
                } else if (codeBlocks.length > 0) {
                    const listHtml = getCodeMarkup(
                        codeBlocks,
                        entityMap,
                        hashtagConfig,
                        directional,
                        customEntityTransform,
                    );
                    html.push(listHtml);
                    codeBlocks = [];
                }
                const blockHtml = getBlockMarkup(
                    block,
                    entityMap,
                    hashtagConfig,
                    directional,
                    customEntityTransform,
                    customBlockTransform,
                );
                html.push(blockHtml);
            });
            if (listBlocks.length > 0) {
                const listHtml = getListMarkup(
                    listBlocks,
                    entityMap,
                    hashtagConfig,
                    directional,
                    customEntityTransform,
                );
                html.push(listHtml);
                listBlocks = [];
            } else if (codeBlocks.length > 0) {
                const listHtml = getCodeMarkup(
                    codeBlocks,
                    entityMap,
                    hashtagConfig,
                    directional,
                    customEntityTransform,
                );
                html.push(listHtml);
                codeBlocks = [];
            }
        }
    }
    return html.join('');
};
