import React from 'react';
import { cn } from '@bem-react/classname';
import { ToggleLeft, ToggleRight } from '@lms-elements/icons';

import './Toggle.scss';

const CnInput = cn('checkbox-ios');
import { IToggleProps } from './Toggle.types';

export const Toggle: React.FC<IToggleProps> = ({ checked, disabled = false, handleToggleChange, defaultChecked }) => (
    <label className={CnInput()} data-testid="test-toggle">
        <input
            type="checkbox"
            disabled={disabled}
            checked={checked}
            defaultChecked={defaultChecked}
            onChange={handleToggleChange}
        />
        <span className={CnInput('switch')}>
            <div className="toggle-icon-left">
                <ToggleLeft />
            </div>
            <div className="toggle-icon-right">
                <ToggleRight />
            </div>
        </span>
    </label>
);
