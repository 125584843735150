import React from 'react';
import { cn } from '@bem-react/classname';
import { CheckIcon, LockIcon } from '@lms-elements/icons';

import { ICheckboxProps } from './Checkbox.types';

import './Checkbox.scss';

const CnCheckbox = cn('checkbox');

export const Checkbox: React.FC<ICheckboxProps> = ({
    label,
    checked,
    disabled,
    handleCheckboxChange,
    blocked,
    isError = false,
    customClasses = '',
    ...props
}) => {
    return (
        <label
            className={`${customClasses} ${CnCheckbox({ blocked, isDisabled: disabled, label: Boolean(label) })}`}
            data-testid="test-checkbox"
        >
            {label && <span className={CnCheckbox('label', { isDisabled: disabled })}>{label}</span>}
            <input
                type="checkbox"
                className={CnCheckbox('input', { isDisabled: disabled, blocked, isError })}
                checked={checked}
                disabled={disabled || blocked}
                onChange={handleCheckboxChange}
                {...props}
            />
            {blocked ? (
                <LockIcon className={CnCheckbox('icon', { blocked })} />
            ) : (
                <CheckIcon className={CnCheckbox('icon')} />
            )}
        </label>
    );
};
