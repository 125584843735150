import React from 'react';
import { cn } from '@bem-react/classname';

import { ITooltipItemProps } from './TooltipItem.types';

import './TooltipItem.styles.scss';

const cnTooltipItem = cn('tooltip-item');

export const TooltipItem: React.FC<ITooltipItemProps> = ({
    icon,
    label,
    onClick,
    labelClassName = '',
    customClassName = '',
}) => {
    return (
        <div className={`${customClassName} ${cnTooltipItem({ withoutIcon: !icon })}`} onClick={onClick}>
            {icon && <div className={cnTooltipItem('icon-wrapper')}>{icon}</div>}
            <span className={`${labelClassName} ${cnTooltipItem('label')}`}>{label}</span>
        </div>
    );
};
