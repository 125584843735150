import { ContentBlock } from 'draft-js';

import { ActionBlockTypes } from '../../types/shared.types';
import { getActionBlockRenderer } from '../ActionBlock';

export const getBlockRenderer = () => (block: ContentBlock) => {
    switch (block.getType()) {
        case ActionBlockTypes.INFO_BLOCK:
            return getActionBlockRenderer(ActionBlockTypes.INFO_BLOCK);
        case ActionBlockTypes.WARNING_BLOCK:
            return getActionBlockRenderer(ActionBlockTypes.WARNING_BLOCK);
        case ActionBlockTypes.THEOREM_BLOCK:
            return getActionBlockRenderer(ActionBlockTypes.THEOREM_BLOCK);
        case ActionBlockTypes.QUOTE_BLOCK:
            return getActionBlockRenderer(ActionBlockTypes.QUOTE_BLOCK);
        case ActionBlockTypes.DEFINITION_BLOCK:
            return getActionBlockRenderer(ActionBlockTypes.DEFINITION_BLOCK);
        case ActionBlockTypes.CODE_BLOCK:
            return getActionBlockRenderer(ActionBlockTypes.CODE_BLOCK);
        default:
            return undefined;
    }
};
