import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgClockIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={22}
            height={22}
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M11 21c5.523 0 10-4.477 10-10S16.523 1 11 1 1 5.477 1 11s4.477 10 10 10z"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeMiterlimit={10}
            />
            <path d="M10 7v6h4" stroke="#0088E9" strokeWidth={1.5} strokeLinecap="round" />
        </svg>
    );
}

const MemoSvgClockIcon = React.memo(SvgClockIcon);
export default MemoSvgClockIcon;
