import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgSend({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={33}
            height={33}
            viewBox="0 0 33 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M24.455 7.99L7.453 12.452a.694.694 0 00-.519.634.755.755 0 00.105.43.781.781 0 00.328.304l7.938 3.89a.78.78 0 01.357.357l3.89 7.938a.78.78 0 00.304.328.754.754 0 00.43.105.694.694 0 00.634-.519l4.46-17.002a.733.733 0 00-.004-.385.774.774 0 00-.537-.536.734.734 0 00-.384-.004v0zM15.545 17.828l4.066-4.066"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgSend = React.memo(SvgSend);
export default MemoSvgSend;
