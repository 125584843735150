export const FIFTEEN_MB_IN_BYTES = 15 * 1024 * 1024;
export const ACCEPT_FILE_TYPES = [
    '.pptx',
    '.ppt',
    '.pdf',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.ods',
    '.odt',
    '.txt',
    '.csv',
    '.rtf',
    '.ods',
    '.gif',
];
