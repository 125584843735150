import React, { useCallback } from 'react';
import { cn } from '@bem-react/classname';

import { NotificationMessage } from '../../NotificationMessage';
import { WithIntersectionObserver } from '../../WithIntersectionObserver';
import { NotificationViewType } from '../NotificationTooltip.types';

import { INotificationGroupProps } from './NotificationGroup.types';

import './NotificationGroup.scss';

const CnNotificationGroup = cn('notificationGroup');

export const NotificationGroup: React.FC<INotificationGroupProps> = ({ title, notifications, onNotificationRead }) => {
    const makeNotificationReadHandlers = useCallback(
        ({
            id,
            makeViewedOn,
            timeoutToViewed,
        }: {
            id: string;
            makeViewedOn?: NotificationViewType;
            timeoutToViewed?: number;
        }) => ({
            onMouseOver: makeViewedOn === NotificationViewType.hover ? () => onNotificationRead?.(id) : undefined,
            onClick: makeViewedOn === NotificationViewType.click ? () => onNotificationRead?.(id) : undefined,
            onTimeoutPassed: makeViewedOn === NotificationViewType.timeout ? () => onNotificationRead?.(id) : undefined,
            timeout: makeViewedOn === NotificationViewType.timeout ? timeoutToViewed : undefined,
        }),
        [onNotificationRead],
    );

    return (
        <div className={CnNotificationGroup()}>
            <p className={CnNotificationGroup('title')}>{title}</p>
            <ul className={CnNotificationGroup('list')}>
                {notifications.map((notification) => {
                    const { id, notificationMessageProps } = notification;
                    const { onClick, onMouseOver, onTimeoutPassed, timeout } =
                        makeNotificationReadHandlers(notification);

                    return (
                        <li
                            onMouseOver={onMouseOver}
                            onClick={onClick}
                            className={CnNotificationGroup('listItem')}
                            key={id}
                        >
                            <WithIntersectionObserver callback={onTimeoutPassed} timeout={timeout}>
                                <NotificationMessage {...notificationMessageProps} />
                            </WithIntersectionObserver>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
