import moment from 'moment';

import { CalendarDropdownDay } from './';

export const getCalendarDropdownDays = (month: number, year: number): CalendarDropdownDay[] => {
    const start = moment().set({ month, year }).startOf('month');
    const daysCount = start.daysInMonth();

    return new Array(daysCount).fill(' ').map((_, index) => {
        const date = moment(start).add(index, 'd');
        return {
            label: date.format('D'),
            inactive: false,
            date: date.format('YYYY-MM-DD'),
        };
    });
};
