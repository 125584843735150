import React, { useCallback, useState } from 'react';
import { cn } from '@bem-react/classname';

import { MAX_COLUMNS, MAX_ROWS } from './constants';
import { ITableCoordinates, ITableSizeProps } from './TableSize.types';

import './TableSize.scss';

const CnTableSize = cn('table-size');

export const TableSize: React.FC<ITableSizeProps> = ({ onElementClick, tableName }) => {
    const [hoveredCoords, setHoveredCoords] = useState<ITableCoordinates>({ x: 0, y: 0 });

    const checkHovered = useCallback(
        (coords: ITableCoordinates) => coords.x <= hoveredCoords.x && coords.y <= hoveredCoords.y,
        [hoveredCoords],
    );

    const makeOnElementClick = useCallback((x: number, y: number) => () => onElementClick({ x, y }), [onElementClick]);

    const makeOnMouseEnter = useCallback((x: number, y: number) => () => setHoveredCoords({ x, y }), []);

    return (
        <div className={CnTableSize('container')}>
            <p className={CnTableSize('title')}>Выберите соотношение столбцов и строк</p>
            <div className={CnTableSize()}>
                {Array.from({ length: MAX_ROWS }).map((_, y) => (
                    <div className={CnTableSize('row')} key={`RowSize${tableName}${y}`}>
                        {Array.from({ length: MAX_COLUMNS }).map((_, x) => (
                            <div
                                className={CnTableSize('element', {
                                    hovered: checkHovered({ x: x + 1, y: y + 1 }),
                                })}
                                key={`ElementSize${tableName}${x}_${y}`}
                                onClick={makeOnElementClick(x + 1, y + 1)}
                                onMouseEnter={makeOnMouseEnter(x + 1, y + 1)}
                            />
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};
