import React, { useCallback, useEffect, useMemo } from 'react';
import { FormSpy } from 'react-final-form';
import { FieldArray, useFieldArray } from 'react-final-form-arrays';
import { cn } from '@bem-react/classname';
import { DropdownCustom, IDropdownType, IDropdownValues, PlanTableCell } from '@lms-elements/atomic';

import { EducationPlanValues } from '../EducationalPlan.types';

import { ERROR_MESSAGES } from './CourseTable.constants';
import { CourseTableProps, Subject } from './CourseTable.types';
import { CuorseTableRow } from './CourseTableRow';
import {
    getPercent,
    SumFact,
    SumInWeek,
    SumInYear,
    SumLecturesInWeek,
    SumLecturesInYear,
    SumLessonsInWeek,
    SumLessonsInYear,
} from './utils';

import './CourseTable.scss';

const CnCourseTable = cn('course-table');

export const CourseTable = React.memo<CourseTableProps>(
    ({
        isMain,
        isBasic,
        coursesOptions,
        calendarPlanOptions,
        isTablet = false,
        readOnly = false,
        onDeleteCourseClick,
        onMainBasicCourseAddClick,
        onMainAdditionalCourseAddClick,
        onSelectiveBasicCourseAddClick,
        onSelectiveAdditionalCourseAddClick,
    }) => {
        const coursesDropdownOptions: IDropdownValues[] = useMemo(() => {
            return coursesOptions.map((course) => {
                return { id: course.courseId, title: course.name };
            });
        }, [coursesOptions]);

        const interationCount = isMain ? [0, 1, 2, 3, 4, 5, 6, 7] : [0, 1, 2, 3, 4, 5];

        const Title = useMemo(
            () =>
                isTablet
                    ? {
                          name: 'title',
                          inWeek: 'В неделю',
                          inYear: 'В год',
                          fact: 'Факт',
                          lessonsInWeek: 'Онл.-занятия в нед.',
                          lessonsInYear: 'Онл.-занятия в год',
                          lecturesInWeek: 'Онл.-лекции в нед.',
                          lecturesInYear: 'Онл.-лекции в год',
                          selection: 'Тип оценивания',
                      }
                    : {
                          name: 'title',
                          inWeek: 'Всего за неделю',
                          inYear: 'Всего за год',
                          fact: 'Факт',
                          lessonsInWeek: 'Онл.-занятия в неделю',
                          lessonsInYear: 'Онл.-занятия в год',
                          lecturesInWeek: 'Онл.-лекции в неделю',
                          lecturesInYear: 'Онл.-лекции в год',
                          selection: 'Тип оценивания',
                      },
            [isTablet],
        );

        const typeCourse = useMemo(() => {
            if (isMain) {
                if (isBasic) {
                    return 'mainBasicCourses';
                }
                return 'mainAdditionalCourses';
            }
            if (isBasic) {
                return 'selectiveBasicCourses';
            }
            return 'selectiveAdditionalCourses';
        }, [isBasic, isMain]);

        const title = useMemo(() => {
            if (isMain) {
                if (isBasic) {
                    return 'Основные курсы';
                }
                return 'Внепредметные курсы';
            }
            if (isBasic) {
                return 'Подготовка к ГИА';
            }
            return 'Не относится к ГИА';
        }, [isBasic, isMain]);

        const headTitle = useMemo(() => {
            if (isMain && isBasic) {
                if (isBasic) {
                    return 'Обязательные курсы';
                }
            }
            if (!isMain && isBasic) {
                return 'Курсы по выбору';
            }
            return '';
        }, [isBasic, isMain]);

        const handleCourseDelete = useCallback(
            (value: number) => {
                if (onDeleteCourseClick) {
                    return onDeleteCourseClick(value);
                }
            },
            [onDeleteCourseClick],
        );

        const handleAddCourse = useCallback(
            (values: IDropdownValues) => {
                switch (typeCourse) {
                    case 'mainBasicCourses':
                        if (onMainBasicCourseAddClick) {
                            return onMainBasicCourseAddClick(values);
                        }
                        break;
                    case 'mainAdditionalCourses':
                        if (onMainAdditionalCourseAddClick) {
                            return onMainAdditionalCourseAddClick(values);
                        }
                        break;
                    case 'selectiveBasicCourses':
                        if (onSelectiveBasicCourseAddClick) {
                            return onSelectiveBasicCourseAddClick(values);
                        }
                        break;
                    case 'selectiveAdditionalCourses':
                        if (onSelectiveAdditionalCourseAddClick) {
                            return onSelectiveAdditionalCourseAddClick(values);
                        }
                        break;
                }
            },
            [
                onMainAdditionalCourseAddClick,
                onMainBasicCourseAddClick,
                onSelectiveAdditionalCourseAddClick,
                onSelectiveBasicCourseAddClick,
                typeCourse,
            ],
        );

        const { fields: fieldsApi } = useFieldArray<Subject>(`${typeCourse}`, { subscription: { value: true } });

        const handleSwap = useCallback(
            (dragIndex: number, hoverIndex: number) => {
                fieldsApi.move(dragIndex, hoverIndex);
            },
            [fieldsApi],
        );
        const handleCourseRemoveAndDelete = useCallback(
            (index: number) => {
                fieldsApi.remove(index);
                handleCourseDelete(fieldsApi.value[index].id);
            },
            [fieldsApi, handleCourseDelete],
        );
        useEffect(() => {
            fieldsApi.value.map((field, index) => {
                if (field.id === undefined) {
                    fieldsApi.remove(index);
                }
            });
        }, [fieldsApi]);

        return (
            <div className={CnCourseTable()}>
                <div className={CnCourseTable('row')}>
                    <div className={CnCourseTable('row-label', { tablet: isTablet })} />
                    <div className={CnCourseTable('title', { bordered: isMain && isBasic, tablet: isTablet })}>
                        {headTitle}
                    </div>
                </div>
                <div className={CnCourseTable('row')}>
                    <div
                        className={CnCourseTable('row-label', {
                            title: true,
                            tabletWithoutLeftPadding: isTablet,
                            readOnly: readOnly,
                        })}
                    >
                        {title}
                    </div>
                    <div className={CnCourseTable('row-table', { title: true, tablet: isTablet })}>
                        {Object.entries(Title)
                            .slice(1)
                            .map(([key, value], index) => {
                                if (!(index >= Object.keys(Title).length - 3 && !isMain))
                                    return (
                                        <PlanTableCell
                                            key={key}
                                            fieldName={''}
                                            width={`${100 / interationCount.length}%`}
                                            initValue={value}
                                            isTitle
                                            canEdit={[0, 3, 5, 7].includes(index)}
                                            isField={false}
                                            isTablet={isTablet}
                                            validators={[]}
                                        />
                                    );
                            })}
                    </div>
                </div>

                <FieldArray<Subject> name={`${typeCourse}`}>
                    {({ fields }) => {
                        return (
                            <>
                                {fields.map(
                                    (name, index) =>
                                        fields?.value[index].id !== undefined && (
                                            <CuorseTableRow
                                                key={fields?.value[index].id || name}
                                                index={index}
                                                courseName={typeCourse}
                                                name={name}
                                                handleDeleteAndRemoveField={handleCourseRemoveAndDelete}
                                                handleSwap={handleSwap}
                                                fieldsValue={fields?.value[index]}
                                                isTablet={isTablet}
                                                typeCourse={typeCourse}
                                                interationCount={interationCount}
                                                isMain={isMain}
                                                calendarPlanOptions={calendarPlanOptions}
                                                readOnly={readOnly}
                                            />
                                        ),
                                )}
                                <div className={CnCourseTable('row')}>
                                    <FormSpy<EducationPlanValues> subscription={{ values: true }}>
                                        {({ values }) => {
                                            const mainBasicIds = values.mainBasicCourses.map((val) => val?.courseId);
                                            const mainAdditional = values.mainAdditionalCourses.map(
                                                (val) => val?.courseId,
                                            );
                                            const selectiveBasic = values.selectiveBasicCourses.map(
                                                (val) => val?.courseId,
                                            );
                                            const selectiveAdditional = values.selectiveAdditionalCourses.map(
                                                (val) => val?.courseId,
                                            );

                                            const subjectIds =
                                                !isMain && !isBasic
                                                    ? selectiveAdditional
                                                    : mainBasicIds.concat(mainAdditional).concat(selectiveBasic);

                                            const dropdownOptions = coursesDropdownOptions.filter(
                                                (value) => !subjectIds.includes(value.id),
                                            );
                                            if (dropdownOptions.length > 0) {
                                                return (
                                                    <div
                                                        className={CnCourseTable('row-label', {
                                                            dropdown: true,
                                                            tabletWithoutLeftPadding: isTablet,
                                                            readOnly: readOnly,
                                                        })}
                                                    >
                                                        <DropdownCustom
                                                            isNeedSearch
                                                            isField={false}
                                                            name={''}
                                                            placeholder="Добавить курс"
                                                            searchPlaceholder="Поиск курса"
                                                            validators={[]}
                                                            type={IDropdownType.course}
                                                            options={dropdownOptions}
                                                            withoutBorder
                                                            getValue={(course) => {
                                                                if (!subjectIds.includes(course.id)) {
                                                                    fields.push(
                                                                        coursesOptions.filter(
                                                                            (val) => val.courseId === course.id,
                                                                        )[0],
                                                                    );
                                                                }
                                                                handleAddCourse(course);
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            }
                                            return (
                                                <div
                                                    className={CnCourseTable('row-label', {
                                                        tablet: isTablet,
                                                    })}
                                                />
                                            );
                                        }}
                                    </FormSpy>

                                    <div className={CnCourseTable('row-table', { tablet: isTablet })}>
                                        {interationCount.map((val, ind) => {
                                            return (
                                                <PlanTableCell
                                                    key={ind}
                                                    fieldName={``}
                                                    width={`${100 / interationCount.length}%`}
                                                    initValue={'-'}
                                                    isField={false}
                                                    validators={[]}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            </>
                        );
                    }}
                </FieldArray>

                <div className={CnCourseTable('row')}>
                    <div
                        className={CnCourseTable('row-label', {
                            title: true,
                            tabletWithoutLeftPadding: isTablet,
                            readOnly: readOnly,
                        })}
                    >
                        Итог
                    </div>

                    <div className={CnCourseTable('row-table', { tablet: isTablet })}>
                        <FormSpy<EducationPlanValues> subscription={{ values: true }}>
                            {({ values }) => (
                                <PlanTableCell
                                    fieldName={``}
                                    width={`${100 / interationCount.length}%`}
                                    initValue={
                                        SumInWeek(values[typeCourse]) > 0 ? String(SumInWeek(values[typeCourse])) : '-'
                                    }
                                    isTotal
                                    isField={false}
                                    validators={[]}
                                />
                            )}
                        </FormSpy>

                        <FormSpy<EducationPlanValues> subscription={{ values: true }}>
                            {({ values }) => (
                                <PlanTableCell
                                    fieldName={``}
                                    width={`${100 / interationCount.length}%`}
                                    initValue={
                                        SumInYear(values[typeCourse]) > 0 ? String(SumInYear(values[typeCourse])) : '-'
                                    }
                                    isError={SumFact(values[typeCourse]) < SumInYear(values[typeCourse])}
                                    errorMessage={ERROR_MESSAGES.TOTAL_BIGGER_THEN_FACT_ERROR}
                                    isTotal
                                    isField={false}
                                    validators={[]}
                                />
                            )}
                        </FormSpy>
                        <FormSpy<EducationPlanValues> subscription={{ values: true }}>
                            {({ values }) => (
                                <PlanTableCell
                                    fieldName={``}
                                    width={`${100 / interationCount.length}%`}
                                    isTotal
                                    initValue={
                                        SumFact(values[typeCourse]) > 0 ? String(SumFact(values[typeCourse])) : '-'
                                    }
                                    isField={false}
                                    validators={[]}
                                />
                            )}
                        </FormSpy>
                        <FormSpy<EducationPlanValues> subscription={{ values: true }}>
                            {({ values }) => (
                                <PlanTableCell
                                    fieldName={``}
                                    width={`${100 / interationCount.length}%`}
                                    initValue={
                                        SumLessonsInWeek(values[typeCourse]) > 0
                                            ? String(SumLessonsInWeek(values[typeCourse]))
                                            : '-'
                                    }
                                    isTotal
                                    percent={
                                        isMain
                                            ? getPercent(
                                                  SumLessonsInWeek(values[typeCourse]),
                                                  SumLessonsInWeek(values[typeCourse]) +
                                                      SumLecturesInWeek(values[typeCourse]),
                                              )
                                            : 0
                                    }
                                    isField={false}
                                    validators={[]}
                                />
                            )}
                        </FormSpy>
                        <FormSpy<EducationPlanValues> subscription={{ values: true }}>
                            {({ values }) => (
                                <PlanTableCell
                                    fieldName={``}
                                    width={`${100 / interationCount.length}%`}
                                    initValue={
                                        SumLessonsInYear(values[typeCourse]) > 0
                                            ? String(SumLessonsInYear(values[typeCourse]))
                                            : '-'
                                    }
                                    isTotal
                                    percent={
                                        isMain
                                            ? getPercent(
                                                  SumLessonsInYear(values[typeCourse]),
                                                  SumLessonsInYear(values[typeCourse]) +
                                                      SumLecturesInYear(values[typeCourse]),
                                              )
                                            : 0
                                    }
                                    isField={false}
                                    validators={[]}
                                />
                            )}
                        </FormSpy>
                        {isMain && (
                            <>
                                <FormSpy<EducationPlanValues> subscription={{ values: true }}>
                                    {({ values }) => (
                                        <PlanTableCell
                                            fieldName={``}
                                            width={`${100 / interationCount.length}%`}
                                            initValue={
                                                SumLecturesInWeek(values[typeCourse]) > 0
                                                    ? String(SumLecturesInWeek(values[typeCourse]))
                                                    : '-'
                                            }
                                            isTotal
                                            percent={
                                                isMain
                                                    ? getPercent(
                                                          SumLecturesInWeek(values[typeCourse]),
                                                          SumLecturesInWeek(values[typeCourse]) +
                                                              SumLessonsInWeek(values[typeCourse]),
                                                      )
                                                    : 0
                                            }
                                            isField={false}
                                            validators={[]}
                                        />
                                    )}
                                </FormSpy>
                                <FormSpy<EducationPlanValues> subscription={{ values: true }}>
                                    {({ values }) => (
                                        <PlanTableCell
                                            fieldName={``}
                                            width={`${100 / interationCount.length}%`}
                                            initValue={
                                                SumLecturesInYear(values[typeCourse]) > 0
                                                    ? String(SumLecturesInYear(values[typeCourse]))
                                                    : '-'
                                            }
                                            isTotal
                                            percent={
                                                isMain
                                                    ? getPercent(
                                                          SumLecturesInYear(values[typeCourse]),
                                                          SumLecturesInYear(values[typeCourse]) +
                                                              SumLessonsInYear(values[typeCourse]),
                                                      )
                                                    : 0
                                            }
                                            isField={false}
                                            validators={[]}
                                        />
                                    )}
                                </FormSpy>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    },
);
