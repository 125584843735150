import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgAlarm({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={24}
            height={25}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M13.299 21.744c-.26.45-.748.756-1.299.756a1.497 1.497 0 01-1.301-.756h2.6zM18 14.887c0 1.22.483 2.389 1.344 3.252l.892.894a.048.048 0 01-.034.082H3.798a.048.048 0 01-.034-.082l.892-.895A4.604 4.604 0 006 14.889V10.84c0-2.966 1.427-5.333 3.833-6.206.67-.243 1.23-.877 1.23-1.692a.938.938 0 111.874 0c0 .815.56 1.448 1.229 1.692C16.564 5.51 18 7.887 18 10.84v4.047z"
                stroke="#585C6F"
                strokeWidth={1.5}
            />
        </svg>
    );
}

const MemoSvgAlarm = React.memo(SvgAlarm);
export default MemoSvgAlarm;
