import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgBorderGray({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={1025}
            height={398}
            viewBox="0 0 1025 398"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <rect
                x={0.75}
                y={0.75}
                width={1023.5}
                height={396.5}
                rx={20.25}
                stroke="#C0C2C9"
                strokeWidth={1.5}
                strokeLinejoin="round"
                strokeDasharray="12 12"
            />
        </svg>
    );
}

const MemoSvgBorderGray = React.memo(SvgBorderGray);
export default MemoSvgBorderGray;
