import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgPlus({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.4 1a.6.6 0 01.6.6V7h5.4a.6.6 0 01.6.6v.8a.6.6 0 01-.6.6H9v5.4a.6.6 0 01-.6.6h-.8a.6.6 0 01-.6-.6V9H1.6a.6.6 0 01-.6-.6v-.8a.6.6 0 01.6-.6H7V1.6a.6.6 0 01.6-.6h.8z"
                fill="#white"
            />
        </svg>
    );
}

const MemoSvgPlus = React.memo(SvgPlus);
export default MemoSvgPlus;
