import React from 'react';
import { logoIcon, logoText } from 'assets';

import { ILogoProps } from './Logo.types';

import './Logo.scss';

export const Logo: React.FC<ILogoProps> = ({ small }) => {
    return (
        <div className="logo">
            <img className="logo-icon" src={logoIcon} alt="Logo" />
            {!small && <img className="logo-text" src={logoText} alt="Logo" />}
        </div>
    );
};
