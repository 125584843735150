import React, { useRef } from 'react';
import { cn } from '@bem-react/classname';

import { StyledNumber } from '../StyledNumber';

import { MarksList } from './MarksList';
import { INotificationMessageProps } from './NotificationMessage.types';

import './NotificationMessage.scss';

const CnNotificationMessage = cn('notificationMessage');

export const NotificationMessage: React.FC<INotificationMessageProps> = ({
    message,
    isImportant,
    title,
    time,
    number,
    marks = [],
    onClick,
}) => {
    const notificationRef = useRef<HTMLDivElement>(null);

    return (
        <div ref={notificationRef} onClick={onClick} className={CnNotificationMessage({ isImportant })}>
            {time && <p className={CnNotificationMessage('time')}>{time}</p>}
            {title && <p className={CnNotificationMessage('title')}>{title}</p>}
            <div
                className={CnNotificationMessage('content', {
                    withNumber: Boolean(number),
                    withMarks: marks.length !== 0,
                    withoutMarginTop: !title && !time,
                })}
            >
                {number && (
                    <div className={CnNotificationMessage('asideBlocks')}>
                        <StyledNumber {...number} />
                    </div>
                )}
                {marks.length !== 0 && (
                    <div className={CnNotificationMessage('asideBlocks')}>
                        <MarksList marks={marks} />
                    </div>
                )}
                <p className={CnNotificationMessage('message')}>{message}</p>
            </div>
        </div>
    );
};
