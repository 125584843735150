import moment from 'moment';

export const statusHomework = (datetimeStart: string, datetimeEnd: string): string => {
    const lateTimeStart = moment(datetimeStart).add(15, 'm').format();
    const isBefore = moment().isBefore(datetimeStart);
    const isPresent = moment().isBetween(datetimeStart, lateTimeStart);
    const isLate = moment().isBetween(lateTimeStart, datetimeEnd);

    if (isBefore) {
        return 'future';
    }

    if (isPresent) {
        return 'present';
    }

    if (isLate) {
        return 'late';
    }

    return 'past';
};
