import React, { useCallback, useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { getWeekdayNumber } from '@lms-elements/utils';

import { IDatepickerDayProps } from './DatepickerDay.types';

import './DatepickerDay.scss';

const CnDatepicker = cn('datepickerDay');

export const DatepickerDay: React.FC<IDatepickerDayProps> = ({ label, date, inactive, isSelected, onDayClick }) => {
    const handleDayClick = useCallback(() => {
        if (onDayClick && !inactive) {
            onDayClick(date);
        }
    }, [onDayClick, date, inactive]);

    const styles = useMemo(() => {
        if (label === '1') {
            const weekday = getWeekdayNumber(date);
            return { gridColumn: weekday + 1 };
        }
        return undefined;
    }, [label, date]);

    return (
        <div className={CnDatepicker({ inactive, isSelected })} onClick={handleDayClick} style={styles}>
            {label}
        </div>
    );
};
