import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgClosedLock({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M18.126 8.97h-.565v-.932c0-.897-.24-1.776-.693-2.558a.868.868 0 00-.081-.15C15.78 3.872 14.087 3 12.26 3h-.524c-2.98 0-5.404 2.312-5.404 5.154v.817H5.87C4.841 8.97 4 9.769 4 10.755v8.461C4 20.198 4.837 21 5.87 21h12.26c1.029 0 1.87-.799 1.87-1.784v-8.462c-.004-.985-.841-1.783-1.874-1.783zm-10.2-.773c0-.986.387-1.904 1.091-2.59a3.875 3.875 0 012.723-1.09h.524c1.018 0 1.974.376 2.697 1.065l.044.046c.173.194.328.405.457.627.325.562.42 1.192.476 1.79v.926h-2.254l-5.759-.018v-.756zm10.477 11.02a.27.27 0 01-.277.263H5.87a.27.27 0 01-.276-.264v-8.462a.27.27 0 01.276-.263h12.256a.27.27 0 01.277.264v8.461z"
                fill="#252232"
            />
            <path
                d="M12 12.79a.864.864 0 00-.864.863v2.157a.864.864 0 001.728 0v-2.157a.864.864 0 00-.864-.864z"
                fill="#252232"
            />
        </svg>
    );
}

const MemoSvgClosedLock = React.memo(SvgClosedLock);
export default MemoSvgClosedLock;
