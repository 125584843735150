import { Position } from '../../Tooltip';

export const getSide = (tooltipPosition: Position): Position => {
    switch (tooltipPosition) {
        case Position.top:
            return Position.bottom;
        case Position.bottom:
            return Position.top;
        case Position.left:
            return Position.right;
        case Position.right:
            return Position.left;
    }
};
