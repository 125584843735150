import { ConnectDragSource } from 'react-dnd';

export enum CodifierElementIconEnum {
    question = 'question',
    text = 'text',
    table = 'table',
    header = 'header',
    video = 'video',
    audio = 'audio',
    document = 'document',
    image = 'image',
    exercise = 'exercise',
    trainer = 'trainer',
}

export interface ICodifierElementProps {
    icon: CodifierElementIconEnum;
    title: string;
    description: string;
    dragging?: boolean;
    dragRef?: ConnectDragSource;
    disabled?: boolean;
}
