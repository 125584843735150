import React, { useCallback, useMemo, useState } from 'react';
import { cn } from '@bem-react/classname';
import { Checkbox } from '@lms-elements/atomic';
import { isURL } from '@lms-elements/utils';

import { OptionPopUpWrapper } from '../../OptionPopUpWrapper';

import { ILinkPopUpProps } from './LinkPopUp.types';

import './LinkPopUp.scss';

const LinkPopUpCn = cn('link-popup');

export const LinkPopUp: React.FC<ILinkPopUpProps> = ({
    url,
    setUrl,
    text,
    setText,
    isBlank,
    setIsBlank,
    onSaveClick,
    setIsShown,
}) => {
    const [isUrlCorrect, setIsUrlCorrect] = useState(url.length !== 0 && !isURL(url, true) ? false : isURL(url, true));
    const onCancelClick = useCallback(() => {
        setUrl('');
        setText('');
        setIsBlank(true);
    }, [setIsBlank, setText, setUrl]);

    const handleCheckboxChange = useCallback(() => setIsBlank((prev) => !prev), [setIsBlank]);

    const onUrlChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const newUrl = e.target.value;

            setIsUrlCorrect(isURL(newUrl, true));

            setUrl(newUrl);
        },
        [setUrl, setIsUrlCorrect],
    );

    const onTextChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setText(e.target.value), [setText]);

    const isSaveDisabled = useMemo(() => text.length === 0 || !isUrlCorrect, [text.length, isUrlCorrect]);

    return (
        <OptionPopUpWrapper
            onSaveClick={onSaveClick}
            setIsShown={setIsShown}
            onCancelClick={onCancelClick}
            isSaveDisabled={isSaveDisabled}
        >
            <div className={LinkPopUpCn()}>
                <div className={LinkPopUpCn('top-row')}>
                    <h5 className={LinkPopUpCn('head')}>Вставка ссылки</h5>
                    <Checkbox
                        label="открывать в новом окне"
                        handleCheckboxChange={handleCheckboxChange}
                        checked={isBlank}
                    />
                </div>
                <input
                    type="text"
                    placeholder="Введите URL (напр.: http://...)"
                    className={LinkPopUpCn('input', { url: true, withError: !isUrlCorrect })}
                    value={url}
                    onChange={onUrlChange}
                />
                <input
                    type="text"
                    placeholder="Введите текст ссылки"
                    className={LinkPopUpCn('input', { text: true })}
                    value={text}
                    onChange={onTextChange}
                />
            </div>
        </OptionPopUpWrapper>
    );
};
