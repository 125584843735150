import { ActionBlockTypes, IBlockRendererObject } from '../../types/shared.types';

import { ActionBlockComponentBlockProps } from './ActionBlockComponent/ActionBlockComponent.types';
import { ActionBlockComponent } from './ActionBlockComponent';

export const getActionBlockRenderer = (
    type: ActionBlockTypes,
): IBlockRendererObject<ActionBlockComponentBlockProps> => ({
    component: ActionBlockComponent,
    props: {
        type,
    },
});
