import { convertToRaw, EditorState } from 'draft-js';

import { EntityTypes } from '../types/shared.types';

export const getEntityDataByType = (editorState: EditorState, entityType: EntityTypes) => {
    const contentState = editorState.getCurrentContent();
    return Object.entries(convertToRaw(contentState).entityMap)
        .filter(([, val]) => val.type === entityType)
        .map(([key, val]) => ({ key, val }));
};
