import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgCalendarDayPointer({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={251}
            height={95}
            viewBox="0 0 251 95"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M7.5 23.5l.348.36-.348-.36zM246.834.628a.5.5 0 00-.706.038l-3.006 3.35a.5.5 0 00.744.667l2.672-2.977 2.977 2.672a.5.5 0 10.668-.745L246.834.628zM31.674 93.532C18.705 88.688 8.783 75.098 4.1 60.72 1.763 53.542.745 46.203 1.295 39.714c.551-6.492 2.667-12.09 6.553-15.855l-.696-.718C3.038 27.127.862 32.987.3 39.63c-.564 6.646.48 14.12 2.85 21.4 4.735 14.538 14.813 28.448 28.176 33.438l.35-.936zM7.849 23.859c4.361-4.225 11.395-6.38 20.473-7.022 9.064-.641 20.078.23 32.306 1.972 24.473 3.486 53.639 10.422 81.625 15.573 27.935 5.142 54.687 8.503 74.158 4.755 9.746-1.875 17.724-5.542 23.106-11.71 5.388-6.179 8.113-14.798 7.483-26.454l-.999.054c.62 11.47-2.064 19.81-7.238 25.742-5.182 5.94-12.92 9.535-22.541 11.387-19.261 3.706-45.838.387-73.788-4.757-27.9-5.136-57.176-12.092-81.665-15.58-12.252-1.744-23.348-2.628-32.518-1.98-9.155.648-16.481 2.83-21.098 7.302l.696.718z"
                fill="#0088E9"
            />
        </svg>
    );
}

const MemoSvgCalendarDayPointer = React.memo(SvgCalendarDayPointer);
export default MemoSvgCalendarDayPointer;
