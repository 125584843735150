import React from 'react';
import { useField } from 'react-final-form';

import { IStudentViewProps } from './StudentView.types';

export const StudentView: React.FC<IStudentViewProps> = ({ name, className = '' }) => {
    const {
        input: {
            value: { html: __html },
        },
    } = useField<Record<string, string>>(name);

    return <div className={className} dangerouslySetInnerHTML={{ __html }} />;
};
