import React, { useCallback, useMemo, useState } from 'react';
import { cn } from '@bem-react/classname';

import { MAX_PERCENT, MIN_PERCENT, ProgressBar } from '../ProgressBar';

import { CheckboxesTooltip } from './CheckboxesTooltip';
import { ProgressBarWithCheckboxesProps } from './ProgressBarWithCheckboxes.types';

import './ProgressBarWithCheckboxes.scss';

const CnProgressBarWithCheckboxes = cn('progressBarWithCheckboxes');

export const ProgressBarWithCheckboxes: React.FC<ProgressBarWithCheckboxesProps> = ({
    checkboxes,
    onCheckboxChange,
    tooltipTitle,
}) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const fillingPercentage = useMemo(() => {
        if (!checkboxes.length) {
            return MAX_PERCENT;
        }

        const oneCheckboxWeight = (MAX_PERCENT - MIN_PERCENT) / checkboxes.length;

        return checkboxes.reduce(
            (percentAccumulator, checkbox) => percentAccumulator + Number(checkbox.checked) * oneCheckboxWeight,
            MIN_PERCENT,
        );
    }, [checkboxes]);

    const handleTooltipClose = useCallback(() => setIsTooltipOpen(false), []);

    const handleProgressBarClick = useCallback(() => setIsTooltipOpen(true), []);

    return (
        <div className={CnProgressBarWithCheckboxes()}>
            <div className={CnProgressBarWithCheckboxes('progressBarWrapper')} onClick={handleProgressBarClick}>
                <ProgressBar fillingPercentage={fillingPercentage} />
            </div>
            {isTooltipOpen && (
                <div className={CnProgressBarWithCheckboxes('tooltipWrapper')}>
                    <CheckboxesTooltip
                        checkboxes={checkboxes}
                        tooltipTitle={tooltipTitle}
                        onTooltipClose={handleTooltipClose}
                        onCheckboxChange={onCheckboxChange}
                    />
                </div>
            )}
        </div>
    );
};
