import React from 'react';
import { cn } from '@bem-react/classname';

import { NotificationGroup } from '../NotificationGroup';

import { INotificationListProps } from './NotificationList.types';

import './NotificationList.scss';

const CnNotificationList = cn('notificationList');

export const NotificationList: React.FC<INotificationListProps> = ({
    notificationGroups,
    needShowFirstTitle = false,
    onNotificationRead,
}) => (
    <div className={CnNotificationList()}>
        {notificationGroups.map(({ title, notifications }, index) => (
            <div className={CnNotificationList('notificationGroupWrapper')} key={title}>
                <NotificationGroup
                    title={`${!needShowFirstTitle && index === 0 ? '' : title}`}
                    notifications={notifications}
                    onNotificationRead={onNotificationRead}
                />
            </div>
        ))}
    </div>
);
