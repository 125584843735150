import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgStrikethroughTextIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M15.531 24.21c-1.148 0-2.149-.182-3.003-.546-.84-.364-1.484-.84-1.932-1.428-.434-.602-.665-1.246-.693-1.932 0-.112.042-.21.126-.294a.427.427 0 01.315-.126h1.03c.251 0 .426.14.524.42.126.588.483 1.099 1.071 1.533.602.42 1.456.63 2.562.63 1.19 0 2.08-.21 2.667-.63.588-.42.882-1.001.882-1.743 0-.476-.147-.868-.44-1.176-.28-.308-.708-.574-1.282-.798-.56-.238-1.407-.525-2.54-.861-1.079-.308-1.94-.637-2.584-.987-.63-.35-1.106-.777-1.428-1.281-.322-.518-.483-1.17-.483-1.953 0-.756.203-1.428.61-2.016.405-.602.993-1.071 1.763-1.407.77-.35 1.68-.525 2.73-.525 1.092 0 2.023.196 2.793.588.784.378 1.38.86 1.785 1.449.406.574.623 1.155.651 1.743a.427.427 0 01-.44.44h-1.05a.532.532 0 01-.316-.104.574.574 0 01-.21-.315c-.084-.602-.42-1.1-1.008-1.491-.588-.392-1.323-.588-2.205-.588-.952 0-1.708.189-2.268.567-.56.364-.84.91-.84 1.638 0 .49.126.889.378 1.197.266.308.665.58 1.197.819.546.238 1.337.51 2.373.819 1.19.336 2.121.672 2.793 1.008.686.336 1.197.756 1.533 1.26.336.49.504 1.127.504 1.91 0 1.317-.504 2.346-1.512 3.088-.994.728-2.345 1.092-4.053 1.092z"
                fill="#252232"
            />
            <path d="M9 17h13.02v1.05H9V17z" fill="#252232" />
            <path d="M9 15h13v2H9v-2z" fill="#fff" />
        </svg>
    );
}

const MemoSvgStrikethroughTextIcon = React.memo(SvgStrikethroughTextIcon);
export default MemoSvgStrikethroughTextIcon;
