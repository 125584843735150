import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgPaperclipIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={29}
            height={29}
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M16.857 8.768l-8.926 9.074a1.714 1.714 0 002.424 2.425l10.64-10.789a3.43 3.43 0 00-4.848-4.849L5.507 15.418a5.143 5.143 0 107.273 7.273l8.791-8.78"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgPaperclipIcon = React.memo(SvgPaperclipIcon);
export default MemoSvgPaperclipIcon;
