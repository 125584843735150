export enum StatusLabelEnum {
    wasAbsent = 'не был',
    isLate = 'опоздал на:',
    wasInClass = 'Был на занятии',
    illness = 'По болезни',
    seriousReason = 'По уваж. причине',
}

export interface IDropDownProps {
    status: StatusLabelEnum;
    lateTime?: number;
    needHideAbsentReasonOptions?: boolean;
    selectHandler?: (values: { status: StatusLabelEnum; lateMinutes: number }) => void;
}
