import { EditorState, RawDraftEntity } from 'draft-js';

import { EntityTypes } from '../types/shared.types';

type FindedEntityData = {
    entityKey: string;
    blockKey: string;
    entity: RawDraftEntity;
};

type EntityData = FindedEntityData & { start: number; end: number };

export const getEntities = (editorState: EditorState, entityType: EntityTypes) => {
    const content = editorState.getCurrentContent();
    const entities: EntityData[] = [];
    content.getBlocksAsArray().forEach((block) => {
        let selectedEntity: FindedEntityData;
        block.findEntityRanges(
            (character) => {
                if (character.getEntity() !== null) {
                    const entity = content.getEntity(character.getEntity());
                    if (!entityType || (entityType && entity.getType() === entityType)) {
                        const entity = content.getEntity(character.getEntity());

                        selectedEntity = {
                            entityKey: character.getEntity(),
                            blockKey: block.getKey(),
                            entity: {
                                data: entity.getData() as Record<string, unknown>,
                                mutability: entity.getMutability(),
                                type: entity.getType(),
                            },
                        };
                        return true;
                    }
                }
                return false;
            },
            (start, end) => {
                entities.push({ ...selectedEntity, start, end });
            },
        );
    });
    return entities;
};
