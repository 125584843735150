import React, { memo } from 'react';
import { useDrag } from 'react-dnd';
import { CodifierElement, CodifierElementIconEnum } from '@lms-elements/atomic';

import { IMinimizedQuestionProps, MIN_QUESTION_DRAG_TYPE } from './MinimizedQuestion.types';
import { ChooseHeading, ChooseParagraph } from './utils';

const MinimizedQuestion: React.FC<IMinimizedQuestionProps> = memo(({ type, onDragEnd, disabled = false }) => {
    const [{ isDragging }, drag] = useDrag({
        type: MIN_QUESTION_DRAG_TYPE,
        item: () => ({ type }),
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (item && dropResult && onDragEnd) {
                onDragEnd();
            }
        },
        collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    });

    return (
        <CodifierElement
            icon={CodifierElementIconEnum.question}
            description={ChooseParagraph(type)}
            title={ChooseHeading(type)}
            dragging={isDragging}
            dragRef={drag}
            disabled={disabled}
        />
    );
});
export default MinimizedQuestion;
