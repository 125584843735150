/**
 * @example capitalizeWord('1 марта 2021', 2) === 1 Марта 2021
 * @param text source text
 * @param wordIndex the ordinal number of the word in the text, starting from 1
 * @returns text with capitalize word
 */
export const capitalizeWord = (text: string, wordIndex: number): string => {
    const result = text
        .split(' ')
        .map((word, index) => {
            if (index === wordIndex - 1) {
                return word[0].toUpperCase() + word.slice(1);
            }

            return word;
        })
        .join(' ');

    return result;
};
