import React, { useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { ExclamationCircle, Info, WarningTriangle } from '@lms-elements/icons';

import { ErrorShedulingProps } from './ErrorSheduling.types';

import './ErrorSheduling.scss';

const CnErrorSheduling = cn('error-sheduling');

export const ErrorSheduling: React.FC<ErrorShedulingProps> = ({
    textError = '',
    isNeedText = true,
    customClassNames = '',
    isNeedIcon = true,
    iconType = 'exclamation',
}) => {
    const chooseIcon = useMemo(() => {
        switch (iconType) {
            case 'warning':
                return <WarningTriangle />;
            case 'info':
                return <Info />;
            case 'exclamation':
                return <ExclamationCircle />;
            default:
                return <ExclamationCircle />;
        }
    }, [iconType]);

    return (
        <div className={`${CnErrorSheduling()} ${customClassNames}`}>
            {isNeedIcon && chooseIcon}
            {isNeedText && <div className={CnErrorSheduling('description')}>{textError}</div>}
        </div>
    );
};
