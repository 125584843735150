import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgCopyIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={18}
            height={20}
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M9.25 15.571H1.567a.527.527 0 01-.4-.194A.724.724 0 011 14.91V1.662c0-.175.06-.344.166-.468a.527.527 0 01.4-.194h11.313c.15 0 .294.07.4.194a.724.724 0 01.165.468v8.999a.763.763 0 01-.043.253.68.68 0 01-.122.215L9.65 15.377a.57.57 0 01-.184.144.495.495 0 01-.216.05z"
                fill="#F9FAFD"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.805 19.001H5.12a.527.527 0 01-.4-.194.724.724 0 01-.165-.468V5.092c0-.176.06-.344.165-.468a.527.527 0 01.4-.194h11.313c.15 0 .294.07.4.194a.724.724 0 01.166.468v8.998a.763.763 0 01-.043.254.682.682 0 01-.122.215l-3.629 4.248a.57.57 0 01-.183.144.495.495 0 01-.217.05z"
                fill="#F9FAFD"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.556 14.43H12.26v4.143"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgCopyIcon = React.memo(SvgCopyIcon);
export default MemoSvgCopyIcon;
