import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useField } from 'react-final-form';
import { cn } from '@bem-react/classname';

import { MIN_CELL_HEIGHT, MIN_CELL_WIDTH } from '../constants';

import { IResizeButtonProps } from './ResizeButton.types';

import './ResizeButton.scss';

const CnResizeButton = cn('resize-button');

const FUNC_STAB = () => undefined;

export const ResizeButton: React.FC<IResizeButtonProps> = ({
    tableName,
    index = 0,
    onPlusClick,
    newColumn,
    isVertical = false,
    handleChangeWidth = FUNC_STAB,
    handleChangeHeight = FUNC_STAB,
    width = MIN_CELL_WIDTH,
    height = MIN_CELL_HEIGHT,
    minHeight = MIN_CELL_HEIGHT,
    setIsResizing = FUNC_STAB,
    setNeedToFocus = FUNC_STAB,
}) => {
    const { input } = useField(isVertical ? `${tableName}.widths.${index}` : `${tableName}.heights.${index}`);

    const [isHovered, setIsHovered] = useState(false);

    const onMouseEnter = useCallback(() => setIsHovered(true), []);
    const onMouseLeave = useCallback(() => setIsHovered(false), []);

    const [localIsResizing, setLocalIsResizing] = useState(false);

    useEffect(() => {
        setIsResizing(localIsResizing);
    }, [localIsResizing, setIsResizing]);

    const [startResizeEvent, setStartResizeEvent] =
        useState<{ clientAxis: number; startValue: number } | undefined>(undefined);

    const resizeRef = useRef<HTMLButtonElement>(null);

    const onMouseDown = useCallback(
        (e: MouseEvent) => {
            if (resizeRef.current && resizeRef.current.contains(e.target as Node)) {
                if (isVertical) {
                    setStartResizeEvent({ clientAxis: e.clientX, startValue: width });
                    setLocalIsResizing((prev) => !prev);
                } else {
                    setStartResizeEvent({ clientAxis: e.clientY, startValue: height });
                    setLocalIsResizing((prev) => !prev);
                }
            }
        },
        [height, isVertical, width],
    );

    const onMouseMove = useCallback(
        (e: MouseEvent) => {
            if (startResizeEvent && localIsResizing) {
                if (isVertical) {
                    handleChangeWidth(
                        index,
                        Math.max(MIN_CELL_WIDTH, startResizeEvent.startValue + e.clientX - startResizeEvent.clientAxis),
                    );
                } else {
                    handleChangeHeight(
                        index,
                        Math.max(minHeight, startResizeEvent.startValue + e.clientY - startResizeEvent.clientAxis),
                    );
                }
            }
        },
        [handleChangeHeight, handleChangeWidth, index, isVertical, localIsResizing, minHeight, startResizeEvent],
    );

    const onMouseUp = useCallback(() => {
        if (localIsResizing) {
            setStartResizeEvent(undefined);
            setLocalIsResizing((prev) => !prev);
            input.onChange(isVertical ? width : height);
            setNeedToFocus(true);
        }
    }, [localIsResizing, input, isVertical, width, height, setNeedToFocus]);

    useEffect(() => {
        window.addEventListener('mousedown', onMouseDown);
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
        return () => {
            window.removeEventListener('mousedown', onMouseDown);
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        };
    }, [onMouseDown, onMouseMove, onMouseUp]);

    const verticalResizable = isVertical && index >= 0;

    const horizontResizable = !isVertical && index >= 0;

    const handleOutDivClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
    }, []);

    const handleAddClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            onPlusClick(newColumn);
            e.stopPropagation();
        },
        [onPlusClick, newColumn],
    );

    return (
        <div
            className={CnResizeButton({ vertical: isVertical })}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={handleOutDivClick}
        >
            {isHovered ? (
                <div className={CnResizeButton('add', { vertical: isVertical })} onClick={handleAddClick}>
                    +
                </div>
            ) : (
                <div className={CnResizeButton('triangle', { vertical: isVertical })} />
            )}
            <div className={CnResizeButton('external', { vertical: isVertical })}>
                <div
                    className={CnResizeButton('external-content', { vertical: isVertical })}
                    style={{ width: isVertical ? undefined : width + 10, height: isVertical ? height + 10 : undefined }}
                />
            </div>
            <button
                type="button"
                className={CnResizeButton('main', { vertical: isVertical, verticalResizable, horizontResizable })}
                ref={resizeRef}
            >
                <div
                    className={CnResizeButton('main-content', {
                        vertical: isVertical,
                        verticalResizable,
                        horizontResizable,
                    })}
                />
            </button>
        </div>
    );
};
