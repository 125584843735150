import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgPencil({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M12 21a9 9 0 100-18 9 9 0 000 18zM13.5 17.25a1.5 1.5 0 113 0M7.5 17.25a1.5 1.5 0 013 0"
                stroke="#fff"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.5 20.875V17.25a1.5 1.5 0 10-3 0v3.625"
                stroke="#fff"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.5 19.796V16.5L12 6.75 7.5 16.5v3.297M9.923 11.25h4.154"
                stroke="#fff"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgPencil = React.memo(SvgPencil);
export default MemoSvgPencil;
