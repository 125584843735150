import { useEffect, useState } from 'react';

export const useScrollCompleteListener = ({
    onScrollComplete,
    isScrollEnd,
    wasScrolled,
}: {
    isScrollEnd: boolean;
    wasScrolled: boolean;
    onScrollComplete?: () => void;
}): void => {
    const [wasScrollCompleted, setWasScrollCompleted] = useState(false);

    // When changing the height, reset the wasScrollCompleted
    useEffect(() => {
        if (wasScrolled) {
            setWasScrollCompleted(false);
        }
    }, [wasScrolled]);

    // wasScrollCompleted is needed so that onScrollComplete is not called when scrolling down again
    useEffect(() => {
        const isScrollComplete = !isScrollEnd && wasScrolled;
        if (isScrollComplete && !wasScrollCompleted) {
            setWasScrollCompleted(true);
            onScrollComplete?.();
        }
    }, [isScrollEnd, onScrollComplete, wasScrollCompleted, wasScrolled]);
};
