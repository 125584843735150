import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgQuestionIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={12}
            height={24}
            viewBox="0 0 12 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M4.319 16.738c-.142-.9-.118-1.706.07-2.421a8.612 8.612 0 01.85-2.006c.354-.622.755-1.199 1.203-1.729.472-.53.909-1.049 1.31-1.556.401-.53.732-1.06.991-1.59.284-.531.425-1.107.425-1.73 0-.922-.295-1.706-.885-2.352-.566-.668-1.416-1.002-2.548-1.002-.78 0-1.523.184-2.23.553a6.065 6.065 0 00-1.841 1.452L0 2.87A9.705 9.705 0 012.62.83C3.633.277 4.79 0 6.087 0c1.794 0 3.222.496 4.284 1.487C11.457 2.455 12 3.804 12 5.533c0 .76-.142 1.453-.425 2.075a9.029 9.029 0 01-1.062 1.764 26.821 26.821 0 01-1.31 1.66c-.448.53-.873 1.083-1.274 1.66a7.577 7.577 0 00-.885 1.867c-.212.646-.271 1.372-.177 2.179H4.32zM5.664 24c-.59 0-1.086-.196-1.487-.588-.401-.415-.602-.934-.602-1.556 0-.669.2-1.199.602-1.59a1.986 1.986 0 011.487-.623c.59 0 1.085.207 1.486.622.425.392.638.922.638 1.59 0 .623-.213 1.142-.638 1.557-.4.392-.896.588-1.486.588z"
                fill="#0088E9"
            />
        </svg>
    );
}

const MemoSvgQuestionIcon = React.memo(SvgQuestionIcon);
export default MemoSvgQuestionIcon;
