import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgCup({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={24}
            height={25}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                clipRule="evenodd"
                d="M12 16.25v0a5 5 0 01-5-5v-8h10v8a5 5 0 01-5 5z"
                stroke="#585C6F"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.112 12.306v0A7.438 7.438 0 013 5.653V5.65a1.4 1.4 0 011.4-1.4H7M16.889 12.306v0A7.438 7.438 0 0021 5.653V5.65a1.4 1.4 0 00-1.4-1.4H17M8 21.25h8"
                stroke="#585C6F"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                clipRule="evenodd"
                d="M9 21.25h6v-3H9v3z"
                stroke="#585C6F"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M12 16.25v2" stroke="#585C6F" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

const MemoSvgCup = React.memo(SvgCup);
export default MemoSvgCup;
