import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { cn } from '@bem-react/classname';
import { useOuterClick } from '@lms-elements/hooks';
import { Add, ArrowClick, LittleArrow, Pen, SearchIcon } from '@lms-elements/icons';

import { SCROLL_LENGTH } from './constant';
import { DropdownCustomCoreProps, IDropdownType, IDropdownValues } from './DropdownCustomCore.types';
import { ViewValue } from './ViewValue';

import './DropdownCustomCore.scss';

const CnDropdownCustomCore = cn('dropdown-custom-core');

export const DropdownCustomCore: React.FC<DropdownCustomCoreProps> = ({
    input,
    meta,
    isNeedSearch = false,
    searchPlaceholder = 'Поиск',
    placeholder = 'Выберите',
    type,
    needBlueIcon = false,
    withoutBorder = false,
    needHoveredIcon = false,
    hoveredIcon = <Pen />,
    customHoveredIconClass = '',
    initValue,
    options,
    customValueClass = '',
    customOptionClass = '',
    disabled = false,
    isNeedForcedOpen = false,
    isClose,
    setIsClose,
    withArrowIcons,
    isNeedShortTitle,
    titleLength,
    getValue,
    needLongOptions,
    dataCy,
}) => {
    const [isOpened, setIsOpened] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const [value, setValue] = useState<IDropdownValues | undefined>(input ? input.value : initValue);
    const [fieldOptions, setFieldOptions] = useState<IDropdownValues[]>(options);
    const [isHovered, setIsHovered] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(false);

    useEffect(() => {
        setFieldOptions(options);
    }, [options]);

    const hide = useCallback(() => !isNeedForcedOpen && setIsOpened(false), [isNeedForcedOpen]);

    useOuterClick(ref, hide);

    useEffect(() => {
        if (isNeedForcedOpen && isClose) {
            setIsOpened(false);
        }
    }, [isNeedForcedOpen, isClose]);

    useEffect(() => {
        if (!input && initValue) setValue(initValue);
    }, [initValue, input]);

    const handleValueClick = useCallback(() => {
        if (!disabled) {
            setIsOpened((prev) => !prev);

            if (isNeedForcedOpen) {
                setIsClose?.(isOpened);
            }
        }
    }, [disabled, isNeedForcedOpen, isOpened, setIsClose]);

    const handleOptionClick = useCallback(
        (val: IDropdownValues) => () => {
            setValue(val);
            setSelectedCourse(true);

            if (getValue) {
                getValue(val);
            }

            if (input) input.onChange(val);

            if (!isNeedForcedOpen) {
                setIsOpened((prev) => !prev);
            }
        },
        [isNeedForcedOpen, getValue, input],
    );

    const isNeedScroll = useMemo(() => options.length >= SCROLL_LENGTH, [options]);

    const searchHandler = useCallback(
        (evt: ChangeEvent<HTMLInputElement>) => {
            const searchValue = evt.target.value;
            setFieldOptions(options.filter((el) => el.title.toLowerCase().includes(searchValue.toLowerCase())));
        },
        [options],
    );

    const handleMouseEnter = useCallback(() => {
        setIsHovered(true);
    }, []);

    const handleMouseLeave = useCallback(() => {
        setIsHovered(false);
    }, []);

    return (
        <div ref={ref} className={CnDropdownCustomCore({ withoutBorder: withoutBorder })}>
            <div onClick={handleValueClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <div
                    className={`${CnDropdownCustomCore('value', {
                        opened: isOpened,
                        withoutBorder: withoutBorder,
                        notValid: Boolean(meta?.error),
                        disable: disabled,
                        isCourse: type === IDropdownType.course,
                        isCourseOpened: type === IDropdownType.course && isOpened,
                        isOpenedSelectedCourse: type === IDropdownType.course && isOpened && selectedCourse,
                    })} ${customValueClass}`}
                >
                    {value && type !== IDropdownType.course ? (
                        <ViewValue
                            value={value}
                            type={type}
                            isNeedShortTitle={isNeedShortTitle}
                            titleLength={titleLength}
                        />
                    ) : (
                        placeholder
                    )}
                    {((type === IDropdownType.course && !selectedCourse) || type === IDropdownType.course) && (
                        <Add className={`${CnDropdownCustomCore('add')}`} />
                    )}
                </div>

                {type !== IDropdownType.course && (
                    <div
                        className={CnDropdownCustomCore('icon', {
                            opened: isOpened,
                            blue: needBlueIcon,
                            withoutBorder: withoutBorder,
                        })}
                    >
                        <LittleArrow />
                    </div>
                )}

                {needHoveredIcon && isHovered && !isOpened && (
                    <div className={`${CnDropdownCustomCore('hovered-icon')} ${customHoveredIconClass}`}>
                        {hoveredIcon}
                    </div>
                )}
            </div>

            <div
                className={CnDropdownCustomCore('options', {
                    opened: isOpened,
                    needScroll: isNeedScroll,
                    isCourseOpened: type === IDropdownType.course && isOpened,
                    isNeedSearch,
                    needLongOptions,
                })}
            >
                {isNeedSearch && (
                    <label
                        className={CnDropdownCustomCore('search', {
                            isCourse: type === IDropdownType.course,
                        })}
                    >
                        <div
                            className={CnDropdownCustomCore('search-icon', {
                                isCourse: type === IDropdownType.course,
                            })}
                        >
                            <SearchIcon />
                        </div>
                        <input
                            spellCheck
                            onChange={searchHandler}
                            className={CnDropdownCustomCore('search-input', {
                                isCourse: type === IDropdownType.course,
                            })}
                            name="search"
                            placeholder={searchPlaceholder}
                        />
                    </label>
                )}
                <div id="scroll-bar" className={CnDropdownCustomCore('options-wrapper')}>
                    {fieldOptions.map((val, index) => (
                        <div
                            className={`${CnDropdownCustomCore('option')} ${customOptionClass}`}
                            key={val.id}
                            onClick={handleOptionClick(val)}
                            title={val.title}
                            data-cy={dataCy?.(index)}
                        >
                            <ViewValue value={val} type={type} />
                            {withArrowIcons && (
                                <div
                                    className={CnDropdownCustomCore('option-icon', {
                                        selected: val.id === value?.id,
                                        blue: needBlueIcon,
                                    })}
                                >
                                    <ArrowClick />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
