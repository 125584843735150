import React, { useCallback } from 'react';

export const useHandleCopyCut = (text: string) => {
    const handleCopyCut = useCallback(
        (e: React.ClipboardEvent<HTMLSpanElement>) => {
            e.preventDefault();
            navigator.clipboard
                .writeText(text)
                .then(() => false)
                .catch((err) => console.error(err));
        },
        [text],
    );

    return handleCopyCut;
};
