import React, { useCallback, useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { Calendar } from '@lms-elements/icons';
import { getCapitalizeMonth, getHomeworkStatusNote } from '@lms-elements/utils';

import { AssignmentLevels, HomeworkItemProps } from './HomeworkItem.types';

import './HomeworkItem.scss';

const CnHomeworkItem = cn('homework-item');
const CnCountTasks = cn('task');
const CnHasRecord = cn('record');
const CnHomeworkItemFirst = cn('first');

export const HomeworkItem: React.FC<HomeworkItemProps> = ({
    description,
    deadline,
    assignmentCount,
    taskId,
    title,
    isShort,
    isForPupils = false,
    isOnCheck = false,
    hasRecord = false,
    lessonTitle = '',
    lessonDescription = '',
    subjectId,
    lessonId,
    onHomeworkClick,
    withAttempts,
    type,
    courseGroupId,
    status,
    completeDate,
    extraInfo,
}) => {
    const isTheme = useMemo(() => Boolean(description), [description]);
    const overdue = useMemo(
        () =>
            getHomeworkStatusNote(deadline ? new Date(deadline) : new Date(), isTheme, isForPupils).includes(
                'просрочено',
            ),
        [deadline, isTheme, isForPupils],
    );

    const isCompleted = useMemo(() => status === 'completed', [status]);

    const isNeedTime = useMemo(() => (deadline && !isOnCheck) || isCompleted, [deadline, isOnCheck, isCompleted]);

    const timeLabel = useMemo(() => {
        if (isCompleted) {
            return `Сдано ${getCapitalizeMonth(completeDate)}`;
        }

        return getHomeworkStatusNote(new Date(deadline ? new Date(deadline) : new Date()), isTheme, isForPupils);
    }, [completeDate, deadline, isCompleted, isForPupils, isTheme]);

    const handleClick = useCallback(() => {
        if (onHomeworkClick && subjectId && lessonId && taskId && courseGroupId) {
            onHomeworkClick({
                subjectId,
                lessonId,
                taskId,
                groupId: courseGroupId,
            });
        }
    }, [lessonId, onHomeworkClick, courseGroupId, subjectId, taskId]);

    if (hasRecord) {
        return (
            <div className={CnHasRecord()}>
                <div
                    className={CnHomeworkItemFirst({ type_overdue: !isCompleted, completed: isCompleted })}
                    onClick={handleClick}
                >
                    <div className={CnHomeworkItemFirst('header')}>
                        <div className={CnHomeworkItemFirst('title', { isForPupils })} title={lessonTitle}>
                            {lessonTitle}
                        </div>
                    </div>
                    <div className={CnHomeworkItemFirst('theme', { lesson: true })} title={lessonDescription}>
                        {lessonDescription}
                    </div>
                    <div className={CnHomeworkItemFirst('transition')}>
                        <div className={CnHomeworkItemFirst('block-one', { completed: isCompleted })}></div>
                        <div className={CnHomeworkItemFirst('line', { completed: isCompleted })}></div>
                        <div className={CnHomeworkItemFirst('block-two')}></div>
                    </div>
                </div>
                <div
                    className={CnHomeworkItem({
                        type_overdue: overdue && !isOnCheck && !isCompleted,
                        isForPupils,
                        hasRecord,
                        completed: isCompleted,
                    })}
                >
                    <div className={CnHomeworkItem('header')}>
                        {!isForPupils && (
                            <div className={CnCountTasks({ type_overdue: overdue, teacherHomework: !isTheme })}>
                                {assignmentCount}
                            </div>
                        )}
                        <div className={CnHomeworkItem('title', { isForPupils })} title={title}>
                            {title}
                        </div>
                    </div>
                    <div
                        className={CnHomeworkItem('theme', { withoutTheme: !isTheme, isForPupils, isOnCheck })}
                        title={description}
                    >
                        {description}
                    </div>
                    {(!isOnCheck || extraInfo) && (
                        <div className={CnHomeworkItem('footer', { isForPupils })}>
                            {!isOnCheck && extraInfo?.underTheDate && (
                                <div
                                    className={CnHomeworkItem('timeLabel', { aboveExtraInfo: extraInfo?.underTheDate })}
                                >
                                    {!isShort && <Calendar className={CnHomeworkItem('icon')} />}
                                    <div className={CnHomeworkItem('date')}>{timeLabel}</div>
                                </div>
                            )}
                            {extraInfo && (
                                <div className={CnHomeworkItem('extraInfo')}>
                                    {extraInfo.IconElement && (
                                        <div className={CnHomeworkItem('icon')}>{extraInfo.IconElement}</div>
                                    )}
                                    <div className={CnHomeworkItem('extraInfo-label')}>{extraInfo.label}</div>
                                </div>
                            )}
                            {!isOnCheck && !extraInfo?.underTheDate && (
                                <div className={CnHomeworkItem('timeLabel')}>
                                    {!isShort && <Calendar className={CnHomeworkItem('icon')} />}
                                    <div className={CnHomeworkItem('date')}>{timeLabel}</div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div
            className={CnHomeworkItem({
                teacherHomework: !isTheme,
                teacherHomeworkWitchExtra: !isTheme && Boolean(extraInfo),
                type_overdue: overdue && !isOnCheck && !isCompleted,
                type_attestation:
                    (!withAttempts || type?.assignmentLevel === AssignmentLevels.ADMINISTRATIVE) && !isCompleted,
                isShort,
                isForPupils: !isOnCheck && isForPupils,
                isOnCheck,
                completed: isCompleted,
            })}
            onClick={handleClick}
        >
            <div className={CnHomeworkItem('header')}>
                {!isForPupils && (
                    <div className={CnCountTasks({ type_overdue: overdue, teacherHomework: !isTheme })}>
                        {assignmentCount}
                    </div>
                )}
                {(lessonTitle || title) && (
                    <div
                        className={CnHomeworkItem('title', { withoutTheme: !isTheme, isShort, isForPupils })}
                        title={title}
                    >
                        {lessonTitle ? lessonTitle : title}
                    </div>
                )}
            </div>
            {!isShort && (
                <div
                    className={CnHomeworkItem('theme', { withoutTheme: !isTheme, isForPupils, isOnCheck })}
                    title={description}
                >
                    {description}
                </div>
            )}
            {(isNeedTime || extraInfo) && (
                <div
                    className={CnHomeworkItem('footer', {
                        teacherHomework: !isTheme,
                        isShort,
                        isForPupils,
                        withoutBorder: !(description || lessonTitle || title || !isForPupils),
                    })}
                >
                    {isNeedTime && extraInfo?.underTheDate && (
                        <div className={CnHomeworkItem('timeLabel', { aboveExtraInfo: extraInfo?.underTheDate })}>
                            {!isShort && <Calendar className={CnHomeworkItem('icon')} />}
                            <div className={CnHomeworkItem('date')}>{timeLabel}</div>
                        </div>
                    )}
                    {extraInfo && (
                        <div className={CnHomeworkItem('extraInfo')}>
                            {extraInfo.IconElement && (
                                <div className={CnHomeworkItem('icon')}>{extraInfo.IconElement}</div>
                            )}
                            <div className={CnHomeworkItem('extraInfo-label')}>{extraInfo.label}</div>
                        </div>
                    )}
                    {isNeedTime && !extraInfo?.underTheDate && (
                        <div className={CnHomeworkItem('timeLabel')}>
                            {!isShort && <Calendar className={CnHomeworkItem('icon')} />}
                            <div className={CnHomeworkItem('date')}>{timeLabel}</div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
