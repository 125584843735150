import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgAngle({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={24}
            height={13}
            viewBox="0 0 24 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d="M.5 12.5L12 1l11.5 11.5" stroke="#252232" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

const MemoSvgAngle = React.memo(SvgAngle);
export default MemoSvgAngle;
