import React, { useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { getValueInRange } from '@lms-elements/utils';

import { MAX_PERCENT, MIN_PERCENT } from './ProgressBarClass.constants';
import { ProgressBarClassProps } from './ProgressBarClass.types';

import './ProgressBarClass.scss';

const CnProgressBarClass = cn('progressBarClass');

export const ProgressBarClass: React.FC<ProgressBarClassProps> = ({ fillingPercentage }) => {
    const checkedPercentage = useMemo(
        () => getValueInRange(MIN_PERCENT, MAX_PERCENT, fillingPercentage),
        [fillingPercentage],
    );

    return (
        <div className={CnProgressBarClass()}>
            <div className={CnProgressBarClass('bar')}>
                {checkedPercentage < 26 && (
                    <div style={{ width: `${checkedPercentage}%` }} className={CnProgressBarClass('progressRed')} />
                )}
                {checkedPercentage > 25 && checkedPercentage < 100 && (
                    <div style={{ width: `${checkedPercentage}%` }} className={CnProgressBarClass('progressOrange')} />
                )}
                {checkedPercentage == 100 && (
                    <div style={{ width: `${checkedPercentage}%` }} className={CnProgressBarClass('progressGreen')} />
                )}
            </div>
        </div>
    );
};
