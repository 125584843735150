import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgPrincipal({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={40}
            height={40}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M16.067 20.26h8.085a3.066 3.066 0 013.068 3.067v4.253c0 .622-.502 1.125-1.125 1.125h-11.97A1.123 1.123 0 0113 27.58v-4.253a3.066 3.066 0 013.067-3.067v0zM20.11 18a3 3 0 100-6 3 3 0 000 6z"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeMiterlimit={10}
            />
            <path
                d="M21.025 22.397h-1.83l-.465-2.137h2.76l-.465 2.137z"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeMiterlimit={10}
            />
            <path
                d="M19.21 22.398l-1.095 3.577a.74.74 0 00.105.6l1.613 2.123h.667l1.538-2.22c.09-.166.12-.36.075-.54l-1.118-3.54h-1.792.007z"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinejoin="round"
            />
            <rect x={0.5} y={0.5} width={39} height={39} rx={7.5} stroke="#0088E9" />
        </svg>
    );
}

const MemoSvgPrincipal = React.memo(SvgPrincipal);
export default MemoSvgPrincipal;
