import { EMAIL_PATTERN, PASSWORD_CYRILLIC_REGEXP } from './RegistrationForm.constants';
import {
    IRegistrationFormValues,
    ISignInFormValues,
    RegistrationFormErrors,
    SignInFormErrors,
} from './RegistrationForm.types';

export const validateRegistrationForm =
    (passwordMinLength: number) =>
    (values: IRegistrationFormValues): RegistrationFormErrors => {
        const errors: RegistrationFormErrors = {
            username: undefined,
            password: undefined,
            confirm: undefined,
        };

        if (!values.username) {
            errors.username = 'Пожалуйста укажите email';
        }

        if (values.username && EMAIL_PATTERN.exec(values.username) === null) {
            errors.username = 'Пожалуйста укажите корректный email';
        }

        if (!values.password) {
            errors.password = 'Пожалуйста укажите пароль';
        }

        if (values.password?.length < passwordMinLength) {
            errors.password = `Длина пароля должна быть не менее ${passwordMinLength} символов`;
        }

        if (values.password && PASSWORD_CYRILLIC_REGEXP.exec(values.password) !== null) {
            errors.password = 'Пароль не должен содержать кириллицу';
        }

        if (!values.confirm) {
            errors.confirm = 'Пожалуйста подтвердите пароль';
        }

        if (values.password !== values.confirm) {
            errors.confirm = 'Пароли не совпадают';
        }

        return errors;
    };

export const validateSignInForm = (values: ISignInFormValues): SignInFormErrors => {
    const errors: SignInFormErrors = {
        username: undefined,
        password: undefined,
    };

    if (!values.username) {
        errors.username = 'Пожалуйста укажите email';
    }

    if (values.username && EMAIL_PATTERN.exec(values.username) === null) {
        errors.username = 'Пожалуйста укажите корректный email';
    }

    if (!values.password) {
        errors.password = 'Пожалуйста укажите пароль';
    }

    return errors;
};
