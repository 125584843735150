import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgTransferredMarksIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={10}
            height={8}
            viewBox="0 0 10 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M9.5 2.694L4.957.5.5 2.79l1.441.729M9.5 2.694v2.555m0-2.555l-1.514.783M1.94 3.52l3.016 1.525 3.029-1.567M1.94 3.52v2.706l2.301.883a2 2 0 001.43.001l2.314-.884V3.477"
                stroke="#2F929A"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgTransferredMarksIcon = React.memo(SvgTransferredMarksIcon);
export default MemoSvgTransferredMarksIcon;
