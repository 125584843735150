import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Field, FieldInputProps } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { useOuterClick } from '@lms-elements/hooks';
import { composeValidators, getDateComponents, getSelectedDate } from '@lms-elements/utils';

import { ICalendarDropdownProps } from './CalendarDropdown.types';
import { DataSelect } from './DataSelect';
import { DateTimePicker } from './DateTimePicker';

import './CalendarDropdown.scss';

const CnCalendarDropdown = cn('calendarDropdown');

export const CalendarDropdown: React.FC<ICalendarDropdownProps> = ({
    fieldName,
    validators,
    placeholder,
    value,
    inactive,
    showTimePicker = true,
    showYears = false,
    startTime,
    endTime,
    stepMinutes,
    inactiveDays = 'past',
    handleScroll,
    offsetY = -1,
    initShowDatepicker = false,
    timePickerPosition = 'left',
    withScrollIntoView,
    isPaymentForm = false,
}) => {
    const [showDatepicker, setShowDatepicker] = useState(initShowDatepicker);

    const calendarRef = useRef(document.createElement('div'));

    const handleFieldClick = useCallback(() => {
        setShowDatepicker((prev) => !prev);
        if (handleScroll) handleScroll(calendarRef.current.offsetTop);
    }, [calendarRef, handleScroll]);

    const {
        month: propMonth,
        year: propYear,
        dateWithoutTime: propDay,
        time: propTime,
    } = useMemo(() => getDateComponents(value), [value]);

    const [month, setMonth] = useState(propMonth);
    const [year, setYear] = useState(propYear);
    const [day, setDay] = useState(propDay);
    const [time, setTime] = useState(propTime);

    const [isDaySelected, setIsDaySelected] = useState(false);
    const [isTimeSelected, setIsTimeSelected] = useState(false);

    const handleDateChange = useCallback(
        (input: FieldInputProps<string, HTMLElement>, value: string) => {
            const { month, year, dateWithoutTime } = getDateComponents(value);
            setDay(dateWithoutTime);
            setMonth(month);
            setYear(year);

            const newDate = getSelectedDate(value, time);

            input.onChange(newDate);
            setIsDaySelected(true);

            if (isTimeSelected || !showTimePicker) {
                setShowDatepicker(false);
                setIsTimeSelected(false);
                setIsDaySelected(false);
            }
        },
        [time, isTimeSelected, showTimePicker],
    );

    const handleTimeChange = useCallback(
        (input: FieldInputProps<string, HTMLElement>, value: string) => {
            if (!showYears) {
                setTime(value);
                const newDate = getSelectedDate(day, value);
                input.onChange(newDate);
                setIsTimeSelected(true);
            } else {
                setYear(Number(value));
            }

            if (isDaySelected) {
                setShowDatepicker(false);
                setIsTimeSelected(false);
                setIsDaySelected(false);
            }
        },
        [day, isDaySelected, showYears],
    );

    useOuterClick(calendarRef, setShowDatepicker);

    const [calendarOffset, setCalendarOffset] = useState(0);

    useEffect(() => {
        if (offsetY != -1 && showDatepicker) {
            if (40 + calendarRef.current.offsetTop - offsetY < 132) setCalendarOffset(128);
            else if (40 + calendarRef.current.offsetTop - offsetY > 288) setCalendarOffset(288);
            else {
                setCalendarOffset(40 + calendarRef.current.offsetTop - offsetY);
            }
        }
    }, [calendarRef.current.offsetTop, offsetY, showDatepicker]);

    const dateTimePickerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (withScrollIntoView && dateTimePickerRef && showDatepicker) {
            dateTimePickerRef.current?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
        }
    }, [showDatepicker, withScrollIntoView]);

    return (
        <Field name={fieldName} value={value} validate={composeValidators(...validators)}>
            {({ input, meta }) => (
                <div ref={calendarRef} className={CnCalendarDropdown({ default: offsetY === -1, isPaymentForm })}>
                    <DataSelect
                        input={input}
                        meta={meta}
                        initValue={value}
                        placeholder={placeholder}
                        inactive={inactive}
                        onFieldClick={handleFieldClick}
                        isPaymentForm={isPaymentForm}
                    />
                    {showDatepicker && (
                        <div className={CnCalendarDropdown({ buffer: offsetY != -1, timePickerPosition })}>
                            <div
                                className={CnCalendarDropdown({ calendar: offsetY != -1 })}
                                style={offsetY != -1 ? { top: `${calendarOffset}px` } : {}}
                            >
                                <DateTimePicker
                                    ref={dateTimePickerRef}
                                    month={month}
                                    year={year}
                                    selectedDay={day}
                                    selectedTime={showYears ? year.toString() : time}
                                    startTime={startTime}
                                    endTime={endTime}
                                    stepMinutes={stepMinutes}
                                    onDateSelect={handleDateChange.bind(null, input)}
                                    onTimeChange={handleTimeChange.bind(null, input)}
                                    showTimePicker={showTimePicker}
                                    showYears={showYears}
                                    inactiveDays={inactiveDays}
                                    timePickerPosition={timePickerPosition}
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </Field>
    );
};
