import React, { useEffect, useMemo, useState } from 'react';
import { useField } from 'react-final-form';
import { cn } from '@bem-react/classname';

import { usePreviouslySubmittedValue } from '../hooks/usePreviouslySubmittedValue';

import { IInsertWordsOptionProps } from './InsertWordsOption.types';

import './InsertWordsOption.scss';

const InsertWordsCn = cn('insert-word-option');

export const InsertWordsOption: React.FC<IInsertWordsOptionProps> = ({
    name,
    correctAnswers,
    insertationPart,
    disabled = false,
    isFetching = false,
    isDraft = false,
}) => {
    const { input } = useField<string>(name);

    const [fetching, setFetching] = useState<boolean>(false);

    useEffect(() => {
        if (!fetching && isFetching) setFetching(true);
    }, [fetching, isFetching]);

    const previouslySubmittedValue = usePreviouslySubmittedValue(input.value || '', isFetching, fetching, setFetching);

    const isCorrect = useMemo(
        () => Array.isArray(correctAnswers) && correctAnswers.length && input.value === correctAnswers[insertationPart],
        [correctAnswers, input.value, insertationPart],
    );

    const isWrong = useMemo(
        () => !isCorrect && previouslySubmittedValue === input.value && !isDraft,
        [input.value, isCorrect, isDraft, previouslySubmittedValue],
    );

    return (
        <span className={InsertWordsCn()}>
            <input
                spellCheck
                {...input}
                className={InsertWordsCn('input', {
                    correct: isCorrect,
                    wrong: isWrong,
                })}
                autoComplete="off"
                disabled={disabled}
            />
            <span className={InsertWordsCn('input-value')}>{input.value || ''}</span>
        </span>
    );
};
