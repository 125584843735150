import { EmbedData, ImageData, LinkData, MentionData } from '../../types/entities.types';
import { BlockTypes, CommonBlocks, EntityTypes, ListTypes } from '../../types/shared.types';

import { CustomEntityTransform, EntityMap, InlineStylesProperty } from './draftToHtml.types';

export const getBlockTag = (type: BlockTypes) => {
    switch (type) {
        case CommonBlocks.UNSTYLED:
            return 'p';
        case CommonBlocks.H1:
            return 'h1';
        case CommonBlocks.H2:
            return 'h2';
        case CommonBlocks.H3:
            return 'h3';
        case CommonBlocks.H4:
            return 'h4';
        case CommonBlocks.H5:
            return 'h5';
        case CommonBlocks.H6:
            return 'h6';
        case ListTypes.UNORDERED_LIST_ITEM:
            return 'ul';
        case ListTypes.ORDERED_LIST_ITEM:
            return 'ol';
        case CommonBlocks.BLOCKQUOTE:
            return 'blockquote';
        case CommonBlocks.CODE:
            return 'pre';
        default:
            return undefined;
    }
};

/**
 * Function returns html for text depending on inline style tags applicable to it.
 */
export const addInlineStyleMarkup = (style: InlineStylesProperty, content: string) => {
    switch (style) {
        case 'BOLD':
            return `<b>${content}</b>`;
        case 'ITALIC':
            return `<i>${content}</i>`;
        case 'UNDERLINE':
            return `<ins>${content}</ins>`;
        case 'STRIKETHROUGH':
            return `<del>${content}</del>`;
        case 'CODE':
            return `<code>${content}</code>`;
        case 'SUPERSCRIPT':
            return `<sup>${content}</sup>`;
        case 'SUBSCRIPT':
            return `<sub>${content}</sub>`;
        default:
            return content;
    }
};

/**
 * Function will return markup for Entity.
 */
export const getEntityMarkup = (
    entityMap: EntityMap,
    entityKey: string | number,
    text = '',
    customEntityTransform?: CustomEntityTransform,
) => {
    const entity = entityMap[entityKey];
    if (typeof customEntityTransform === 'function') {
        const html = customEntityTransform(entity, text);
        if (html) {
            return html;
        }
    }

    const entityType = entity.type as EntityTypes;

    switch (entityType) {
        case EntityTypes.MENTION: {
            const mentionData = entity.data as MentionData;
            return `<a href="${mentionData.url}" class="wysiwyg-mention" data-mention data-value="${mentionData.value}">${text}</a>`;
        }
        case EntityTypes.LINK: {
            const linkData = entity.data as LinkData;
            return `<a href="${linkData.url}" target="${linkData.target}">${text}</a>`;
        }
        case EntityTypes.IMAGE: {
            const imageData = entity.data as ImageData;
            if (imageData.alignment && imageData.alignment.length) {
                return `<div style="text-align:${imageData.alignment};"><img src="${imageData.src}" alt="${imageData.alt}" style="height: ${imageData.height};width: ${imageData.width}"/></div>`;
            }
            return `<img src="${imageData.src}" alt="${imageData.alt}" style="height: ${imageData.height};width: ${imageData.width}"/>`;
        }
        case EntityTypes.EMBEDDED_LINK: {
            const embedData = entity.data as EmbedData;
            return `<iframe width="${embedData.width}" height="${embedData.height}" src="${embedData.src}" frameBorder="0"></iframe>`;
        }

        default:
            return text;
    }
};
