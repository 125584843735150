import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useFormState } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { getIn } from 'final-form';

import { Markup } from '../../Markup';

import { ITableCellProps } from './TableCell.types';

import './TableCell.scss';

const CnTableCell = cn('table-content-cell');

export const TableCell: React.FC<ITableCellProps> = ({
    tableName,
    x,
    y,
    border = false,
    isResizing = false,
    isHorizontalResizing = false,
    isFocused = false,
    focusedCell,
    onClick,
    width,
    height,
    cellClassName,
    handleChangeHeight,
    handleChangeMinHeight,
}) => {
    const { values } = useFormState();

    const html = useMemo(
        () => (getIn(values, `${tableName}.html[${y}][${x}]`) as string) ?? '',
        [tableName, values, x, y],
    );

    const focused = useMemo(
        () => (!isResizing || !isHorizontalResizing) && isFocused && focusedCell.x === x && focusedCell.y === y,
        [focusedCell.x, focusedCell.y, isFocused, isHorizontalResizing, isResizing, x, y],
    );

    const handleClick = useCallback(() => onClick({ x, y }), [onClick, x, y]);

    const style = useMemo<React.CSSProperties>(() => ({ width, minHeight: height }), [width, height]);

    const cellRef = useRef<HTMLDivElement>(null);

    const textRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (cellRef.current?.offsetHeight !== height) {
            handleChangeHeight(y, Number(cellRef?.current?.offsetHeight));
        }
        if (Number(textRef.current?.offsetHeight) > 50) {
            handleChangeMinHeight(y, Number(textRef.current?.offsetHeight) + 12);
        }
    }, [
        cellRef?.current?.offsetHeight,
        textRef?.current?.offsetHeight,
        handleChangeHeight,
        handleChangeMinHeight,
        height,
        y,
    ]);

    return (
        <div
            className={CnTableCell({
                border,
                verticalResizing: isResizing,
                horizontalResizing: isHorizontalResizing,
                focused,
            })}
            key={`ContentCell${tableName}${x}_${y}`}
            onClick={handleClick}
            style={style}
            ref={cellRef}
        >
            <div className={CnTableCell('markup')}>
                {focused && (
                    <Markup
                        html={html}
                        editorName={`${tableName}.table[${y}][${x}]`}
                        name={`${tableName}.html[${y}][${x}]`}
                    />
                )}
            </div>
            <div className="table-content-text" ref={textRef}>
                <div className={cellClassName} dangerouslySetInnerHTML={{ __html: html }} />
            </div>
        </div>
    );
};
