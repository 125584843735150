import React from 'react';
import { Field } from 'react-final-form';
import { composeValidators } from '@lms-elements/utils';

import { IFieldInputCounterProps } from './FieldInputCounter.types';
import { FieldInputCounterCore } from './FieldInputCounterCore';

export const FieldInputCounter: React.FC<IFieldInputCounterProps> = ({
    isField = false,
    validators,
    name = '',
    ...props
}) =>
    isField ? (
        <Field<number> name={name} validate={composeValidators(...(validators || []))}>
            {({ input, meta }) => <FieldInputCounterCore {...props} {...input} {...meta} />}
        </Field>
    ) : (
        <FieldInputCounterCore {...props} value={props.value || 0} onChange={props.onChange || (() => undefined)} />
    );
