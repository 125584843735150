import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FieldInputProps, useField } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { Basket } from '@lms-elements/icons';
import debounce from 'lodash.debounce';

import { IExpandedImageMaterialProps, IImageValues } from './ExpandedImageMaterial.types';
import { ImageItem } from './ImageItem';
import { UploadDialog } from './UploadDialog';

import './ExpandedImageMaterial.scss';

const ExpandedImageMaterialCn = cn('expanded-image-material');

export const ExpandedImageMaterial: React.FC<IExpandedImageMaterialProps> = ({
    name,
    onDelete,
    onUploadImage,
    onDeleteImage,
    showForStudents = false,
}) => {
    const { input, meta } = useField<IImageValues>(name);

    const validate = useCallback((value: string) => {
        if (!value.length) {
            return 'error';
        }
        return undefined;
    }, []);

    const [isError, setIsError] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const onBlurDescriptionInput = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;
            setIsFocused(false);

            if (!value || validate(value)) {
                setIsError(true);
            } else {
                setIsError(false);
            }
        },
        [validate],
    );

    const handleInputFocus = useCallback(() => {
        setIsFocused(true);
        setIsError(false);
    }, []);

    const changeInput = useRef(
        debounce(
            (input: FieldInputProps<IImageValues, HTMLElement>, description: string) =>
                input.onChange({
                    ...input.value,
                    imageData: {
                        ...input.value.imageData,
                        description,
                    },
                }),
            500,
        ),
    );

    const handleInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const description = event.target.value;

            changeInput.current(input, description);
        },
        [input],
    );

    const imagesData = useMemo(() => input.value.imageData.data, [input]);

    return (
        <div>
            {!showForStudents && <h3 className={ExpandedImageMaterialCn('title')}>Изображение</h3>}
            <div className={ExpandedImageMaterialCn({ 'student-view': showForStudents, error: !meta.valid })}>
                <div className={ExpandedImageMaterialCn('row')}>
                    {imagesData.map((_, index) => (
                        <div key={index} className={ExpandedImageMaterialCn('row-item')}>
                            <ImageItem
                                name={name}
                                index={index}
                                showForStudents={showForStudents}
                                onDeleteImage={onDeleteImage}
                            />
                        </div>
                    ))}
                    {!showForStudents && <UploadDialog name={name} onUpload={onUploadImage} />}
                </div>
                {!showForStudents && (
                    <button type="button" onClick={onDelete} className={ExpandedImageMaterialCn('delete')}>
                        <Basket />
                    </button>
                )}
                {!showForStudents && !!imagesData.length && (
                    <input
                        className={ExpandedImageMaterialCn('input', {
                            noBorders: true,
                            oneBorderFocus: isFocused,
                            oneBorderNotValid: isError,
                        })}
                        spellCheck
                        defaultValue={input.value.imageData.description}
                        placeholder="Введите описание изображения"
                        onChange={handleInputChange}
                        onBlur={onBlurDescriptionInput}
                        onFocus={handleInputFocus}
                        disabled={showForStudents}
                    />
                )}
                {showForStudents && (
                    <p className={ExpandedImageMaterialCn('description')}>{input.value.imageData.description}</p>
                )}
            </div>
        </div>
    );
};
