import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { cn } from '@bem-react/classname';
import { useOuterClick } from '@lms-elements/hooks';

import { Avatar } from '../Avatar';
import { ExtendedTooltip } from '../ExtendedTooltip';
import { PointerPosition } from '../Tooltip';

import { IAvatarWithTooltipProps } from './AvatarWithTooltip.types';
import { TooltipItem } from './TooltipItem';

import './AvatarWithTooltip.styles.scss';

const cnAvatarWithTooltip = cn('avatar-with-tooltip');

export const AvatarWithTooltip: React.FC<IAvatarWithTooltipProps> = ({
    avatarSource,
    tooltipItems = [],
    avatarAltText,
    name,
    onAvatarClick,
    customClassName = '',
    tooltipClassName = '',
    label,
}) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const avatarRef = useRef(null);

    const handleAvatarClick = useCallback(() => {
        setShowTooltip((prev) => !prev);

        onAvatarClick?.();
    }, [onAvatarClick]);

    useOuterClick(avatarRef, setShowTooltip);

    useEffect(() => {
        const hideTooltipByEsc = (evt: KeyboardEvent) => {
            if (evt.key === 'Escape') {
                setShowTooltip(false);
            }
        };

        if (showTooltip) {
            document.addEventListener('keydown', hideTooltipByEsc);
        }

        return () => {
            document.removeEventListener('keydown', hideTooltipByEsc);
        };
    }, [showTooltip]);

    const withLabel = useMemo(() => Boolean(label), [label]);

    return (
        <div className={`${customClassName} ${cnAvatarWithTooltip({ withLabel: Boolean(label) })}`} ref={avatarRef}>
            <Avatar
                name={label}
                source={avatarSource}
                alt={avatarAltText}
                onAvatarClick={handleAvatarClick}
                isSelected={showTooltip}
                customClassName={cnAvatarWithTooltip('avatar', { withLabel })}
            />
            {showTooltip && (
                <ExtendedTooltip
                    pointerPosition={withLabel ? PointerPosition.LEFT : PointerPosition.RIGHT}
                    customWrapperClass={`${tooltipClassName} ${cnAvatarWithTooltip('tooltip-wrapper', { withLabel })}`}
                    customContentClass={cnAvatarWithTooltip('tooltip-content')}
                >
                    {name && (
                        <div className={cnAvatarWithTooltip('user-name')} title={name}>
                            {name}
                        </div>
                    )}
                    {tooltipItems.map((item, index) => (
                        <TooltipItem {...item} key={index} />
                    ))}
                </ExtendedTooltip>
            )}
        </div>
    );
};
