import { useCallback, useMemo, useState } from 'react';

export const useRefForDrag = (
    containerElement: HTMLElement | null,
): { element: HTMLElement | null; handleContainerFocus: () => void; handleContainerBlur: () => void } => {
    // Фикс бага, когда в firefox не работает выделение текста и перемещение курсора.
    // Когда в материале идет работа с текстом, отключить dnd.
    const [isContainerFocused, setIsContainerFocused] = useState(false);
    const refForDrag = useMemo(() => {
        if (isContainerFocused) {
            return null;
        }
        return containerElement;
    }, [isContainerFocused, containerElement]);

    const handleContainerFocus = useCallback(() => {
        setIsContainerFocused(true);
    }, []);

    const handleContainerBlur = useCallback(() => {
        setIsContainerFocused(false);
    }, []);

    return {
        element: refForDrag,
        handleContainerFocus,
        handleContainerBlur,
    };
};
