import React, { useCallback, useEffect, useState } from 'react';
import { cn } from '@bem-react/classname';
import { deepEqual } from '@lms-elements/utils';

import { Input } from '../Input';

import { BLUR_KEY_NAME, EMPTY_ITEM, NEW_LINE_KEY_NAME } from './InputListCore.constants';
import { IInputItem, IInputListCoreProps } from './InputListCore.types';
import {
    addInputItemToList,
    checkAndFixInputList,
    convertInputListToItems,
    convertItemsToInputList,
    filterAndFixItems,
    validateItem,
} from './InputListCore.utils';

import './InputListCore.scss';

const CnInputList = cn('inputList');

export const InputListCore: React.FC<IInputListCoreProps> = ({ items, onChange, placeholder, error }) => {
    const [inputList, setInputList] = useState<IInputItem[]>([]);
    const [currentFocusedInputIndex, setCurrentFocusedInputIndex] = useState<number | null>(null);

    const makeHandleInputRender = useCallback(
        (inputIndex) => (input: HTMLTextAreaElement | null) => {
            if (currentFocusedInputIndex === null) {
                input?.blur();
            }
            if (inputIndex === currentFocusedInputIndex) {
                input?.focus();
            }
        },
        [currentFocusedInputIndex],
    );

    const handleUpdateInputs = useCallback(() => {
        onChange(convertInputListToItems(inputList));
        setCurrentFocusedInputIndex(null);
    }, [inputList, onChange]);

    const handleNewLineAdd = useCallback(() => {
        if (currentFocusedInputIndex === null) {
            return;
        }

        const newFocusedInputIndex = currentFocusedInputIndex + 1;

        setInputList((oldInputList) => {
            const currentInputItem = oldInputList[currentFocusedInputIndex];
            if (!validateItem(currentInputItem.label)) {
                return oldInputList;
            }

            setCurrentFocusedInputIndex((oldFocusedItemIndex) =>
                oldFocusedItemIndex === null ? oldFocusedItemIndex : newFocusedInputIndex,
            );

            return addInputItemToList(oldInputList, EMPTY_ITEM, newFocusedInputIndex);
        });
    }, [currentFocusedInputIndex]);

    const handleInputKeyDown = useCallback(
        (event: React.KeyboardEvent) => {
            if (event.key === NEW_LINE_KEY_NAME) {
                event.preventDefault();
                return handleNewLineAdd();
            }
            if (event.key === BLUR_KEY_NAME) {
                handleUpdateInputs();
            }
        },
        [handleUpdateInputs, handleNewLineAdd],
    );

    const makeHandleInputFocus = useCallback(
        (inputIndex: number) => () => {
            setCurrentFocusedInputIndex(inputIndex);
        },
        [],
    );

    const makeHandleInputBlur = useCallback(
        (inputIndex: number) => () => {
            const isCurrentInputValid = validateItem(inputList[inputIndex].label);
            if (!isCurrentInputValid) {
                handleUpdateInputs();
            }
        },
        [handleUpdateInputs, inputList],
    );

    const makeHandleInputChange = useCallback(
        (inputIndex: number) => (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            const newItems = [...inputList];
            newItems[inputIndex].label = event.target.value;

            if (validateItem(event.target.value)) {
                onChange(convertInputListToItems(newItems));
            } else {
                setInputList(newItems);
            }
        },
        [inputList, onChange],
    );

    const getInputHasPlaceholder = useCallback(
        (inputIndex: number) => {
            const isHasItems = inputList.reduce((accumulator, { label }) => accumulator || Boolean(label), false);
            const isInputFirst = inputIndex === 0;
            return placeholder && !isHasItems && isInputFirst;
        },
        [inputList, placeholder],
    );

    useEffect(() => {
        const filteredItems = filterAndFixItems(items);
        const isOldItemsEqualToFiltered = deepEqual(filteredItems, items);

        setInputList((oldInputList) => checkAndFixInputList(convertItemsToInputList(filteredItems, oldInputList)));

        if (!isOldItemsEqualToFiltered) {
            onChange(filteredItems);
        }
    }, [items, onChange]);

    return (
        <div className={CnInputList()}>
            <div className={CnInputList('field')}>
                <ul className={CnInputList('list')}>
                    {inputList.map(({ label, id }, index) => (
                        <li
                            key={id}
                            className={CnInputList('listItem', { isSecondary: getInputHasPlaceholder(index) })}
                        >
                            <Input
                                value={label}
                                placeholder={getInputHasPlaceholder(index) ? placeholder : undefined}
                                onRender={makeHandleInputRender(index)}
                                onBlur={makeHandleInputBlur(index)}
                                onChange={makeHandleInputChange(index)}
                                onFocus={makeHandleInputFocus(index)}
                                onKeyDown={handleInputKeyDown}
                            />
                        </li>
                    ))}
                </ul>
            </div>
            {error && <p className={CnInputList('errorMessage')}>{error}</p>}
        </div>
    );
};
