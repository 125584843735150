import React from 'react';
import { decreaseDate, getDiffBetweenDates } from '@lms-elements/utils';

import { INewNotificationsProps, NewNotifications } from './NewNotifications';
import { GROUP_DATE_FORMAT } from './NotificationTooltip.constants';
import { INotification, INotificationGroup, NotificationPage, PropsByPage } from './NotificationTooltip.types';
import { IViewedNotificationsProps, ViewedNotifications } from './ViewedNotifications';

export const sortNotificationsByImportance = <Notification extends INotification>(
    notifications: Notification[],
): Notification[] =>
    [...notifications].sort(
        (prevNotification, nextNotification) =>
            Number(!!nextNotification.notificationMessageProps.isImportant) -
            Number(!!prevNotification.notificationMessageProps.isImportant),
    );

export const sortNotificationsByDate = <Notification extends INotification>(
    notifications: Notification[],
): Notification[] =>
    [...notifications].sort(({ date: prevDate }, { date: nextDate }) => getDiffBetweenDates(nextDate, prevDate));

export const convertToNotificationGroups = <Notification extends INotification>(
    notifications: Notification[],
): INotificationGroup<Notification>[] => {
    const groupedNotifications = notifications.reduce<Record<string, Notification[]>>((accumulator, notification) => {
        const notificationFormattedDate = decreaseDate({ date: notification.date, format: GROUP_DATE_FORMAT });
        return Object.assign(accumulator, {
            [notificationFormattedDate]: [...(accumulator[notificationFormattedDate] ?? []), notification],
        });
    }, {});

    return Object.entries(groupedNotifications).map(([title, notificationsInGroup]) => ({
        title,
        notifications: notificationsInGroup,
    }));
};

export const getNotificationSortedGroups = <Notification extends INotification>(
    notifications: Notification[],
): INotificationGroup<Notification>[] =>
    convertToNotificationGroups(sortNotificationsByDate(notifications)).map((group) => ({
        ...group,
        notifications: sortNotificationsByImportance(group.notifications),
    }));

export const getPropsByPage = ({
    page,
    newNotificationProps,
    viewedNotificationProps,
    notificationsTitle,
}: {
    page: NotificationPage;
    newNotificationProps: INewNotificationsProps;
    viewedNotificationProps: IViewedNotificationsProps;
    notificationsTitle?: string;
}): PropsByPage[typeof page] =>
    ({
        [NotificationPage.new]: {
            notificationsElement: <NewNotifications {...newNotificationProps} />,
            hasCloseButton: true,
            hasReturnButton: false,
            title: `${notificationsTitle ? `${notificationsTitle} ` : ''} ${
                newNotificationProps.notificationGroups[0]?.title ?? decreaseDate({ format: GROUP_DATE_FORMAT })
            }`,
        },
        [NotificationPage.viewed]: {
            notificationsElement: <ViewedNotifications {...viewedNotificationProps} />,
            hasCloseButton: false,
            hasReturnButton: true,
            title: 'Архив - просмотренные',
        },
    }[page]);
