import moment from 'moment';

import { TimeStatus } from './';

export const getTimeStatus = (start: Date | string, end: Date | string): TimeStatus => {
    const lateTimeStart = moment(start).add(15, 'm').format();

    const isBefore = moment().isBefore(start);
    const isPresent = moment().isBetween(start, lateTimeStart);
    const isLate = moment().isBetween(lateTimeStart, end);

    if (isBefore) {
        return 'future';
    }

    if (isPresent) {
        return 'present';
    }

    if (isLate) {
        return 'late';
    }

    return 'past';
};
