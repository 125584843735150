import React from 'react';
import { Field } from 'react-final-form';
import { composeValidators } from '@lms-elements/utils';

import { IRadioFieldProps } from './RadioField.types';
import { RadioFieldCore } from './RadioFieldCore';

export const RadioField = React.forwardRef<HTMLInputElement, IRadioFieldProps>(
    ({ isField = true, validators = [], name = '', ...props }, ref) =>
        isField ? (
            <Field<string> type="radio" name={name} value={props.value} validate={composeValidators(...validators)}>
                {({ input, meta }) => (
                    <RadioFieldCore {...props} {...input} error={!!meta.error && meta.touched} ref={ref} />
                )}
            </Field>
        ) : (
            <RadioFieldCore {...props} ref={ref} />
        ),
);
