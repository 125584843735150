import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgCamDisabled({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={23}
            height={23}
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M14.334 14.777a4.061 4.061 0 01-1.361.912 4.184 4.184 0 01-3.244-.032c-.509-.22-.965-.54-1.34-.938a3.888 3.888 0 01-.841-1.378 3.788 3.788 0 01.275-3.12c.266-.474.63-.89 1.07-1.221"
                stroke="#C0C2C9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.334 20H3.04c-.452 0-.886-.176-1.207-.488-.32-.313-.5-.737-.5-1.179V6.667c0-.442.18-.866.5-1.179C2.153 5.176 2.588 5 3.04 5h2.328M7.333 2.31L7.55 2h6.891l1.723 2.465h3.447c.456 0 .895.173 1.218.482.323.308.505.726.505 1.162V17M1.333 1l20 21"
                stroke="#C0C2C9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgCamDisabled = React.memo(SvgCamDisabled);
export default MemoSvgCamDisabled;
