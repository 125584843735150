import React, { useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { useWindowResize } from '@lms-elements/hooks';
import {
    checkIsAfter,
    checkIsBefore,
    getDateComponents,
    getDayOfMonthTitle,
    getWeekday,
    increaseDate,
} from '@lms-elements/utils';

import { DayOnCalendarProps } from './DayOnCalendar.types';

import './DayOnCalendar.scss';

const CnDayOnCalendar = cn('dayOnCalendar');

const createCalendarLessonLabelStab = (count: number) => `${count} занятие(ий)`;
const createMeetingLabelStab = (count: number) => `${count} собрание(ий)`;
const createTaskLabelStab = (count: number) => `${count} ДЗ`;

export const DayOnCalendar: React.FC<DayOnCalendarProps> = ({
    date,
    lessonsCount,
    meetingsCount,
    taskData,
    isSelected,
    isStudent,
    createCalendarLessonLabel = createCalendarLessonLabelStab,
    createMeetingLabel = createMeetingLabelStab,
    createTaskLabel = createTaskLabelStab,
}) => {
    const isTablet = useWindowResize();

    const isWeekend = useMemo(() => {
        return getDateComponents(date).weekday === 5 || getDateComponents(date).weekday === 6;
    }, [date]);

    const status = useMemo(() => {
        const tommorowDate = increaseDate({ amount: 24, unit: 'h' });
        const isBefore = checkIsBefore(date, new Date(), 'day');
        const isAfter = checkIsAfter(date, tommorowDate);

        if (isBefore) {
            return 'past';
        }

        if (isAfter) {
            return 'future';
        }

        return 'present';
    }, [date]);

    const dateTitle = useMemo(() => getDayOfMonthTitle(date), [date]);

    const weekday = useMemo(() => getWeekday(date), [date]);

    return (
        <div className={CnDayOnCalendar({ isWeekend, isSelected, status })}>
            <div className={CnDayOnCalendar('date')}>
                <span className={CnDayOnCalendar('dateTitle')}>{dateTitle}</span>
                {weekday && <span>{` - ${weekday}`}</span>}
            </div>
            <ul className={CnDayOnCalendar('list')}>
                {lessonsCount > 0 && (
                    <li className={CnDayOnCalendar('listItem')}>
                        <span>
                            {isTablet ? `${lessonsCount} занят.` : `${createCalendarLessonLabel(lessonsCount)}`}
                        </span>
                    </li>
                )}
                {meetingsCount > 0 && (
                    <li className={CnDayOnCalendar('listItem')}>
                        <span>{isTablet ? `${meetingsCount} собр.` : `${createMeetingLabel(meetingsCount)}`}</span>
                    </li>
                )}
                {taskData && taskData.assigned > 0 && (
                    <li className={CnDayOnCalendar('listItem')}>
                        <span>{`${taskData.assigned} дз`} задано</span>
                    </li>
                )}
                {taskData && taskData.deadline > 0 && (
                    <li className={CnDayOnCalendar('listItem')}>
                        <span title={`${createTaskLabel(taskData.deadline)} ${isStudent ? 'выполнить' : 'проверить'}`}>
                            {taskData.deadline} дз {isStudent ? 'выполнить' : 'проверить'}
                        </span>
                    </li>
                )}
            </ul>
        </div>
    );
};
