import moment from 'moment';

import { capitalizeWord, getDayOfMonth } from './';

export const getWeekday = (date: Date | string): string | undefined => {
    const startDateWeekday = moment(date).startOf('month').weekday();
    const countDaysToWeekEnd = 7 - startDateWeekday;
    const monthDay = getDayOfMonth(date);

    if (monthDay <= countDaysToWeekEnd) {
        const result = moment(date).format('dd');

        return capitalizeWord(result, 1);
    }
};
