import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgEditFile({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={26}
            height={26}
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M9.85 21.125H5.63a.755.755 0 01-.755-.756v-4.22a.756.756 0 01.221-.533L16.428 4.284a.755.755 0 011.068 0l4.22 4.22a.755.755 0 010 1.068L10.384 20.904a.755.755 0 01-.534.221zM14.625 7.313l4.063 4.062M9.75 21.125L4.875 16.25"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgEditFile = React.memo(SvgEditFile);
export default MemoSvgEditFile;
