import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgPasswordHidden({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={19}
            height={14}
            viewBox="0 0 19 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M18.87 6.578C18.7 6.331 14.646.575 9.5.575a8.47 8.47 0 00-4.06 1.136L4 .215A.694.694 0 003.503 0a.693.693 0 00-.499.213.743.743 0 00-.207.516c0 .193.073.379.205.516L4.222 2.49A18.347 18.347 0 00.13 6.578a.746.746 0 000 .845c.17.247 4.223 6.003 9.37 6.003a8.5 8.5 0 004.12-1.168l1.478 1.529a.692.692 0 00.498.213.692.692 0 00.497-.213.741.741 0 00.207-.516.742.742 0 00-.207-.515l-1.238-1.285a18.522 18.522 0 004.014-4.048.745.745 0 000-.845zM5.713 4.055l2.28 2.363a1.688 1.688 0 00.367 1.762c.302.312.712.488 1.139.488.191 0 .381-.038.56-.11l2.276 2.367a4.598 4.598 0 01-5.331.252 4.843 4.843 0 01-1.749-1.982 5.047 5.047 0 01.458-5.14zm7.614 5.825L11.031 7.5c.093-.291.106-.603.038-.9a1.669 1.669 0 00-.424-.788 1.584 1.584 0 00-.76-.44 1.546 1.546 0 00-.87.038L6.716 3.032a4.621 4.621 0 013.16-.926A4.669 4.669 0 0112.86 3.53a4.976 4.976 0 011.37 3.09 5.023 5.023 0 01-.902 3.268V9.88zM1.594 7c.663-.84 1.395-1.62 2.189-2.33a6.49 6.49 0 000 4.66A18.481 18.481 0 011.594 7zm13.623 2.33a6.49 6.49 0 000-4.66A18.48 18.48 0 0117.406 7a18.237 18.237 0 01-2.189 2.33z"
                fill="#0088E9"
            />
        </svg>
    );
}

const MemoSvgPasswordHidden = React.memo(SvgPasswordHidden);
export default MemoSvgPasswordHidden;
