import React, { useCallback, useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { Cam, CamDisabled, Mic, MicDisabled, UserCircle, UserCircleDisabled } from '@lms-elements/icons';

import { getMeta } from './Toolptip.utils';
import { ITooltipProps, PointerPosition, Position, TooltipViewEnum } from './Tooltip.types';

import './Tooltip.scss';

const CnTooltip = cn('tooltip');

const AVARAGE_COMMENT = 'Средневзвешенный балл:';

export const Tooltip: React.FC<ITooltipProps> = ({
    children,
    view = TooltipViewEnum.comment,
    metaData,
    approved,
    average,
    attandanceData,
    pointerPosition = PointerPosition.CENTER,
    pointerSide = Position.bottom,
    commentLabel,
    filesLabel = 'Прикрепленные файлы:',
    files,
    withPointer = true,
    isError = false,
    listData,
    author,
}) => {
    const showMeta = useMemo(
        () =>
            view === TooltipViewEnum.detailed ||
            view === TooltipViewEnum.semester ||
            view === TooltipViewEnum.period ||
            view === TooltipViewEnum.attendance,
        [view],
    );

    const meta = useMemo(() => getMeta(view, metaData), [view, metaData]);

    const handleMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    }, []);

    return (
        <div className={CnTooltip({ view, error: isError, isList: Boolean(listData) })} onMouseDown={handleMouseDown}>
            {showMeta && meta !== '' && <div className={CnTooltip('meta-data')}>{meta}</div>}
            {author && (
                <div className={CnTooltip('authorLabel')}>
                    Поставил оценку:
                    <div className={CnTooltip('author')}>{author}</div>
                </div>
            )}
            {commentLabel && <div className={CnTooltip('comment-label')}>{commentLabel}</div>}
            {view !== TooltipViewEnum.semester && <div className={CnTooltip('comment')}>{children}</div>}
            {view === TooltipViewEnum.period && (
                <>
                    <div className={CnTooltip('approved')}>
                        {approved ? 'Утвержденная оценка' : 'Предварительная оценка'}
                    </div>
                    <div className={CnTooltip('flex')}>
                        <div className={CnTooltip('value-title')}>{AVARAGE_COMMENT}</div>
                        <div className={CnTooltip('value')}>{average}</div>
                    </div>
                </>
            )}
            {view === TooltipViewEnum.semester && (
                <div className={CnTooltip('footnote')}>формула по которой рассчитывается оценка за период</div>
            )}
            {view === TooltipViewEnum.attendance && attandanceData && (
                <div className={CnTooltip('attendance-data')}>
                    <div className={CnTooltip('device')}>
                        {attandanceData.isCameraDisabled ? <CamDisabled /> : <Cam />}
                        <span className={CnTooltip('device-description')}>{attandanceData.cameraDescription}</span>
                    </div>
                    <div className={CnTooltip('device')}>
                        {attandanceData.isMicDisabled ? <MicDisabled /> : <Mic />}
                        <span className={CnTooltip('device-description')}>{attandanceData.micDescription}</span>
                    </div>
                    <div className={CnTooltip('device')}>
                        {attandanceData.isUserDisabled ? <UserCircleDisabled /> : <UserCircle />}
                        <span className={CnTooltip('device-description')}>{attandanceData.userDescription}</span>
                    </div>
                </div>
            )}
            {view === TooltipViewEnum.list && listData && (
                <div className={CnTooltip('tooltipList')}>
                    {listData.title && <div className={CnTooltip('tooltipList-title')}>{listData.title}</div>}
                    {listData?.list.map((item) => (
                        <div className={CnTooltip('tooltipList-row')} key={item.text}>
                            <div className={CnTooltip('tooltipList-circle', { isValid: item.isValid })}></div>
                            <div className={CnTooltip('tooltipList-text')}>{item.text}</div>
                        </div>
                    ))}
                </div>
            )}
            {!!files?.length && (
                <div className={CnTooltip('files-container')}>
                    <div className={CnTooltip('files-label')}>{filesLabel}</div>
                    <div className={CnTooltip('files-wrapper')}>
                        {files.map((file) => (
                            <a
                                key={file.url}
                                className={CnTooltip('file-link')}
                                href={file.url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {file.title}
                            </a>
                        ))}
                    </div>
                </div>
            )}
            {withPointer && <div className={CnTooltip('pointer', { pointerPosition, pointerSide, error: isError })} />}
        </div>
    );
};
