import React, { useCallback } from 'react';
import { cn } from '@bem-react/classname';

import { StatusLabelEnum } from '../Dropdown.types';
import { statusIcon } from '../utils';

import { IDropdownOptionProps } from './DropdownOption.types';

import './DropdownOption.scss';

const CnDropdownOption = cn('dropdownOption');

export const DropdownOption: React.FC<IDropdownOptionProps> = ({ status, isSelected, lateTime, handleOptionClick }) => {
    const isLate = status === StatusLabelEnum.isLate;

    const handleOptionLabelClick = useCallback(() => {
        if (!isLate && handleOptionClick) handleOptionClick(status);
    }, [handleOptionClick, isLate, status]);

    return (
        <div className={CnDropdownOption()}>
            <div className={CnDropdownOption('optionLabel', { isSelected, isLate })} onClick={handleOptionLabelClick}>
                <div className={CnDropdownOption('statusIcon', { isLate })}>{statusIcon(status)}</div>

                <div className={CnDropdownOption('label')}>{status}</div>
            </div>

            {isLate && (
                <ul className={CnDropdownOption('lateTimesList')}>
                    {[5, 10, 15, 20, 25, 30].map((time, index) => (
                        <li
                            key={String(index)}
                            className={CnDropdownOption('lateTimeListItem', {
                                isSelected: isSelected && lateTime === time,
                            })}
                            onClick={() => {
                                if (handleOptionClick) handleOptionClick(status, time);
                            }}
                        >
                            {index ? time : `${time} мин`}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
