import React from 'react';
import { useDrag } from 'react-dnd';
import { CodifierElement, CodifierElementIconEnum } from '@lms-elements/atomic';

import { MINIMIZED_DESC, MINIMIZED_TITLE } from './CONSTANTS';
import { IMinimizedImageMaterialProps, MIN_IMAGE_MATERIAL_DRAG_TYPE } from './MinimizedImageMaterial.types';

const MinimizedImageMaterial: React.FC<IMinimizedImageMaterialProps> = React.memo(({ onDragEnd, disabled = false }) => {
    const [{ isDragging }, drag] = useDrag({
        type: MIN_IMAGE_MATERIAL_DRAG_TYPE,
        item: () => ({ name: 'IMAGE_MATERIAL' }),
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (item && dropResult && onDragEnd) {
                onDragEnd();
            }
        },
        collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    });

    return (
        <CodifierElement
            title={MINIMIZED_TITLE}
            description={MINIMIZED_DESC}
            icon={CodifierElementIconEnum.image}
            dragging={isDragging}
            dragRef={drag}
            disabled={disabled}
        />
    );
});

export default MinimizedImageMaterial;
