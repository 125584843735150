import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgTextIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={26}
            height={18}
            viewBox="0 0 26 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M15.851 1h9.14M1 1h9.139M15.852 6.333h9.139M1 6.333h9.139M1.01 17H25M1.01 11.667H25"
                stroke="#0088E9"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgTextIcon = React.memo(SvgTextIcon);
export default MemoSvgTextIcon;
