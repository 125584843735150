import moment from 'moment';

export const getHomeworkStatusNote = (date: Date, hasTitle: boolean, isForPupils: boolean): string => {
    const time = moment(date).fromNow();
    return time.includes('назад')
        ? `просрочено на ${time.split(' ').slice(0, -1).join(' ')}`
        : isForPupils
        ? `еще ${time.split(' ').slice(1).join(' ')} до срока сдачи`
        : hasTitle
        ? `еще ${time.split(' ').slice(1).join(' ')} на проверку`
        : `Проверить до ${moment(date).format('D MMMM')}`;
};
