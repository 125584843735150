import React from 'react';
import { cn } from '@bem-react/classname';

import { Mark } from '../../Mark';

import { IMarksListProps } from './MarksList.types';

import './MarksList.scss';

const CnMarksList = cn('marksList');

export const MarksList: React.FC<IMarksListProps> = ({ marks }) => (
    <ul className={CnMarksList()}>
        {marks.map((mark) => (
            <li key={mark.markId}>
                <Mark {...mark} />
            </li>
        ))}
    </ul>
);
