import React, { useMemo } from 'react';
import { useField } from 'react-final-form';
import { cn } from '@bem-react/classname';

import { ExpandedExerciseMaterialEmpty } from './Empty/ExpandedExerciseMaterialEmpty';
import { ExpandedExerciseMaterialFull } from './Full/ExpandedExerciseMaterialFull';
import { IExerciseData } from './Full/ExpandedExerciseMaterialFull.types';
import { IExpandedExerciseMaterialProps } from './ExpandedExerciseMaterial.types';

import './ExpandedExerciseMaterial.scss';

const cnExercise = cn('expanded-exercise-material');

export const ExpandedExerciseMaterial: React.FC<IExpandedExerciseMaterialProps> = ({ name, ...props }) => {
    const { input, meta } = useField<IExerciseData>(name);

    const isEmpty = useMemo(() => !input.value?.exerciseData?.isFull, [input]);

    if (isEmpty && !props.isStudentView) {
        return (
            <div>
                <h3 className={cnExercise('title')}>Упражнение - вопрос</h3>
                <ExpandedExerciseMaterialEmpty {...props} error={!meta.valid} />
            </div>
        );
    }

    if (isEmpty) {
        return (
            <div>
                <h3 className={cnExercise('title')}>Упражнение без вопроса. Повторите материалы выше.</h3>
            </div>
        );
    }

    return (
        <div>
            {!props.isStudentView && <h3 className={cnExercise('title')}>Упражнение - вопрос</h3>}
            <ExpandedExerciseMaterialFull name={name} {...props} />
        </div>
    );
};
