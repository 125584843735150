import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgCalendar({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={16}
            height={17}
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M13 2.75H3a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h10a.5.5 0 00.5-.5v-10a.5.5 0 00-.5-.5zM11 1.75v2M5 1.75v2M2.5 5.75h11"
                stroke="#4C5862"
                strokeWidth={1.1}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgCalendar = React.memo(SvgCalendar);
export default MemoSvgCalendar;
