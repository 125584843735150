import React, { useCallback, useMemo } from 'react';
import { useField } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { Button, ButtonViewEnum } from '@lms-elements/atomic';
import { Basket } from '@lms-elements/icons';
import { Answer } from '@lms-elements/test-task';

import { IExerciseData, IExpandedExerciseMaterialFullProps } from './ExpandedExerciseMaterialFull.types';

import './ExpandedExerciseMaterialFull.scss';

const CnExerciseMaterialFull = cn('expanded-exercise-material-full');

export const ExpandedExerciseMaterialFull: React.FC<IExpandedExerciseMaterialFullProps> = ({
    name,
    isStudentView,
    questionClassName = '',
    optionsClassName = '',
    onDelete,
    onCheckAnswer,
    onChangeQuestion,
    onEditQuestion,
    isFetching = false,
}) => {
    const { input } = useField<IExerciseData>(name);
    const currentAnswer = useMemo(() => input.value.exerciseData.answer || [], [input?.value?.exerciseData]);

    const handleAnswerCheck = useCallback(() => {
        if (onCheckAnswer) {
            onCheckAnswer(input.value.exerciseData);
        }
    }, [input, onCheckAnswer]);

    return (
        <div className={CnExerciseMaterialFull({ 'student-view': isStudentView })}>
            {isStudentView && <h3 className={CnExerciseMaterialFull('title')}>Вопрос</h3>}
            <Answer
                name={`${name}.exerciseData`}
                questionClassName={questionClassName}
                optionsClassName={optionsClassName}
                needValidation={false}
                correctAnswers={input.value?.exerciseData?.correctAnswers}
                isFetching={isFetching}
                disabled={!isStudentView}
                withEditor
                randomOrder={input.value?.exerciseData?.randomOrder}
            />
            <div className={CnExerciseMaterialFull('button-block')}>
                {isStudentView ? (
                    <Button
                        size="m"
                        view={ButtonViewEnum.action}
                        onClick={handleAnswerCheck}
                        disabled={currentAnswer.length === 0}
                    >
                        Проверить
                    </Button>
                ) : (
                    <>
                        <Button size="m" view={ButtonViewEnum.action} onClick={onChangeQuestion}>
                            Заменить
                        </Button>
                        <Button size="m" view={ButtonViewEnum.bordered} onClick={onEditQuestion}>
                            Редактировать
                        </Button>
                        <button type="button" onClick={onDelete} className={CnExerciseMaterialFull('delete')}>
                            <Basket />
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};
