import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgComment({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={10}
            height={9}
            viewBox="0 0 10 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d="M2.395 6.357L.685 8.903A.419.419 0 010 8.588V.412A.41.41 0 01.122.12.42.42 0 01.417 0h9.166a.42.42 0 01.295.12.41.41 0 01.122.292v5.464a.41.41 0 01-.122.292.42.42 0 01-.295.12H2.587l-.192.07z" />
        </svg>
    );
}

const MemoSvgComment = React.memo(SvgComment);
export default MemoSvgComment;
