import React from 'react';
import { cn } from '@bem-react/classname';
import { DropdownIcon } from '@lms-elements/icons';

import { StatusLabelEnum } from '../Dropdown.types';
import { statusIcon } from '../utils';

import { IDropdownLabelProps } from './DropdownLabel.types';

import './DropdownLabel.scss';

const CnDropdownLabel = cn('dropdownLabel');

export const DropdownLabel: React.FC<IDropdownLabelProps> = ({ isOpened, status, lateTime, needHideIcon }) => {
    const isLate = status === StatusLabelEnum.isLate;

    return (
        <div className={CnDropdownLabel()}>
            <div className={CnDropdownLabel('statusIcon', { isLate })}>{statusIcon(status)}</div>
            {isOpened && <div className={CnDropdownLabel('label')}>{status}</div>}

            {isLate && lateTime && (
                <div className={CnDropdownLabel('lateTimeLabel', { isOpened })}>
                    {isOpened ? `${lateTime} мин` : `${lateTime}м`}
                </div>
            )}

            {!needHideIcon && (
                <div className={CnDropdownLabel('icon', { isOpened })}>
                    <DropdownIcon />
                </div>
            )}
        </div>
    );
};
