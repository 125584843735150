import React, { useCallback, useMemo, useState } from 'react';
import { useField } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { Button, ButtonViewEnum, File as FileComponent, TextareaField, Title } from '@lms-elements/atomic';
import { CheckMarkBig, DeleteIcon } from '@lms-elements/icons';
import { isFilenameInAvailableExtensions } from '@lms-elements/utils';

import { IQuestion } from '../Answer.types';

import {
    ACCEPT_FILE_TYPES,
    INPUT_ACCEPT_FILE_TYPES,
    MAX_FILE_SIZE_MB,
    MAX_PROGRESS_VALUE,
    UPLOAD_ERROR_TITLE,
} from './DetailedAnswer.constants';
import { IDetailedAnswerProps } from './DetailedAnswer.types';
import { getMaxFileSizeBytes } from './DetailedAnswer.utils';

import './DetailedAnswer.scss';

const DetailedAnswerCn = cn('detailed-answer');

export const DetailedAnswer: React.FC<IDetailedAnswerProps> = ({
    name,
    withEditor,
    disabled,
    onSmartBoardClick,
    needHideEditButton,
    needHideFileAddButton,
    needHideFileDeleteButton,
    needHideCopyToClipboardButton,
    onUploadFile,
    onDeleteFile,
    onFileOversize,
}) => {
    const { input } = useField<IQuestion>(name);

    const { question, isFileAvailable, isTextAvailable, isSmartBoardAvailable, file, uploadProgresses, answer } =
        input.value;

    const [fileIds, setFileIds] = useState<number[]>(
        !file || file.length === 0
            ? []
            : file.map((el, index) => (!(el instanceof File) && el.id ? Number(el.id) : index)),
    );

    const smartBoardClick = useCallback(() => {
        if (onSmartBoardClick) onSmartBoardClick();
    }, [onSmartBoardClick]);

    const fileProps = useMemo(
        () =>
            file?.length === 0
                ? undefined
                : file?.map((el, index) => {
                      return 'id' in el
                          ? el
                          : {
                                id: `${fileIds[index]}`,
                                name: el.name || '',
                                url: el ? URL.createObjectURL(el) : '',
                            };
                  }),
        [file, fileIds],
    );

    const handleFileDelete = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            const fileId = e.currentTarget.id;
            const index = fileProps?.findIndex((el) => el.id === fileId);
            if (index !== -1 && input.value.file && index !== undefined) {
                const newFiles = [...input.value.file];
                newFiles.splice(index, 1);

                input.onChange({ ...input.value, file: newFiles });
                setFileIds((prev) => prev.filter((id) => id !== Number(fileId)));

                onDeleteFile?.(Number(fileId));
            }
        },
        [fileProps, input, onDeleteFile],
    );

    const onAddFileClick = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const file = e.target?.files?.[0];
            const maxFileSizeInBytes = getMaxFileSizeBytes(MAX_FILE_SIZE_MB);

            if (file && file.size > maxFileSizeInBytes) {
                return onFileOversize?.(
                    `${file.name} слишком большой! Размер файла не должен превышать ${MAX_FILE_SIZE_MB}МБ`,
                );
            }

            if (file && isFilenameInAvailableExtensions(file.name.toLowerCase(), ACCEPT_FILE_TYPES)) {
                onUploadFile?.(file, fileIds.length ? fileIds[fileIds.length - 1] + 1 : 0);
                input.onChange({ ...input.value, file: input.value.file ? [...input.value.file, file] : [file] });

                setFileIds((prev) => {
                    if (prev.length) {
                        return [...prev, prev[prev.length - 1] + 1];
                    }

                    return [0];
                });
            }
        },
        [fileIds, input, onFileOversize, onUploadFile],
    );

    const haveAnswer = typeof answer === 'string' && answer.trim().length > 0;
    const haveFiles = Array.isArray(file) && file.length > 0;

    return (
        <>
            {(isTextAvailable || haveAnswer) && (
                <TextareaField
                    isField
                    name={`${name}.answer`}
                    validators={isFileAvailable ? [] : [(value: string) => (value ? undefined : 'error')]}
                    placeholder={withEditor ? 'Введите ответ' : question}
                    readOnly={disabled}
                />
            )}
            {(isFileAvailable || isSmartBoardAvailable || haveFiles) && (
                <div className={DetailedAnswerCn('files')}>
                    {!needHideFileAddButton && (
                        <Title name="Файл" onClickAdd={onAddFileClick} accept={INPUT_ACCEPT_FILE_TYPES} />
                    )}
                    {fileProps?.length &&
                        fileProps?.map((el) => (
                            <div key={el.id} className={DetailedAnswerCn('file-wrapper')}>
                                <FileComponent
                                    {...el}
                                    deleteFile={handleFileDelete}
                                    needHideSendButton
                                    needHideDeleteButton={needHideFileDeleteButton}
                                    needHideEditButton={!disabled || needHideEditButton}
                                    needHideCopyToClipboardButton={needHideCopyToClipboardButton}
                                />
                                {uploadProgresses?.[el.id] !== undefined &&
                                    uploadProgresses[el.id] >= 0 &&
                                    (uploadProgresses[el.id] >= MAX_PROGRESS_VALUE ? (
                                        <CheckMarkBig className={DetailedAnswerCn('success-icon')} />
                                    ) : (
                                        <progress
                                            className={DetailedAnswerCn('progress-bar')}
                                            max={MAX_PROGRESS_VALUE}
                                            value={uploadProgresses?.[el.id] ?? 0}
                                        />
                                    ))}
                                {uploadProgresses?.[el.id] !== undefined && uploadProgresses[el.id] < 0 && (
                                    <div className={DetailedAnswerCn('error-icon')} title={UPLOAD_ERROR_TITLE}>
                                        <DeleteIcon />
                                    </div>
                                )}
                            </div>
                        ))}
                    {disabled && isSmartBoardAvailable && (
                        <>
                            <div className={DetailedAnswerCn('title')}>Задание решено в SmartBoard</div>
                            <Button view={ButtonViewEnum.bordered} size="m" onClick={smartBoardClick}>
                                Перейти в SmartBoard
                            </Button>
                        </>
                    )}
                </div>
            )}
        </>
    );
};
