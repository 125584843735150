import React, { useMemo } from 'react';
import { cn } from '@bem-react/classname';

import { MAX_CARDS } from './constants';
import { LessonCardProps, typesCard } from './LessonsCard.types';

import './LessonsCard.scss';

const CnLessonCard = cn('lesson-card');

export const LessonCard: React.FC<LessonCardProps> = React.memo(
    ({ type, singleLesson, groupLesson, selfPreparation, meeting, common }) => {
        const chooseCard = useMemo(() => {
            switch (type) {
                case typesCard.singleLesson:
                    return (
                        singleLesson && (
                            <div
                                className={CnLessonCard('single', {
                                    alert: singleLesson.alert,
                                    disabled: singleLesson.disabled,
                                    grabbing: singleLesson.isGrabbing,
                                })}
                                style={{ backgroundColor: `${singleLesson.color}` }}
                            >
                                <p className={CnLessonCard('subject')}>{singleLesson.subject}</p>
                                <p className={CnLessonCard('mini-text')}>{singleLesson.nameTeacher}</p>
                                {singleLesson.auditorium && (
                                    <p className={CnLessonCard('mini-text')}>{singleLesson.auditorium}</p>
                                )}
                                {singleLesson.group && <p className={CnLessonCard('class')}>{singleLesson.group}</p>}
                            </div>
                        )
                    );
                case typesCard.groupLesson:
                    return (
                        groupLesson && (
                            <div className={CnLessonCard('group')}>
                                {groupLesson.map((lesson, index) => (
                                    <div
                                        key={lesson.nameGroup}
                                        className={CnLessonCard('group-item', {
                                            alert: lesson.alert,
                                            grabbing: lesson.isGrabbing,
                                        })}
                                        style={{ backgroundColor: `${lesson.color}` }}
                                    >
                                        <p className={CnLessonCard('mini-text', { smallest: index === MAX_CARDS })}>
                                            {lesson.nameGroup}
                                        </p>
                                        <p className={CnLessonCard('group-subject', { smallest: index === MAX_CARDS })}>
                                            {lesson.subject}
                                        </p>
                                        {lesson.auditorium && (
                                            <p className={CnLessonCard('mini-text')}>{lesson.auditorium}</p>
                                        )}
                                        {lesson.group && (
                                            <p className={CnLessonCard('group-item-class')}>{lesson.group}</p>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )
                    );
                case typesCard.selfPreparation:
                    return (
                        selfPreparation && (
                            <div
                                className={`${CnLessonCard('single', {
                                    alert: selfPreparation.alert,
                                    grabbing: selfPreparation.isGrabbing,
                                })} ${CnLessonCard('self')} `}
                                style={{ backgroundColor: `${selfPreparation.color}` }}
                            >
                                <p className={CnLessonCard('subject')}>{selfPreparation.subject}</p>
                                <p>{`Самоподготовка - ${selfPreparation.type}`}</p>
                                {selfPreparation.auditorium && (
                                    <p className={CnLessonCard('mini-text')}>{selfPreparation.auditorium}</p>
                                )}
                                {selfPreparation.group && (
                                    <p className={CnLessonCard('class')}>{selfPreparation.group}</p>
                                )}
                            </div>
                        )
                    );
                case typesCard.meeting:
                    return (
                        meeting && (
                            <div
                                className={`${CnLessonCard('single', {
                                    alert: meeting.alert,
                                    grabbing: meeting.isGrabbing,
                                })} ${CnLessonCard('meeting', {
                                    disabled: meeting.disabled,
                                })}`}
                                style={{ backgroundColor: `${meeting.color}` }}
                            >
                                <p>Родительское собрание</p>
                                {meeting.teacher && <p>{`с ${meeting.teacher}`}</p>}
                                {meeting.auditorium && (
                                    <p className={CnLessonCard('mini-text')}>{meeting.auditorium}</p>
                                )}
                                {meeting.group && <p className={CnLessonCard('class')}>{meeting.group}</p>}
                            </div>
                        )
                    );
                case typesCard.common:
                    return (
                        common && (
                            <div
                                className={CnLessonCard('single', {
                                    alert: common.alert,
                                    disabled: common.disabled,
                                    grabbing: common.isGrabbing,
                                })}
                                style={{ backgroundColor: `${common.color}` }}
                            >
                                <p className={CnLessonCard('subject')}>{common.title}</p>
                                {common.auditorium && <p className={CnLessonCard('mini-text')}>{common.auditorium}</p>}
                                {common.group && <p className={CnLessonCard('class')}>{common.group}</p>}
                            </div>
                        )
                    );
                default:
                    break;
            }
        }, [common, groupLesson, meeting, selfPreparation, singleLesson, type]);

        return <div className={CnLessonCard()}>{chooseCard}</div>;
    },
);
