import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useField } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { ImageIcon } from '@lms-elements/icons';
import { isURL } from '@lms-elements/utils';

import { IDropItem, IImageValues, MIN_IMAGE_WIDTH, supportedMimeTypes } from '../ExpandedImageMaterial.types';
import { computeWidth, isFileSizeValid, isMimeTypeValid } from '../utils';

import { IUploadDialogProps } from './UploadDialog.types';

import './UploadDialog.scss';

const UploadDialogCn = cn('expanded-image-material-upload-dialog');

export const UploadDialog: React.FC<IUploadDialogProps> = ({ name, onUpload }) => {
    const { input } = useField<IImageValues>(name);
    const [link, setLink] = useState('');
    const [isLinkError, setIsLinkError] = useState(false);
    const [isFileSizeError, setIsFileSizeError] = useState(false);
    const [isMimeTypeError, setIsMimeTypeError] = useState(false);

    const handleFile = useCallback(
        (file: File) => {
            if (file.type.split('/')[0] !== 'image') {
                return;
            }

            if (!isFileSizeValid(file)) {
                setIsFileSizeError(true);
                setTimeout(() => setIsFileSizeError(false), 6000);
                return;
            }
            if (file.name && !isMimeTypeValid(file)) {
                setIsMimeTypeError(true);
                setTimeout(() => setIsMimeTypeError(false), 6000);
                return;
            }
            void createImageBitmap(file)
                .then(({ width }) => computeWidth(width))
                .then((width) => {
                    const { imageData } = input.value;

                    input.onChange({
                        ...input.value,
                        imageData: {
                            ...imageData,
                            data: [
                                ...imageData.data,
                                {
                                    data: file,
                                    width: width,
                                },
                            ],
                        },
                    });
                    if (onUpload) {
                        onUpload(file);
                    }
                });
        },
        [input, onUpload],
    );

    const [, drop] = useDrop(
        () => ({
            accept: [NativeTypes.FILE],
            drop: (item: IDropItem) => {
                handleFile(item.files[0]);
            },
        }),
        [input],
    );

    const handleFileAdd = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            if (!e.target.files?.length) {
                return;
            }
            handleFile(e.target.files[0]);
        },
        [handleFile],
    );

    const handleLinkInputBlur = useCallback(() => {
        if (!isURL(link)) {
            return;
        }
        const { imageData } = input.value;

        input.onChange({
            ...input.value,
            imageData: {
                ...imageData,
                data: [
                    ...imageData.data,
                    {
                        data: link,
                        width: MIN_IMAGE_WIDTH,
                    },
                ],
            },
        });
        setLink('');
    }, [input, link]);

    const handleLinkInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setLink(e.target.value), []);

    const bordered = useMemo(() => !!input.value.imageData.data.length, [input.value.imageData.data.length]);

    useEffect(() => {
        setIsLinkError(() => !isURL(link) && !!link);
    }, [link]);

    return (
        <div ref={drop} className={UploadDialogCn({ bordered })}>
            <h4>Загрузите изображения</h4>
            <div className={UploadDialogCn('manual')}>
                {!bordered && <ImageIcon className={UploadDialogCn('image-icon')} />}
                перетащив файл сюда или
                <label className={UploadDialogCn('file-label')}>
                    укажите путь
                    <input type="file" accept="image/*" onChange={handleFileAdd} />
                </label>
            </div>
            {isMimeTypeError && (
                <div className={UploadDialogCn('file-error')}>Тип загружаемого файла не поддерживается</div>
            )}
            {isFileSizeError && (
                <div className={UploadDialogCn('file-error')}>
                    Вес загружаемого файла более 5mb, уменьшите размер и загрузите повторно
                </div>
            )}
            <div className={UploadDialogCn('mime-types')}>подходят {supportedMimeTypes.join(' ')} до 5mb</div>
            <input
                type="text"
                value={link}
                onChange={handleLinkInputChange}
                onBlur={handleLinkInputBlur}
                placeholder="Или укажите прямую ссылку"
                className={UploadDialogCn('link-input', { error: isLinkError })}
            />
            {isLinkError && <p className={UploadDialogCn('link-error')}>ссылка не подходит</p>}
        </div>
    );
};
