import React, { useCallback, useRef } from 'react';
import { cn } from '@bem-react/classname';
import { useGetScrollPosition } from '@lms-elements/hooks';
import { emptyDashboard } from 'assets';

import { ScheduleItem } from '../ScheduleItem';

import { ScheduleItemListProps } from './ScheduleItemList.types';
import { getClassTitleUtil } from './utils';

import './ScheduleItemList.scss';

const CnScheduleItemList = cn('schedule-item-list');

export const ScheduleItemList: React.FC<ScheduleItemListProps> = ({
    content,
    disabledActionButton,
    onButtonClick,
    onScheduleClick,
}) => {
    const element = useRef(null);

    const { isScrollEnd, isScrollStart, onScroll } = useGetScrollPosition(element);

    const handleButtonClick = useCallback(
        (lessonSchedule: number) => {
            if (onButtonClick) {
                onButtonClick(lessonSchedule);
            }
        },
        [onButtonClick],
    );

    const handleLinkClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            if (onScheduleClick) {
                onScheduleClick(event.currentTarget.dataset);
            }
        },
        [onScheduleClick],
    );

    return (
        <div className={CnScheduleItemList('container')}>
            {content.length ? (
                <div className={CnScheduleItemList()} ref={element} onScroll={onScroll} id="scroll-bar">
                    {content.map((el) => {
                        return (
                            <div
                                key={el.id}
                                onClick={handleLinkClick}
                                className={CnScheduleItemList('link')}
                                data-lesson-id={el.lesson.id}
                                data-subject-id={el.subject.id}
                                data-group-id={el.courseGroup.id}
                            >
                                <ScheduleItem
                                    {...el}
                                    title={`${el.subject.title}, ${el.courseGroup.title}`}
                                    label={getClassTitleUtil(el.parallels)}
                                    description={el.lesson.title}
                                    disabledActionButton={disabledActionButton}
                                    handleButtonActionClick={handleButtonClick}
                                />
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div className={CnScheduleItemList('empty')}>
                    <img src={emptyDashboard} />
                    <span>На сегодня нет занятий!</span>
                </div>
            )}
            <div className={CnScheduleItemList('bottom', { transparent: isScrollEnd })}></div>
            <div className={CnScheduleItemList('top', { transparent: isScrollStart })}></div>
        </div>
    );
};
