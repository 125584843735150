import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { cn } from '@bem-react/classname';
import { useClickOutside } from '@lms-elements/hooks';

import { ProgressBarClass } from './ProgressBarClass/ProgressBarClass';
import { CurrentClassProps } from './CurrentClass.types';

import './CurrentClass.scss';

const CnCurrentClass = cn('currentClass');

export const CurrentClass: React.FC<CurrentClassProps> = ({
    groupTitle,
    students,
    blocked,
    computed,
    date,
    rate,
    tutor,
}) => {
    const percent = useMemo(() => {
        return (students / computed) * 100;
    }, [computed, students]);

    const [hovered, setHovered] = useState(false);
    const [tooltipState, setTooltipState] = useState({
        top: -9999,
        left: -9999,
        opacity: '0',
    });

    const handleDocumentScroll = useCallback(() => {
        setHovered(false);
        setTooltipState({ top: -9999, left: -9999, opacity: '0' });
    }, []);

    const cardRef = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = useCallback(() => {
        if (tooltipState.opacity === '1') {
            setHovered(false);
            setTooltipState({ top: -9999, left: -9999, opacity: '0' });
        }
    }, [tooltipState.opacity]);

    useClickOutside(tooltipRef, handleClickOutside);

    const handleOnCellClick = useCallback(() => {
        const card = cardRef.current as Element;
        const { x, y } = card.getBoundingClientRect();
        setTooltipState({
            top: y,
            left: x,
            opacity: '0',
        });

        document.addEventListener('touchmove', handleDocumentScroll);
        document.addEventListener('mousewheel', handleDocumentScroll);
        document.addEventListener('scroll', handleDocumentScroll);

        setHovered(true);
    }, [handleDocumentScroll]);

    const handleOnTooltipClick = useCallback(() => {
        setTooltipState({ top: -9999, left: -9999, opacity: '0' });

        document.removeEventListener('touchmove', handleDocumentScroll);
        document.removeEventListener('mousewheel', handleDocumentScroll);
        document.removeEventListener('scroll', handleDocumentScroll);

        setHovered(false);
    }, [handleDocumentScroll]);

    useEffect(() => {
        if (tooltipRef.current) {
            const widthTooltip = tooltipRef.current.getBoundingClientRect().width;
            const heightTooltip = tooltipRef.current.getBoundingClientRect().height;
            const mark = cardRef.current as HTMLDivElement;
            const { x, y, width, height } = mark.getBoundingClientRect();

            setTooltipState({
                top: y - (heightTooltip - height) / 2,
                left: x - (widthTooltip - width) / 2,
                opacity: '1',
            });
        }
    }, [tooltipState.left]);

    return (
        <div className={CnCurrentClass()}>
            <div className={CnCurrentClass('card')} onClick={handleOnCellClick} ref={cardRef}>
                <span className={CnCurrentClass('groupTitle')}>{groupTitle}</span>
                <div className={CnCurrentClass('studentsTab')}>
                    <span className={CnCurrentClass('students')}>{students}</span>
                    <span className={CnCurrentClass('dash')}> - </span>
                    <span className={CnCurrentClass('blocked')}>{blocked}</span>
                </div>
                <div className={CnCurrentClass('bar')}>
                    <ProgressBarClass fillingPercentage={percent} />
                </div>
            </div>
            {hovered && (
                <div
                    className={CnCurrentClass('tooltip')}
                    style={tooltipState}
                    onClick={handleOnTooltipClick}
                    ref={tooltipRef}
                >
                    <span className={CnCurrentClass('groupTitleTool')}>{groupTitle}</span>
                    <div className={CnCurrentClass('studentsTool')}>
                        <div className={CnCurrentClass('studentsLine')}>
                            <span className={CnCurrentClass('title')}>Ученики:</span>
                            <span className={CnCurrentClass('number')}>{students}</span>
                        </div>
                        <div className={CnCurrentClass('studentsLine')}>
                            <span className={CnCurrentClass('title')}>Заблокированы:</span>
                            <span className={CnCurrentClass('number')}>{blocked}</span>
                        </div>
                    </div>
                    <div className={CnCurrentClass('line')} />
                    <div className={CnCurrentClass('underTool')}>
                        <div className={CnCurrentClass('studentsLine')}>
                            <span className={CnCurrentClass('title')}>Создание:</span>
                            <span className={CnCurrentClass('number')}>{date}</span>
                        </div>
                        <div className={CnCurrentClass('studentsLine')}>
                            <span className={CnCurrentClass('title')}>Тариф:</span>
                            <span className={CnCurrentClass('number')}>{rate}</span>
                        </div>
                        <div className={CnCurrentClass('studentsLine')}>
                            <span className={CnCurrentClass('title')}>Куратор:</span>
                            <span className={CnCurrentClass('number')}>{tutor}</span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
