import { RawDraftContentBlock } from 'draft-js';

import { ActionBlockTypes, BlockTypes } from '../../types/shared.types';

import { forEachKeyValueInObject, isEmptyString, trimLeadingZeros, trimTrailingZeros, trimZeros } from './common';
import {
    CustomBlockTransform,
    CustomEntityTransform,
    EntityMap,
    EntitySection,
    HashtagConfig,
    InlineStylesProperty,
    SectionRange,
    StylePropertySection,
} from './draftToHtml.types';
import { getHashtagRanges } from './hashtag';
import { addInlineStyleMarkup, getBlockTag, getEntityMarkup } from './markup';
import {
    addStylePropertyMarkup,
    getBlockStyle,
    getStyleArrayForBlock,
    getStylesAtOffset,
    sameStyleAsPrevious,
} from './style';

/**
 * The function returns an array of entity-sections in blocks.
 * These will be areas in block which have same entity or no entity applicable to them.
 */
const getSections = (block: RawDraftContentBlock, hashtagConfig?: HashtagConfig): EntitySection[] => {
    const sections = [];
    let lastOffset = 0;
    let sectionRanges = block.entityRanges.map<SectionRange>((range) => ({
        ...range,
        type: 'ENTITY',
    }));
    sectionRanges = sectionRanges.concat(getHashtagRanges(block.text, hashtagConfig));
    sectionRanges = sectionRanges.sort((s1, s2) => s1.offset - s2.offset);
    sectionRanges.forEach((r) => {
        if (r.offset > lastOffset) {
            sections.push({
                start: lastOffset,
                end: r.offset,
            });
        }
        sections.push({
            start: r.offset,
            end: r.offset + r.length,
            entityKey: r.key,
            type: r.type,
        });
        lastOffset = r.offset + r.length;
    });
    if (lastOffset < block.text.length) {
        sections.push({
            start: lastOffset,
            end: block.text.length,
        });
    }
    return sections;
};

/**
 * Function to check if the block is an atomic entity block.
 */
const isAtomicEntityBlock = (block: RawDraftContentBlock) =>
    block.entityRanges.length > 0 && (block.type === 'atomic' || isEmptyString(block.text));

/**
 * The function returns text for given section of block after doing required character replacements.
 */
const getSectionText = (text: string[]) => {
    if (text && text.length > 0) {
        const chars = text.map((ch) => {
            switch (ch) {
                case '\n':
                    return '<br>';
                case '&':
                    return '&amp;';
                case '<':
                    return '&lt;';
                case '>':
                    return '&gt;';
                default:
                    return ch;
            }
        });
        return chars.join('');
    }
    return '';
};

/**
 * For a given section in a block the function will return a further list of sections,
 * with similar inline styles applicable to them.
 */
const getInlineStyleSections = (
    block: RawDraftContentBlock,
    styles: InlineStylesProperty[],
    start: number,
    end: number,
) => {
    const styleSections = [];
    const text = Array.from(block.text);
    if (text.length > 0) {
        const inlineStyles = getStyleArrayForBlock(block);
        let section: StylePropertySection = {
            styles: {},
            end: 0,
            start: 0,
            text: [],
        };
        for (let i = start; i < end; i += 1) {
            if (i !== start && sameStyleAsPrevious(inlineStyles, styles, i)) {
                section?.text.push(text[i]);
                section.end = i + 1;
            } else {
                section = {
                    styles: getStylesAtOffset(inlineStyles, i),
                    text: [text[i]],
                    start: i,
                    end: i + 1,
                };
                styleSections.push(section);
            }
        }
    }
    return styleSections;
};

/**
 * The method returns markup for section to which inline styles
 * like BOLD, ITALIC, UNDERLINE, STRIKETHROUGH, CODE, SUPERSCRIPT, SUBSCRIPT are applicable.
 */
const getStyleTagSectionMarkup = (styleSection: StylePropertySection) => {
    const { styles, text } = styleSection;
    let content = getSectionText(text);
    forEachKeyValueInObject(styles, (style) => {
        content = addInlineStyleMarkup(style as InlineStylesProperty, content);
    });
    return content;
};

/**
* The method returns markup for section to which inline styles
like color, background-color, font-size are applicable.
*/
const getInlineStyleSectionMarkup = (block: RawDraftContentBlock, styleSection: StylePropertySection) => {
    const styleTagSections = getInlineStyleSections(
        block,
        ['BOLD', 'ITALIC', 'UNDERLINE', 'STRIKETHROUGH', 'CODE', 'SUPERSCRIPT', 'SUBSCRIPT'],
        styleSection.start,
        styleSection.end,
    );
    let styleSectionText = '';
    styleTagSections.forEach((stylePropertySection) => {
        styleSectionText += getStyleTagSectionMarkup(stylePropertySection);
    });
    styleSectionText = addStylePropertyMarkup(styleSection.styles, styleSectionText);
    return styleSectionText;
};

/*
 * The method returns markup for an entity section.
 * An entity section is a continuous section in a block
 * to which same entity or no entity is applicable.
 */
const getSectionMarkup = (
    block: RawDraftContentBlock,
    entityMap: EntityMap,
    section: EntitySection,
    customEntityTransform?: CustomEntityTransform,
) => {
    const entityInlineMarkup: string[] = [];
    const inlineStyleSections = getInlineStyleSections(
        block,
        ['COLOR', 'BGCOLOR', 'FONTSIZE', 'FONTFAMILY'],
        section.start,
        section.end,
    );
    inlineStyleSections.forEach((styleSection) => {
        entityInlineMarkup.push(getInlineStyleSectionMarkup(block, styleSection));
    });
    let sectionText = entityInlineMarkup.join('');
    if (section.type === 'ENTITY') {
        if (section.entityKey !== undefined && section.entityKey !== null) {
            sectionText = getEntityMarkup(entityMap, section.entityKey, sectionText, customEntityTransform) ?? '';
        }
    } else if (section.type === 'HASHTAG') {
        sectionText = `<a href="${sectionText}" class="wysiwyg-hashtag">${sectionText}</a>`;
    }
    return sectionText;
};

/**
 * Function will return the markup for block preserving the inline styles and
 * special characters like newlines or blank spaces.
 */
export const getBlockInnerMarkup = (
    block: RawDraftContentBlock,
    entityMap: EntityMap,
    hashtagConfig?: HashtagConfig,
    customEntityTransform?: CustomEntityTransform,
) => {
    const blockMarkup: string[] = [];

    const sections = getSections(block, hashtagConfig);

    sections.forEach((section, index) => {
        let sectionText = getSectionMarkup(block, entityMap, section, customEntityTransform);
        if (block.type === ActionBlockTypes.CODE_BLOCK) {
            if (section.entityKey === undefined) {
                sectionText = trimZeros(sectionText);
            }
        } else {
            if (section.entityKey !== undefined && index === 0) {
                sectionText = trimLeadingZeros(sectionText);
            }
            if (section.entityKey !== undefined && index === sections.length - 1) {
                sectionText = trimTrailingZeros(sectionText);
            }
        }

        blockMarkup.push(sectionText);
    });
    return blockMarkup.join('');
};

/**
 * Function will return html for the block.
 */
export const getBlockMarkup = (
    block: RawDraftContentBlock,
    entityMap: EntityMap,
    hashtagConfig?: HashtagConfig,
    directional?: boolean,
    customEntityTransform?: CustomEntityTransform,
    customBlockTransform?: CustomBlockTransform,
) => {
    const blockHtml = [];
    if (isAtomicEntityBlock(block)) {
        blockHtml.push(getEntityMarkup(entityMap, block.entityRanges[0].key, undefined, customEntityTransform));
    } else {
        const blockTag = getBlockTag(block.type as BlockTypes);
        if (blockTag) {
            blockHtml.push(`<${blockTag}`);
            const blockStyle = getBlockStyle(block.data);

            if (blockStyle) {
                blockHtml.push(` style="${blockStyle}"`);
            }
            if (directional) {
                blockHtml.push(' dir = "auto"');
            }
            blockHtml.push('>');
            blockHtml.push(getBlockInnerMarkup(block, entityMap, hashtagConfig, customEntityTransform));
            blockHtml.push(`</${blockTag}>`);
        } else {
            const customBlockMarkup = customBlockTransform?.(
                block,
                getBlockInnerMarkup(block, entityMap, hashtagConfig, customEntityTransform),
            );
            if (customBlockMarkup) {
                blockHtml.push(customBlockMarkup);
            }
        }
    }
    blockHtml.push('\n');
    return blockHtml.join('');
};
