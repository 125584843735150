/**
 * returns string 'bottom' | 'top' | 'right' | 'left' of touch direction
 * @param start start event
 * @param end end event
 * @returns direction of touch
 */
export const detectTouchDirection = (
    start: React.TouchEvent<HTMLElement>,
    end: React.TouchEvent<HTMLElement>,
): 'bottom' | 'top' | 'right' | 'left' => {
    const startTouch = start.touches[0];
    const endTouch = end.touches[0];

    const diffY = startTouch.clientY - endTouch.clientY;
    const diffX = startTouch.clientX - endTouch.clientX;

    if (Math.abs(diffY) > Math.abs(diffX)) {
        return diffY < 0 ? 'bottom' : 'top';
    }

    return diffX < 0 ? 'right' : 'left';
};
