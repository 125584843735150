import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgWarningTriangle({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M12 9v4.5M10.702 3.749L2.454 17.998a1.5 1.5 0 001.298 2.251h16.496a1.5 1.5 0 001.298-2.251l-8.248-14.25a1.5 1.5 0 00-2.596 0v0z"
                stroke="#EA5959"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M12 18a1.125 1.125 0 100-2.25A1.125 1.125 0 0012 18z" fill="#EA5959" />
        </svg>
    );
}

const MemoSvgWarningTriangle = React.memo(SvgWarningTriangle);
export default MemoSvgWarningTriangle;
