import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgExclamationCircle({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M10 2.734A7.267 7.267 0 1017.266 10 7.267 7.267 0 0010 2.734zm-.625 3.047c0-.086.07-.156.156-.156h.938c.086 0 .156.07.156.156v5.313c0 .086-.07.156-.156.156H9.53a.157.157 0 01-.156-.156V5.78zM10 14.375a.938.938 0 010-1.875.937.937 0 010 1.875z"
                fill="#FFD6E7"
            />
            <path
                d="M10 1.25a8.75 8.75 0 10.001 17.501A8.75 8.75 0 0010 1.25zm0 16.016a7.267 7.267 0 010-14.532 7.267 7.267 0 010 14.532z"
                fill="#EA5959"
            />
            <path
                d="M9.531 11.25h.938c.086 0 .156-.07.156-.156V5.78a.157.157 0 00-.156-.156H9.53a.157.157 0 00-.156.156v5.313c0 .086.07.156.156.156zm-.469 2.188a.937.937 0 101.875 0 .937.937 0 00-1.874 0z"
                fill="#EA5959"
            />
        </svg>
    );
}

const MemoSvgExclamationCircle = React.memo(SvgExclamationCircle);
export default MemoSvgExclamationCircle;
