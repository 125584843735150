import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgDefaultAvatar({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={48}
            height={48}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <rect width={48} height={48} rx={24} fill="#EEF4FB" />
            <path
                d="M24.004 26.953a7.477 7.477 0 100-14.953 7.477 7.477 0 000 14.953z"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeMiterlimit={10}
            />
            <path
                d="M12 34.313a13.463 13.463 0 0124 0"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgDefaultAvatar = React.memo(SvgDefaultAvatar);
export default MemoSvgDefaultAvatar;
