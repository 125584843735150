interface IAnyLevel<T extends unknown> {
    [key: string]: T | IAnyLevel<T> | unknown[];
}

/**
 * returns value from any object by flat object string
 * @param object any object
 * @param str string to find the value in object
 * @returns T value
 */
export const getObjectPropertyByString = <T extends unknown>(object: IAnyLevel<T>, str: string): T | undefined => {
    const props = str
        .replace(/\[(\w+)\]/g, '.$1')
        .replace(/^\./, '')
        .split('.');
    props.forEach((key) => {
        if (key in (object as Record<string, unknown>)) {
            object = object[key] as IAnyLevel<T>;
        } else {
            return undefined;
        }
    });
    return object ? (object as T) : undefined;
};
