import React, { useEffect, useMemo, useState } from 'react';
import { useField } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { TextareaField } from '@lms-elements/atomic';

import { IQuestion } from '../Answer.types';
import { usePreviouslySubmittedValue } from '../hooks/usePreviouslySubmittedValue';

import { IOpenAnswerOptionProps } from './OpenAnswerOption.types';

import './OpenAnswerOption.scss';

const AnswerCn = cn('test-task-answer');

export const OpenAnswerOption: React.FC<IOpenAnswerOptionProps> = ({
    name,
    questionComponent,
    disabled,
    needValidation,
    placeholder,
    isFetching = false,
    correctAnswer,
    needQuestionLink,
    linkComponent,
    isDraft = false,
    onQuestionEnter,
    onQuestionLeave,
}) => {
    const { input } = useField<IQuestion>(name);

    const [fetching, setFetching] = useState<boolean>(false);

    useEffect(() => {
        if (!fetching && isFetching) setFetching(true);
    }, [fetching, isFetching]);

    const previouslySubmittedValue = usePreviouslySubmittedValue(
        input.value.answer || '',
        isFetching,
        fetching,
        setFetching,
    );

    const isCorrect = useMemo(
        () => correctAnswer && correctAnswer === input.value.answer,
        [correctAnswer, input.value.answer],
    );

    const isWrong = useMemo(
        () =>
            correctAnswer &&
            previouslySubmittedValue === input.value.answer &&
            correctAnswer !== input.value.answer &&
            !isDraft,
        [correctAnswer, input.value.answer, isDraft, previouslySubmittedValue],
    );

    const validators = useMemo(
        () => (needValidation ? [(value: string) => (value ? undefined : 'error')] : []),
        [needValidation],
    );

    return (
        <div className={AnswerCn()} data-testid="test-task-answer">
            <div className={AnswerCn('flex')} onMouseEnter={onQuestionEnter} onMouseLeave={onQuestionLeave}>
                {questionComponent}
                {needQuestionLink ? linkComponent : null}
            </div>
            <p className={AnswerCn('type')}>*открытый ответ</p>
            <TextareaField
                isField
                name={`${name}.answer`}
                validators={validators}
                placeholder={placeholder}
                className={AnswerCn('open-question', {
                    correct: isCorrect,
                    wrong: isWrong,
                })}
                readOnly={disabled}
            />
        </div>
    );
};
