import React, { useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { Task } from '@lms-elements/icons';
import { getDateComponents } from '@lms-elements/utils';

import { HomeworkStatusProps } from './HomeworkStatus.types';

import './HomeworkStatus.scss';

const CnHomeworkStatus = cn('homeworkStatus');

export const HomeworkStatus: React.FC<HomeworkStatusProps> = ({
    id,
    status,
    autoIssue,
    autoIssueSetUp,
    autoIssuetotalTasks,
    autoIssuetasks,
    autoIssueTime,
    createTaskLabel,
    givenHometaskTitle,
    passOffTasks,
    toCheckTasks,
    withoutBorder,
    isStudent,
}) => {
    const title = useMemo(() => autoIssue || !givenHometaskTitle, [autoIssue, givenHometaskTitle]);

    const titleInfo = useMemo(() => {
        if (isStudent) {
            return 'нет заданий';
        }
        if (autoIssue && !autoIssueSetUp) {
            return 'Автовыдача не настроена';
        }
        if (autoIssue && autoIssueSetUp) {
            return `Автовыдача для ${autoIssuetasks} из ${autoIssuetotalTasks} заданий`;
        }

        return 'ДЗ не было выдано';
    }, [autoIssuetasks, autoIssueSetUp, autoIssuetotalTasks, autoIssue, isStudent]);
    const { time = '', dateWithMonth = 'сегодня' } = useMemo(() => getDateComponents(autoIssueTime), [autoIssueTime]);

    const autoIssueLabel = useMemo(
        () =>
            createTaskLabel
                ? `${createTaskLabel(autoIssuetasks)} будут выданы ${dateWithMonth} к ${time}`
                : `${autoIssuetasks} заданий будут выданы ${dateWithMonth} к ${time}`,
        [createTaskLabel, autoIssuetasks, dateWithMonth, time],
    );

    const passOffTasksLabel = useMemo(
        () => (createTaskLabel ? `сдано: ${createTaskLabel(passOffTasks)}` : `сдано: ${passOffTasks} заданий`),
        [createTaskLabel, passOffTasks],
    );

    const toCheckTasksLabel = useMemo(
        () =>
            createTaskLabel ? `к проверке: ${createTaskLabel(toCheckTasks)}` : `к проверке: ${toCheckTasks} заданий`,
        [createTaskLabel, toCheckTasks],
    );

    return (
        <div key={id} className={CnHomeworkStatus({ status, withoutBorder })}>
            <div className={CnHomeworkStatus('icon', { status, withoutBorder })}>
                <Task />
            </div>
            <div className={CnHomeworkStatus('info')}>
                {title && <div className={CnHomeworkStatus('title', { status, withoutBorder })}>{titleInfo}</div>}
                {autoIssueSetUp && !isStudent && <div className={CnHomeworkStatus('body')}>{autoIssueLabel}</div>}
                {Boolean(givenHometaskTitle) && (
                    <ul className={CnHomeworkStatus('hometask')}>
                        <li>{`задано: ${givenHometaskTitle}`}</li>
                        {Boolean(passOffTasks) && <li>{passOffTasksLabel}</li>}
                        {Boolean(toCheckTasks) && !isStudent && <li>{toCheckTasksLabel}</li>}
                    </ul>
                )}
            </div>
        </div>
    );
};
