import React, { useMemo } from 'react';
import { cn } from '@bem-react/classname';
import {
    HeaderIcon,
    ImageIcon,
    MusicNotes,
    PaperclipIcon,
    PuzzlePieceIcon,
    QuestionIcon,
    TableIcon,
    TaskNoMark,
    TextIcon,
    VideoCameraIcon,
} from '@lms-elements/icons';

import { CodifierElementIconEnum, ICodifierElementProps } from './CodifierElement.types';

import './CodifierElement.scss';

const CnCodifierElement = cn('codifierElement');

export const CodifierElement: React.FC<ICodifierElementProps> = ({
    icon,
    title,
    description,
    dragging,
    dragRef,
    disabled = false,
}) => {
    const ElementIcon = useMemo(() => {
        switch (icon) {
            case CodifierElementIconEnum.text:
                return TextIcon;
            case CodifierElementIconEnum.table:
                return TableIcon;
            case CodifierElementIconEnum.header:
                return HeaderIcon;
            case CodifierElementIconEnum.video:
                return VideoCameraIcon;
            case CodifierElementIconEnum.document:
                return PaperclipIcon;
            case CodifierElementIconEnum.image:
                return ImageIcon;
            case CodifierElementIconEnum.exercise:
                return PuzzlePieceIcon;
            case CodifierElementIconEnum.audio:
                return MusicNotes;
            case CodifierElementIconEnum.trainer:
                return TaskNoMark;
            default:
                return QuestionIcon;
        }
    }, [icon]);

    return (
        <div ref={disabled ? null : dragRef} className={CnCodifierElement({ dragging, disabled })}>
            <div className={CnCodifierElement('iconWrapper')}>
                <ElementIcon
                    className={[
                        CnCodifierElement('icon', { scaled: icon === CodifierElementIconEnum.table }),
                        icon,
                    ].join(' ')}
                />
            </div>
            <div className={CnCodifierElement('info')}>
                <h4 className={CnCodifierElement('header')}>{title}</h4>
                <p className={CnCodifierElement('description')}>{description}</p>
            </div>
        </div>
    );
};
