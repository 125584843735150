import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgToggleRight({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={8}
            height={6}
            viewBox="0 0 8 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M1 3.308l1.846 1.846L7 1"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgToggleRight = React.memo(SvgToggleRight);
export default MemoSvgToggleRight;
