import React from 'react';

import { Avatar } from '../../../Avatar';
import { IDropdownType } from '../DropdownCustomCore.types';

import { ViewValueProps } from './ViewValue.types';

export const ViewValue: React.FC<ViewValueProps> = ({ value, type, isNeedShortTitle, titleLength }) => {
    const { title, img, children } = value;

    const getShortTitle = (title: string): string =>
        isNeedShortTitle && titleLength && title.length > titleLength ? `${title.slice(0, titleLength)}...` : title;

    switch (type) {
        case IDropdownType.optionWithId:
            return (
                <span>
                    {getShortTitle(title)}
                    {children}
                </span>
            );
        case IDropdownType.user:
            return <Avatar name={getShortTitle(title)} source={img} />;
        case IDropdownType.course:
            return (
                <span>
                    {getShortTitle(title)}
                    {children}
                </span>
            );
        default:
            return (
                <span>
                    {getShortTitle(title)}
                    {children}
                </span>
            );
    }
};
