/**
 * Utility function to execute callback for eack key->value pair.
 */
export const forEachKeyValueInObject = (
    obj: Record<string, unknown> | undefined,
    callback: (key: string, value: unknown) => void,
) => {
    if (obj) {
        for (const key in obj) {
            if (Object.hasOwnProperty.call(obj, key)) {
                callback(key, obj[key]);
            }
        }
    }
};

/**
 * The function returns true if the string passed to it has no content.
 */
export const isEmptyString = (str: string) => !str?.trim().length;

/**
 * Replace trailing blank spaces by &nbsp;
 */
export const trimZeros = (sectionText: string) => sectionText.replaceAll(' ', '&nbsp;');

/**
 * Replace leading blank spaces by &nbsp;
 */
export const trimLeadingZeros = (sectionText: string) =>
    sectionText.replace(/^ +/, (match) => '&nbsp;'.repeat(match.length));

/**
 * Replace trailing blank spaces by &nbsp;
 */
export const trimTrailingZeros = (sectionText: string) =>
    sectionText.replace(/ +$/, (match) => '&nbsp;'.repeat(match.length));
