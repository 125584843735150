import React, { ReactElement, useCallback, useMemo } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { cn } from '@bem-react/classname';
import { useWindowResize } from '@lms-elements/hooks';
import { Basket } from '@lms-elements/icons';
import arrayMutators from 'final-form-arrays';

import { Button, ButtonViewEnum } from '../../Button';
import { CalendarDropdown } from '../../CalendarDropdown';
import { Checkbox } from '../../Checkbox';
import { FieldDropdown } from '../../FieldDropdown';
import { FieldForm } from '../../FieldForm';
import { LessonItem } from '../../Lesson';
import { TextareaField } from '../../TextareaField';

import { getInactiveDays } from './LessonEditMode.constants';
import { ILessonFormProps, Lesson } from './LessonEditMode.types';
import { validate, validateCheckboxesArray, validateDate } from './utils';

import './LessonEditMode.scss';

const getFieldDropdownOptions = (count: number, options: Array<string>) => {
    for (let i = 1; i <= count; i++) {
        options.push(`${i} по порядку`);
    }
    return options;
};

const CnLessonEdit = cn('lesson_edit');

const MUTATORS_STAB = { ...arrayMutators };
const TIMEPICKER_MINUTES_STEP = 10;

const LESSON_ITEMS_STUB = [] as LessonItem[];

export const LessonEditMode: React.FC<ILessonFormProps> = ({
    initialValues = {
        order: 1,
        orderTitle: '',
        name: '',
        description: '',
        type: 'Обычный',
        lessonItems: LESSON_ITEMS_STUB,
    },
    isEditBlocked,
    lessonOverall,
    isEditModeOn,
    withCourseAssignment,
    needChangeSaveButtonTextOnTablet = true,
    needValidateDescription = true,
    needPublishButton,
    timePickerPosition = 'right',
    hideCheckbox = false,
    needHideDeleteButton,
    needValidateCheckboxes,
    onPublishClick,
    onCancelClick,
    onCreateClick,
    onEditClick,
    onDeleteClick,
}) => {
    const { isTablet } = useWindowResize();

    const handleFormSubmit = useCallback(
        (values) => {
            if (isEditModeOn && onEditClick) {
                onEditClick(values);
            }
            if (!isEditModeOn && onCreateClick) {
                onCreateClick(values);
            }
        },
        [onCreateClick, onEditClick, isEditModeOn],
    );

    const lessonTypes = useMemo(() => (withCourseAssignment ? ['Резервный'] : ['Обычный']), [withCourseAssignment]);

    const saveButtonText = useMemo(() => {
        if (!isEditModeOn) {
            return 'Создать урок';
        }
        if (needChangeSaveButtonTextOnTablet && !isTablet) {
            return 'Редактировать урок';
        }
        return 'Сохранить';
    }, [isEditModeOn, isTablet, needChangeSaveButtonTextOnTablet]);

    return (
        <div className={CnLessonEdit()}>
            <Form<Lesson>
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                subscription={{ submitting: true, pristine: true, errors: true }}
                key={`LessonForm-${initialValues.lessonId || 'newLesson'}`}
                mutators={MUTATORS_STAB}
            >
                {({ handleSubmit }): ReactElement => (
                    <form id={`lessonForm-${initialValues.lessonId || 'newLesson'}`} onSubmit={handleSubmit}>
                        <div className={CnLessonEdit('form')}>
                            <FieldDropdown
                                options={getFieldDropdownOptions(isEditModeOn ? lessonOverall : lessonOverall + 1, [])}
                                name={'orderTitle'}
                                placeholder={`${initialValues.order} по порядку`}
                                validators={[]}
                                disabled={
                                    initialValues.type === 'Обычный' &&
                                    (withCourseAssignment || (isEditModeOn && isEditBlocked))
                                }
                            />
                            <FieldDropdown
                                options={lessonTypes}
                                name={'type'}
                                placeholder={initialValues.type}
                                validators={[]}
                                disabled={isEditBlocked || isEditModeOn}
                            />
                            <div className={CnLessonEdit('field-description')}>
                                <FieldForm
                                    name={'name'}
                                    placeholder={'Название урока'}
                                    validators={[validate]}
                                    customClassName={CnLessonEdit('field-description-input')}
                                    disable={
                                        initialValues.type === 'Обычный' &&
                                        (withCourseAssignment || (isEditModeOn && isEditBlocked))
                                    }
                                />
                            </div>
                            <TextareaField
                                name={'description'}
                                placeholder={'Описание'}
                                validators={needValidateDescription ? [validate] : undefined}
                                isField
                                disabled={
                                    initialValues.type === 'Обычный' &&
                                    (withCourseAssignment || (isEditModeOn && isEditBlocked))
                                }
                            />
                            <div className={CnLessonEdit('form-buttons')}>
                                <div className={CnLessonEdit('form-buttons-row')}>
                                    <Button view={ButtonViewEnum.action} size={'l'} disabled={false} type="submit">
                                        {saveButtonText}
                                    </Button>
                                    <Button
                                        view={ButtonViewEnum.bordered}
                                        size={'l'}
                                        disabled={false}
                                        onClick={onCancelClick}
                                    >
                                        Отмена
                                    </Button>
                                    {isEditModeOn &&
                                        !needHideDeleteButton &&
                                        !(
                                            (isEditBlocked || withCourseAssignment) &&
                                            initialValues.type === 'Обычный'
                                        ) && (
                                            <Button
                                                view={ButtonViewEnum.bordered}
                                                size={'l'}
                                                disabled={false}
                                                icon={<Basket />}
                                                onClick={onDeleteClick}
                                                customClasses={CnLessonEdit('form-buttons-delete_button', {
                                                    visible: Boolean(isEditModeOn),
                                                })}
                                            />
                                        )}
                                </div>
                                {isEditModeOn && needPublishButton && (
                                    <div className={CnLessonEdit('form-buttons-row')}>
                                        <Button view={ButtonViewEnum.action} size={'l'} onClick={onPublishClick}>
                                            Опубликовать урок
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>

                        {!hideCheckbox && (
                            <div className={CnLessonEdit('contents')}>
                                <span>Состоит из:</span>
                                <FieldArray<LessonItem>
                                    name={'lessonItems'}
                                    validate={needValidateCheckboxes ? validateCheckboxesArray : undefined}
                                >
                                    {({ fields }) =>
                                        fields.map((name, index) => (
                                            <React.Fragment key={name}>
                                                <Field type="checkbox" name={`${name}.checked`}>
                                                    {({ input: { onChange, checked } }) => (
                                                        <Checkbox
                                                            defaultChecked={checked}
                                                            handleCheckboxChange={onChange}
                                                            label={fields.value[index]?.label ?? ''}
                                                            isError={
                                                                needValidateCheckboxes &&
                                                                Boolean(fields.value.every(({ checked }) => !checked))
                                                            }
                                                            disabled={fields.value[index]?.disabled}
                                                        />
                                                    )}
                                                </Field>
                                                {withCourseAssignment &&
                                                    ((fields.value[index]?.canEdit && !fields.value[index]?.disabled) ||
                                                        fields.value[index]?.canEditDate) && (
                                                        <FormSpy<Lesson> subscription={{ values: true }}>
                                                            {({ values }) => {
                                                                if (values.lessonItems[index]?.checked) {
                                                                    return (
                                                                        <>
                                                                            <div
                                                                                className={CnLessonEdit(
                                                                                    'form-calendar',
                                                                                )}
                                                                            >
                                                                                {values.lessonItems[index]
                                                                                    ?.canEditEndData && (
                                                                                    <span>Начало:</span>
                                                                                )}
                                                                                <CalendarDropdown
                                                                                    fieldName={`${name}.startDate`}
                                                                                    startTime={0}
                                                                                    endTime={23}
                                                                                    stepMinutes={
                                                                                        TIMEPICKER_MINUTES_STEP
                                                                                    }
                                                                                    validators={
                                                                                        fields.value[index]?.canEditDate
                                                                                            ? [validate]
                                                                                            : [validateDate]
                                                                                    }
                                                                                    timePickerPosition={
                                                                                        timePickerPosition
                                                                                    }
                                                                                    withScrollIntoView
                                                                                    value={
                                                                                        isEditModeOn
                                                                                            ? String(
                                                                                                  values.lessonItems[
                                                                                                      index
                                                                                                  ]?.startDate ?? '',
                                                                                              )
                                                                                            : undefined
                                                                                    }
                                                                                    inactiveDays={
                                                                                        fields.value[index]?.canEditDate
                                                                                            ? getInactiveDays
                                                                                            : 'past'
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            {values.lessonItems[index]
                                                                                ?.canEditEndData && (
                                                                                <div
                                                                                    className={CnLessonEdit(
                                                                                        'form-calendar',
                                                                                    )}
                                                                                >
                                                                                    <span>Конец:</span>
                                                                                    <CalendarDropdown
                                                                                        fieldName={`${name}.endDate`}
                                                                                        startTime={0}
                                                                                        endTime={23}
                                                                                        stepMinutes={
                                                                                            TIMEPICKER_MINUTES_STEP
                                                                                        }
                                                                                        validators={
                                                                                            fields.value[index]
                                                                                                ?.canEditDate
                                                                                                ? [validate]
                                                                                                : [validateDate]
                                                                                        }
                                                                                        timePickerPosition={
                                                                                            timePickerPosition
                                                                                        }
                                                                                        withScrollIntoView
                                                                                        value={
                                                                                            isEditModeOn
                                                                                                ? String(
                                                                                                      values
                                                                                                          .lessonItems[
                                                                                                          index
                                                                                                      ]?.endDate ?? '',
                                                                                                  )
                                                                                                : undefined
                                                                                        }
                                                                                        inactiveDays={
                                                                                            fields.value[index]
                                                                                                ?.canEditDate
                                                                                                ? getInactiveDays
                                                                                                : 'past'
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    );
                                                                }
                                                                return null;
                                                            }}
                                                        </FormSpy>
                                                    )}
                                            </React.Fragment>
                                        ))
                                    }
                                </FieldArray>
                            </div>
                        )}
                    </form>
                )}
            </Form>
        </div>
    );
};
