import { ReactElement } from 'react';
import { FieldInputProps, FieldMetaState } from 'react-final-form';

export interface IDropdownValues {
    id: number;
    title: string;
    children?: JSX.Element;
    img?: string;
}

export enum IDropdownType {
    optionWithId = 'optionWithId',
    user = 'user',
    course = 'course',
}

export interface DropdownCustomCoreProps {
    input?: FieldInputProps<IDropdownValues, HTMLInputElement>;
    meta?: Pick<FieldMetaState<IDropdownValues>, 'touched' | 'active' | 'error'>;
    isNeedSearch?: boolean;
    searchPlaceholder?: string;
    type: IDropdownType;
    placeholder?: string;
    initValue?: IDropdownValues;
    options: IDropdownValues[];
    needBlueIcon?: boolean;
    withoutBorder?: boolean;
    needHoveredIcon?: boolean;
    hoveredIcon?: ReactElement;
    customHoveredIconClass?: string;
    customValueClass?: string;
    customOptionClass?: string;
    disabled?: boolean;
    isNeedForcedOpen?: boolean;
    isClose?: boolean;
    withArrowIcons?: boolean;
    isNeedShortTitle?: boolean;
    titleLength?: number;
    setIsClose?: React.Dispatch<React.SetStateAction<boolean>>;
    getValue?: (value: IDropdownValues) => void;
    needLongOptions?: boolean;
    dataCy?: (index: number) => string;
}
