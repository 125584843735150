export enum HeaderSize {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

export type Header = {
    size: HeaderSize;
    text: string;
};

export interface IExpandedHeaderMaterialProps {
    name: string;
    onDelete: () => void;
    textValidators?: ((value: string) => string | undefined)[];
    isStudentView?: boolean;
    customClassName?: string;
}

export type VerboseSize = {
    size: HeaderSize;
    verboseName: string;
};

export interface IHeaderValues {
    headerData: Header;
}
