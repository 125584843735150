import { ValidationErrors } from 'final-form';

import { IChangePasswordFormErrors, IFormValues } from './ChangePasswordForm.types';

const PASSWORD_PATTERN = /^[a-z0-9_-]+$/i;

export const createValidateNewPasswordFormFields =
    (passwordMinLength: number) =>
    (values: IFormValues): ValidationErrors => {
        const errors = {} as IChangePasswordFormErrors;

        if (!values.newPassword) {
            errors.newPassword = 'Пожалуйста укажите пароль';
        }

        if (values.newPassword && values.newPassword.length < passwordMinLength) {
            errors.newPassword = 'Пароль слишком короткий';
        }

        if (values.newPassword && !PASSWORD_PATTERN.test(values.newPassword)) {
            errors.newPassword = 'Недопустимый пароль';
        }

        if (!values.confirmPassword) {
            errors.confirmPassword = 'Пожалуйста подтвердите пароль';
        }

        if (values.newPassword !== values.confirmPassword) {
            errors.confirmPassword = 'Пароли не совпадают';
        }

        return errors;
    };

export const validateCurrentPasswordFormField = (values: IFormValues): ValidationErrors => {
    const errors = {} as IChangePasswordFormErrors;

    if (!values.currentPassword) {
        errors.currentPassword = 'Пожалуйста укажите ваш пароль';
    }

    if (values.currentPassword === values.newPassword) {
        errors.newPassword = 'Выберите другой пароль';
    }

    return errors;
};
