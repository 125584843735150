import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgNoteBlank({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M19.586 27H6a1 1 0 01-1-1V6a1 1 0 011-1h20a1 1 0 011 1v13.586a1.001 1.001 0 01-.293.707l-6.414 6.414a1 1 0 01-.707.293v0z"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.91 19.998H20v6.91"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgNoteBlank = React.memo(SvgNoteBlank);
export default MemoSvgNoteBlank;
