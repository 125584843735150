import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgGetMore({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M5.977 10c1.092 0 1.977.895 1.977 2s-.885 2-1.977 2A1.988 1.988 0 014 12c0-1.105.885-2 1.977-2zM11.977 10c1.092 0 1.977.895 1.977 2s-.885 2-1.977 2A1.989 1.989 0 0110 12c0-1.105.885-2 1.977-2zM18.023 10c1.092 0 1.977.895 1.977 2s-.885 2-1.977 2a1.989 1.989 0 01-1.977-2c0-1.105.885-2 1.977-2z"
                fill="#0088E9"
            />
        </svg>
    );
}

const MemoSvgGetMore = React.memo(SvgGetMore);
export default MemoSvgGetMore;
