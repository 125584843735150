import { EditorState } from 'draft-js';

import { EntityTypes, INSERT_WORD_REPLACE, NEW_LINE_DELIMETER } from '../types/shared.types';

import { getEntities } from './getEntities';

export const insertationToTemplate = (editorState: EditorState) =>
    Object.values(
        getEntities(editorState, EntityTypes.INSERT_WORD)
            .sort((a, b) => b.start - a.start)
            .reduce(
                (accum, { blockKey, start, end }) =>
                    Object.assign(accum, {
                        [blockKey]: accum[blockKey].slice(0, start) + INSERT_WORD_REPLACE + accum[blockKey].slice(end),
                    }),
                editorState
                    .getCurrentContent()
                    .getBlocksAsArray()
                    .reduce<Record<string, string>>(
                        (accum, block) => Object.assign(accum, { [block.getKey()]: block.getText() }),
                        {},
                    ),
            ),
    ).join(NEW_LINE_DELIMETER);
