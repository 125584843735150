import React from 'react';
import { cn } from '@bem-react/classname';

import { ButtonWithIconProps } from './ButtonWithIcon.types';

import './ButtonWithIcon.scss';

const CnButtonWithIcon = cn('buttonWithIcon');

export const ButtonWithIcon: React.FC<ButtonWithIconProps> = ({
    text,
    onClickCallback,
    isActive = true,
    hoverable = true,
    CustomIcon,
}) => {
    return (
        <button
            type="button"
            className={CnButtonWithIcon({ active: isActive, hoverable: hoverable && isActive })}
            onClick={onClickCallback}
        >
            {CustomIcon}
            {text && <div className={CnButtonWithIcon('text')}>{text}</div>}
        </button>
    );
};
