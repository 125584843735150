import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgOpenedLock({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d="M18.126 8.97h-4.442l-5.759-.017v-.756c0-.986.388-1.904 1.092-2.59a3.875 3.875 0 012.723-1.09h.524c1.018 0 1.974.376 2.697 1.065.188.18.354.373.498.581a.817.817 0 001.047.243.757.757 0 00.388-.482.725.725 0 00-.107-.595C15.78 3.873 14.087 3 12.26 3h-.524c-2.98 0-5.404 2.312-5.404 5.154v.817H5.87C4.841 8.97 4 9.769 4 10.755v8.461C4 20.198 4.837 21 5.87 21h12.26c1.029 0 1.87-.799 1.87-1.784v-8.462c-.004-.985-.841-1.783-1.874-1.783zm0 1.52a.27.27 0 01.277.264v8.462a.27.27 0 01-.277.264H5.87a.27.27 0 01-.276-.264v-8.462a.27.27 0 01.276-.263h12.256z" />
            <path d="M11.865 12.648a.864.864 0 00-.865.863v2.155c0 .476.387.862.865.862a.864.864 0 00.865-.862V13.51a.864.864 0 00-.865-.863z" />
        </svg>
    );
}

const MemoSvgOpenedLock = React.memo(SvgOpenedLock);
export default MemoSvgOpenedLock;
