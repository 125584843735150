import moment from 'moment';

type StartAndEndOfStudyDay = {
    startOfDay: string;
    endOfDay: string;
};

export const getStartAndEndOfStudyDay = (date: string, start: number, end: number): StartAndEndOfStudyDay => {
    return {
        startOfDay: moment(date).add(start, 'h').format(),
        endOfDay: moment(date).add(end, 'h').format(),
    };
};
