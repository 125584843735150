/**
 * deep comparison of a and b
 * @param a any value
 * @param b any value
 * @returns are a and b equal by values
 */
export const deepEqual = <T extends unknown>(a: T, b: T): boolean => {
    if ((typeof a !== 'object' && typeof b !== 'object') || a === null || b === null) {
        return a === b;
    }

    if (Object.keys(a as Record<string, unknown>).length !== Object.keys(b as Record<string, unknown>).length)
        return false;

    for (const key in a as Record<string, unknown>) {
        if (!(key in (b as Record<string, unknown>))) return false;
        if (!deepEqual<unknown>((a as Record<string, unknown>)[key], (b as Record<string, unknown>)[key])) return false;
    }

    return true;
};
