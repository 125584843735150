export interface IImageUploaderProps {
    onDelete?: () => void;
    onUploadImage?: (file: File) => void;
    onDeleteImage?: (id: number) => void;
    name: string;
}

export type ImageData = {
    data: File | string;
    id?: number;
};

export interface IDropItem {
    files: FileList;
    items: DataTransferItemList;
}

export interface IImageValues {
    values: {
        imageData: {
            data: ImageData;
        };
    };
}

export const MIN_IMAGE_WIDTH = 168;

export const MAX_IMAGE_WIDTH = 732;

export const supportedMimeTypes = ['.jpg', '.jpeg', '.bmp', '.png'];
