import React, { useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { getValueInRange } from '@lms-elements/utils';

import { MAX_PERCENT, MIN_PERCENT } from './ProgressBar.constants';
import { IProgressBarProps } from './ProgressBar.types';

import './ProgressBar.scss';

const CnProgressBar = cn('progressBar');

export const ProgressBar: React.FC<IProgressBarProps> = ({ fillingPercentage }) => {
    const checkedPercentage = useMemo(
        () => getValueInRange(MIN_PERCENT, MAX_PERCENT, fillingPercentage),
        [fillingPercentage],
    );

    return (
        <div className={CnProgressBar()}>
            <div className={CnProgressBar('bar')}>
                <div style={{ width: `${checkedPercentage}%` }} className={CnProgressBar('progress')} />
            </div>
        </div>
    );
};
