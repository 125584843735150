import moment from 'moment';

import { capitalizeWord } from './';

/**
 * Return date string 'D MMMM' with capitalaze month name
 * @example 1 марта -> 1 Марта
 * @param date incoming date
 * @returns
 */
export const getCapitalizeMonth = (date?: Date | string): string => {
    const result = moment(date).format('D MMMM');

    return capitalizeWord(result, 2);
};
