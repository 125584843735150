export const secondsToHms = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const sec = Math.floor((seconds % 3600) % 60);

    const hDisplay = hours > 0 ? `${hours}:` : '';
    const mDisplay = minutes > 10 ? `${String(minutes).padStart(2, '0')}:` : `${minutes}:`;
    const sDisplay = sec > 0 ? `${sec}` : '';
    return hDisplay + mDisplay + sDisplay.padStart(2, '0');
};
