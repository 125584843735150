import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgFolders({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={24}
            height={25}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M19.5 10.5v9.028a.724.724 0 01-.72.722H3.31a.808.808 0 01-.81-.813V8.063a.814.814 0 01.81-.812h4.587c.175 0 .345.057.486.162l2.806 2.113c.14.105.31.162.486.162h7.015a.808.808 0 01.81.813z"
                stroke="#585C6F"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.5 6.5V5.062a.814.814 0 01.81-.812h4.587c.175 0 .345.057.486.162l2.806 2.113c.14.105.31.162.486.162h7.015a.808.808 0 01.81.813v9.028a.724.724 0 01-.72.722h-1.518"
                stroke="#585C6F"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgFolders = React.memo(SvgFolders);
export default MemoSvgFolders;
