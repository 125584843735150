import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgClasscardUser({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={17}
            height={17}
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d="M8.437 10.352a4 4 0 100-8 4 4 0 000 8z" stroke="#000" strokeMiterlimit={10} />
            <path
                d="M2.373 13.851a7.002 7.002 0 0112.126 0"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgClasscardUser = React.memo(SvgClasscardUser);
export default MemoSvgClasscardUser;
