import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgChat({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={24}
            height={25}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M8 13.102h8m-8-4h8M6.79 18.39l-3.42 2.875A.833.833 0 012 20.628V4.082a.833.833 0 01.833-.833h18.334a.833.833 0 01.833.833v13.334a.833.833 0 01-.833.833H7.174l-.384.14z"
                stroke="#585C6F"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgChat = React.memo(SvgChat);
export default MemoSvgChat;
