import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgNoteBlankTemplate({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M19.586 27H6a1 1 0 01-1-1V6a1 1 0 011-1h20a1 1 0 011 1v13.586a1.001 1.001 0 01-.293.707l-6.414 6.414a1 1 0 01-.707.293z"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.91 19.998H20v6.91"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.9 16a.231.231 0 01-.17-.07.231.231 0 01-.07-.17v-4.72c0-.073.023-.13.07-.17a.231.231 0 01.17-.07h.8c.073 0 .133.023.18.07.047.047.07.103.07.17v3.89h1.47v-3.89c0-.067.023-.123.07-.17a.231.231 0 01.17-.07h.75c.067 0 .123.023.17.07.047.047.07.103.07.17v3.89h1.47v-3.89c0-.067.023-.123.07-.17a.244.244 0 01.18-.07h.8c.073 0 .13.023.17.07.047.04.07.097.07.17v4.72a.231.231 0 01-.07.17.212.212 0 01-.17.07H9.9zm12.716-2.72c.013.147.02.263.02.35 0 .087-.007.19-.02.31-.034.667-.264 1.193-.69 1.58-.427.387-1.03.58-1.81.58-.827 0-1.444-.213-1.85-.64-.407-.433-.627-1.073-.66-1.92l-.01-.6.01-.6c.02-.947.166-1.657.44-2.13.273-.473.67-.817 1.19-1.03.52-.22 1.363-.47 2.53-.75l.06-.01c.053 0 .1.02.14.06a.183.183 0 01.08.13l.13.67.01.06c0 .053-.017.1-.05.14a.24.24 0 01-.13.08c-.134.027-.234.05-.3.07-.807.173-1.384.323-1.73.45-.34.12-.6.307-.78.56-.18.247-.284.627-.31 1.14.16-.2.37-.36.63-.48.266-.12.546-.18.84-.18.7 0 1.243.193 1.63.58.386.387.596.913.63 1.58zm-1.38.61a2.19 2.19 0 00.01-.26c0-.14-.004-.24-.01-.3-.014-.413-.114-.73-.3-.95-.18-.22-.43-.33-.75-.33-.36 0-.647.113-.86.34-.214.22-.327.533-.34.94l-.01.3.01.26c.013.42.116.74.31.96.193.213.466.32.82.32.353 0 .623-.107.81-.32.193-.22.296-.54.31-.96z"
                fill="#0088E9"
            />
        </svg>
    );
}

const MemoSvgNoteBlankTemplate = React.memo(SvgNoteBlankTemplate);
export default MemoSvgNoteBlankTemplate;
