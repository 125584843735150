import moment from 'moment';

/**
 *
 * @param date start week date
 * @returns array of dates
 */

export const getWeekArray = (date: Date | string): string[] => {
    const start = moment(date);
    const resultArray = [];

    for (let i = 0; i < 7; i = i + 1) {
        const newDate = moment(start).add(i, 'd').format('YYYY-MM-DD');
        resultArray.push(newDate);
    }

    return resultArray;
};
