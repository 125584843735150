import { INotificationMessageProps } from '../NotificationMessage';

export enum NotificationViewType {
    hover = 'hover',
    timeout = 'timeout',
    click = 'click',
}

export enum NotificationPage {
    new = 'new',
    viewed = 'viewed',
}

export interface INotification {
    id: string;
    date: string;
    notificationMessageProps: INotificationMessageProps;
}

export interface INewNotification extends INotification {
    makeViewedOn: NotificationViewType;
    timeoutToViewed?: number;
}

export interface INotificationGroup<Notification extends INotification = INotification> {
    title: string;
    notifications: Notification[];
}

export interface INotificationTooltipProps {
    newNotifications: INewNotification[];
    viewedNotifications: INotification[];
    notificationsTitle?: string;
    viewedLinkText?: string;
    returnButtonText?: string;
    isLoading?: boolean;
    onScrollComplete?: () => void;
    onCloseButtonClick?: () => void;
    onNotificationRead?: (id: string) => void;
}

export type PropsByPage = {
    [p in NotificationPage]: {
        notificationsElement: JSX.Element;
        hasCloseButton: boolean;
        hasReturnButton: boolean;
        title: string;
    };
};
