import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgTaskNoMark({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={22}
            height={26}
            viewBox="0 0 22 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M16.714 4.059H21v20.94H1V4.06h4.286"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                clipRule="evenodd"
                d="M13.354 3.446C13.354 2.096 12.3 1 11 1 9.702 1 8.648 2.096 8.648 3.446H6.295v3.058h9.412V3.446h-2.353z"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgTaskNoMark = React.memo(SvgTaskNoMark);
export default MemoSvgTaskNoMark;
