import { useEffect, useState } from 'react';

export const usePreviouslySubmittedValue = <T extends unknown>(
    value: T,
    isFetching: boolean,
    fetching: boolean,
    setFetching?: React.Dispatch<React.SetStateAction<boolean>>,
): T => {
    const [previouslySubmittedValue, setPreviouslySubmittedValue] = useState<T>(() => value);

    useEffect(() => {
        if (fetching && !isFetching) {
            setPreviouslySubmittedValue(value);
            if (setFetching) setFetching(false);
        }
    }, [value, isFetching, fetching, setFetching]);

    return previouslySubmittedValue;
};
