import React, { useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { getObjectPropertyByString } from '@lms-elements/utils';

import { CheckboxField } from '../CheckboxField';

import { IDetailedAnswerFieldsProps, IDetailedAnswerOption } from './DetailedAnswerFields.types';

import './DetailedAnswerFields.scss';

const DetailedCn = cn('detailed-question');

const DetailedAnswerFields: React.FC<IDetailedAnswerFieldsProps> = ({
    name,
    checkboxesValidators = [],
    needHideSmartBoardToggle,
}) => {
    const { values } = useFormState();

    const answerOption = getObjectPropertyByString<IDetailedAnswerOption>(values, name);

    const [checkboxError, setCheckboxError] = useState(false);

    useEffect(() => {
        setCheckboxError(!(answerOption?.isFileAvailable || answerOption?.isTextAvailable));
    }, [values, answerOption]);

    return (
        <div className={[DetailedCn('answer-option'), 'expanded-question-answer-option'].join(' ')}>
            <p className={DetailedCn('suggest')}>Принимать ответы в виде:</p>

            <div className={'center-flex'}>
                <div className={'center-vertical-file'}>
                    <CheckboxField
                        name={`${name}.isFileAvailable`}
                        isError={checkboxError}
                        validators={[() => (checkboxError ? 'error' : undefined), ...checkboxesValidators]}
                    />
                    <div className={DetailedCn('option-name')}>Файла</div>
                </div>

                <div className={'center-vertical-text'}>
                    <CheckboxField
                        name={`${name}.isTextAvailable`}
                        isError={checkboxError}
                        validators={[() => (checkboxError ? 'error' : undefined), ...checkboxesValidators]}
                    />
                    <div className={DetailedCn('option-name')}>Текстового поля</div>
                </div>

                {!needHideSmartBoardToggle && (
                    <div className={'center-vertical-smart'}>
                        <CheckboxField
                            name={`${name}.isSmartBoardAvailable`}
                            isError={checkboxError}
                            validators={[() => (checkboxError ? 'error' : undefined), ...checkboxesValidators]}
                        />
                        <div className={DetailedCn('option-name-smart')}>Использование SmartBoard</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DetailedAnswerFields;
