import React, { useCallback, useMemo } from 'react';
import { cn } from '@bem-react/classname';

import { INavbarItemProps } from './NavbarItem.types';

import './NavbarItem.scss';

const CnNavbarItem = cn('navbarItem');

export const NavbarItem: React.FC<INavbarItemProps> = ({
    currentPath,
    path,
    text,
    icon,
    isMobile,
    onNavbarItemClick,
}) => {
    const handleNavBaritemClick = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();

            onNavbarItemClick(path);
        },
        [onNavbarItemClick, path],
    );

    const isChecked = useMemo(() => currentPath === path, [currentPath, path]);

    return (
        <div className={CnNavbarItem('container')}>
            <a
                className={CnNavbarItem({ checked: isChecked, isMobile })}
                href={path}
                tabIndex={-1}
                onClick={handleNavBaritemClick}
            >
                <span className={CnNavbarItem('icon', { isMobile })}>{icon}</span>
                <span className={CnNavbarItem('text')}>{text}</span>
            </a>
        </div>
    );
};
