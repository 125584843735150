import { RefObject, useEffect } from 'react';

export function useClickOutside<T extends HTMLElement>(ref: RefObject<T>, handler: (e: Event) => void): void {
    useEffect(() => {
        const handleClickOutside = (e: Event) => {
            if (!ref?.current?.contains(e.target as Node)) {
                handler(e);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [handler, ref]);
}
