import React, { useCallback, useEffect, useState } from 'react';
import { cn } from '@bem-react/classname';

import { SubscriptionListProps } from './SubscriptionList.types';
import { SubscriptionListMonth } from './SubscriptionListMonth';

import './SubscriptionList.scss';

const CnSubscriptionList = cn('subscriptionList');

export const SubscriptionList: React.FC<SubscriptionListProps> = ({ list, isMobile, onCheckList }) => {
    const [data, setData] = useState({} as { [x: string]: { cost: number; ids: number[] } });

    const handleListMonthChange = useCallback(
        ({ month, cost, ids }: { month: string; cost: number; ids: number[] }) =>
            setData((prev) => ({ ...prev, [month]: { cost, ids } })),
        [],
    );

    useEffect(() => {
        const ids = Object.values(data).reduce((ids: number[], cur) => [...ids, ...cur.ids], []);
        const totalCost = Object.values(data).reduce((sum, cur) => sum + cur.cost, 0);
        onCheckList?.(totalCost, ids);
    }, [data, onCheckList]);

    return (
        <form className={CnSubscriptionList()}>
            <ul className={CnSubscriptionList('list')}>
                {list?.map((item) => (
                    <li key={item.month} className={CnSubscriptionList('list-item')}>
                        <SubscriptionListMonth
                            month={item.month}
                            list={item.list}
                            isMobile={isMobile}
                            handleListMonthChange={handleListMonthChange}
                        />
                    </li>
                ))}
            </ul>
        </form>
    );
};
