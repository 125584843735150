import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgTriangle({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={8}
            height={6}
            viewBox="0 0 8 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.772 4.365A1 1 0 017 6H1a1 1 0 01-.772-1.635L3.192.411a.999.999 0 011.616 0l2.964 3.954z"
                fill="#2F929A"
            />
        </svg>
    );
}

const MemoSvgTriangle = React.memo(SvgTriangle);
export default MemoSvgTriangle;
