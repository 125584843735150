import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgLoading({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={22}
            height={18}
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M18.885 9.197a8.036 8.036 0 00-8.035-8.035M18.884 9.197v3.013M21 10.703l-2.116 2.116M18.884 12.819l-2.116-2.116M3.115 8.803a8.035 8.035 0 008.035 8.035M3.115 8.803V5.79M1 7.297L3.116 5.18M3.116 5.181L5.23 7.297"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgLoading = React.memo(SvgLoading);
export default MemoSvgLoading;
