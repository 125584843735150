import React, { useEffect, useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useEditorToHTML } from '@lms-elements/custom-editor';
import { EditorState } from 'draft-js';
import { getIn } from 'final-form';

import { IMarkupProps } from './Markup.types';

export const Markup: React.FC<IMarkupProps> = ({ editorName, name }) => {
    const { change } = useForm();
    const { values } = useFormState();

    const editor = useMemo(() => {
        const value = getIn(values, editorName) as EditorState | string;
        if (typeof value !== 'string') {
            return value;
        }
    }, [editorName, values]);

    const { render, markup } = useEditorToHTML(editor ?? EditorState.createEmpty());

    useEffect(() => {
        change(name, markup);
    }, [change, markup, name]);

    return <>{render()}</>;
};
