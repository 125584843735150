import React from 'react';
import { cn } from '@bem-react/classname';
import { Button, ButtonViewEnum } from '@lms-elements/atomic';
import { Basket } from '@lms-elements/icons';

import { IExpandedExerciseMaterialEmptyProps } from './ExpandedExerciseMaterialEmpty.types';

import './ExpandedExerciseMaterialEmpty.scss';

const CnExerciseMaterialEmpty = cn('expanded-exercise-material-empty');

export const ExpandedExerciseMaterialEmpty: React.FC<IExpandedExerciseMaterialEmptyProps> = ({
    onDelete,
    onCreateQuestion,
    onSelectQuestiuon,
    error,
}) => {
    return (
        <div className={CnExerciseMaterialEmpty({ error })}>
            <div className={CnExerciseMaterialEmpty('content')}>
                <Button size="m" view={ButtonViewEnum.action} onClick={onCreateQuestion}>
                    Создать новый вопрос
                </Button>
                <span className={CnExerciseMaterialEmpty('text')}>или</span>
                <Button size="m" view={ButtonViewEnum.action} onClick={onSelectQuestiuon}>
                    Выбрать из банка
                </Button>
            </div>
            <button type="button" onClick={onDelete} className={CnExerciseMaterialEmpty('delete')}>
                <Basket />
            </button>
        </div>
    );
};
