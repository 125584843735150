import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgCloseIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={8}
            height={8}
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.586 4L.293 1.705A.999.999 0 111.707.293L4 2.584 6.293.294a1 1 0 011.414 1.413L5.414 4l2.293 2.294a1 1 0 01-1.414 1.413L4 5.413 1.707 7.707A1 1 0 01.293 6.293l2.293-2.294z"
                fill="#252232"
            />
        </svg>
    );
}

const MemoSvgCloseIcon = React.memo(SvgCloseIcon);
export default MemoSvgCloseIcon;
