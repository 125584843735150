import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgSetting({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={14}
            height={14}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.488 5.98L12.1 5.75a5.224 5.224 0 00-.44-1.174l.889-1.101a.612.612 0 00-.02-.792l-.55-.62a.612.612 0 00-.785-.113l-1.186.746a5.218 5.218 0 00-1.75-.79L8.027.512A.612.612 0 007.423 0h-.831a.61.61 0 00-.604.512l-.233 1.395a5.215 5.215 0 00-1.472.611l-1.128-.805a.613.613 0 00-.789.065l-.587.588a.612.612 0 00-.065.79l.807 1.13a5.23 5.23 0 00-.607 1.46l-1.402.235A.612.612 0 000 6.585v.83c0 .3.217.556.512.604l1.402.235c.106.43.26.84.464 1.22l-.884 1.095a.612.612 0 00.02.792l.55.62c.2.225.532.272.785.113l1.203-.756c.51.348 1.085.604 1.703.756l.233 1.394a.61.61 0 00.604.512h.83c.3 0 .556-.217.605-.512l.232-1.394a5.206 5.206 0 001.455-.602l1.175.84a.611.611 0 00.789-.066l.587-.588a.61.61 0 00.065-.788l-.837-1.173c.273-.45.48-.941.608-1.466l1.387-.232A.611.611 0 0014 7.415v-.83c0-.3-.217-.556-.512-.604zM7 10.113a3.112 3.112 0 11.001-6.224 3.112 3.112 0 01-.001 6.224z"
                fill="#0088E9"
            />
        </svg>
    );
}

const MemoSvgSetting = React.memo(SvgSetting);
export default MemoSvgSetting;
