import moment from 'moment';

type SubstructMinutesData = {
    date?: string | Date;
    minutes?: number;
};

export const substructMinutes = ({ date, minutes = 0 }: SubstructMinutesData): string => {
    return moment(date).subtract(minutes, 'minutes').format();
};
