import React from 'react';
import { Field, useField } from 'react-final-form';
import { composeValidators } from '@lms-elements/utils';

import { REG_FRACTION, REG_NOT_ALLOW_CHARS } from './constants';
import { IPlanTableCellProps } from './PlanTableCell.types';
import { PlanTableCellCore } from './PlanTableCellCore';

export const PlanTableCell: React.FC<IPlanTableCellProps> = ({
    fieldName,
    isTitle = false,
    isTotal = false,
    isError = false,
    isTablet = false,
    errorMessage = '',
    width,
    canEdit = false,
    isByHand = false,
    allowFractional = false,
    numericalLimit = Infinity,
    isSelection = false,
    percent = 0,
    initValue = '-',
    isField = true,
    validators,
    withValue,
    types,
    isDateCell = false,
}) => {
    const { input } = useField(fieldName);
    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value.replace('-', '');

        if (REG_NOT_ALLOW_CHARS.test(value)) value = value.slice(0, -1);

        let text: RegExpExecArray | string | null = value;

        const valueWithoutPoints = value.replaceAll('.', '').split('');
        const lenWithoutPoints = valueWithoutPoints.length;

        switch (true) {
            case isSelection:
                input.onChange(event.target.value);
                return;
            case allowFractional:
                text = REG_FRACTION.exec(String(value));
                if (text !== null && Number(text[0]) >= numericalLimit) text = String(numericalLimit - 1);
                input.onChange(text);
                return;
            case isDateCell:
                if (lenWithoutPoints >= 3) {
                    valueWithoutPoints.splice(2, 0, '.');
                }
                if (lenWithoutPoints >= 5) {
                    valueWithoutPoints.splice(5, 0, '.');
                }
                input.onChange(valueWithoutPoints.splice(0, 10).join(''));
                return;
            default:
                input.onChange(value.replaceAll('.', ''));
                return;
        }
    };

    return (
        <>
            {isField ? (
                <Field
                    name={fieldName}
                    value={`${initValue ? initValue : isByHand ? '' : '-'}`}
                    validate={composeValidators(...validators)}
                >
                    {({ input, meta }) => (
                        <PlanTableCellCore
                            isTitle={isTitle}
                            isTotal={isTotal}
                            isError={isError}
                            isTablet={isTablet}
                            errorMessage={errorMessage}
                            width={width}
                            name={fieldName}
                            canEdit={canEdit}
                            isByHand={isByHand}
                            value={initValue ? initValue : isByHand ? '' : '-'}
                            percent={percent}
                            input={input}
                            meta={meta}
                            onChange={handleChangeInput}
                            withValue={withValue}
                            isSelection={isSelection}
                            types={types}
                        />
                    )}
                </Field>
            ) : (
                <PlanTableCellCore
                    isTitle={isTitle}
                    isTotal={isTotal}
                    isError={isError}
                    isTablet={isTablet}
                    errorMessage={errorMessage}
                    width={width}
                    canEdit={canEdit}
                    isByHand={isByHand}
                    name={fieldName}
                    value={initValue ? initValue : isByHand ? '' : '-'}
                    percent={percent}
                    isField={false}
                    withValue={withValue}
                />
            )}
        </>
    );
};
