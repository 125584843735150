import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgUnderlinedTextIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M16.371 24.21c-1.806 0-3.206-.483-4.2-1.449-.98-.98-1.47-2.457-1.47-4.431V9.804c0-.154.042-.273.126-.357a.486.486 0 01.357-.147h1.071c.154 0 .273.049.357.147a.445.445 0 01.147.357v8.568c0 1.344.315 2.352.945 3.024.63.672 1.519 1.008 2.667 1.008s2.037-.336 2.667-1.008c.63-.672.945-1.68.945-3.024V9.804c0-.154.042-.273.126-.357a.486.486 0 01.357-.147h1.092a.42.42 0 01.336.147.486.486 0 01.147.357v8.526c0 1.974-.497 3.451-1.491 4.431-.98.966-2.373 1.449-4.179 1.449zM9 25.575h14.721v1.05H9v-1.05z"
                fill="#252232"
            />
        </svg>
    );
}

const MemoSvgUnderlinedTextIcon = React.memo(SvgUnderlinedTextIcon);
export default MemoSvgUnderlinedTextIcon;
