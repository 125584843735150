import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgInfo({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M16 28c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12z"
                stroke="#EA5959"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M15 15h1v7h1" stroke="#EA5959" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 12a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" fill="#EA5959" />
        </svg>
    );
}

const MemoSvgInfo = React.memo(SvgInfo);
export default MemoSvgInfo;
