import React from 'react';
import { cn } from '@bem-react/classname';

import { EditorField } from './EditorField';
import { IExpandedTextMaterialProps } from './ExpandedTextMaterial.types';
import { StudentView } from './Student';
import { TextareaFieldWrapper } from './TextareaFieldWrapper';

import './ExpandedTextMaterial.scss';

const ExpandedTextMaterialCn = cn('expanded-text-material');

export const ExpandedTextMaterial: React.FC<IExpandedTextMaterialProps> = ({
    name,
    onDelete = () => undefined,
    isStudentView = false,
    isEditor = false,
    placeholder = 'Введите текст',
    isBlocked = false,
    studentViewClassName = '',
    editorFieldName,
    htmlFieldName,
    needUnlimitedEditorHeight,
}) => {
    if (isStudentView && isEditor) {
        return <StudentView name={name} className={studentViewClassName} />;
    }

    return (
        <div className={ExpandedTextMaterialCn()}>
            {isEditor && editorFieldName ? (
                <EditorField
                    name={name}
                    onDelete={onDelete}
                    placeholder={placeholder}
                    isBlocked={isBlocked}
                    editorFieldName={editorFieldName}
                    htmlFieldName={htmlFieldName}
                    needUnlimitedHeight={needUnlimitedEditorHeight}
                />
            ) : (
                <TextareaFieldWrapper
                    name={name}
                    onDelete={onDelete}
                    placeholder={placeholder}
                    isStudentView={isStudentView}
                />
            )}
        </div>
    );
};
