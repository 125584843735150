import { HashtagConfig } from './draftToHtml.types';

/**
 * The function returns an array of hashtag-sections in blocks.
 * These will be areas in block which have hashtags applicable to them.
 */
export const getHashtagRanges = (blockText: string, hashtagConfig?: HashtagConfig) => {
    const sections = [];
    if (hashtagConfig) {
        let counter = 0;
        let startIndex = 0;
        let text = blockText;
        const trigger = hashtagConfig.trigger || '#';
        const separator = hashtagConfig.separator || ' ';
        for (; text.length > 0 && startIndex >= 0; ) {
            if (text[0] === trigger) {
                startIndex = 0;
                counter = 0;
                text = text.substr(trigger.length);
            } else {
                startIndex = text.indexOf(separator + trigger);
                if (startIndex >= 0) {
                    text = text.substr(startIndex + (separator + trigger).length);
                    counter += startIndex + separator.length;
                }
            }
            if (startIndex >= 0) {
                const endIndex = text.indexOf(separator) >= 0 ? text.indexOf(separator) : text.length;
                const hashtag = text.substr(0, endIndex);
                if (hashtag && hashtag.length > 0) {
                    sections.push({
                        offset: counter,
                        length: hashtag.length + trigger.length,
                        type: 'HASHTAG',
                    });
                }
                counter += trigger.length;
            }
        }
    }
    return sections;
};
