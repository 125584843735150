import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgPreloaderArrows({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={134}
            height={120}
            viewBox="0 0 134 120"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M125.293 60.707a1 1 0 001.414 0l6.364-6.364a1 1 0 10-1.414-1.414L126 58.586l-5.657-5.657a1 1 0 10-1.414 1.414l6.364 6.364zM66 0a1 1 0 100 2V0zm0 2c32.607 0 59 25.428 59 58h2c0-33.72-27.333-60-61-60v2zM8.707 59.293a1 1 0 00-1.414 0L.929 65.657a1 1 0 101.414 1.414L8 61.414l5.657 5.657a1 1 0 001.414-1.414l-6.364-6.364zM68 120a1 1 0 000-2v2zm0-2C35.393 118 9 92.572 9 60H7c0 33.72 27.333 60 61 60v-2z"
                fill="#0088E9"
            />
        </svg>
    );
}

const MemoSvgPreloaderArrows = React.memo(SvgPreloaderArrows);
export default MemoSvgPreloaderArrows;
