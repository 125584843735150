import { EMPTY_ITEM, MIN_INPUT_ID } from './InputListCore.constants';
import { IInputItem } from './InputListCore.types';

export const validateItem = (item: string): boolean => Boolean(item.trim());

// Remove line breaks
export const fixItem = (item: string): string => item.replace(/\r?\n/g, '');

export const filterAndFixItems = (items: string[]): string[] => items.filter(validateItem).map(fixItem);

export const checkAndFixInputList = (inputList: IInputItem[]): IInputItem[] => {
    const filteredInputList = inputList.filter(({ label }) => validateItem(label));
    return filteredInputList.length ? filteredInputList : [{ id: MIN_INPUT_ID, label: EMPTY_ITEM }];
};

export const getMaxIdInInputList = (inputList: IInputItem[]): number =>
    inputList.reduce((maxId, input) => (input.id > maxId ? input.id : maxId), MIN_INPUT_ID);

export const addInputItemToList = (oldInputList: IInputItem[], newItem: string, newItemIndex: number): IInputItem[] => {
    const newInputList = [...oldInputList];
    const newItemId = getMaxIdInInputList(oldInputList) + 1;
    const newInputItem = {
        id: newItemId,
        label: newItem,
    };

    newInputList.splice(newItemIndex, 0, newInputItem);

    return newInputList;
};

export const convertItemsToInputList = (items: string[], oldInputList: IInputItem[] = []): IInputItem[] => {
    const oldInputListCopy = [...oldInputList];
    let newInputList: IInputItem[] = [];
    const differingItems: { index: number; item: string }[] = [];

    items.forEach((item, index) => {
        const inputItemInOldListIndex = oldInputListCopy.findIndex((input) => input.label === item);
        if (inputItemInOldListIndex === -1) {
            differingItems.push({
                item,
                index,
            });
        } else {
            const foundInputItem = oldInputListCopy[inputItemInOldListIndex];
            newInputList.push(foundInputItem);
            oldInputListCopy.splice(inputItemInOldListIndex, 1);
        }
    });

    differingItems.forEach(({ item, index }) => {
        newInputList = addInputItemToList(newInputList, item, index);
    });

    return newInputList;
};

export const convertInputListToItems = (inputList: IInputItem[]): string[] => inputList.map(({ label }) => label);
