import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgWasAbsentIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={22}
            height={22}
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M11 21.002c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
                stroke="#EA5959"
                strokeWidth={1.5}
                strokeMiterlimit={10}
            />
            <path d="M8 7.002v8M14 7.002v8M8.5 11.002H14" stroke="#EA5959" strokeWidth={1.5} strokeLinecap="round" />
        </svg>
    );
}

const MemoSvgWasAbsentIcon = React.memo(SvgWasAbsentIcon);
export default MemoSvgWasAbsentIcon;
