import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgCheckIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={9}
            height={6}
            viewBox="0 0 9 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.958.3c-.4-.4-1-.4-1.4 0l-3.3 3.3-1.3-1.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l2 2c.2.2.4.3.7.3.3 0 .5-.1.7-.3l4-4c.4-.4.4-1 0-1.4z"
                fill="#fff"
            />
        </svg>
    );
}

const MemoSvgCheckIcon = React.memo(SvgCheckIcon);
export default MemoSvgCheckIcon;
