import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgBasket({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={19}
            height={21}
            viewBox="0 0 19 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M15.715 3.621l-1 16h-10l-1-16M1.715 3.621h16"
                stroke="#EA5959"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.715 3.621v-1a1 1 0 011-1h2a1 1 0 011 1v1"
                stroke="#EA5959"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgBasket = React.memo(SvgBasket);
export default MemoSvgBasket;
