export interface IImageItemProps {
    name: string;
    index: number;
    showForStudents: boolean;
    onDeleteImage?: (id: number) => void;
}

export const MATERIALS_IMAGE_ITEM = 'MATERIALS_IMAGE_ITEM';

export interface IDragItem {
    index: number;
    type: string;
}
