import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgDefinition({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M15.5 23.276c-1.258 0-2.377-.322-3.359-.966-.966-.644-1.725-1.556-2.277-2.737-.536-1.18-.805-2.576-.805-4.186 0-1.61.269-2.99.805-4.14.552-1.165 1.311-2.055 2.277-2.668.982-.629 2.1-.943 3.358-.943 1.273 0 2.392.314 3.358.943.966.613 1.725 1.503 2.277 2.668.552 1.15.828 2.53.828 4.14 0 1.61-.276 3.005-.828 4.186-.552 1.18-1.31 2.093-2.277 2.737-.966.644-2.085.966-3.358.966zm0-1.679c.904 0 1.686-.253 2.345-.759.675-.521 1.196-1.242 1.564-2.162.368-.935.552-2.032.552-3.289 0-1.87-.406-3.35-1.219-4.439-.812-1.089-1.893-1.633-3.243-1.633-1.35 0-2.43.544-3.243 1.633-.812 1.089-1.219 2.568-1.219 4.439 0 1.257.184 2.354.552 3.289.368.92.89 1.64 1.564 2.162.675.506 1.457.759 2.346.759zM7.863 24.725h15.272v1.15H7.863v-1.15z"
                fill="#252232"
            />
        </svg>
    );
}

const MemoSvgDefinition = React.memo(SvgDefinition);
export default MemoSvgDefinition;
