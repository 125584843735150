import React, { useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { Book, Parents, WebcamIcon } from '@lms-elements/icons';
import { getTimeData, getTimeStatus } from '@lms-elements/utils';

import { DateHomeworkProps } from './DateHomeworkItem.types';

import './DateHomeworkItem.scss';

const CnDateHomeworkItem = cn('date-item');

export const DateHomeworkItem: React.FC<DateHomeworkProps> = ({
    id,
    type,
    datetimeEnd,
    datetimeStart,
    groupTitle,
    subjectTitle,
    lessonTitle,
    isSelfPreparation = false,
    IconElement,
    customTitle,
}) => {
    const notLesson = useMemo(() => {
        return type !== 'lesson' && type !== 'meeting';
    }, [type]);

    const status = useMemo(() => getTimeStatus(datetimeStart, datetimeEnd), [datetimeStart, datetimeEnd]);

    const meeting = useMemo(() => type === 'meeting', [type]);

    const timeInfoText = useMemo(() => {
        if (type === 'meeting') {
            return `Собрание в ${getTimeData(datetimeStart)}`;
        }

        if (status === 'future') {
            return isSelfPreparation ? getTimeData(datetimeStart) : `начало в ${getTimeData(datetimeStart)}`;
        }

        if (status === 'past') {
            return 'Проведено';
        }

        // TODO: заменить тип attestation в проверке на актуальный!
        if (type === 'attestation') {
            return `Аттестация в ${getTimeData(datetimeStart)}`;
        }

        return isSelfPreparation ? 'сейчас' : 'Идет сейчас';
    }, [status, datetimeStart, type, isSelfPreparation]);

    const rightIcon = useMemo(() => {
        if (meeting) {
            return <Parents />;
        }
        if (isSelfPreparation) {
            return <Book />;
        }

        return <WebcamIcon />;
    }, [meeting, isSelfPreparation]);

    return (
        <div
            key={id}
            className={CnDateHomeworkItem({
                notLesson,
                status,
                isSelfPreparationPresent: status === 'present' && isSelfPreparation,
            })}
        >
            <div className={CnDateHomeworkItem('icon', { status, meeting })}>
                {IconElement ? IconElement : rightIcon}
            </div>
            <div className={CnDateHomeworkItem('info')}>
                <span className={CnDateHomeworkItem('statusLabel')}>
                    {customTitle || isSelfPreparation
                        ? `${customTitle ? customTitle : 'Самоподго\u00adтовка'} ${timeInfoText}, `
                        : timeInfoText}
                </span>
                <div className={CnDateHomeworkItem('title', { status })}>
                    {subjectTitle && (
                        <span className={CnDateHomeworkItem('subject-title')} title={subjectTitle}>
                            {subjectTitle},
                        </span>
                    )}
                    {lessonTitle && (
                        <span className={CnDateHomeworkItem('topic-title')} title={lessonTitle}>
                            {lessonTitle}
                        </span>
                    )}
                    {groupTitle && (
                        <span className={CnDateHomeworkItem('group-title')} title={groupTitle}>
                            {groupTitle}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};
