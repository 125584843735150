import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgWithReasonIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={22}
            height={22}
            viewBox="5 5 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M13.5 12.5l3 5.5M19 12.5L16.5 19c-.167.5-.5 1.5-2.5 1.5"
                stroke="#E6A139"
                strokeWidth={1.5}
                strokeLinecap="round"
            />
            <path
                d="M16 26.5c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
                stroke="#E6A139"
                strokeWidth={1.5}
                strokeMiterlimit={10}
            />
        </svg>
    );
}

const MemoSvgWithReasonIcon = React.memo(SvgWithReasonIcon);
export default MemoSvgWithReasonIcon;
