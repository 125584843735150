import { IVideoValues, supportedVideoFormats } from './ExpandedVideoMaterial.types';

export const validateUrl = (value: string): string | undefined =>
    value?.startsWith('http') && supportedVideoFormats.some((format) => value?.includes(format)) ? undefined : 'error';

export const validateVideoValues = (values: IVideoValues): string | undefined =>
    values?.videoData?.file ? undefined : validateUrl(values?.videoData?.url);

export const transformUrl = (value: string): string => {
    switch (true) {
        case value.includes('youtube'):
            return value.replace('watch?v=', 'embed/').replace(/&.*$/, '');

        case value.includes('vimeo'):
            return value.replace('vimeo.com', 'player.vimeo.com/video');

        case value.includes('facebook'): {
            const startUrl = 'https://www.facebook.com/plugins/video.php?href=';
            const embedVideo = value.replace('/', '%2F').replace(':', '%3A');
            return startUrl + embedVideo;
        }

        default:
            return value;
    }
};
