import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Latex from 'react-latex-next';
import { convertToRaw, EditorState } from 'draft-js';

import { ActionBlockTypes, EntityTypes, INSERT_WORD_REPLACE } from '../types/shared.types';
import { draftToHtml, getActionBlockMarkup } from '../utils';

import { useHiddenNode } from './useHiddenNode';

type ReturnType = { render: () => React.ReactPortal | undefined; markup: string };

/**
 *
 * @param editorState draft-js state of your editor
 * @returns object with render() fuction that should be located anywhere in your component return statement.
 * It will not be affect your component visual state because it creates hidden element at the end of the <html> tag.
 * markup is a string with editorState converted to html
 */
export const useEditorToHTML = (editorState: EditorState): ReturnType => {
    const [markup, setMarkup] = useState('');
    const [latexes, setLatexes] = useState<string[]>([]);

    const hiddenNode = useHiddenNode({ needHide: true });

    useEffect(() => {
        // Использован setTimeout, потому что при вставке latex markup изначально создается неправильный(<p>{формула}</p> вместо полной разметки latex)
        setTimeout(() => {
            setMarkup(
                draftToHtml(
                    convertToRaw(editorState.getCurrentContent()),
                    undefined,
                    undefined,
                    ({ type }, text) => {
                        switch (type) {
                            case EntityTypes.LATEX:
                                setLatexes((prev) => [...prev.filter((el) => el !== text), text]);
                                return (
                                    hiddenNode &&
                                    Array.from(hiddenNode?.children).find((el) => el.id === text)?.innerHTML
                                );
                            case EntityTypes.INSERT_WORD:
                                return INSERT_WORD_REPLACE;
                            default:
                                return undefined;
                        }
                    },
                    (block, innerMarkup) => {
                        switch (block.type) {
                            case ActionBlockTypes.INFO_BLOCK:
                            case ActionBlockTypes.WARNING_BLOCK:
                            case ActionBlockTypes.THEOREM_BLOCK:
                            case ActionBlockTypes.QUOTE_BLOCK:
                            case ActionBlockTypes.DEFINITION_BLOCK:
                                return getActionBlockMarkup(block.type, innerMarkup);
                            default:
                                return innerMarkup;
                        }
                    },
                ),
            );
        });
    }, [editorState, hiddenNode]);

    const render = useCallback(
        () =>
            hiddenNode &&
            ReactDOM.createPortal(
                latexes.map((text) => (
                    <div key={text} id={text}>
                        <Latex>{text.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll('&amp;', '&')}</Latex>
                    </div>
                )),
                hiddenNode,
            ),
        [hiddenNode, latexes],
    );

    return { render, markup };
};
