import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useField, useForm } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { useClickOutside } from '@lms-elements/hooks';
import { Basket } from '@lms-elements/icons';
import debounce from 'lodash.debounce';

import { ITableCoordinates } from './TableSize/TableSize.types';
import { DEFAULT_CELL } from './constants';
import { EditorField } from './EditorField';
import { IExpandedTableMaterialProps, ITableValues, TableData } from './ExpandedTableMaterial.types';
import { TableContentStudent } from './TableContentStudent';
import { TableContentTeacher } from './TableContentTeacher';
import { TableSize } from './TableSize';
import {
    addTableColumn,
    addTableRow,
    createColumnWidth,
    createEmptyHTML,
    createEmptyTable,
    createRowHeight,
    removeTableColumn,
    removeTableRow,
} from './utils';

import './ExpandedTableMaterial.scss';

const CnExpandedTableMaterial = cn('expanded-material-table');

export const ExpandedTableMaterial: React.FC<IExpandedTableMaterialProps> = ({
    fieldName,
    onDelete,
    showForStudents = false,
    cellClassName = CnExpandedTableMaterial('content-cell'),
}) => {
    const { change, batch } = useForm();

    const {
        input: { value: fieldValue },
        meta,
    } = useField<ITableValues>(fieldName);

    const [titleInputFocused, setTitleInputFocused] = useState(false);

    const onTitleInputFocus = useCallback(() => setTitleInputFocused(true), []);

    const onTitleInputBlur = useCallback(() => setTitleInputFocused(false), []);

    const titleChange = useRef(
        debounce((name: string, value: string) => {
            change(name, value);
        }, 500),
    );

    const onTitleInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => titleChange.current(`${fieldName}.tableData.title`, e.target.value),
        [fieldName],
    );

    const tableName = `${fieldName}.tableData.table`;

    const { input: tableData } = useField<TableData>(tableName);

    const handleTableResize = useCallback(
        (value: ITableCoordinates) =>
            batch(() => {
                change(`${tableName}.size`, value);
                change(`${tableName}.table`, createEmptyTable(value));
                change(`${tableName}.widths`, createColumnWidth(value.x));
                change(`${tableName}.heights`, createRowHeight(value.y));
                change(`${tableName}.minHeights`, createRowHeight(value.y));
                change(`${tableName}.html`, createEmptyHTML(value));
            }),
        [batch, change, tableName],
    );

    const needShowTableSize = useMemo(() => !(tableData.value?.size?.x + tableData.value?.size?.y), [tableData]);

    const [focusedCell, setFocusedCell] = useState(DEFAULT_CELL);
    const [isFocused, setIsFocused] = useState(false);
    const [needToFocus, setNeedToFocus] = useState(false);

    const handleFocus = useCallback(
        (value: ITableCoordinates) => {
            if (!showForStudents) {
                setFocusedCell(value);
                setIsFocused(true);
                setNeedToFocus(true);
                setIsEditorUpdated(false);
            }
        },
        [showForStudents],
    );

    const [isResizing, setIsResizing] = useState(false);
    const [isHorizontalResizing, setIsHorizontalResizing] = useState(false);

    const tableRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = useCallback(() => {
        if (!isResizing || !isHorizontalResizing) {
            setIsFocused(false);
        }
    }, [isResizing, isHorizontalResizing]);

    useClickOutside(tableRef, handleClickOutside);

    const editorFieldName = isFocused
        ? `${tableName}.table.${focusedCell.y}.${focusedCell.x}`
        : `${tableName}.editorBuffer`;

    const addEmptyRow = useCallback(
        (newRow: number) => {
            void new Promise((resolve) => resolve(change(tableName, addTableRow(tableData.value, newRow)))).then(() =>
                batch(() => {
                    change(`${tableName}.table.${newRow}.${focusedCell.x}`, '');
                    setNeedToFocus(true);
                }),
            );
        },
        [batch, change, focusedCell.x, tableData.value, tableName],
    );

    const addEmptyColumn = useCallback(
        (newColumn: number) => {
            void new Promise((resolve) => resolve(change(tableName, addTableColumn(tableData.value, newColumn)))).then(
                () =>
                    batch(() => {
                        change(`${tableName}.table.${focusedCell.y}.${newColumn}`, '');
                        setNeedToFocus(true);
                    }),
            );
        },
        [batch, change, focusedCell.y, tableData.value, tableName],
    );

    useEffect(() => {
        if (!showForStudents) {
            const onPressEscape = (e: KeyboardEvent) => {
                if (e.key?.toLowerCase() === 'escape') {
                    setIsFocused(false);
                }
            };
            document.addEventListener('keydown', onPressEscape, false);
            return () => {
                document.removeEventListener('keypress', onPressEscape, false);
            };
        }
    }, [showForStudents]);

    const handleRemoveCol = useCallback(
        (colIndex: number) => {
            void new Promise((resolve) =>
                resolve(setFocusedCell((prev) => (prev.x > colIndex ? { ...prev, x: prev.x - 1 } : prev))),
            )
                .then(() => change(tableName, removeTableColumn(tableData.value, colIndex)))
                .then(() => setIsFocused(false));
        },
        [change, tableData.value, tableName],
    );

    const handleRemoveRow = useCallback(
        (rowIndex: number) => {
            void new Promise((resolve) =>
                resolve(setFocusedCell((prev) => (prev.y > rowIndex ? { ...prev, y: prev.y - 1 } : prev))),
            )
                .then(() => change(tableName, removeTableRow(tableData.value, rowIndex)))
                .then(() => setIsFocused(false));
        },
        [change, tableData.value, tableName],
    );

    const [isEditorUpdated, setIsEditorUpdated] = useState(false);

    return (
        <div>
            {!showForStudents && <h3 className={CnExpandedTableMaterial('header')}>Таблица</h3>}
            <div className={CnExpandedTableMaterial({ student: showForStudents, error: !meta.valid })}>
                {showForStudents ? (
                    <h3 className={CnExpandedTableMaterial('header')}>{fieldValue?.tableData?.title}</h3>
                ) : (
                    <input
                        spellCheck
                        defaultValue={fieldValue?.tableData?.title}
                        onChange={onTitleInputChange}
                        onFocus={onTitleInputFocus}
                        onBlur={onTitleInputBlur}
                        className={CnExpandedTableMaterial('input', {
                            noBorders: true,
                            oneBorderFocus: titleInputFocused,
                        })}
                        placeholder="Введите название таблицы"
                    />
                )}
                {needShowTableSize ? (
                    <TableSize onElementClick={handleTableResize} tableName={tableName} />
                ) : (
                    <div ref={tableRef} className={CnExpandedTableMaterial('table', { student: showForStudents })}>
                        {!showForStudents && (
                            <EditorField
                                name={editorFieldName}
                                placeholder={isFocused ? 'Введите значение ячейки' : 'Выберите ячейку'}
                                isFocused={isFocused}
                                needToFocus={needToFocus}
                                setNeedToFocus={setNeedToFocus}
                                isEditorUpdated={isEditorUpdated}
                                setIsEditorUpdated={setIsEditorUpdated}
                            />
                        )}
                        {showForStudents ? (
                            <TableContentStudent
                                tableName={tableName}
                                tableHTML={tableData.value.html}
                                tableWidths={tableData.value.widths}
                                cellClassName={cellClassName}
                            />
                        ) : (
                            <TableContentTeacher
                                tableName={tableName}
                                onClickAddColumns={addEmptyColumn}
                                onClickAddRows={addEmptyRow}
                                onFocus={handleFocus}
                                isFocused={isFocused}
                                focusedCell={focusedCell}
                                setNeedToFocus={setNeedToFocus}
                                isResizing={isResizing}
                                setIsResizing={setIsResizing}
                                isHorizontalResizing={isHorizontalResizing}
                                setIsHorizontalResizing={setIsHorizontalResizing}
                                cellClassName={cellClassName}
                                onRemoveCol={handleRemoveCol}
                                onRemoveRow={handleRemoveRow}
                            />
                        )}
                    </div>
                )}
                {!showForStudents && (
                    <button className={CnExpandedTableMaterial('delete')} onClick={onDelete} type="button">
                        <Basket />
                    </button>
                )}
            </div>
        </div>
    );
};
