import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { EditorState } from 'draft-js';
import { getIn } from 'final-form';
import { throttle } from 'lodash';

import { MIN_CELL_HEIGHT, MIN_CELL_WIDTH } from '../constants';
import { ITableCoordinates } from '../TableSize/TableSize.types';
import { createRowHeight } from '../utils';

import { HorizontalResizeButtonGroup } from './HorizontalResizeButtonGroup';
import { TableCell } from './TableCell';
import { ITableContentTeacherProps } from './TableContentTeacher.types';
import { VerticalResizeButtonGroup } from './VerticalResizeButtonGroup';

import './TableContentTeacher.scss';

const CnTableContent = cn('table-content');

export const TableContentTeacher: React.FC<ITableContentTeacherProps> = ({
    tableName,
    onFocus,
    isFocused,
    focusedCell,
    onClickAddRows,
    onClickAddColumns,
    setNeedToFocus,
    isResizing,
    setIsResizing,
    isHorizontalResizing,
    setIsHorizontalResizing,
    cellClassName,
    onRemoveRow,
    onRemoveCol,
}) => {
    const { values } = useFormState();

    const { change, batch } = useForm();

    const [widths, setWidths] = useState<number[]>(getIn(values, `${tableName}.widths`) ?? []);
    const [heights, setHeights] = useState<number[]>(getIn(values, `${tableName}.heights`) ?? []);
    const [minHeights, setMinHeights] = useState<number[]>(getIn(values, `${tableName}.minHeights`) ?? []);

    const [size] = useState<ITableCoordinates>(getIn(values, `${tableName}.size`) ?? { x: 0, y: 0 });

    useEffect(() => {
        if (size.y && !heights.length) {
            batch(() => {
                change(`${tableName}.heights`, createRowHeight(size.y));
                change(`${tableName}.minHeights`, createRowHeight(size.y));
            });
            setHeights(createRowHeight(size.y));
            setMinHeights(createRowHeight(size.y));
        }
    }, [batch, change, heights.length, size.y, tableName]);

    const table = useMemo(() => (getIn(values, `${tableName}.table`) as EditorState[][]) ?? [], [tableName, values]);

    const checkBorder = useCallback(
        (value: number, checkValue: number) => !isFocused && value < checkValue - 1,
        [isFocused],
    );

    const changeWidth = useRef(
        throttle(
            (indexChange: number, width: number) =>
                setWidths((prev) => prev.map((value, index) => (indexChange === index ? width : value))),
            100,
        ),
    );

    const changeHeight = useRef(
        throttle(
            (indexChange: number, height: number) =>
                setHeights((prev) => prev.map((value, index) => (indexChange === index ? height : value))),
            100,
        ),
    );

    const changeMinHeight = useRef(
        throttle(
            (indexChange: number, minHeight: number) =>
                setMinHeights((prev) => prev.map((value, index) => (indexChange === index ? minHeight : value))),
            100,
        ),
    );

    useEffect(() => {
        if (!isResizing) {
            change(`${tableName}.widths`, widths);
        }
    }, [change, isResizing, tableName, widths]);

    useEffect(() => {
        if (!isHorizontalResizing) {
            change(`${tableName}.heights`, heights);
        }
    }, [change, isHorizontalResizing, tableName, heights]);

    const width = useMemo(() => widths.reduce((sum, cur) => sum + cur + 2, 0), [widths]);

    const handleRemoveRow = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            const coords = e.currentTarget.dataset as { y?: string; x?: string };

            if (coords.x && onRemoveCol) {
                onRemoveCol(Number(coords.x));
                setWidths((prev) => prev.filter((_, x) => `${x}` !== coords.x));
            }

            if (coords.y && onRemoveRow) {
                onRemoveRow(Number(coords.y));
                setHeights((prev) => prev.filter((_, y) => `${y}` !== coords.y));
                setMinHeights((prev) => prev.filter((_, y) => `${y}` !== coords.y));
            }
        },
        [onRemoveCol, onRemoveRow],
    );

    const handleAddColumn = useCallback(
        (x: number) => {
            onClickAddColumns(x);
            setWidths((prev) => {
                if (x === prev.length) {
                    return [...prev, MIN_CELL_WIDTH];
                }

                return [...prev.slice(0, x), MIN_CELL_WIDTH, ...prev.slice(x)];
            });
        },
        [onClickAddColumns],
    );

    const handleAddRow = useCallback(
        (y: number) => {
            onClickAddRows(y);
            setHeights((prev) => {
                if (y === prev.length) {
                    return [...prev, MIN_CELL_HEIGHT];
                }

                return [...prev.slice(0, y), MIN_CELL_HEIGHT, ...prev.slice(y)];
            });

            setMinHeights((prev) => {
                if (y === prev.length) {
                    return [...prev, MIN_CELL_HEIGHT];
                }

                return [...prev.slice(0, y), MIN_CELL_HEIGHT, ...prev.slice(y)];
            });
        },
        [onClickAddRows],
    );

    return (
        <div className={CnTableContent({ focused: isFocused })} style={{ maxWidth: width }}>
            {table?.map((row, y) => (
                <React.Fragment key={`ContentRow${tableName}${y}`}>
                    <div className={CnTableContent('row', { border: checkBorder(y, table?.length) })} style={{ width }}>
                        {isFocused && (
                            <div className={CnTableContent('remove-btn-wrapper')}>
                                <button
                                    type="button"
                                    className={CnTableContent('remove-btn')}
                                    data-y={y}
                                    onClick={handleRemoveRow}
                                >
                                    <span className={CnTableContent('remove-btn-text')}>-</span>
                                </button>
                            </div>
                        )}
                        {row.map((_, x) => (
                            <div key={`ContentCell${tableName}${x}_${y}`} className={CnTableContent('cell-wrapper')}>
                                {isFocused && y === 0 && (
                                    <div
                                        className={CnTableContent('remove-btn-wrapper', { col: true })}
                                        style={{ width: widths[x] }}
                                    >
                                        <button
                                            type="button"
                                            className={CnTableContent('remove-btn', { col: true })}
                                            data-x={x}
                                            onClick={handleRemoveRow}
                                        >
                                            <span className={CnTableContent('remove-btn-text', { col: true })}>-</span>
                                        </button>
                                    </div>
                                )}
                                <TableCell
                                    border={checkBorder(x, row.length)}
                                    focusedCell={focusedCell}
                                    isFocused={isFocused}
                                    isResizing={isResizing}
                                    isHorizontalResizing={isHorizontalResizing}
                                    onClick={onFocus}
                                    handleChangeHeight={changeHeight.current}
                                    handleChangeMinHeight={changeMinHeight.current}
                                    tableName={tableName}
                                    width={widths[x]}
                                    height={heights[y]}
                                    x={x}
                                    y={y}
                                    cellClassName={cellClassName}
                                />
                            </div>
                        ))}
                    </div>
                </React.Fragment>
            ))}
            {isFocused && (
                <>
                    <HorizontalResizeButtonGroup
                        handleTableResize={changeHeight.current}
                        onClickAddRows={handleAddRow}
                        setIsResizing={setIsHorizontalResizing}
                        setNeedToFocus={setNeedToFocus}
                        tableName={tableName}
                        heights={heights}
                        minHeights={minHeights}
                        width={width}
                    />
                    <VerticalResizeButtonGroup
                        handleTableResize={changeWidth.current}
                        onClickAddColumns={handleAddColumn}
                        setIsResizing={setIsResizing}
                        setNeedToFocus={setNeedToFocus}
                        tableName={tableName}
                        widths={widths}
                    />
                </>
            )}
        </div>
    );
};
