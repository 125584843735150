import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgUserCircleDisabled({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={22}
            height={22}
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M4.18 3.686A9.973 9.973 0 001 11c0 5.523 4.477 10 10 10a9.964 9.964 0 006.73-2.603m1.876-2.3A9.953 9.953 0 0021 11c0-5.523-4.477-10-10-10-1.39 0-2.714.284-3.917.796"
                stroke="#C0C2C9"
                strokeWidth={1.5}
                strokeMiterlimit={10}
                strokeLinecap="round"
            />
            <path
                d="M13.38 13.587A4.167 4.167 0 017.88 7.406M15 11.34a4.167 4.167 0 00-4.65-5.289"
                stroke="#C0C2C9"
                strokeWidth={1.5}
                strokeMiterlimit={10}
                strokeLinecap="round"
            />
            <path
                d="M4.313 18.435a7.503 7.503 0 0113.375 0M2.5 1.5l17.5 19"
                stroke="#C0C2C9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgUserCircleDisabled = React.memo(SvgUserCircleDisabled);
export default MemoSvgUserCircleDisabled;
