import { RawDraftContentBlock } from 'draft-js';

import { CnCodeBlockComponent } from '../../components/CodeBlockWrapper';

import { getBlockInnerMarkup } from './block';
import { CustomEntityTransform, EntityMap, HashtagConfig } from './draftToHtml.types';

export const getCodeMarkup = (
    codeBlocks: RawDraftContentBlock[],
    entityMap: EntityMap,
    hashtagConfig?: HashtagConfig,
    directional?: boolean,
    customEntityTransform?: CustomEntityTransform,
) => {
    const codeHtml: string[] = [];
    codeHtml.push(`<ol class="${CnCodeBlockComponent()}">`);
    codeBlocks.forEach((block) => {
        codeHtml.push('<li>');
        codeHtml.push(getBlockInnerMarkup(block, entityMap, hashtagConfig, customEntityTransform));
        codeHtml.push('</li>');
    });
    codeHtml.push('</ol>');
    return codeHtml.join('');
};
