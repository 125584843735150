import React, { useCallback, useEffect, useState } from 'react';
import { cn } from '@bem-react/classname';
import { Angle } from '@lms-elements/icons';

import { AdditionalCourseCard } from '../AdditionalCourseCard';

import { IAdditionalCourseCarouselProps } from './AdditionalCourseCarousel.types';

import './AdditionalCourseCarousel.scss';

export const CnAdditionalCourseCarousel = cn('additionalCourseCarousel');

export const AdditionalCourseCarousel: React.FC<IAdditionalCourseCarouselProps> = ({
    list,
    isMobile,
    onCarouselClick,
}) => {
    const [current, setCurrent] = useState(0);
    const [leftDisable, setLeftDisable] = useState(true);
    const [rightDisable, setRightDisable] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [checked, setChecked] = useState(0);

    useEffect(() => {
        setLeftDisable(current === 0);
        setRightDisable(current === list.length - 1);
    }, [current, list]);

    const handleLeftButtonClick = useCallback(() => setCurrent((prev) => (prev > 0 ? prev - 1 : 0)), []);

    const handleRightButtonClick = useCallback(
        () => setCurrent((prev) => (prev < list.length - 1 ? prev + 1 : list.length - 1)),
        [list],
    );

    const handleCarouselClick = useCallback(() => {
        setIsChecked(true);
        setChecked(current);
        onCarouselClick?.(list[current].id);
    }, [current, list, onCarouselClick]);

    return (
        <div className={CnAdditionalCourseCarousel({ isMobile })}>
            <button
                type="button"
                className={CnAdditionalCourseCarousel('button', { is: 'left', leftDisable })}
                onClick={handleLeftButtonClick}
            >
                <Angle />
            </button>
            <div
                className={CnAdditionalCourseCarousel('card', { isMobile, isChecked, checked: checked === current })}
                onClick={handleCarouselClick}
            >
                {<AdditionalCourseCard {...list[current]} isMobile={isMobile} />}
            </div>
            <button
                type="button"
                className={CnAdditionalCourseCarousel('button', { is: 'right', rightDisable })}
                onClick={handleRightButtonClick}
            >
                <Angle />
            </button>
        </div>
    );
};
