import moment from 'moment';

export const checkIsDateBeetween = (
    time: string | Date,
    element: { startTime: string; startTimeFormat?: string; endTime: string; endTimeFormat?: string },
): boolean => {
    return moment(time).isBetween(
        moment(element.startTime, element.startTimeFormat),
        moment(element.endTime, element.endTimeFormat),
        'minutes',
        '[]',
    );
};
