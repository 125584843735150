import React, { useCallback } from 'react';
import { cn } from '@bem-react/classname';

import { IBreadcrumbsItemProps } from './BreadcrumbsItem.types';

import './BreadcrumbsItem.scss';

const CnBreadcrumbsItem = cn('breadcrumbsItem');

export const BreadcrumbsItem: React.FC<IBreadcrumbsItemProps> = ({
    title,
    path = '',
    isChecked,
    isShort,
    onItemClick,
}) => {
    const handleItemClick = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();

            onItemClick(path);
        },
        [onItemClick, path],
    );

    return (
        <div className={CnBreadcrumbsItem({ checked: isChecked, isShort })}>
            {isChecked ? (
                <span>{title}</span>
            ) : (
                <a href={path} onClick={handleItemClick}>
                    {title}
                </a>
            )}
        </div>
    );
};
