export enum MessageBlockText {
    EmailConfirmed = 'Адрес электронной почты подтвержден.',
    EmailToBeConfirmed = 'Письмо со ссылкой для подтверждения адреса email отправлено на',
    ResetToBeConfirmed = 'Письмо со ссылкой для подтверждения сброса пароля отправлено на',
}

export enum MessageBlockType {
    Done = 'done',
    ToBeDone = 'toBeDone',
    Error = 'error',
}

export interface IMessageBlockProps {
    text: MessageBlockText | string;
    type: MessageBlockType;
    email?: string;
}
