import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, useField } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { Checkbox } from '@lms-elements/atomic';

import { AnswerOption, IQuestion } from '../Answer.types';
import { usePreviouslySubmittedValue } from '../hooks/usePreviouslySubmittedValue';

import { IMultipleAnswerOptionsProps } from './MultipleAnswerOptions.types';

import './MultipleAnswerOptions.scss';

const MultipleAnswerOptionsCn = cn('multiple-answer-options');

export const MultipleAnswerOptions: React.FC<IMultipleAnswerOptionsProps> = ({
    name,
    withEditor,
    optionsClassName,
    disabled,
    correctAnswers = [],
    isFetching = false,
    randomOrder = false,
    isDraft = false,
}) => {
    const { input } = useField<IQuestion>(name);
    const { input: answerOptions } = useField<AnswerOption[]>(`${name}.answerOptions`);

    const [randomArray, setRandomArray] = useState<AnswerOption[]>(answerOptions.value);
    const [fetching, setFetching] = useState<boolean>(false);

    useEffect(() => {
        if (!fetching && isFetching) setFetching(true);
    }, [fetching, isFetching]);

    const previouslySubmittedValue = usePreviouslySubmittedValue(
        input.value.answer || [],
        isFetching,
        fetching,
        setFetching,
    );

    const checkCorrect = useCallback(
        (answer: string) => correctAnswers.length > 0 && correctAnswers.includes(answer),
        [correctAnswers],
    );

    const checkNotCorrect = useCallback(
        (answer: string) =>
            correctAnswers.length > 0 &&
            previouslySubmittedValue.includes(answer) &&
            !correctAnswers.includes(answer) &&
            !isDraft,
        [correctAnswers, isDraft, previouslySubmittedValue],
    );

    const incomplete = useMemo(
        () =>
            !correctAnswers.reduce(
                (complete, correctAns) => complete && Boolean(input.value.answer?.includes(correctAns)),
                true,
            ),
        [correctAnswers, input.value.answer],
    );

    useEffect(() => {
        if (randomOrder) {
            if ((input.value.correctAnswers || []).length === 0) {
                setRandomArray([...answerOptions.value].sort(() => Math.random() - 0.5));
            }
        } else {
            setRandomArray(answerOptions.value);
        }
    }, [answerOptions.value, input.value.correctAnswers, randomOrder]);
    return (
        <>
            {randomArray.map((value) => (
                <div
                    className={MultipleAnswerOptionsCn('option', {
                        incomplete,
                        correct: checkCorrect(value.id),
                        'not-correct': checkNotCorrect(value.id),
                    })}
                    key={value.id}
                >
                    <Field<string> type="checkbox" name={`${name}.answer`} value={value.id} role={'checkbox'}>
                        {({ input }) =>
                            withEditor ? (
                                <>
                                    <Checkbox {...input} label="" disabled={disabled} />
                                    <div
                                        className={optionsClassName}
                                        dangerouslySetInnerHTML={{ __html: value.markup }}
                                    />
                                </>
                            ) : (
                                <Checkbox {...input} label={value.markup} disabled={disabled} />
                            )
                        }
                    </Field>
                </div>
            ))}
        </>
    );
};
