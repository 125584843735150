import React, { useCallback, useMemo, useState } from 'react';
import { cn } from '@bem-react/classname';
import { Basket, CopyIcon, EditFile, NoteBlank, NoteBlankTemplate, Send } from '@lms-elements/icons';

import { IFileProps } from './File.types';

import './File.scss';

const CnFile = cn('file');

export const File: React.FC<IFileProps> = ({
    name,
    isTemplate,
    url,
    id,
    deleteFile,
    needHideDeleteButton,
    needHideSendButton,
    needHideEditButton,
    needHideCopyToClipboardButton,
    editFile,
}) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = useCallback(() => setIsHovered(true), []);
    const handleMouseLeave = useCallback(() => setIsHovered(false), []);

    const isEditable = useMemo(() => !isTemplate && isHovered, [isTemplate, isHovered]);

    const copyFile = useCallback(() => navigator.clipboard.writeText(url), [url]);

    return (
        <div className={CnFile()} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className={CnFile('main')}>
                {isTemplate ? <NoteBlankTemplate /> : <NoteBlank />}
                <a href={url} rel="noreferrer" target="_blank" className={CnFile('name')}>
                    {name}
                </a>
            </div>
            {isEditable &&
                (!needHideSendButton ||
                    !needHideDeleteButton ||
                    !needHideEditButton ||
                    !needHideCopyToClipboardButton) && (
                    <div className={CnFile('hoverIcons')}>
                        {!needHideSendButton && (
                            <button className={CnFile('send')} id={String(id)} type="button">
                                <Send />
                            </button>
                        )}
                        {!needHideCopyToClipboardButton && (
                            <button className={CnFile('copy')} id={String(id)} onClick={copyFile} type="button">
                                <CopyIcon />
                            </button>
                        )}
                        {!needHideDeleteButton && (
                            <button className={CnFile('basket')} id={String(id)} onClick={deleteFile} type="button">
                                <Basket />
                            </button>
                        )}
                        {!needHideEditButton && (
                            <button className={CnFile('edit')} id={String(id)} onClick={editFile} type="button">
                                <EditFile />
                            </button>
                        )}
                    </div>
                )}
        </div>
    );
};
