import React from 'react';
import { cn } from '@bem-react/classname';

import { NavbarItem } from '../NavbarItem';
import { NavBarStudent } from '../NavBarStudent';

import { NavbarContentProps } from './NavbarContent.types';

import './NavbarContent.scss';

const CnNavbarContent = cn('navbarContent');

export const NavbarContent: React.FC<NavbarContentProps> = ({
    students,
    navbarItems,
    currentPath,
    isMobile = false,
    onNavbarItemClick,
    onStudentClick,
    onSettingClick,
}) => {
    const hasStudents = students && students.length > 0;

    return (
        <div className={CnNavbarContent({ isMobile })}>
            {hasStudents && (
                <div className={CnNavbarContent('students', { isMobile })}>
                    <div className={CnNavbarContent('students-content')}>
                        {students?.map((student) => (
                            <div className={CnNavbarContent('student')} key={student.id} data-id={student.id}>
                                <NavBarStudent
                                    id={student.id}
                                    firstName={student.firstName}
                                    lastName={student.lastName}
                                    photoUrl={student.photoUrl}
                                    grade={student.grade}
                                    selected={student.selected}
                                    isMobile={isMobile}
                                    onSettingClick={onSettingClick}
                                    onStudentClick={onStudentClick}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <div className={CnNavbarContent('nav', { isMobile })}>
                {navbarItems.map((el, index) => {
                    return (
                        <NavbarItem
                            isMobile={isMobile}
                            key={index}
                            currentPath={currentPath}
                            {...el}
                            onNavbarItemClick={onNavbarItemClick}
                        />
                    );
                })}
            </div>
        </div>
    );
};
