import React from 'react';
import { cn } from '@bem-react/classname';

import { EmptyNotifications } from '../EmptyNotifications';
import { NotificationList } from '../NotificationList';

import { IViewedNotificationsProps } from './ViewedNotifications.types';

const CnViewedNotifications = cn('viewedNotifications');

export const ViewedNotifications: React.FC<IViewedNotificationsProps> = ({ notificationGroups, emptyText }) => (
    <div className={CnViewedNotifications()}>
        <NotificationList notificationGroups={notificationGroups} needShowFirstTitle />
        {notificationGroups.length === 0 && <EmptyNotifications text={emptyText || 'Нет просмотренных оповещений'} />}
    </div>
);
