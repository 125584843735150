import React from 'react';

import { LessonEditMode } from './LessonEditMode/LessonEditMode';
import { LessonViewMode } from './LessonViewMode/LessonViewMode';
import { ILessonStorybookProps } from './Lesson.types';

export const LessonStorybook: React.FC<ILessonStorybookProps> = ({
    number = 1,
    name = '',
    isEditBlocked = false,
    themeOrder = 1,
    homeworkStatus,
    description = '',
    lessonOverall = 1,
    needHideTopicOrder = false,
    haveOnlineLesson = false,
    isActive = true,
    isDisabled = false,
    isEditModeOn = false,
    isCreateModeOn,
    canEdit,
    showContents = true,
    needChangeSaveButtonTextOnTablet,
    needValidateDescription,
    needPublishButton,
    withNotPublishedLabel,
    needHideTasks,
    type = 'Обычный',
    withCourseAssignment = true,
    isBlockedMaterials,
    timePickerPosition,
    canOnlineLessonEdit,
    isButtonEditBlock = false,
    hideCheckbox = false,
    lessonItems = [],
    onEditClick,
    onSaveClick,
    onCancelClick,
}) => {
    if (isEditModeOn || isCreateModeOn) {
        return (
            <LessonEditMode
                isEditModeOn={isEditModeOn}
                isCreateModeOn={isCreateModeOn}
                isEditBlocked={isEditBlocked}
                lessonOverall={lessonOverall}
                initialValues={{
                    lessonId: 1,
                    order: number,
                    name: name,
                    orderTitle: `${isEditModeOn ? number + 1 : number} по порядку`,
                    description: description,
                    type: type,
                    lessonItems: lessonItems,
                }}
                withCourseAssignment={withCourseAssignment}
                needChangeSaveButtonTextOnTablet={needChangeSaveButtonTextOnTablet}
                needValidateDescription={needValidateDescription}
                needPublishButton={needPublishButton}
                timePickerPosition={timePickerPosition}
                canOnlineLessonEdit={canOnlineLessonEdit}
                hideCheckbox={hideCheckbox}
                onEditClick={onSaveClick}
                onCancelClick={onCancelClick}
            />
        );
    }
    return (
        <LessonViewMode
            lessonId={1}
            name={name}
            order={number}
            type={type === 'Резервный' ? 'reserve' : 'common'}
            themeOrder={themeOrder}
            needHideTopicOrder={needHideTopicOrder}
            description={description}
            homeworkStatus={homeworkStatus}
            isOnlineLesson={haveOnlineLesson}
            canEdit={canEdit}
            showContents={showContents}
            withNotPublishedLabel={withNotPublishedLabel}
            needHideTasks={needHideTasks}
            isActive={isActive}
            isDisabled={isDisabled}
            withCourseAssignment={withCourseAssignment}
            isBlockedMaterials={isBlockedMaterials}
            isButtonEditBlock={isButtonEditBlock}
            lessonItems={lessonItems}
            onSettingsClick={onEditClick}
            needShowMoveLessonsButton={true}
        />
    );
};
