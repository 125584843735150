export enum typesCard {
    singleLesson = 'single',
    groupLesson = 'group',
    selfPreparation = 'self',
    meeting = 'meeting',
    common = 'common',
}

export interface LessonCardProps {
    type: typesCard;
    singleLesson?: {
        color: string;
        subject: string;
        nameTeacher?: string;
        auditorium?: string;
        group?: string;
        disabled?: boolean;
        alert?: boolean;
        isGrabbing?: boolean;
    };
    groupLesson?: {
        color: string;
        subject: string;
        nameGroup: string;
        auditorium?: string;
        group?: string;
        alert?: boolean;
        isGrabbing?: boolean;
    }[];
    selfPreparation?: {
        color: string;
        subject?: string;
        auditorium?: string;
        group?: string;
        type: string;
        alert?: boolean;
        isGrabbing?: boolean;
    };
    meeting?: {
        color: string;
        teacher?: string;
        auditorium?: string;
        group?: string;
        disabled?: boolean;
        alert?: boolean;
        isGrabbing?: boolean;
    };
    common?: {
        color: string;
        title?: string;
        auditorium?: string;
        group?: string;
        disabled?: boolean;
        alert?: boolean;
        isGrabbing?: boolean;
    };
}
