import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgItalicTextIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M17.318 11.043a.454.454 0 01-.336-.126.44.44 0 01-.063-.357l.273-1.134a.682.682 0 01.189-.357.56.56 0 01.378-.147h1.302a.42.42 0 01.336.147c.07.07.105.154.105.252a.345.345 0 01-.021.105l-.252 1.134a.628.628 0 01-.21.357.614.614 0 01-.378.126h-1.323zM14.735 24a.454.454 0 01-.336-.126.44.44 0 01-.063-.357l2.121-9.954a.537.537 0 01.21-.336.56.56 0 01.378-.147h1.008c.14 0 .245.042.315.126a.44.44 0 01.042.357l-2.1 9.954a.628.628 0 01-.21.357.53.53 0 01-.357.126h-1.008z"
                fill="#252232"
            />
        </svg>
    );
}

const MemoSvgItalicTextIcon = React.memo(SvgItalicTextIcon);
export default MemoSvgItalicTextIcon;
