import React, { useCallback } from 'react';
import { cn } from '@bem-react/classname';
import { Plus } from '@lms-elements/icons';

import { ITitlerProps } from './Title.types';

import './Title.scss';

const CnTitle = cn('title');

export const Title: React.FC<ITitlerProps> = ({
    name,
    onClickAdd,
    inscription,
    onClickMove = () => undefined,
    isTask = false,
    accept,
}) => {
    const handleInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onClickAdd(e);

            e.currentTarget.value = '';
        },
        [onClickAdd],
    );

    return (
        <div className={CnTitle()}>
            <div className={CnTitle('header')}>
                <div className={CnTitle('name')}>
                    {name}
                    {!inscription && <div className={CnTitle('value')}>объёмом до 200mb</div>}
                </div>
                <label className={CnTitle('labelFileUploader')}>
                    <Plus fill="#0088E9" />

                    {!isTask ? (
                        <input
                            type="file"
                            name="file"
                            className={CnTitle('uploadFiles')}
                            onChange={handleInputChange}
                            accept={accept}
                        />
                    ) : (
                        <input type="button" className={CnTitle('uploadFiles')} onClick={onClickMove} />
                    )}
                </label>
            </div>
            {inscription && <div className={CnTitle('legend')}>{inscription}</div>}
        </div>
    );
};
