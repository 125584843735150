import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgTrash({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={18}
            height={20}
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M15 3l-1 16H4L3 3M1 3h16M7 3V2a1 1 0 011-1h2a1 1 0 011 1v1"
                stroke="#EA5959"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgTrash = React.memo(SvgTrash);
export default MemoSvgTrash;
