import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgArrowClick({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={10}
            height={18}
            viewBox="0 0 10 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d="M9 1L1.707 8.293a1 1 0 000 1.414L9 17" stroke="#0088E9" strokeWidth={2} strokeLinecap="round" />
        </svg>
    );
}

const MemoSvgArrowClick = React.memo(SvgArrowClick);
export default MemoSvgArrowClick;
