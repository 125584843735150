import React from 'react';
import { useDrag } from 'react-dnd';
import { CodifierElement, CodifierElementIconEnum } from '@lms-elements/atomic';

import { MINIMIZED_DESC, MINIMIZED_TITLE } from './CONSTANTS';
import { IMinimizedHeaderMaterialProps, MIN_HEADER_MATERIAL_DRAG_TYPE } from './MinimizedHeaderMaterial.types';

const MinimizedHeaderMaterial: React.FC<IMinimizedHeaderMaterialProps> = React.memo(
    ({ onDragEnd, disabled = false }) => {
        const [{ isDragging }, drag] = useDrag({
            type: MIN_HEADER_MATERIAL_DRAG_TYPE,
            item: () => ({ name: 'HEADER_MATERIAL' }),
            end: (item, monitor) => {
                const dropResult = monitor.getDropResult();
                if (item && dropResult && onDragEnd) {
                    onDragEnd();
                }
            },
            collect: (monitor) => ({ isDragging: monitor.isDragging() }),
        });

        return (
            <CodifierElement
                title={MINIMIZED_TITLE}
                description={MINIMIZED_DESC}
                icon={CodifierElementIconEnum.header}
                dragging={isDragging}
                dragRef={drag}
                disabled={disabled}
            />
        );
    },
);

export default MinimizedHeaderMaterial;
