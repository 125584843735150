import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgDropdownDots({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={12}
            height={21}
            viewBox="0 0 12 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <circle cx={2} cy={2} r={2} fill="#0088E9" />
            <circle cx={9.6} cy={2} r={2} fill="#0088E9" />
            <circle cx={2} cy={9.6} r={2} fill="#0088E9" />
            <circle cx={9.6} cy={9.6} r={2} fill="#0088E9" />
            <circle cx={2} cy={17.199} r={2} fill="#0088E9" />
            <circle cx={9.6} cy={17.199} r={2} fill="#0088E9" />
        </svg>
    );
}

const MemoSvgDropdownDots = React.memo(SvgDropdownDots);
export default MemoSvgDropdownDots;
