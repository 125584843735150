export const getTimeArray = (startTime: number, maxTime: number, step = 60): string[] => {
    const resultArray: string[] = [];
    const startTimeMinutes = startTime * 60;
    const endTimeMinutes = maxTime * 60;
    for (let i = startTimeMinutes; i <= endTimeMinutes; i = i + step) {
        const hours = Math.floor(i / 60);
        const minutes = i % 60;
        const newHours = String(hours).length < 2 ? `0${hours}` : String(hours);
        const newMinutes = String(minutes).length < 2 ? `0${minutes}` : String(minutes);

        resultArray.push(`${newHours}:${newMinutes}`);
    }

    return resultArray;
};
