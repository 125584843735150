export enum CounterViews {
    ARROWS = 'arrows',
    MATH = 'math',
}

export interface IFieldInputCounterCoreProps {
    makeTitle?: (value: number) => string;
    value: number;
    onChange: (value: number) => void;
    disabled?: boolean;
    min?: number;
    max?: number;
    step?: number;
    parse?: (value: number) => number;
    label?: string;
    view: CounterViews;
}
