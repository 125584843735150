import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgToggle({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={40}
            height={40}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <rect
                x={27.25}
                y={27.25}
                width={15.5}
                height={15.5}
                rx={3.25}
                transform="rotate(-180 27.25 27.25)"
                stroke="#585C6F"
                strokeWidth={1.5}
            />
            <path
                d="M25 22.715C25 23.977 23.818 25 22.36 25H21V14h1.36c1.458 0 2.64 1.023 2.64 2.285v6.43z"
                fill="#585C6F"
            />
            <path
                d="M15 23l4-3.5-4-3.5"
                stroke="#585C6F"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect x={0.5} y={0.5} width={39} height={39} rx={7.5} stroke="#585C6F" />
        </svg>
    );
}

const MemoSvgToggle = React.memo(SvgToggle);
export default MemoSvgToggle;
