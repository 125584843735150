import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgExpand({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={15}
            height={15}
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <circle cx={7.5} cy={7.5} r={6.75} fill="#EEF4FB" stroke="#0088E9" strokeWidth={1.5} />
            <path
                d="M6 4l3.5 3.5L6 11"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgExpand = React.memo(SvgExpand);
export default MemoSvgExpand;
