import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { cn } from '@bem-react/classname';
import { useOuterClickField } from '@lms-elements/hooks';
import { LittleArrow, SearchIcon } from '@lms-elements/icons';

import { Checkbox } from '../../Checkbox';

import { CustomOption } from './CustomOption';
import { IFieldDropdownCoreProps, IValues } from './FieldDropdownCore.types';
import { convertOptionsToObject } from './utils';

import './FieldDropdownCore.scss';

const CnFiedDropdownCore = cn('select');
const CnTogether = cn('together');

const DEFAULT_ID_ARRAY = [] as number[];
const DEFAULT_OPTIONS = [] as string[];

export const FieldDropdownCore: React.FC<IFieldDropdownCoreProps> = ({
    placeholder = '',
    options,
    customOptions = [],
    idArr = DEFAULT_ID_ARRAY,
    meta,
    input,
    value = null,
    onChange = undefined,
    onMultiSelect,
    needBlueIcon = false,
    needBlackIcon = false,
    child = undefined,
    isTextArea = false,
    rows = 0,
    multiple,
    newValue,
    isLocationDropdown,
    initOptions = DEFAULT_OPTIONS,
    disabled = false,
    flagReset = false,
    isNeedSearch = false,
    searchPlaceholder = 'Поиск',
    searchForAll = false,
    setFlagReset = undefined,
    makeMultipleInputValue = (values) =>
        Object.entries(values)
            .filter(([key, val]) => values[key].value === val.value)
            .reduce(
                (accum, [, val], index, arr) =>
                    accum + (val.isSelected ? val.value + (index === arr.length - 1 ? '' : ', ') : ''),
                '',
            ),
    dataCy,
}) => {
    const [isBlur, setIsBlur] = useState(false);
    const ref = useRef(null);
    const [values, setValues] = useState<IValues | undefined>();
    const [searchValue, setSearchValue] = useState<string>();

    useEffect(() => {
        if (options) {
            setValues(convertOptionsToObject(options, initOptions, idArr));
        }
    }, [idArr, initOptions, options]);

    const isDisabled = useMemo(() => (options?.length === 0 && !child) || disabled, [child, options?.length, disabled]);

    const makeHandlerMultipleOptionsClick = useCallback(
        (el: string, index: number) =>
            multiple
                ? () => {
                      if (values) {
                          const newValues = {
                              ...values,
                              [`${index}`]: {
                                  value: el,
                                  id: idArr && idArr.length === options?.length ? idArr[index] : index,
                                  isSelected: !values[index].isSelected,
                              },
                          };

                          if (onMultiSelect) {
                              const selectedValues = options?.filter(
                                  (option, index) => newValues[`${index}`].isSelected,
                              );

                              if (selectedValues) {
                                  onMultiSelect(selectedValues);
                              }
                          }
                          setValues(newValues);
                      }
                  }
                : () => {
                      input.onBlur();
                      setIsBlur((prev) => !prev);
                      input.onChange(el);
                      if (onChange) {
                          onChange(el);
                      }
                  },
        [idArr, input, multiple, onChange, onMultiSelect, options, values],
    );

    useEffect(() => {
        if (multiple && values) {
            const inputValue = makeMultipleInputValue(values);
            input.onChange(inputValue);
        }
    }, [input, makeMultipleInputValue, multiple, values]);
    useEffect(() => {
        if (flagReset) {
            setValues(convertOptionsToObject(options, [], idArr));
            if (setFlagReset) setFlagReset(false);
        }
    }, [flagReset, setFlagReset, options, idArr]);
    useEffect(() => {
        if (initOptions.length) {
            setValues(convertOptionsToObject(options, initOptions, idArr));
        }
    }, [initOptions, options, idArr]);
    useEffect(() => {
        if (!multiple && value !== null && input.value !== value) {
            input.onChange(value);
        }
    }, [value, input, multiple]);
    useOuterClickField(
        ref,
        () => {
            input.onBlur();
            setIsBlur((prev) => !prev);
        },
        isBlur,
    );
    const handleInputClick = useCallback(() => {
        if (isDisabled) {
            return;
        }

        if (isBlur) {
            input.onBlur();
        } else {
            input.onFocus();
        }

        setIsBlur((prev) => !prev);
    }, [isDisabled, isBlur, input]);
    const isNeedScroll = useMemo(() => options && options.length >= 5, [options]);

    useEffect(() => {
        if (newValue !== undefined && newValue !== null) {
            input.onChange(newValue);
        }
    }, [newValue, input]);
    const handleSearchChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
        const searchValue = evt.target.value;
        setSearchValue((prev) => {
            if (prev !== searchValue || !prev) {
                return searchValue;
            }
            return prev;
        });
    }, []);
    return (
        <div className={CnFiedDropdownCore({ 'location-opened': meta.active && isLocationDropdown })} ref={ref}>
            <div className={CnTogether({ disable: isDisabled })} onClick={handleInputClick}>
                {isTextArea ? (
                    <textarea
                        spellCheck
                        type="button"
                        className={CnFiedDropdownCore('together-input', {
                            focus: options && meta.active,
                            disable: isDisabled,
                            placeholder: Boolean(!input.value && !value),
                            notValid: Boolean(meta.error) && meta.touched && !meta.active,
                        })}
                        {...input}
                        rows={rows}
                        value={input.value ? input.value : value ? value : placeholder}
                        readOnly={true}
                        onFocus={(e): void => {
                            input.onFocus(e);
                        }}
                        autoComplete="off"
                        onBlur={undefined}
                    />
                ) : (
                    <input
                        type="text"
                        className={CnFiedDropdownCore('together-input', {
                            focus: meta.active,
                            disable: isDisabled,
                            placeholder: Boolean(!input.value),
                            notValid: Boolean(meta.error) && meta.touched && !meta.active,
                        })}
                        {...input}
                        value={input.value ? input.value : value ? value : placeholder}
                        readOnly={Boolean(input.value)}
                        onFocus={(e): void => {
                            input.onFocus(e);
                        }}
                        autoComplete="off"
                        onBlur={undefined}
                    />
                )}
                <div
                    className={CnFiedDropdownCore('together-icon', {
                        open: options && meta.active,
                        blue: needBlueIcon,
                        black: needBlackIcon,
                    })}
                >
                    <LittleArrow />
                </div>
            </div>
            <div className={CnFiedDropdownCore('suggest', { focus: options && meta.active, needScroll: isNeedScroll })}>
                {isNeedSearch && (
                    <label className={CnFiedDropdownCore('search', { focus: options && meta.active })}>
                        <div className={CnFiedDropdownCore('search-icon')}>
                            <SearchIcon />
                        </div>
                        <input
                            spellCheck
                            onChange={handleSearchChange}
                            className={CnFiedDropdownCore('search-input')}
                            name="searchDropDown"
                            placeholder={searchPlaceholder}
                        />
                    </label>
                )}
                <div className={CnFiedDropdownCore('options')} id="scroll-bar">
                    {!child && (
                        <>
                            {options?.map((el, index) => {
                                if (
                                    searchValue &&
                                    !el.toLowerCase().includes(searchValue.toLowerCase()) &&
                                    (!searchForAll && multiple ? !values?.[`${index}`]?.isSelected : true)
                                ) {
                                    return null;
                                }
                                return (
                                    <div
                                        key={index}
                                        className={CnFiedDropdownCore('option', { focus: meta.active })}
                                        onClick={makeHandlerMultipleOptionsClick(el, index)}
                                        data-cy={dataCy?.(index)}
                                    >
                                        {multiple && <Checkbox checked={values?.[`${index}`]?.isSelected} readOnly />}
                                        <span title={el}>{el}</span>
                                    </div>
                                );
                            })}
                            {customOptions?.map((customOption, index) => {
                                if (
                                    searchValue &&
                                    !customOption.title.toLowerCase().includes(searchValue.toLowerCase())
                                ) {
                                    return null;
                                }
                                return (
                                    <div
                                        key={index}
                                        className={CnFiedDropdownCore('option--custom', { focus: meta.active })}
                                        data-cy={dataCy?.(index)}
                                    >
                                        <CustomOption
                                            {...customOption}
                                            makeHandlerClick={makeHandlerMultipleOptionsClick}
                                        />
                                    </div>
                                );
                            })}
                        </>
                    )}
                    {child && meta.active && (
                        <div className={CnFiedDropdownCore('option', { focus: meta.active })}>{child}</div>
                    )}
                </div>
            </div>
        </div>
    );
};
