import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgPlusCircle({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <rect x={0.5} y={0.5} width={31} height={31} rx={15.5} stroke="#0088E9" strokeDasharray="5 5" />
            <path
                d="M15.112 21.623v-4.536h-4.296v-2.016h4.296v-4.536H17.2v4.536h4.296v2.016H17.2v4.536h-2.088z"
                fill="#0088E9"
            />
        </svg>
    );
}

const MemoSvgPlusCircle = React.memo(SvgPlusCircle);
export default MemoSvgPlusCircle;
