import React from 'react';
import { Field } from 'react-final-form';
import { composeValidators } from '@lms-elements/utils';

import { ITextareaFieldProps } from './TextareaField.types';
import { TextareaFieldCore } from './TextareaFieldCore';

export const TextareaField: React.FC<ITextareaFieldProps> = ({ isField, name = '', validators = [], ...props }) =>
    isField ? (
        <Field name={name} validate={composeValidators(...validators)}>
            {({ input, meta }) => <TextareaFieldCore {...props} input={input} meta={meta} />}
        </Field>
    ) : (
        <TextareaFieldCore {...props} />
    );
