import { EditorState } from 'draft-js';
import { getSelectedBlock } from 'draftjs-utils';

const PHRASE_REGEX = /^[^\s,.](.*[^\s,.])?$/;
const NOT_AVAILABLE_CHAR_REGEX = /[\s,.]/;

export const isPhraseSelected = (editorState: EditorState) => {
    const selection = editorState.getSelection();
    const plainText = getSelectedBlock(editorState).getText();
    const startOffset = selection.getStartOffset();
    const endOffset = selection.getEndOffset();

    return (
        !NOT_AVAILABLE_CHAR_REGEX.test(plainText.slice(startOffset, endOffset)) &&
        PHRASE_REGEX.test(plainText.slice(startOffset, endOffset))
    );
};
