import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgPreloaderLogo({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={120}
            height={120}
            viewBox="0 0 120 120"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M77.394 60.534L75.5 61.578 49.997 47.275c-.442-.246-.631-.798-.316-1.228.253-.43.821-.614 1.263-.307l26.45 14.794z"
                fill="#0088E9"
            />
            <path
                d="M80.108 55.992L63.38 46.6l-10.29-5.832c-4.04-2.21-9.09.614-9.09 5.095v23.326c0 4.482 4.987 7.244 8.964 5.034l20.074-11.172-24.303-13.628c-1.642-.92-2.21-3.008-1.263-4.604.947-1.657 3.093-2.21 4.734-1.289l27.65 15.53.19-.122c1.262-.614 1.262-2.333.062-2.946z"
                fill="#0088E9"
            />
        </svg>
    );
}

const MemoSvgPreloaderLogo = React.memo(SvgPreloaderLogo);
export default MemoSvgPreloaderLogo;
