import React from 'react';
import { cn } from '@bem-react/classname';
import { SettingsBlackNoBg } from '@lms-elements/icons';

import { SettingsButtonProps } from './SettingsButton.types';

import './SettingsButton.scss';

const CnSettingsButton = cn('settings-button');

export const SettingsButton: React.FC<SettingsButtonProps> = ({
    onClickCallback,
    isActive = true,
    hoverable = true,
}) => {
    return (
        <button
            type="button"
            className={CnSettingsButton({ active: isActive, hoverable: hoverable && isActive })}
            onClick={onClickCallback}
        >
            <SettingsBlackNoBg />
        </button>
    );
};
