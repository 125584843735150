import React, { useCallback, useEffect, useRef, useState } from 'react';
import { cn } from '@bem-react/classname';
import { ClosedLock, MoveLessonIcon, Pencil, Task, WebcamIcon } from '@lms-elements/icons';
import { getDateLesson } from '@lms-elements/utils';

import { HomeworkStatus } from '../../HomeworkStatus';
import { LessonItem } from '../../Lesson';
import { SettingsButton } from '../../SettingsButton';

import { ButtonWithIcon } from './ButtonWithIcon';
import { ILessonViewModeProps } from './LessonViewMode.types';

import './LessonViewMode.scss';

const CnLessonView = cn('lesson_view');

const LESSON_ITEMS_STUB = [] as LessonItem[];

export const LessonViewMode: React.FC<ILessonViewModeProps> = ({
    lessonId,
    order = 1,
    name = '',
    themeOrder = 0,
    homeworkStatus,
    type = 'common',
    description = '',
    isOnlineLesson = false,
    needHideTopicOrder = false,
    isAnyFormOn = false,
    isActive = true,
    isDisabled = false,
    onSettingsClick,
    canEdit,
    onLessonClick,
    onMoveLessonClick,
    onHomeworkBlockClick,
    onAddClick,
    isStudent,
    needShowMoveLessonsButton,
    showContents = true,
    withNotPublishedLabel,
    needHideTasks,
    withCourseAssignment = false,
    isBlockedMaterials = false,
    isButtonEditBlock = false,
    lessonItems = LESSON_ITEMS_STUB,
    LessonIcon = WebcamIcon,
}) => {
    const [buttonsWithoutText, setButtonsWithoutText] = useState<boolean>();
    const handleLessonClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            if (!isDisabled && onLessonClick) {
                onLessonClick(lessonId, e.ctrlKey || e.metaKey);
            }
        },
        [isDisabled, lessonId, onLessonClick],
    );

    const handleAddClick = useCallback(() => {
        onAddClick?.(lessonId);
    }, [lessonId, onAddClick]);

    const handleSettingsClick = useCallback(() => {
        onSettingsClick?.(lessonId);
    }, [lessonId, onSettingsClick]);

    const handleHomeworkClick = useCallback(() => {
        onHomeworkBlockClick?.(lessonId);
    }, [lessonId, onHomeworkBlockClick]);

    const handleMoveLessonClick = useCallback(() => {
        onMoveLessonClick?.(lessonId);
    }, [lessonId, onMoveLessonClick]);

    const buttonsRef = useRef<HTMLDivElement>(null);

    const handleButtonsSize = useCallback(() => {
        if (buttonsRef.current) {
            setButtonsWithoutText(buttonsRef.current.getBoundingClientRect().width < 372);
        }
    }, [buttonsRef]);

    useEffect(() => {
        handleButtonsSize();
        window.addEventListener('resize', handleButtonsSize);

        return () => {
            window.removeEventListener('resize', handleButtonsSize);
        };
    }, [handleButtonsSize]);

    return (
        <div className={CnLessonView()}>
            <div className={CnLessonView('main')}>
                <div className={CnLessonView('header-wrapper')}>
                    {isOnlineLesson && <LessonIcon className={CnLessonView('icon-camera')} />}
                    {isBlockedMaterials && <ClosedLock className={CnLessonView('icon-lock')} />}
                    <h4
                        className={CnLessonView('main-header', {
                            inactive: type != 'common' || !isActive,
                            disabled: isDisabled,
                        })}
                        onClick={handleLessonClick}
                    >
                        {type === 'common'
                            ? `${needHideTopicOrder ? '' : `Т${themeOrder},\u00A0`}${
                                  order + 1
                              }\u00A0урок\u00A0-\u00A0${name}`
                            : `Резервный\u00A0-\u00A0${name}`}
                    </h4>
                </div>
                {withNotPublishedLabel && <div className={CnLessonView('label')}>Не опубликовано</div>}
                <div
                    className={CnLessonView('main-description', {
                        inactive: type != 'common' || !isActive,
                        disabled: isDisabled,
                    })}
                >
                    <span>{description}</span>
                </div>
                {canEdit && (
                    <div
                        className={CnLessonView('main-buttons', { buttonsWithoutText: buttonsWithoutText })}
                        ref={buttonsRef}
                    >
                        {!isBlockedMaterials && (
                            <ButtonWithIcon
                                isActive={isActive}
                                hoverable={!isAnyFormOn}
                                onClickCallback={handleAddClick}
                                text={buttonsWithoutText ? undefined : 'Наполнить'}
                                CustomIcon={<Pencil />}
                            />
                        )}
                        {needShowMoveLessonsButton && (
                            <ButtonWithIcon
                                isActive={isActive}
                                hoverable={!isAnyFormOn}
                                onClickCallback={handleMoveLessonClick}
                                text={buttonsWithoutText ? undefined : 'Переместить'}
                                CustomIcon={<MoveLessonIcon />}
                            />
                        )}
                        {!isButtonEditBlock && (
                            <SettingsButton
                                onClickCallback={handleSettingsClick}
                                isActive={isActive}
                                hoverable={!isAnyFormOn}
                            />
                        )}
                    </div>
                )}
            </div>
            {showContents && (
                <div className={CnLessonView('contents')}>
                    {!needHideTasks && (
                        <div className={CnLessonView('contents-tasks')} onClick={handleHomeworkClick}>
                            {homeworkStatus ? (
                                <HomeworkStatus {...homeworkStatus} withoutBorder={true} isStudent={isStudent} />
                            ) : (
                                <>
                                    <Task />
                                    <h4>нет заданий</h4>
                                </>
                            )}
                        </div>
                    )}
                    {lessonItems.map((item) =>
                        item.checked ? (
                            <div
                                key={item.type}
                                className={CnLessonView('contents-materials', {
                                    have_materials: true,
                                    inactive: !isActive || type != 'common',
                                })}
                            >
                                <div className={CnLessonView('contents-materials_have_materials-indicator')} />
                                <div className={CnLessonView('contents-materials_have_materials-content')}>
                                    <span className="title-material">{item.label}</span>
                                    <span className="time-material">
                                        {item.startDate && item.endDate && withCourseAssignment
                                            ? getDateLesson(item.startDate, item.endDate)
                                            : ''}
                                    </span>
                                </div>
                            </div>
                        ) : null,
                    )}
                </div>
            )}
        </div>
    );
};
