import React, { useCallback, useState } from 'react';
import { cn } from '@bem-react/classname';
import { Angle } from '@lms-elements/icons';
import { minusDaysToCurrentDate, plusDaysToCurrentDate } from '@lms-elements/utils';

import { CarouselItems } from '../CarouselItems';

import { ScheduleDateProps } from './ScheduleDate.types';

import './ScheduleDate.scss';

const CnScheduleDate = cn('schedule-date');

const CAROUSEL_STEP = 7;

export const ScheduleDate: React.FC<ScheduleDateProps> = ({ selectedDate, onChange }) => {
    const [carouselDate, setCarouselDate] = useState(new Date());

    const selectedDateChangeCallback = useCallback(
        (value: Date) => {
            onChange(value);
        },
        [onChange],
    );

    const angleTopClickCallback = useCallback(() => {
        setCarouselDate(minusDaysToCurrentDate(carouselDate, 7));
    }, [carouselDate]);

    const angleBottomClickCallback = useCallback(() => {
        setCarouselDate(plusDaysToCurrentDate(carouselDate, CAROUSEL_STEP));
    }, [carouselDate]);

    return (
        <div className={CnScheduleDate()}>
            <Angle onClick={angleTopClickCallback} className={CnScheduleDate('angle', { top: true })} />
            <div className={CnScheduleDate('column')}>
                <CarouselItems
                    carouselDate={carouselDate}
                    selectedDate={selectedDate}
                    onChange={selectedDateChangeCallback}
                />
            </div>
            <Angle onClick={angleBottomClickCallback} className={CnScheduleDate('angle', { bottom: true })} />
        </div>
    );
};
