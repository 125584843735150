export interface IPercentageProps {
    percent: number;
    middleFrom?: number;
    highFrom?: number;
    shouldRound?: boolean;
}

export enum PercentageColor {
    red = 'red',
    yellow = 'yellow',
    green = 'green',
}
