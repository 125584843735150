import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgMonitorPlay({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={77}
            height={77}
            viewBox="0 0 77 77"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M14.438 57.75h48.124a4.812 4.812 0 004.813-4.813V19.25a4.812 4.812 0 00-4.813-4.813H14.438a4.812 4.812 0 00-4.813 4.813v33.688a4.812 4.812 0 004.813 4.812zM48.125 67.375h-19.25"
                stroke="#EDEEF2"
                strokeWidth={4}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M48.125 36.094l-14.438-9.625v19.25l14.438-9.625z"
                stroke="#EDEEF2"
                strokeWidth={4}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgMonitorPlay = React.memo(SvgMonitorPlay);
export default MemoSvgMonitorPlay;
