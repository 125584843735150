import React, { useCallback, useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { Book, User, WebcamIcon } from '@lms-elements/icons';
import { getDateComponents, getNumberMinutesBeforeDate, getTimeStatus } from '@lms-elements/utils';

import { Button, ButtonViewEnum } from '../Button';

import { ScheduleLessonCardProps } from './ScheduleLessonCard.types';

import './ScheduleLessonCard.scss';

const CnScheduleLessonCard = cn('scheduleLessonCard');

export const ScheduleLessonCard: React.FC<ScheduleLessonCardProps> = ({
    subjectTitle,
    groupTitle,
    lessonTitle,
    type,
    datetimeStart,
    datetimeEnd,
    link,
    isLinkReady,
    customTitle,
    IconElement,
    handleButtonActionClick,
    isSelfPreparation = false,

    isNeedHideLessonButton = false,
}) => {
    const isLesson = useMemo(() => {
        return type === 'lesson';
    }, [type]);

    const status = useMemo(() => getTimeStatus(datetimeStart, datetimeEnd), [datetimeStart, datetimeEnd]);

    const timeInfoText = useMemo(() => {
        if (status === 'future') {
            return `в ${getDateComponents(datetimeStart).time ?? ''}`;
        }

        if (status === 'past') {
            return isSelfPreparation ? 'просрочено' : `прошел в ${getDateComponents(datetimeEnd).time ?? ''}`;
        }

        return 'идет сейчас';
    }, [status, datetimeEnd, datetimeStart, isSelfPreparation]);

    const [buttonView, buttonLabel] = useMemo(() => {
        return status === 'future'
            ? [ButtonViewEnum.bordered, `Через ${getNumberMinutesBeforeDate(datetimeStart)}`]
            : [ButtonViewEnum.action, 'Войти'];
    }, [status, datetimeStart]);

    const hideLessonButton = useMemo(
        () => isNeedHideLessonButton && (status === 'present' || status === 'late'),
        [isNeedHideLessonButton, status],
    );

    const handleButtonClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            if (handleButtonActionClick) {
                handleButtonActionClick(link);
            }
        },
        [link, handleButtonActionClick],
    );

    return (
        <div className={CnScheduleLessonCard({ isLesson, status })}>
            <div className={CnScheduleLessonCard('icon', { isLesson, status })}>
                {IconElement ? IconElement : isSelfPreparation ? <Book /> : isLesson ? <WebcamIcon /> : <User />}
            </div>
            <div className={CnScheduleLessonCard('info')}>
                <div className={CnScheduleLessonCard('title', { status })}>
                    <span>
                        {customTitle || isSelfPreparation
                            ? `${customTitle ? customTitle : 'Самоподготовка'}, ${subjectTitle}, `
                            : `${subjectTitle}, ${groupTitle}, `}
                    </span>
                    <span>{timeInfoText}</span>
                </div>
                <div className={CnScheduleLessonCard('topic')} title={lessonTitle}>{`${lessonTitle}`}</div>
            </div>
            {!isSelfPreparation && status !== 'past' && !hideLessonButton && (
                <div className={CnScheduleLessonCard('button')}>
                    <Button
                        view={buttonView}
                        size="s"
                        onClick={handleButtonClick}
                        disabled={!isLinkReady}
                        disabledBlue={!isLinkReady}
                    >
                        {buttonLabel}
                    </Button>
                </div>
            )}
        </div>
    );
};
