import React, { useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { getTimeStatus } from '@lms-elements/utils';

import { HomeworkStatus } from '../HomeworkStatus';
import { ScheduleLessonCard } from '../ScheduleLessonCard';

import { LessonHomeworkItemProps } from './LessonHomeworkItem.types';

import './LessonHomeworkItem.scss';

const CnLesson = cn('lesson-date');

export const LessonHomeworkItem: React.FC<LessonHomeworkItemProps> = ({
    id,
    autoIssue,
    autoIssueSetUp,
    autoIssuetotalTasks,
    autoIssuetasks,
    autoIssueTime,
    givenHometaskTitle,
    passOffTasks,
    toCheckTasks,
    type,
    subject,
    datetimeStart,
    datetimeEnd,
    courseGroup,
    link,
    isLinkReady,
    extendedClass,
    lesson,
    hideHomeworkStatus,
    isVertical,
    customTitle,
    IconElement,
    onLessonClick,
    onHomeworkClick,
    createTaskLabel,
    isNeedHideLessonButton,
}) => {
    const meeting = useMemo(() => type === 'meeting', [type]);
    const status = useMemo(() => {
        if (autoIssue) {
            return autoIssueSetUp ? 'auto' : 'notAuto';
        }

        return givenHometaskTitle ? 'homework' : 'notHomework';
    }, [autoIssue, autoIssueSetUp, givenHometaskTitle]);

    const timeStatus = useMemo(() => getTimeStatus(datetimeStart, datetimeEnd), [datetimeStart, datetimeEnd]);

    const noTasks = useMemo(
        () => hideHomeworkStatus && !autoIssuetasks && !passOffTasks && !toCheckTasks,
        [autoIssuetasks, hideHomeworkStatus, passOffTasks, toCheckTasks],
    );

    const isLesson = type?.includes('lesson');

    return (
        <div className={`${CnLesson({ isVertical })} ${extendedClass ? extendedClass : ''}`}>
            <div className={CnLesson('card', { full: meeting || noTasks || isVertical })} onClick={onLessonClick}>
                <ScheduleLessonCard
                    type={isLesson ? 'lesson' : 'meeting'}
                    subjectTitle={subject?.title ?? ''}
                    groupTitle={courseGroup?.title ?? ''}
                    lessonTitle={lesson?.title ?? ''}
                    datetimeStart={datetimeStart}
                    datetimeEnd={datetimeEnd}
                    link={link}
                    isLinkReady={isLinkReady}
                    customTitle={customTitle}
                    isSelfPreparation={type === 'selfpreparation_lesson'}
                    IconElement={IconElement}
                    isNeedHideLessonButton={isNeedHideLessonButton}
                />
            </div>
            {!meeting && !noTasks && (
                <>
                    <div className={CnLesson('line', { timeStatus, status, isVertical })}></div>
                    <div className={CnLesson('task', { isVertical })} onClick={onHomeworkClick}>
                        <HomeworkStatus
                            id={id}
                            status={status}
                            autoIssue={autoIssue}
                            autoIssueTime={autoIssueTime}
                            autoIssuetasks={autoIssuetasks}
                            autoIssueSetUp={autoIssueSetUp}
                            givenHometaskTitle={givenHometaskTitle}
                            autoIssuetotalTasks={autoIssuetotalTasks}
                            passOffTasks={passOffTasks}
                            toCheckTasks={toCheckTasks}
                            createTaskLabel={createTaskLabel}
                        />
                    </div>
                </>
            )}
        </div>
    );
};
