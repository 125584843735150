import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgDeleteIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.233 3.333a.6.6 0 010 .849L9.414 8l3.819 3.819a.6.6 0 010 .848l-.566.566a.6.6 0 01-.849 0L8 9.414l-3.818 3.819a.6.6 0 01-.849 0l-.566-.566a.6.6 0 010-.848L6.586 8 2.767 4.182a.6.6 0 010-.849l.566-.566a.6.6 0 01.849 0L8 6.586l3.818-3.819a.6.6 0 01.849 0l.566.566z"
                fill="#0088E9"
            />
        </svg>
    );
}

const MemoSvgDeleteIcon = React.memo(SvgDeleteIcon);
export default MemoSvgDeleteIcon;
