import { isEmail } from '@lms-elements/utils';

import { ISignInFormValues, SignInFormErrors } from './SignInForm.types';

export const validateForm = (values: ISignInFormValues): SignInFormErrors => {
    const errors: SignInFormErrors = {
        username: undefined,
        password: undefined,
    };

    if (!values.username) {
        errors.username = 'Пожалуйста укажите email';
    }

    if (values.username && !isEmail(values.username)) {
        errors.username = 'Некорректный email';
    }

    if (!values.password) {
        errors.password = 'Пожалуйста укажите пароль';
    }

    return errors;
};
