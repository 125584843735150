import React, { useEffect, useRef } from 'react';

import { IWithIntersectionObserverProps } from './WithIntersectionObserver.types';

export const WithIntersectionObserver: React.FC<IWithIntersectionObserverProps> = ({
    children,
    rootElement,
    rootMargin,
    threshold,
    containerClassName,
    timeout,
    callback,
    needCallbackBeforeDisconnect,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (containerRef.current) {
            let timeoutId: NodeJS.Timeout | undefined;

            const handleElementObserve = ([entry]: IntersectionObserverEntry[]) => {
                if (entry.isIntersecting && callback) {
                    if (timeout === undefined) {
                        callback();
                    } else {
                        timeoutId = setTimeout(callback, timeout);
                    }
                }
            };

            const intersectionObserver = new IntersectionObserver(handleElementObserve, {
                threshold: threshold,
                root: rootElement,
                rootMargin,
            });
            intersectionObserver.observe(containerRef.current);

            return () => {
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
                if (needCallbackBeforeDisconnect && callback) {
                    callback();
                }
                intersectionObserver.disconnect();
            };
        }
    }, [callback, needCallbackBeforeDisconnect, rootElement, rootMargin, threshold, timeout]);

    return (
        <div ref={containerRef} className={containerClassName}>
            {children}
        </div>
    );
};
