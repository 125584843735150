import React, { useCallback, useEffect, useState } from 'react';
import { cn } from '@bem-react/classname';
import { defaultUserAvatar } from 'assets';

import { IAvatarProps } from './Avatar.types';

import './Avatar.scss';

const CnAvatar = cn('avatar');

export const Avatar: React.FC<IAvatarProps> = ({
    name,
    alt,
    source,
    onAvatarClick,
    customClassName = '',
    isOnAdaptation,
    isOnline,
    isSelected,
    isAcademic,
    enrollDate,
    dismissDate,
    school,
}) => {
    const [avatarUrl, setAvatarUrl] = useState(source || defaultUserAvatar);

    const handleAvatarError = useCallback(() => {
        setAvatarUrl(defaultUserAvatar);
    }, []);

    useEffect(() => {
        if (source) {
            setAvatarUrl(source);
        }
    }, [source]);

    return (
        <div className={`${CnAvatar()} ${customClassName}`} onClick={onAvatarClick}>
            <div className={CnAvatar('image-wrapper', { isSelected })}>
                <img onError={handleAvatarError} className={CnAvatar('image')} src={avatarUrl} alt={alt} />
                {isOnAdaptation && <div className={CnAvatar('adaptation-icon')}>A</div>}
                {isOnline && <div className={CnAvatar('online-icon')} />}
            </div>
            {name && isAcademic !== true && <div className={CnAvatar('name')}>{name}</div>}
            {name && isAcademic === true && (
                <div className={CnAvatar('academName')}>
                    <div className={CnAvatar('name')}>{name}</div>
                    <div className={CnAvatar('school')}>{school || 'Без прикрепления'}</div>
                    <div className={CnAvatar('date')}>
                        {enrollDate && `Зачисл. ${enrollDate}       `}
                        {dismissDate && `Отчисл. ${dismissDate}`}
                    </div>
                </div>
            )}
        </div>
    );
};
