import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgOpenLinkIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={18}
            height={18}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M17 11v5.273a.727.727 0 01-.727.727H1.727A.727.727 0 011 16.273V1.727C1 1.326 1.326 1 1.727 1H7M12.636 1H17v4.364M11.91 6.09L17 1"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgOpenLinkIcon = React.memo(SvgOpenLinkIcon);
export default MemoSvgOpenLinkIcon;
