import React from 'react';
import { Field } from 'react-final-form';
import { composeValidators } from '@lms-elements/utils';

import { FieldSuggestCore } from './FieldSuggestCore/FieldSuggestCore';
import { FieldSuggestProps } from './FieldSuggest.types';

export const FieldSuggest: React.FC<FieldSuggestProps> = ({ validators, name, ...props }) => {
    return (
        <Field
            name={name}
            validate={composeValidators(...(validators || []))}
            subscriptions={{ value: true, error: true, active: true }}
        >
            {({ input, meta }) => <FieldSuggestCore {...props} input={input} meta={meta} />}
        </Field>
    );
};
