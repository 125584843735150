import React from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { cn } from '@bem-react/classname';
import { NoteBlank } from '@lms-elements/icons';

import { IDocument, IExpandedDocumentMaterialStudentProps } from './ExpandedDocumentMaterialStudent.types';

import './ExpandedDocumentMaterialStudent.scss';

const ExpandedDocumentMaterialStudentCn = cn('expanded-document-material-student');

export const ExpandedDocumentMaterialStudent: React.FC<IExpandedDocumentMaterialStudentProps> = ({ name }) => {
    const {
        fields: { value: documents },
    } = useFieldArray<IDocument>(`${name}.documentData`);

    return (
        <div className={ExpandedDocumentMaterialStudentCn()}>
            <div className={ExpandedDocumentMaterialStudentCn('title')}>Файлы</div>
            {documents.map(({ name, url }) => (
                <div key={name} className={ExpandedDocumentMaterialStudentCn('doc')}>
                    <NoteBlank />
                    <a
                        href={url}
                        rel="noreferrer"
                        target="_blank"
                        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.stopPropagation()}
                    >
                        {name}
                    </a>
                </div>
            ))}
        </div>
    );
};
