import React, { useCallback, useEffect, useRef, useState } from 'react';
import { cn } from '@bem-react/classname';
import { useOuterClickField } from '@lms-elements/hooks';
import { CloseIcon, Password, PasswordHidden } from '@lms-elements/icons';

import { Position, Tooltip, TooltipViewEnum } from '../../Tooltip';

import { IFieldFormCoreProps } from './FieldFormCore.types';
import { getSide } from './FieldFormCore.utils';

import './FieldFormCore.scss';

const FieldFormCoreCn = cn('field-form-core');

export const FieldFormCore: React.FC<IFieldFormCoreProps> = ({
    placeholder = '',
    meta,
    input,
    value,
    disable = false,
    forTitle = false,
    needDeleteIcon = true,
    onBlur,
    withoutBorders = false,
    type = 'text',
    customClassName = '',
    isInvalid,
    isTextArea,
    onInputChange,
    withPasswordIcon = false,
    tooltip = null,
    isInitialValidate = false,
    tooltipPosition = Position.right,
    errorMessage,
}) => {
    const ref = useRef(null);
    const [isBlur, setIsBlur] = useState(false);
    const [inputType, setInputType] = useState(type);
    const pointerSide = getSide(tooltipPosition);

    const onFirstBlurInput = useCallback(() => {
        setIsBlur((prev) => !prev);
    }, [setIsBlur]);

    const onClickXIcon = useCallback(() => {
        input.onChange('');
        input.onBlur();
    }, [input]);

    const handleClickPasswordIcon = useCallback(
        () => setInputType((prev) => (prev === 'text' ? 'password' : 'text')),
        [],
    );

    useEffect(() => {
        onInputChange?.(input.value, Boolean(meta.invalid));
    }, [input, meta, onInputChange]);

    useEffect(() => {
        if (!meta.active && isBlur && onBlur) {
            onBlur(input.value);
        }
    }, [meta, isBlur, onBlur, input.value]);

    useOuterClickField(
        ref,
        () => {
            input.onBlur();
            setIsBlur((prev) => !prev);
        },
        isBlur,
    );

    return (
        <div
            className={`${FieldFormCoreCn('wrapper', { hasErrorMessage: Boolean(errorMessage), isTextArea })}`}
            ref={ref}
        >
            <div className={`${FieldFormCoreCn({ isTextArea })} ${customClassName}`}>
                {isTextArea ? (
                    <textarea
                        spellCheck
                        type="button"
                        className={FieldFormCoreCn('input', {
                            focus: meta.active && !forTitle,
                            disable: disable,
                            placeholder: Boolean(!input.value && !value),
                            notValid:
                                (Boolean(meta.error) && (meta.touched || isInitialValidate) && !forTitle) || isInvalid,
                            isTextArea,
                        })}
                        {...input}
                        value={input.value || value || ''}
                        placeholder={placeholder}
                        onFocus={(e): void => {
                            input.onFocus(e);
                            if (!isBlur) {
                                onFirstBlurInput();
                            }
                        }}
                        autoComplete="off"
                    />
                ) : (
                    <input
                        spellCheck
                        type={inputType}
                        className={FieldFormCoreCn('input', {
                            focus: meta.active && !forTitle,
                            disable: disable,
                            notValid:
                                (Boolean(meta.error) && (meta.touched || isInitialValidate) && !forTitle) || isInvalid,
                            noBorders: forTitle,
                            oneBorderFocus: meta.active && forTitle,
                            oneBorderNotValid: Boolean(meta.error) && meta.touched && forTitle,
                            withoutBorders,
                            withoutBordersFocus: meta.active && withoutBorders,
                        })}
                        {...input}
                        value={input.value || value || ''}
                        onFocus={(e): void => {
                            input.onFocus(e);
                            if (!isBlur) {
                                onFirstBlurInput();
                            }
                        }}
                        autoComplete="off"
                        placeholder={placeholder}
                    />
                )}
                {!disable &&
                    (withPasswordIcon && isBlur ? (
                        <div className={FieldFormCoreCn('icon-password')} onClick={handleClickPasswordIcon}>
                            {inputType === 'password' ? <PasswordHidden /> : <Password />}
                        </div>
                    ) : (
                        needDeleteIcon && (
                            <div
                                className={FieldFormCoreCn('icon', { notEmpty: Boolean(input.value), isTextArea })}
                                onClick={onClickXIcon}
                            >
                                <CloseIcon />
                            </div>
                        )
                    ))}
                {tooltip && isBlur && (
                    <div className={FieldFormCoreCn('tooltip', { tooltipPosition })}>
                        <Tooltip listData={tooltip} view={TooltipViewEnum.list} pointerSide={pointerSide} />
                    </div>
                )}
            </div>
            {errorMessage && meta.error && <p className={FieldFormCoreCn('errorMessage')}>{errorMessage}</p>}
        </div>
    );
};
