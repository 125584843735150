import React from 'react';
import { useField } from 'react-final-form';
import { cn } from '@bem-react/classname';

import { HeaderSize, IHeaderValues } from '../ExpandedHeaderMaterial.types';

import { IStudentViewHeaderProps } from './StudentViewHeader.types';

import './StudentViewHeader.scss';

const CnStudentViewHeader = cn('student-view-header');

export const StudentViewHeader: React.FC<IStudentViewHeaderProps> = ({ name, customClassName = '' }) => {
    const {
        input: {
            value: {
                headerData: { text, size },
            },
        },
    } = useField<IHeaderValues>(name);

    switch (size) {
        case HeaderSize.LARGE:
            return <h1 className={`${CnStudentViewHeader({ size })} ${customClassName}`}>{text}</h1>;
        case HeaderSize.MEDIUM:
            return <h2 className={`${CnStudentViewHeader({ size })} ${customClassName}`}>{text}</h2>;
        case HeaderSize.SMALL:
            return <h3 className={`${CnStudentViewHeader({ size })} ${customClassName}`}>{text}</h3>;
        default:
            return <p className={`${CnStudentViewHeader()} ${customClassName}`}>{text}</p>;
    }
};
