import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgParents({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={16}
            height={20}
            viewBox="0 0 16 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M5.63 11.516l-.099.694c-.126.5-.465.922-.93 1.153l-1.361.677a3.542 3.542 0 00-1.86 2.305L.89 18.304H15.11l-.493-1.959a3.537 3.537 0 00-1.86-2.305l-1.36-.677a1.77 1.77 0 01-.93-1.153l-.098-.694"
                stroke="#C0C2C9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                clipRule="evenodd"
                d="M9.28 12.079v0a2.22 2.22 0 01-2.56 0c-1.881-1.343-2.988-3.451-2.988-5.69v-.507c0-2.244 1.91-4.064 4.267-4.064 2.356 0 4.267 1.82 4.267 4.064v.508c0 2.238-1.107 4.346-2.987 5.689z"
                stroke="#C0C2C9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgParents = React.memo(SvgParents);
export default MemoSvgParents;
