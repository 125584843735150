import React, { ReactElement, useCallback, useRef } from 'react';
import { cn } from '@bem-react/classname';
import { CloseIcon, SearchIcon } from '@lms-elements/icons';

import { ISearchCoreProps } from './SearchCore.types';

import './SearchCore.scss';

const CnSearch = cn('search');

export const SearchCore: React.FC<ISearchCoreProps> = ({
    placeholder = '',
    meta,
    input,
    onChange,
    initValue,
}): ReactElement => {
    const handleCloseIconClick = useCallback(() => {
        input?.onChange('');
        input?.onBlur();
        onChange?.('');
    }, [input, onChange]);

    const inputRef = useRef<HTMLInputElement>(null);

    const handleInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            input?.onChange(e.currentTarget.value);
            onChange?.(e.currentTarget.value);
        },
        [input, onChange],
    );

    return (
        <div className={CnSearch()}>
            <div className={CnSearch('searchIcon')}>
                <SearchIcon />
            </div>
            <input
                spellCheck
                ref={inputRef}
                {...input}
                onChange={handleInputChange}
                value={input?.value ?? initValue ?? ''}
                type="text"
                className={CnSearch('input')}
                autoComplete="off"
                placeholder={meta?.active ? `  ${placeholder}` : `${placeholder}`}
            />
            <div
                className={CnSearch('icon', { notEmpty: Boolean(input?.value || inputRef.current?.value) })}
                onClick={handleCloseIconClick}
            >
                <CloseIcon />
            </div>
        </div>
    );
};
