import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgPen({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={19}
            height={18}
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                clipRule="evenodd"
                d="M3.278 12.556L1.5 17l4.444-1.778L17.24 3.927a.888.888 0 000-1.257l-1.41-1.41a.888.888 0 00-1.256 0L3.278 12.557z"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.145 5.644L12.76 3.26"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgPen = React.memo(SvgPen);
export default MemoSvgPen;
