import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgAbsentIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={32}
            height={33}
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M16 26.5c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
                stroke="#EA5959"
                strokeWidth={1.5}
                strokeMiterlimit={10}
            />
            <path d="M13 12.5v8M19 12.5v8M13.5 16.5H19" stroke="#EA5959" strokeWidth={1.5} strokeLinecap="round" />
        </svg>
    );
}

const MemoSvgAbsentIcon = React.memo(SvgAbsentIcon);
export default MemoSvgAbsentIcon;
