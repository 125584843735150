import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useField } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { Basket, MonitorPlay } from '@lms-elements/icons';

import { ACCEPT_FILE_TYPES } from './ExpandedVideoMaterial.constants';
import { IExpandedVideoMaterialProps, IUploadVideoProps, IVideoValues } from './ExpandedVideoMaterial.types';
import { transformUrl, validateUrl, validateVideoValues } from './ExpandedVideoMaterial.utils';

import './ExpandedVideoMaterial.scss';

const ExpandedVideoMaterialCn = cn('expanded-video-material');

export const ExpandedVideoMaterial: React.FC<IExpandedVideoMaterialProps> = ({
    name,
    onDelete,
    videoUrl = '',
    onClickAddVideo,
    onSaveVideo,
    showForStudents = false,
}) => {
    const [url, setUrl] = useState(transformUrl(videoUrl));
    const [isError, setIsError] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    const { input, meta } = useField<IVideoValues>(name, { validate: validateVideoValues });

    const [, drop] = useDrop(
        () => ({
            accept: [NativeTypes.FILE],
            drop: (item) => {
                const video = item as IUploadVideoProps;
                const videoFile = video.files[0];

                if (videoFile.type && ACCEPT_FILE_TYPES.includes(videoFile.type.toLowerCase())) {
                    input.onChange({
                        ...input.value,
                        videoData: {
                            url: '',
                            file: videoFile,
                        },
                    });
                    input.onBlur();
                    setUrl(URL.createObjectURL(videoFile as unknown as MediaSource));
                }
            },
        }),
        [input],
    );

    const handleAddFile = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            if (!e.target.files) {
                return;
            }
            const file = e.target?.files?.[0];
            input.onChange({
                ...input.value,
                videoData: {
                    url: '',
                    file,
                },
            });
            input.onBlur();
            setUrl(URL.createObjectURL(file));
            onClickAddVideo?.(file);
        },
        [input, onClickAddVideo],
    );

    const handleClickDeleteButton = useCallback(() => {
        setUrl('');
        input.onChange({
            ...input.value,
            videoData: { url: '' },
        });
        onDelete?.();
    }, [input, onDelete]);

    const handleInputBlur = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            input.onBlur(e);
            const { value } = e.target;
            setIsFocused(false);

            if (input.value?.videoData?.file && !value) {
                return;
            }

            if (!meta.valid) {
                setIsError(true);
                return;
            }

            setIsError(false);
            const correctEmbedUrl = transformUrl(value);
            setUrl(correctEmbedUrl);
            onSaveVideo?.(correctEmbedUrl);
        },
        [input, meta.valid, onSaveVideo],
    );

    const handleInputFocus = useCallback(
        (event: React.FocusEvent<HTMLInputElement>) => {
            input.onFocus(event);
            setIsFocused(true);
            setIsError(false);
        },
        [input],
    );

    const handleInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            input.onChange({
                ...input.value,
                videoData: { url: e.target.value },
            });
            if (!e.target.value || !validateUrl(e.target.value)) {
                setUrl(transformUrl(e.target.value));
            }
        },
        [input],
    );

    const handleIframeLoad = useCallback(() => {
        // Stop the video when there is a local file
        if (input.value?.videoData?.file) {
            const iframeDocument = iframeRef.current?.contentDocument;
            if (iframeDocument) {
                const video = iframeDocument.getElementsByTagName('video')[0];
                video.pause();
                video.currentTime = 0;
            }
        }
    }, [input.value?.videoData?.file]);

    const urlInput = useMemo(
        () => (
            <input
                className={ExpandedVideoMaterialCn('input', {
                    noBorders: true,
                    oneBorderFocus: isFocused,
                    oneBorderNotValid: isError,
                })}
                value={input.value?.videoData?.url || ''}
                placeholder="Или укажите прямую ссылку"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
            />
        ),
        [isFocused, isError, input.value?.videoData?.url, handleInputChange, handleInputFocus, handleInputBlur],
    );

    const showForm = useMemo(() => !meta.valid && !showForStudents, [meta.valid, showForStudents]);

    useEffect(() => {
        setIsError(Boolean(!meta.valid && meta.touched));
    }, [meta.valid, meta.touched]);

    useEffect(() => {
        const iframe = iframeRef.current;

        iframe?.addEventListener('load', handleIframeLoad);

        return () => {
            iframe?.removeEventListener('load', handleIframeLoad);
        };
    }, [handleIframeLoad]);

    return (
        <div>
            {!showForStudents && <h3 className="videofile">Видеофайл</h3>}
            <div className={ExpandedVideoMaterialCn({ showForStudents, error: !meta.valid })} ref={drop}>
                {showForm ? (
                    <div className={ExpandedVideoMaterialCn('heading')}>
                        <div>
                            <div className={ExpandedVideoMaterialCn('title')}>Загрузите видео</div>
                            <div className={ExpandedVideoMaterialCn('instaction')}>
                                <span className={ExpandedVideoMaterialCn('text')}>перетащив файл сюда или</span>
                                <div className={ExpandedVideoMaterialCn('upload')}>
                                    <label className={ExpandedVideoMaterialCn('note')}>
                                        укажите путь
                                        <input
                                            type="file"
                                            className={ExpandedVideoMaterialCn('addvideo')}
                                            accept={ACCEPT_FILE_TYPES.join(',')}
                                            onChange={handleAddFile}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className={ExpandedVideoMaterialCn('helper')}>.webm .mp4 до 100mb</div>
                        </div>
                        <div className={ExpandedVideoMaterialCn('icon')}>
                            <MonitorPlay />
                        </div>
                    </div>
                ) : (
                    <>
                        <iframe
                            className={ExpandedVideoMaterialCn('video')}
                            ref={iframeRef}
                            src={url}
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </>
                )}
                {!showForStudents && urlInput}
                {showForm && (
                    <div className={ExpandedVideoMaterialCn('info')}>
                        Поддерживаемые сервисы: Youtube, Vimeo, Soundcloud, Facebook
                    </div>
                )}
                {!showForStudents && (
                    <button
                        onClick={handleClickDeleteButton}
                        className={ExpandedVideoMaterialCn('delete-card')}
                        type="button"
                    >
                        <Basket />
                    </button>
                )}
            </div>
        </div>
    );
};
