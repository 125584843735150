import React from 'react';
import { cn } from '@bem-react/classname';

import { IStyledNumberProps, StyledNumberColor } from './StyledNumber.types';

import './StyledNumber.scss';

const CnStyledNumber = cn('styledNumber');

export const StyledNumber: React.FC<IStyledNumberProps> = ({ value, color = StyledNumberColor.green }) => (
    <span className={CnStyledNumber({ color })}>{value}</span>
);
