import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { cn } from '@bem-react/classname';
import { useOuterClick } from '@lms-elements/hooks';

import { Tooltip } from '../../Tooltip';
import { SelectionPopupContent } from '../SelectionPopupContent';

import { PlanTableCellCoreProps } from './PlanTableCellCore.types';

import './PlanTableCellCore.scss';

const CnPlanTableCell = cn('plan-table-cell');

export const PlanTableCellCore: React.FC<PlanTableCellCoreProps> = ({
    isTitle = false,
    isTotal = false,
    isError = false,
    errorMessage = '',
    width,
    canEdit = false,
    isByHand = false,
    isTablet = false,
    percent = 0,
    value = '',
    input,
    meta,
    isField = true,
    withValue,
    isSelection = false,
    types,
    onChange,
    name,
}) => {
    const [hovered, setHovered] = useState(false);
    const [tooltipState, setTooltipState] = useState({
        top: -9999,
        left: -9999,
    });

    const [selectionHovered, setSelectionHovered] = useState(false);

    const ref = useRef<HTMLDivElement>(null);

    useOuterClick(ref, setSelectionHovered);

    const handleDocumentScroll = useCallback(() => {
        setHovered(false);
        setSelectionHovered(false);
    }, [setSelectionHovered]);

    const handleCellMouseEnter = useCallback(
        (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
            const mark = e.currentTarget as HTMLDivElement;

            const { x, y, width } = mark.getBoundingClientRect();
            setTooltipState({
                top: y,
                left: x - (120 - width / 2),
            });

            document.addEventListener('touchmove', handleDocumentScroll);
            document.addEventListener('mousewheel', handleDocumentScroll);
            document.addEventListener('scroll', handleDocumentScroll);

            setHovered(true);
        },
        [handleDocumentScroll],
    );

    const handleCellMouseClick = useCallback(() => {
        document.addEventListener('touchmove', handleDocumentScroll);
        document.addEventListener('mousewheel', handleDocumentScroll);
        document.addEventListener('scroll', handleDocumentScroll);

        setSelectionHovered(true);
    }, [handleDocumentScroll]);

    document.addEventListener('scroll', handleDocumentScroll);

    const handleCellMouseLeave = useCallback(() => {
        setTooltipState({ top: -9999, left: -9999 });

        document.removeEventListener('touchmove', handleDocumentScroll);
        document.removeEventListener('mousewheel', handleDocumentScroll);
        document.removeEventListener('scroll', handleDocumentScroll);

        setHovered(false);
    }, [handleDocumentScroll]);

    const subText = useMemo(() => {
        if (canEdit) {
            return isTablet ? `\n(ввод)` : `\n(редактируемое)`;
        }
        if (isByHand) {
            return `\n(задается вручную)`;
        }
        if (percent > 0 && percent < 100 && isTotal) {
            return `(~${String(percent)}%)`;
        }
        return '';
    }, [canEdit, isByHand, isTablet, isTotal, percent]);

    useEffect(() => {
        if (withValue && isField) {
            input?.onChange(value);
        }
    }, [value, withValue, isField, input, isSelection]);

    useEffect(() => {
        if (input?.onChange && isSelection) {
            setSelectionHovered(false);
        }
    }, [input?.onChange, isSelection]);

    useEffect(() => {
        if (input?.onBlur && isSelection && !input.value) {
            input.onChange(value);
        }
    }, [input, input?.onChange, isSelection, value]);

    return (
        <div className={CnPlanTableCell('wrapper')} style={{ width: width }}>
            {!isField || isTitle || (percent > 0 && percent < 100 && isTotal) ? (
                <div
                    className={CnPlanTableCell({
                        title: isTitle,
                        total: isTotal,
                        error: isError,
                        edit: canEdit || isByHand,
                    })}
                    onMouseEnter={handleCellMouseEnter}
                    onMouseLeave={handleCellMouseLeave}
                    onTouchStart={handleCellMouseEnter}
                >
                    {value}
                    <span>{subText}</span>
                </div>
            ) : (
                <input
                    className={CnPlanTableCell({
                        title: isTitle,
                        total: isTotal,
                        error: isError,
                        edit: canEdit || isByHand,
                        selection: isSelection,
                        notValid: Boolean(meta?.error) && meta?.touched && !meta?.active,
                    })}
                    readOnly={!(canEdit || isByHand) || isTotal || isSelection}
                    {...input}
                    value={input?.value ? input.value : value}
                    onChange={onChange}
                    onMouseEnter={handleCellMouseEnter}
                    onMouseLeave={handleCellMouseLeave}
                    onTouchStart={handleCellMouseEnter}
                    onClick={isSelection ? handleCellMouseClick : undefined}
                />
            )}
            {hovered && isError && (
                <>
                    <div className={CnPlanTableCell('tooltip')} style={tooltipState}>
                        <Tooltip files={[]} withPointer={true} commentLabel="Ошибка!" isError={isError}>
                            {errorMessage}
                        </Tooltip>
                    </div>
                    <div className={CnPlanTableCell('tooltip-pointer')} />
                </>
            )}
            {selectionHovered && (
                <>
                    <div className={CnPlanTableCell('selectionTooltip')} ref={ref}>
                        <SelectionPopupContent name={name} types={types} />
                    </div>
                </>
            )}
        </div>
    );
};
