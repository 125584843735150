import moment from 'moment';

type AddData = {
    date?: string | Date;
    amount: number;
    unit: moment.unitOfTime.DurationConstructor;
    format?: string;
};

export const increaseDate = ({ date, amount, unit, format }: AddData): string => {
    return moment(date).add(amount, unit).format(format);
};
