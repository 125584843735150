import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgDownloadIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M12 4.501v12.146M8.74 13.957l2.852 2.527a.74.74 0 00.815 0l2.854-2.527"
                stroke="#2F929A"
                strokeWidth={1.5}
                strokeMiterlimit={10}
                strokeLinecap="round"
            />
            <path
                d="M4.501 14.772V19.5H19.5v-4.728"
                stroke="#2F929A"
                strokeWidth={1.5}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgDownloadIcon = React.memo(SvgDownloadIcon);
export default MemoSvgDownloadIcon;
