import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { cn } from '@bem-react/classname';
import { ContentBlock, ContentState, EditorState, Modifier } from 'draft-js';
import { Map } from 'immutable';

import { ActionBlockTypes, CommonBlocks, EntityTypes, InlineStyles, ListTypes } from '../../types/shared.types';
import { addNewBlockAt, handlePastedText } from '../../utils';
import { ActionBlockOption } from '../ActionBlock';
import { BlockOption } from '../BlockOption';
import { InlineOption } from '../InlineOption';
import { insertWordDecorator, InsertWordProvider } from '../InsertWord';
import { latexDecorator, LatexOption } from '../Latex';
import { linkDecorator, LinkOption } from '../Link';
import { OptionsGroupWrapper } from '../OptionsGroupWrapper';

import { customRenderMap } from './CustomEditor.constants';
import { ICustomEditorProps } from './CustomEditor.types';
import { getBlockRenderer } from './getBlockRenderer';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'katex/dist/katex.min.css';
import './CustomEditor.scss';

const CnCustomEditor = cn('editor');

export const CustomEditor: React.FC<ICustomEditorProps> = ({
    editorState,
    setEditorState,
    toolbarHidden = false,
    isError = false,
    insertWords = false,
    insertWordContainerRef,
    savePastedStyles = false,
    disableActionBlocks = false,
    needLimitHeight,
    ...props
}) => {
    const [editorRect, setEditorRect] = useState<DOMRect | undefined>(undefined);

    const decorators = useMemo(
        () => [latexDecorator, linkDecorator].concat(insertWords ? [insertWordDecorator] : []),
        [insertWords],
    );

    const ref = useRef<HTMLDivElement>(null);

    const handleRect = useCallback(() => {
        if (!ref.current) {
            return;
        }
        setEditorRect(ref.current.getBoundingClientRect());
    }, []);

    useEffect(() => {
        handleRect();

        document.addEventListener('resize', handleRect, { passive: true });
        document.addEventListener('scroll', handleRect, { passive: true });

        return () => {
            document.removeEventListener('resize', handleRect);
            document.removeEventListener('scroll', handleRect);
        };
    }, [handleRect]);

    useEffect(() => {
        handleRect();
    }, [handleRect, editorState]);

    const onTextPaste = useCallback(
        (text: string, html: string, editorState: EditorState, onChange: (state: EditorState) => void) =>
            handlePastedText(text, html, editorState, onChange, savePastedStyles),
        [savePastedStyles],
    );

    const handleEditorStateChange = useCallback(
        (newState: EditorState) => {
            setEditorState((state) => {
                const blockKey = state.getSelection().getAnchorKey();
                const currentBlockMap = state.getCurrentContent().getBlockMap();
                const currentBlock = currentBlockMap.get(blockKey);

                const newBlockKey = newState.getSelection().getAnchorKey();
                const newContent = newState.getCurrentContent();
                const newBlockMap = newContent.getBlockMap();
                const newBlock = newBlockMap.get(newBlockKey);

                const changedBlock = newBlockMap.get(blockKey);

                if (
                    currentBlock !== newBlock &&
                    Object.prototype.hasOwnProperty.call(ActionBlockTypes, currentBlock.getType()) &&
                    currentBlock.getType() === newBlock.getType() &&
                    newBlockMap.count() > currentBlockMap.count() &&
                    changedBlock.getText().length === 0
                ) {
                    const newMap = newBlockMap.update(blockKey, (block) => {
                        return new ContentBlock({
                            key: blockKey,
                            type:
                                currentBlock.getType() === ActionBlockTypes.CODE_BLOCK
                                    ? ActionBlockTypes.CODE_BLOCK
                                    : CommonBlocks.UNSTYLED,
                            text: block.getText(),
                            characterList: block.getCharacterList(),
                            depth: block.getDepth(),
                            data: block.getData(),
                        });
                    });

                    const content = newContent.merge({
                        blockMap: newMap,
                    }) as ContentState;

                    return EditorState.push(newState, content, 'split-block');
                }

                if (
                    changedBlock &&
                    changedBlock.getType() === ActionBlockTypes.CODE_BLOCK &&
                    changedBlock.getText().includes('\n')
                ) {
                    const textParts = changedBlock.getText().split('\n');

                    let newBlockState = newState;
                    textParts.slice(1).map((textLine) => {
                        newBlockState = addNewBlockAt(
                            newBlockState,
                            newBlockState.getSelection().getAnchorKey(),
                            ActionBlockTypes.CODE_BLOCK,
                            Map({}),
                            textLine,
                        );
                    });

                    const newBlockStateMap = newBlockState.getCurrentContent().getBlockMap();
                    const nreBlockContent = newBlockState.getCurrentContent();

                    const newBlockMap = newBlockStateMap.update(blockKey, (block) => {
                        return new ContentBlock({
                            key: blockKey,
                            type: block.getType(),
                            text: textParts[0],
                            depth: block.getDepth(),
                            data: block.getData(),
                        });
                    });

                    const content = nreBlockContent.merge({
                        blockMap: newBlockMap,
                    }) as ContentState;

                    return EditorState.push(newBlockState, content, 'split-block');
                }

                return newState;
            });
        },
        [setEditorState],
    );

    const TabPress = useCallback(
        (event: KeyboardEvent) => {
            const selection = editorState.getSelection();
            const blockKey = editorState.getSelection().getAnchorKey();
            const newContent = editorState.getCurrentContent();
            const newBlockMap = newContent.getBlockMap();
            const changedBlock = newBlockMap.get(blockKey);

            if (event.key === 'Tab' && changedBlock.getType() === ActionBlockTypes.CODE_BLOCK) {
                setEditorState(() => {
                    event.preventDefault();
                    let content: ContentState;

                    if (selection.getAnchorOffset() !== selection.getFocusOffset()) {
                        content = Modifier.replaceText(
                            newContent,
                            selection,
                            '    ',
                            editorState.getCurrentInlineStyle(),
                            undefined,
                        );
                    } else {
                        content = Modifier.insertText(
                            newContent,
                            selection,
                            '    ',
                            editorState.getCurrentInlineStyle(),
                            undefined,
                        );
                    }

                    return EditorState.push(editorState, content, 'insert-characters');
                });
            }
        },
        [editorState, setEditorState],
    );

    useEffect(() => {
        document.addEventListener('keydown', TabPress);
        return () => {
            document.removeEventListener('keydown', TabPress);
        };
    }, [TabPress]);

    return (
        <div ref={ref}>
            <Editor
                {...props}
                toolbarHidden={toolbarHidden && !insertWords}
                editorClassName={CnCustomEditor({ isError, needLimitHeight })}
                toolbarClassName={CnCustomEditor('toolbar', { toolbarHidden })}
                editorState={editorState}
                onEditorStateChange={handleEditorStateChange}
                toolbar={{
                    options: [],
                }}
                customDecorators={decorators}
                customBlockRenderFunc={getBlockRenderer()}
                blockRenderMap={customRenderMap}
                handlePastedText={onTextPaste}
                toolbarCustomButtons={[
                    <OptionsGroupWrapper key={'bold and italic'}>
                        <InlineOption
                            key={InlineStyles.BOLD}
                            type={InlineStyles.BOLD}
                            editorState={editorState}
                            onChange={setEditorState}
                        />
                        <InlineOption
                            key={InlineStyles.ITALIC}
                            type={InlineStyles.ITALIC}
                            editorState={editorState}
                            onChange={setEditorState}
                        />
                    </OptionsGroupWrapper>,
                    <OptionsGroupWrapper key={'underlined and strikethrough'}>
                        <InlineOption
                            key={InlineStyles.UNDERLINE}
                            type={InlineStyles.UNDERLINE}
                            editorState={editorState}
                            onChange={setEditorState}
                        />
                        <InlineOption
                            key={InlineStyles.STRIKETHROUGH}
                            type={InlineStyles.STRIKETHROUGH}
                            editorState={editorState}
                            onChange={setEditorState}
                        />
                    </OptionsGroupWrapper>,
                    ...(insertWords
                        ? []
                        : [
                              <OptionsGroupWrapper key={'additional-options'}>
                                  <LinkOption key={'link'} editorState={editorState} onChange={setEditorState} />
                                  <LatexOption key={'latex'} editorState={editorState} onChange={setEditorState} />
                                  {!disableActionBlocks && (
                                      <ActionBlockOption
                                          key={ActionBlockTypes.INFO_BLOCK}
                                          type={ActionBlockTypes.INFO_BLOCK}
                                          editorState={editorState}
                                          onChange={setEditorState}
                                      />
                                  )}
                                  {!disableActionBlocks && (
                                      <ActionBlockOption
                                          key={ActionBlockTypes.WARNING_BLOCK}
                                          type={ActionBlockTypes.WARNING_BLOCK}
                                          editorState={editorState}
                                          onChange={setEditorState}
                                      />
                                  )}
                                  {!disableActionBlocks && (
                                      <ActionBlockOption
                                          key={ActionBlockTypes.THEOREM_BLOCK}
                                          type={ActionBlockTypes.THEOREM_BLOCK}
                                          editorState={editorState}
                                          onChange={setEditorState}
                                      />
                                  )}
                                  {!disableActionBlocks && (
                                      <ActionBlockOption
                                          key={ActionBlockTypes.QUOTE_BLOCK}
                                          type={ActionBlockTypes.QUOTE_BLOCK}
                                          editorState={editorState}
                                          onChange={setEditorState}
                                      />
                                  )}
                                  {!disableActionBlocks && (
                                      <ActionBlockOption
                                          key={ActionBlockTypes.DEFINITION_BLOCK}
                                          type={ActionBlockTypes.DEFINITION_BLOCK}
                                          editorState={editorState}
                                          onChange={setEditorState}
                                      />
                                  )}
                                  {!disableActionBlocks && (
                                      <ActionBlockOption
                                          key={ActionBlockTypes.CODE_BLOCK}
                                          type={ActionBlockTypes.CODE_BLOCK}
                                          editorState={editorState}
                                          onChange={setEditorState}
                                      />
                                  )}
                              </OptionsGroupWrapper>,
                              <OptionsGroupWrapper key={'list'}>
                                  <BlockOption
                                      key={ListTypes.UNORDERED_LIST_ITEM}
                                      type={ListTypes.UNORDERED_LIST_ITEM}
                                      editorState={editorState}
                                      onChange={setEditorState}
                                  />
                                  <BlockOption
                                      key={ListTypes.ORDERED_LIST_ITEM}
                                      type={ListTypes.ORDERED_LIST_ITEM}
                                      editorState={editorState}
                                      onChange={setEditorState}
                                  />
                              </OptionsGroupWrapper>,
                          ]),
                    insertWords ? (
                        <InsertWordProvider
                            key={EntityTypes.INSERT_WORD}
                            editorState={editorState}
                            onChange={setEditorState}
                            editorRect={editorRect}
                            containerRef={insertWordContainerRef ?? ref}
                        />
                    ) : (
                        <></>
                    ),
                ]}
            />
        </div>
    );
};
