import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgUserCircle({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={50}
            height={50}
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M10.834 21c5.522 0 10-4.477 10-10s-4.478-10-10-10c-5.523 0-10 4.477-10 10s4.477 10 10 10z"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeMiterlimit={10}
            />
            <path
                d="M10.834 14.333a4.167 4.167 0 100-8.333 4.167 4.167 0 000 8.333z"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeMiterlimit={10}
            />
            <path
                d="M4.146 18.435a7.503 7.503 0 0113.375 0"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgUserCircle = React.memo(SvgUserCircle);
export default MemoSvgUserCircle;
