export const getQuestionParts = (question: string): string[] => {
    const INSERT_WORD_REGEX = /(<p>).*(<\/p>)/gm;

    const result: string[] = [];
    let lastIndex = 0;

    const getQuestion = (text: string): string[] => {
        const questionPartExec = INSERT_WORD_REGEX.exec(text);

        if (questionPartExec !== null) {
            if (result.length === 0 && questionPartExec.index > 0) {
                result.push(`${question.slice(0, questionPartExec.index)}</br>`);
            }

            lastIndex = questionPartExec.index + questionPartExec[0].length;

            result.push(`${questionPartExec[0].slice(3, questionPartExec[0].length - 4)}</br>`);
            return getQuestion(text);
        }

        if (lastIndex > 0 && lastIndex !== question.length - 1) {
            result.push(`${question.slice(lastIndex, question.length)}`.replace('<p>', ''));
        }

        return result;
    };

    return getQuestion(question);
};
