import React, { useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { ClasscardUser, Notebook, Pencil } from '@lms-elements/icons';
import { checkOverdue } from '@lms-elements/utils';

import { Button, ButtonViewEnum } from '../../../Button';
import { StatusBar } from '../../../StatusBar';

import { IClasscardTeacherProps } from './ClasscardTeacher.types';

const CnClassCard = cn('classcard');
const CnCourseCard = cn('courseCard');

const ClasscardTeacher: React.FC<IClasscardTeacherProps> = ({
    createTasksLabel,
    handleEditButtonClick,
    hwCount,
    hwDeadline,
    isCompleted,
    isEditable,
    isEmpty,
    isMetodist,
    nextLessonDate,
    onClasscardClick,
    studentsLabel,
    themeLabel,
    title,
    parallels,
}): JSX.Element => {
    const isOverdue = useMemo(() => (isEmpty ? false : checkOverdue(hwDeadline)), [isEmpty, hwDeadline]);

    return (
        <div className={CnClassCard({ isOverdue: !isEmpty && isOverdue })} onClick={onClasscardClick}>
            <div className={CnClassCard('title')}>{title}</div>
            <div className={CnClassCard('parallels')}>
                <span className={CnClassCard('parallels-title')}>Параллель:</span>
                <div className={CnClassCard('parallel')}>{parallels}</div>
            </div>
            <div className={CnClassCard('info')}>
                <div className={CnClassCard('students')}>
                    <span className={CnClassCard('students-icon')}>
                        <ClasscardUser />
                    </span>
                    <span className={CnClassCard('students-label')}>{studentsLabel}</span>
                </div>

                <div className={CnClassCard('lessons')}>
                    <span className={CnClassCard('lessons-icon')}>
                        <Notebook />
                    </span>
                    <span className={CnClassCard('lessons-label')}>{themeLabel}</span>
                </div>
            </div>

            <StatusBar
                isCompleted={isCompleted}
                tasks={hwCount}
                overdue={isOverdue}
                nextLessonDate={nextLessonDate}
                createTasksLabel={createTasksLabel}
            />

            {isEditable && isMetodist && (
                <div className={CnClassCard('edit')}>
                    <Button
                        icon={<Pencil width="24px" height="24px" />}
                        view={ButtonViewEnum.action}
                        size="l"
                        onClick={handleEditButtonClick}
                        customClasses={CnCourseCard('button')}
                    >
                        <span className={CnCourseCard('button-text')}>Редактировать</span>
                    </Button>
                </div>
            )}
        </div>
    );
};

export default React.memo(ClasscardTeacher);
