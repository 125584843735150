import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgXIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M23.001 23.144L8.859 9M23 23.143L8.858 9M8.857 23.143L22.999 9"
                stroke="#252232"
                strokeWidth={1.5}
                strokeLinecap="round"
            />
        </svg>
    );
}

const MemoSvgXIcon = React.memo(SvgXIcon);
export default MemoSvgXIcon;
