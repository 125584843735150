import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgMicDisabled({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={19}
            height={24}
            viewBox="0 0 19 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M16.501 12.453a7.313 7.313 0 01-.458 1.863M5.701 2.942A4.088 4.088 0 019.183 1h0a4.091 4.091 0 014.09 4.09v6.181M1 2.635l16.364 18M9.182 19v3.273M11.932 14.662a4.076 4.076 0 01-2.751 1.063h0a4.09 4.09 0 01-4.091-4.09v-4.5M14.135 17.084a7.365 7.365 0 01-12.272-4.63"
                stroke="#C0C2C9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoSvgMicDisabled = React.memo(SvgMicDisabled);
export default MemoSvgMicDisabled;
