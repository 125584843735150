import React, { CSSProperties, useCallback, useMemo } from 'react';
import { cn } from '@bem-react/classname';

import { ITabletProps } from './Tablets.types';

import './Tablets.scss';

const CnTablet = cn('tablet');

export const Tablets: React.FC<ITabletProps> = ({
    titles,
    activeElement,
    setActiveElement,
    isForThemeCrete = false,
    isResponsive,
}) => {
    const makeHandlerClickTablet = useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            const { title } = event.currentTarget.dataset as { title: string };
            setActiveElement(title);
        },
        [setActiveElement],
    );

    const styles: CSSProperties | undefined = useMemo(() => {
        if (isResponsive) {
            return {
                width: `calc(100% / ${titles.length})`,
            };
        }
    }, [isResponsive, titles.length]);

    return (
        <div className={CnTablet()}>
            {titles.map((item, index) => (
                <div
                    key={index}
                    data-title={item}
                    className={CnTablet('block', { checked: item === activeElement, isForThemeCrete })}
                    onClick={makeHandlerClickTablet}
                    style={styles}
                >
                    {item}
                </div>
            ))}
        </div>
    );
};
