import React from 'react';
import { useDrag } from 'react-dnd';
import { CodifierElement, CodifierElementIconEnum } from '@lms-elements/atomic';

import { MINIMIZED_DESC, MINIMIZED_TITLE } from './CONSTANTS';
import { IMinimizedDocumentMaterialProps, MIN_DOC_MATERIAL_DRAG_TYPE } from './MinimizedDocumentMaterial.types';

const MinimizedDocumentMaterial: React.FC<IMinimizedDocumentMaterialProps> = React.memo(
    ({ onDragEnd, disabled = false }) => {
        const [{ isDragging }, drag] = useDrag({
            type: MIN_DOC_MATERIAL_DRAG_TYPE,
            item: () => ({ name: 'DOCUMENT_MATERIAL' }),
            end: (item, monitor) => {
                const dropResult = monitor.getDropResult();
                if (item && dropResult && onDragEnd) {
                    onDragEnd();
                }
            },
            collect: (monitor) => ({ isDragging: monitor.isDragging() }),
        });

        return (
            <CodifierElement
                title={MINIMIZED_TITLE}
                description={MINIMIZED_DESC}
                icon={CodifierElementIconEnum.document}
                dragging={isDragging}
                dragRef={drag}
                disabled={disabled}
            />
        );
    },
);

export default MinimizedDocumentMaterial;
