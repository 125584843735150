import React, { useCallback, useMemo } from 'react';

import { ClasscardCourseCreator } from './components/ClasscardCourseCreator/ClasscardCourseCreator';
import ClasscardOLStudent from './components/ClasscardOLStudent/ClasscardOLStudent';
import { ClasscardStudent } from './components/ClasscardStudent/ClasscardStudent';
import ClasscardTeacher from './components/ClasscardTeacher/ClasscardTeacher';
import { IClasscardProps } from './Classcard.types';

import './Classcard.scss';

export const Classcard: React.FC<IClasscardProps> = (props: IClasscardProps): JSX.Element => {
    const {
        canCreateCourse = false,
        canEdit = false,
        courseType = '',
        createLessonsLabel,
        createStudentsLabel,
        createTasksLabel,
        createThemeLabel,
        hwCount = 0,
        hwDeadline = '',
        nextLessonDate = '',
        isCompleted,
        isMetodist = false,
        isStudent = false,
        isOLStudent = false,
        isSubscribed = true,
        lessonsCount = 0,
        name = '',
        onEdit,
        onClasscardClick,
        progressesOverdue = 0,
        studentsCount = 0,
        teacher = '',
        themesCount = 0,
        parallels,
        onTeacherClick,
    } = props;

    const isEmpty = useMemo(() => themesCount === undefined || themesCount <= 0, [themesCount]);

    const teacherText = useMemo(() => (teacher ? `уч. ${teacher}` : ''), [teacher]);
    const isEditable = useMemo(() => canEdit && !isEmpty, [canEdit, isEmpty]);
    const title = useMemo(() => (canEdit ? `${courseType} - ${name}` : `${name}`), [canEdit, courseType, name]);

    const handleEditButtonClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            if (onEdit) {
                onEdit();
            }
        },
        [onEdit],
    );

    const handlerTeacherClick = useCallback(
        (ctrl: boolean) => {
            onTeacherClick?.(ctrl);
        },
        [onTeacherClick],
    );

    const themeLabel = useMemo(
        () => (createThemeLabel ? createThemeLabel(themesCount ?? 0) : `${themesCount ?? 0} тем`),
        [createThemeLabel, themesCount],
    );
    const studentsLabel = useMemo(
        () => (createStudentsLabel ? createStudentsLabel(studentsCount) : `${studentsCount} учеников`),
        [createStudentsLabel, studentsCount],
    );
    const lessonsLabel = useMemo(
        () => (createLessonsLabel ? createLessonsLabel(lessonsCount) : `${lessonsCount} уроков`),
        [createLessonsLabel, lessonsCount],
    );

    if (canCreateCourse) {
        return (
            <ClasscardCourseCreator
                courseType={courseType}
                handleEditButtonClick={handleEditButtonClick}
                isEmpty={isEmpty}
                isMetodist={isMetodist}
                name={name}
                onClasscardClick={onClasscardClick}
                studentsCount={studentsCount}
                studentsLabel={studentsLabel}
                teacher={teacher}
                teacherText={teacherText}
                themesCount={themesCount}
                themeLabel={themeLabel}
                onTeacherClick={handlerTeacherClick}
            />
        );
    }

    if (isOLStudent) {
        return (
            <ClasscardOLStudent
                courseType={courseType}
                lessonsLabel={lessonsLabel}
                name={name}
                isSubscribed={isSubscribed}
                onClasscardClick={onClasscardClick}
                themeLabel={themeLabel}
            />
        );
    }

    return isStudent ? (
        <ClasscardStudent
            createTasksLabel={createTasksLabel}
            hwCount={hwCount}
            isCompleted={isCompleted}
            name={name}
            onClasscardClick={onClasscardClick}
            progressesOverdue={progressesOverdue}
            teacherText={teacherText}
            onTeacherClick={handlerTeacherClick}
        />
    ) : (
        <ClasscardTeacher
            createTasksLabel={createTasksLabel}
            handleEditButtonClick={handleEditButtonClick}
            hwCount={hwCount}
            hwDeadline={hwDeadline}
            isCompleted={isCompleted}
            isEditable={isEditable}
            isEmpty={isEmpty}
            isMetodist={isMetodist}
            nextLessonDate={nextLessonDate}
            onClasscardClick={onClasscardClick}
            studentsLabel={studentsLabel}
            themeLabel={themeLabel}
            title={title}
            parallels={parallels}
        />
    );
};
