import React, { useCallback, useMemo, useState } from 'react';
import { cn } from '@bem-react/classname';
import { LittleArrow } from '@lms-elements/icons';
import { getCalendarDropdownDays, getCapitalizeMonthAndYear, getWeekdayNames, isInactive } from '@lms-elements/utils';

import { DatepickerDay } from '../DatepickerDay';
import { Timepicker } from '../Timepicker';

import { IDateTimePickerProps } from './DateTimePicker.types';

import './DateTimePicker.scss';

const CnDateTimePicker = cn('dateTimePicker');

export const DateTimePicker = React.forwardRef<HTMLDivElement, IDateTimePickerProps>(
    (
        {
            showTimePicker,
            showYears,
            month,
            year,
            selectedTime,
            selectedDay,
            startTime,
            endTime,
            stepMinutes,
            inactiveDays,
            onDateSelect,
            onTimeChange,
            timePickerPosition,
        },
        ref,
    ) => {
        const [monthState, setMonthState] = useState(month);
        const [yearState, setYearState] = useState(year);
        const weekdayNames = useMemo(() => getWeekdayNames(), []);
        const title = useMemo(() => getCapitalizeMonthAndYear([monthState, yearState]), [monthState, yearState]);
        const days = useMemo(() => getCalendarDropdownDays(monthState, yearState), [monthState, yearState]);

        const handlePrevBtnClick = useCallback(() => {
            setMonthState((value) => {
                if (value === 0) {
                    setYearState((year) => year - 1);
                    return 11;
                }
                return value - 1;
            });
        }, []);

        const handleNextBtnClick = useCallback(() => {
            setMonthState((value) => {
                if (value === 11) {
                    setYearState((year) => year + 1);
                    return 0;
                }
                return value + 1;
            });
        }, []);

        const handleDayClick = useCallback(
            (date: string) => {
                if (onDateSelect) {
                    onDateSelect(date);
                }
            },
            [onDateSelect],
        );

        const handleTimeClick = useCallback(
            (value: string) => {
                if (onTimeChange) {
                    onTimeChange(value);
                }
                if (showYears) {
                    setYearState(Number(value));
                }
            },
            [onTimeChange, showYears],
        );

        return (
            <div ref={ref} className={CnDateTimePicker({ timePickerPosition })}>
                <div className={CnDateTimePicker('datepickerContainer')}>
                    <div className={CnDateTimePicker('monthSelect')}>
                        <div className={CnDateTimePicker('monthTitle')}>{title}</div>
                        <div className={CnDateTimePicker('actions')}>
                            <button
                                type="button"
                                className={CnDateTimePicker('button', { button: 'prev' })}
                                onClick={handlePrevBtnClick}
                            >
                                <LittleArrow />
                            </button>
                            <button
                                type="button"
                                className={CnDateTimePicker('button', { button: 'next' })}
                                onClick={handleNextBtnClick}
                            >
                                <LittleArrow />
                            </button>
                        </div>
                    </div>
                    <div className={CnDateTimePicker('monthDays')}>
                        <div className={CnDateTimePicker('weekdays')}>
                            {weekdayNames.map((weekday) => (
                                <div key={weekday} className={CnDateTimePicker('weekdayName')}>
                                    {weekday}
                                </div>
                            ))}
                        </div>
                        <div className={CnDateTimePicker('days')}>
                            {days.map((day) => (
                                <DatepickerDay
                                    key={day.date}
                                    {...day}
                                    isSelected={day.date === selectedDay}
                                    inactive={isInactive(day.date, inactiveDays)}
                                    onDayClick={handleDayClick}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                {(showTimePicker || showYears) && (
                    <div className={CnDateTimePicker('timepicker', { timePickerPosition })}>
                        <Timepicker
                            startTime={startTime}
                            endTime={endTime}
                            stepMinutes={stepMinutes}
                            time={selectedTime}
                            onTimeClick={handleTimeClick}
                            disabled={!selectedDay && !showYears}
                            showYears={showYears}
                            timePickerPosition={timePickerPosition}
                        />
                    </div>
                )}
            </div>
        );
    },
);
