import React, { ReactElement } from 'react';
import { ClockIcon, DueIllnessIcon, WasAbsentIcon, WithReasonIcon } from '@lms-elements/icons';

import { StatusLabelEnum } from './Dropdown.types';

export const statusIcon = (status: StatusLabelEnum): ReactElement => {
    switch (status) {
        case StatusLabelEnum.illness:
            return <DueIllnessIcon />;
        case StatusLabelEnum.wasAbsent:
            return <WasAbsentIcon />;
        case StatusLabelEnum.wasInClass:
            return <ClockIcon />;
        case StatusLabelEnum.seriousReason:
            return <WithReasonIcon />;
        default:
            return <ClockIcon />;
    }
};
