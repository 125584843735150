import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgBoldTextIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M11.31 24a.53.53 0 01-.357-.126.53.53 0 01-.126-.357V9.804c0-.154.042-.273.126-.357a.486.486 0 01.357-.147h5.754c1.498 0 2.625.357 3.381 1.071.756.714 1.134 1.652 1.134 2.814 0 .784-.182 1.435-.546 1.953-.35.518-.77.896-1.26 1.134.588.294 1.085.749 1.491 1.365.406.616.609 1.323.609 2.121 0 .784-.182 1.498-.546 2.142-.35.644-.875 1.155-1.575 1.533-.7.378-1.54.567-2.52.567H11.31zm5.439-8.484c.882 0 1.554-.203 2.016-.609.476-.406.714-.973.714-1.701 0-.714-.231-1.26-.693-1.638-.462-.378-1.141-.567-2.037-.567h-3.864v4.515h3.864zm.21 6.783c.882 0 1.568-.238 2.058-.714.504-.476.756-1.085.756-1.827 0-.77-.252-1.386-.756-1.848-.49-.476-1.176-.714-2.058-.714h-4.074v5.103h4.074z"
                fill="#252232"
            />
        </svg>
    );
}

const MemoSvgBoldTextIcon = React.memo(SvgBoldTextIcon);
export default MemoSvgBoldTextIcon;
