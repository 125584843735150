import { RefObject, useCallback, useState } from 'react';

/**
 *
 * @param element listening element
 * @returns:
 * @scrollTop the amount of scrolling up;
 * @scrollLeft the amount of scrolling left;
 * @handleContentScroll onScroll event listener;
 */
export const useGetOnScrollHandler = (
    element: RefObject<HTMLDivElement>,
): {
    scrollTop: number;
    scrollLeft: number;
    handleContentScroll: () => void;
} => {
    const [scrollTop, setScrollTop] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const handleContentScroll = useCallback(() => {
        const scheduleElement = element;
        const top = scheduleElement.current?.scrollTop ? scheduleElement.current?.scrollTop : 0;
        const left = scheduleElement.current?.scrollLeft ? scheduleElement.current?.scrollLeft : 0;
        setScrollLeft(left);
        setScrollTop(top);
    }, [element]);

    return {
        scrollTop,
        scrollLeft,
        handleContentScroll,
    };
};
