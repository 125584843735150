import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgHeaderIcon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={26}
            height={21}
            viewBox="0 0 26 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M17 4h8M17 9h8M1 14h24M1 19h24"
                stroke="#0088E9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.224 11a.278.278 0 01-.204-.084.278.278 0 01-.084-.204V2.9a.32.32 0 01.072-.216.292.292 0 01.216-.084h1.104c.088 0 .16.028.216.084a.292.292 0 01.084.216v3.084h3.504V2.9c0-.088.028-.16.084-.216A.278.278 0 016.42 2.6h1.116c.088 0 .156.028.204.084a.292.292 0 01.084.216v7.812c0 .08-.028.148-.084.204a.254.254 0 01-.204.084H6.42a.278.278 0 01-.204-.084.278.278 0 01-.084-.204V7.508H2.628v3.204c0 .08-.028.148-.084.204a.292.292 0 01-.216.084H1.224zm10.866 0a.278.278 0 01-.204-.084.278.278 0 01-.084-.204V4.52L9.99 5.912a.272.272 0 01-.18.06.264.264 0 01-.228-.12l-.516-.672a.288.288 0 01-.06-.168c0-.096.044-.176.132-.24l2.688-2.088a.547.547 0 01.3-.084h1.08c.08 0 .148.028.204.084a.278.278 0 01.084.204v7.824c0 .08-.028.148-.084.204a.278.278 0 01-.204.084H12.09z"
                fill="#0088E9"
            />
        </svg>
    );
}

const MemoSvgHeaderIcon = React.memo(SvgHeaderIcon);
export default MemoSvgHeaderIcon;
