import React, { useCallback } from 'react';
import { cn } from '@bem-react/classname';
import { LittleArrow } from '@lms-elements/icons';

import { MinimizedEducationPlanProps } from './MinimizedEducationPlan.types';

import './MinimizedEducationPlan.scss';

const CnMinimizedEducationPlan = cn('minimized-education-plan');

export const MinimizedEducationPlan = React.memo<MinimizedEducationPlanProps>(
    ({ plan, year, parallel, onClick, id }) => {
        const handleClick = useCallback(() => {
            onClick(id);
        }, [onClick, id]);

        return (
            <div className={CnMinimizedEducationPlan()} onClick={handleClick}>
                <div className={CnMinimizedEducationPlan('firstLine')}>
                    <div className={CnMinimizedEducationPlan('firstLine-icon')}>
                        <LittleArrow />
                    </div>
                    <div className={CnMinimizedEducationPlan('firstLine-plan')}>{plan}</div>
                    <div className={CnMinimizedEducationPlan('firstLine-year')}>{year}</div>
                </div>
                <div className={CnMinimizedEducationPlan('parallel')}>{parallel}</div>
            </div>
        );
    },
);
