import React, { useCallback } from 'react';

export const useResizeTextarea: () => {
    handleTextareaInput: (event: React.FormEvent<HTMLTextAreaElement>) => void;
    handleTextareaRender: (textareaElement: HTMLTextAreaElement | null) => void;
    handleTextareaResize: (textareaElement: HTMLTextAreaElement | null) => void;
} = () => {
    const updateTextareaHeight = useCallback((textareaElement: HTMLTextAreaElement | null) => {
        if (textareaElement) {
            textareaElement.style.height = `auto`;
            textareaElement.style.height = `${textareaElement.scrollHeight}px`;
        }
    }, []);

    const handleTextareaInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const element = event.currentTarget;
            updateTextareaHeight(element);
        },
        [updateTextareaHeight],
    );

    return {
        handleTextareaInput,
        handleTextareaRender: updateTextareaHeight,
        handleTextareaResize: updateTextareaHeight,
    };
};
