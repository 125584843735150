import moment from 'moment';

export const getYearArray = (): string[] => {
    const start = moment(new Date());
    const resultArray: string[] = [];

    for (let i = 0; i < 100; i = i + 1) {
        const newDate = moment(start).subtract(i, 'y').format('YYYY');
        resultArray.push(newDate);
    }

    return resultArray;
};
