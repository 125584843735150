import React from 'react';
import { cn } from '@bem-react/classname';

import { IReturnButtonProps } from './ReturnButton.types';

import './ReturnButton.scss';

const CnClassReturnButton = cn('return-button');
const CnReturnButtonContainer = cn('return-button-container');

export const ReturnButton: React.FC<IReturnButtonProps> = ({ text = 'Назад', onClick }) => {
    return (
        <div className={CnReturnButtonContainer()} onClick={onClick}>
            <button type="button" className={CnClassReturnButton()}>
                <div className={CnClassReturnButton('text')}>{text}</div>
            </button>
        </div>
    );
};
